import React, {useState, useEffect, useRef} from "react"
import styled from "styled-components"
import {useSpring, animated, config} from 'react-spring'
import {screenMinWidth} from "../mixins/breakpoints.js"

const StyledTooltipWrapper = styled.div`
    position: relative;
    z-index: 1;
`

const StyledTooltipIconWrapper = styled.div`
    width: 1rem;
    height: auto;
    color:  white;
`

const StyledTooltipInner = styled(animated.div)`
    position: absolute;
    right: 0;
    transform: translateY(-100%);
    top: 0;
    width: 18rem;
    max-width: 100vw;

    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    border: 0.1rem solid #E8E8E8;
    border-radius: 0.5rem;
    background-color: white;
    color: black;
    cursor: pointer;
    word-break: keep-all;

    z-index: 100;

    ${screenMinWidth.sm`
        width: 20rem;
    `}
`

const StyledTooltipColumn = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledTooltipRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`

const StyledTooltipTitle = styled.div`
    font-weight: bold;
    text-align: left;
    margin-bottom: 0.5rem;
`

const StyledTooltipSubtitle = styled.div`
    text-align: left;
`

const StyledTooltipTitleIconWrapper = styled.div`
    margin-right: 0.5rem;
    width: auto;
    height: 1rem;
`

export const Tooltip = ({ title, subtitle, Icon = null, TitleIcon = null, onClick = () => {}}) => {
    const toolTipRef = useRef(null)
    const [isHovered, setIsHovered] = useState(false)
    const [isClicked, setIsClicked] = useState(false)

    const handleOnClickInsideTooltip = e => {
        setIsClicked(true)
        onClick(e)
    }

    const handleOnClickOutsideTooltip = e => {
        if (toolTipRef && !(toolTipRef?.current?.contains(e?.target))) {
            setIsClicked(false)
        }
    }

    useEffect(() => {
        return document.addEventListener("mousedown", handleOnClickOutsideTooltip)
    }, [])

    const tooltipSpring = useSpring({
        to: { 
            opacity: isHovered || isClicked ? 1 : 0, 
            display: isHovered || isClicked ? "block" : "none", 
            transform: isHovered || isClicked ? "translateY(-110%)" : "translateY(-108%)"
        },
        config: config.wobbly,
    })

    return (
        <StyledTooltipWrapper 
            ref={toolTipRef} 
            onClick={handleOnClickInsideTooltip} 
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)}
        >
            { Icon && <StyledTooltipIconWrapper><Icon /></StyledTooltipIconWrapper> }
            <StyledTooltipInner style={tooltipSpring} >
                <StyledTooltipColumn>
                    <StyledTooltipRow>
                        { TitleIcon && <StyledTooltipTitleIconWrapper><TitleIcon /></StyledTooltipTitleIconWrapper> }
                        <StyledTooltipTitle>{ title }</StyledTooltipTitle>
                    </StyledTooltipRow>
                    <StyledTooltipRow>
                        <StyledTooltipSubtitle>{ subtitle }</StyledTooltipSubtitle>
                    </StyledTooltipRow>
                </StyledTooltipColumn>
            </StyledTooltipInner>
        </StyledTooltipWrapper>
    )
}
