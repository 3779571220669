import React from "react"
import styled from "styled-components"
import {useSpring, animated, config} from 'react-spring'

const StyledDropdown = styled.div`
    position: absolute;
    left: ${props => props.anchor === "left" ? 0 : "unset"};
    right: ${props => props.anchor === "left" ? "unset" : 0};
    top: 22px;
    width: 10rem;
    z-index: 1;
`

const StyledDropDownInner = styled(animated.div)`
    position: relative;
    width: 100%;

    background-color: white;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 1rem;
    box-sizing: border-box;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
`

const StyledDropDownItem = styled.div`
    position: relative;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    a {
        text-decoration: none;
        color: #6a6a6a;
        -webkit-appearance: none;
        -moz-appearance: none;

        transition: color 0.2s;

        &:hover {
            color: var(--text-secondary);
        }
    }
`

export const DropDown = ({ isOpen, style = {}, options = [], anchor = "left", ...props}) => {
    const dropDownSpring = useSpring({
        to: { 
            opacity: isOpen ? 1 : 0, 
            width: "100%", 
            display: isOpen ? "block" : "none", 
            transform: isOpen ? "translateY(0%)" : "translateY(2%)"
        },
        config: config.wobbly,
    })

    return (
        <StyledDropdown style={style} anchor={anchor}>
            <StyledDropDownInner {...props} style={dropDownSpring}>
                {options.map(o => <StyledDropDownItem>{o}</StyledDropDownItem>)}
            </StyledDropDownInner>
        </StyledDropdown>
    )
}