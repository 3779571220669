import React, {useState, useEffect} from "react"
import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import { Transaction } from "../../transaction.js"

import { useDoesAccountHaveUSDCSupport } from "../../../../hooks/use-does-account-have-usdc-support.js"

import {
  template as usdcSetupTemplate,
  CODE as usdcSetupCode,
  HASH as usdcSetupHash,
  DESCRIPTION as usdcSetupDescription,
} from "@onflow/six-usdc-setup"

import { TRANSACTION_MANIFEST_VALUE } from "../../transaction-manifest-constructors.js"

export const Manifest = ({ 
  qp,
  hash,
  txn,
  showCode,
  consent,
  qpString,
  submitTransaction,
  submitTransactionLocked,
  currentUserAddr,
  hiddenArgs,
  lockedArgs,
}) => {
  const [currentUser, setCurrentUser] = useState(null)

  const [manifestValue, setManifestValue] = useState(new TRANSACTION_MANIFEST_VALUE({
    hash: usdcSetupHash,
    title: "Transaction - USDC Setup",
    description: usdcSetupDescription,
    code: usdcSetupCode,
    args: {},
    isSix: true,
    transactionFunction: () => {}
  }))

  useEffect(() => fcl.currentUser().subscribe(setCurrentUser), [])

  const isLedgerUser = currentUser?.services[0]?.uid === "fcl-ledger-authz"

  const { doesAccountHaveUSDCSupport } = useDoesAccountHaveUSDCSupport(currentUserAddr)

  const computeManifestValue = async () => {
    setManifestValue(
      new TRANSACTION_MANIFEST_VALUE({
        hash: usdcSetupHash,
        disclaimer: null,
        error: doesAccountHaveUSDCSupport ? (
          <span>Your account is already configured for USDC.</span>
        ) : (isLedgerUser ? <span>This transaction is not available for Ledger users at this time. Ledger support coming soon.</span> : null),
        title: "Transaction - USDC Setup",
        description: usdcSetupDescription,
        code: usdcSetupCode,
        args: {},
        isSix: true,
        transactionFunction: async (hash, txn, args) => {
          return await fcl.decode(
            await fcl.send([
              usdcSetupTemplate({
                proposer: fcl.currentUser().authorization,
                authorization: fcl.currentUser().authorization,
                payer: fcl.currentUser().authorization,
              }),
              fcl.limit(1000),
            ])
          )
        },
      })
    )
  }

  useEffect(() => {
    computeManifestValue()
  }, [qpString, doesAccountHaveUSDCSupport])

  return (
    <Transaction 
      title={manifestValue?.title}
      description={manifestValue?.description}
      disclaimer={manifestValue?.disclaimer}
      error={manifestValue?.error}
      code={manifestValue?.code}
      isSix={manifestValue?.isSix}
      args={manifestValue?.args}
      transactionFunction={manifestValue?.transactionFunction}
      submitTransaction={submitTransaction}
      submitTransactionLocked={submitTransactionLocked}
      consent={consent}
      showCode={showCode}
      hash={hash}
      txn={txn}
      qp={qp}
    />
  )
}
