import React from "react"
import styled from "styled-components"
import {Link} from "react-router-dom"
import { HASH as withdrawUnlockedFlowHash } from "@onflow/six-withdraw-unlocked-flow"

const StyledAccountUserBalanceItem = styled.div`
    position: relative;
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: black;
    box-sizing: border-box;
    flex-wrap: wrap;
`

const StyledAccountUserClaimableBalanceItem = styled(StyledAccountUserBalanceItem)`
    padding-bottom: 0.5rem; 
    padding-left:  2rem;
` 

const StyledAccountUserTotalBalanceItem = styled(StyledAccountUserBalanceItem)`
    padding-top: 0.5rem; 
    border-top: 2px solid black;
` 

const StyledAccountUserBalanceItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
`

const StyledAccountUserBalanceItemAction = styled(Link)`
    color: #3745f5;
    border: 1px solid #3745f5;
    text-decoration: none;
    cursor: pointer;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.75rem;
    line-height: 0.75rem;

    &:hover {
        background-color: #3745f5;
        color: white;
    }

    transition: background-color 0.2s, color 0.2s;
`

const StyledAccountUserBalanceItemTitle = styled.div`
    margin-right: 1rem;
    color: black;
`

const StyledAccountUserBalanceItemContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
`

const StyledAccountUserBalanceTotalWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0.5rem 0rem 0.5rem 0rem;
`

const StyledAccountUserBalanceQuantity = styled.div`
    margin-right: 0.5rem;
    color: ${({color}) => color ? color: "black"};
    font-size: 1.25rem;
`

const StyledAccountUserBalanceCurrency = styled.div`
    color: ${({color}) => color ? color: "black"};
    font-size: 0.75rem;
    font-weight: bold;
`

const StyledAccountUserBalancesActions = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
`

const StyledAccountUserBalancesAction = styled(Link)`
    margin-top: 1rem;
    margin-right: 1rem;
    border: 1px solid white;
    border-radius: 2rem;
    padding: 0.25rem 1rem 0.25rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    text-decoration: none;
    color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;

    transition: color 0.2s, background 0.2s, border 0.2s;

    svg {
        margin-right: 0.5rem;
        path {
            transition: fill 0.2s;
            fill: white;
        }
    }

    &:hover {
        color: black;
        border: 1px solid white;
        background: white;
        svg {
            margin-right: 0.5rem;
            path {
                fill: black;
            }
        }
    }
`

const StyledAccountUserBalancesLink = styled.a`
    margin-top: 1rem;
    margin-right: 1rem;
    border: 1px solid white;
    border-radius: 2rem;
    padding: 0.25rem 1rem 0.25rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    text-decoration: none;
    color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;

    transition: color 0.2s, background 0.2s, border 0.2s;

    svg {
        margin-right: 0.5rem;
        path {
            transition: fill 0.2s;
            fill: white;
        }
    }

    &:hover {
        color: black;
        border: 1px solid white;
        background: white;
        svg {
            margin-right: 0.5rem;
            path {
                fill: black;
            }
        }
    }
`

const BaseAccountFlowBalanceBreakdownItem = ({
    label = "",
    quantity = "",
    currency = "",
    link = "",
    linkLabel = "",
}) => (
    <>
        <StyledAccountUserBalanceItemWrapper>
            <StyledAccountUserBalanceItemTitle>{ label }</StyledAccountUserBalanceItemTitle>
            <StyledAccountUserBalanceTotalWrapper>
                <StyledAccountUserBalanceQuantity>
                    { quantity }
                </StyledAccountUserBalanceQuantity>
                <StyledAccountUserBalanceCurrency>
                    { currency }
                </StyledAccountUserBalanceCurrency>
            </StyledAccountUserBalanceTotalWrapper>
        </StyledAccountUserBalanceItemWrapper>
        {link && linkLabel && 
            <StyledAccountUserBalanceItemWrapper>
                <StyledAccountUserBalanceItemAction to={link}>{linkLabel}</StyledAccountUserBalanceItemAction>
            </StyledAccountUserBalanceItemWrapper>
        }
    </>
)

export const AccountFlowBalanceBreakdownItem = (props) => (
    <StyledAccountUserBalanceItem>
        <BaseAccountFlowBalanceBreakdownItem {...props} />
    </StyledAccountUserBalanceItem>
)

export const AccountUserClaimableBalanceItem = (props) => (
    <StyledAccountUserClaimableBalanceItem>
        <BaseAccountFlowBalanceBreakdownItem {...props} />
    </StyledAccountUserClaimableBalanceItem>
)

export const AccountUserTotalBalanceItem = (props) => (
    <StyledAccountUserTotalBalanceItem>
        <BaseAccountFlowBalanceBreakdownItem {...props} />
    </StyledAccountUserTotalBalanceItem>
)