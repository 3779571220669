import {useEffect} from "react"
import * as fcl from "@onflow/fcl"
import {atomFamily, selectorFamily, useRecoilState, useSetRecoilState} from "recoil"
import {doesAcctHaveLockedTokenSupport} from "../interactions/does-acct-have-locked-token-support"
import {ERROR, INIT_PROCESSING, INIT, IDLE, PROCESSING} from "../global/constants.js"

export const valueAtom = atomFamily({
    key: "does-account-have-locked-token-support::state",
    default: null
})

export const statusAtom = atomFamily({
    key: "does-account-have-locked-token-support::status",
    default: INIT,
})

export function useDoesAccountHaveLockedTokenSupport(address) {
    const [doesAccountHaveLockedTokenSupport, setDoesAccountHaveLockedTokenSupport] = useRecoilState(valueAtom(address))
    const [status, setStatus] = useRecoilState(statusAtom(address))

    async function refresh() {
        try {
            setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
            await doesAcctHaveLockedTokenSupport(address).then(setDoesAccountHaveLockedTokenSupport)
            setStatus(IDLE)
        } catch (e) {
            setStatus(ERROR)
        }
    }

    useEffect(() => {
        async function load({ address }) {
            if (address) {
                try {
                    setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
                    await doesAcctHaveLockedTokenSupport(address).then(_doesAcctHaveLockedTokenSupport => {
                        setDoesAccountHaveLockedTokenSupport(_doesAcctHaveLockedTokenSupport)
                        setStatus(IDLE)
                    })
                } catch (e) {
                    setStatus(ERROR)
                }
            }
        }
        load({ address })
    }, [address])

    return {
        doesAccountHaveLockedTokenSupport,
        status,
        fetch: refresh,
        refresh
    }
}