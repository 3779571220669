import {useEffect} from "react"
import * as fcl from "@onflow/fcl"
import {atomFamily, selectorFamily, useRecoilState, useSetRecoilState} from "recoil"
import {getTransaction} from "../interactions/get-transaction.js"
import {INIT, INIT_PROCESSING, ERROR, IDLE, PROCESSING} from "../global/constants.js"

export const valueAtom = atomFamily({
    key: "get-transaction::state",
    default:  null
})

export const statusAtom = atomFamily({
    key: "get-transaction::status",
    default: INIT,
})

export function useTransaction(txid) {
    const [transaction, setTransactioin] = useRecoilState(valueAtom(txid))
    const [status, setStatus] = useRecoilState(statusAtom(txid))

    async function refresh() {
        try {
            setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
            await getTransaction(txid).then(setTransactioin)
            setStatus(IDLE)
        } catch (e) {
            setStatus(ERROR)
        }
    }

    useEffect(() => {
        async function load({ txid }) {
            if (txid) {
                try {
                    setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
                    await getTransaction(txid).then(_transaction => {
                        setTransactioin(_transaction)
                        setStatus(IDLE)
                    })
                } catch (e) {
                    setStatus(ERROR)
                }
            }
        }
        load({ txid })
    }, [txid])

    return {
        transaction,
        status,
        fetch: refresh,
        refresh
    }
}