import React, {useState, useEffect} from "react"
import * as fcl from "@onflow/fcl"
import {useHistory} from "react-router-dom"
import styled from "styled-components"
import AceEditor from "react-ace"
import "ace-builds/src-noconflict/theme-github"
import "ace-builds/src-noconflict/mode-rust"
import {Button} from "../components/button.js"
import {screenMinWidth} from "../mixins/breakpoints.js"
import {DropDown} from "../components/dropdown.js"
import {useTransaction} from "../hooks/use-transaction.js"
import {useBlockAtId} from "../hooks/use-block-at-id.js"

const StyledTransactionStatus = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledTransactionStatusContainer = styled.div`
    margin: 0rem 2rem 2rem 2rem;
    padding: 2rem;
    border: 1px solid #E8E8E8;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;

    background-color: white;

    border-radius: 1rem;
`

const StyledTransactionStatusTop = styled.div`
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`

const StyledTransactionStatusIDContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    margin-top: 1rem;

    ${screenMinWidth.sm`
        margin-top: 0rem;
        align-items: flex-end;
    `}
`

const StyledTransactionStatusID = styled.div`
    color: black;
    word-break: break-all;
`

const StyledTransactionStatusIDTitle = styled.div`
    color: #6a6a6a;
    word-break: break-all;
`

const StyledTransactionStatusTitle = styled.h2``

const StyledTransactionStatusVisualizer = styled.div`
    @keyframes pulse {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0.5;
        }

        100% {
            opacity: 1;
        }
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(38,255,118);
        background: ${({isTxnError}) => isTxnError ? "#e95f43" : `linear-gradient(90deg, rgb(45,201,103) 0%, rgb(38,255,118) 100%)`};   
        animation: ${({txnStatus, isTxnError}) => isTxnError ? "none" : txnStatus === "4" ? "none" : "pulse 2s infinite"};  
    }

    margin-top: 2rem;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 2rem;
    border-radius: 2rem;
`

const StyledTransactionStatusVisualizerBar = styled.div`
    position: absolute;
    right: 0;
    width: ${({txnStatus, isTxnError}) => isTxnError ? "0%" : `${((4 - txnStatus) / 4) * 100}%`};
    height: 2rem;
    background-color: #6a6a6a;
    transition: width 0.5s;
    z-index: 2;
`

const StyledTransactionStatusMessage = styled.span`
    margin: auto;
    box-sizing: border-box;
    text-align: center;
    margin-top: 2rem;
`

const StyledTransactionStatusItems = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const StyledTransactionStatusItem = styled.div`
    margin-top: 1rem;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
`

const StyledTransactionStatusItemTitle = styled.div`
    margin-bottom: 0.5rem;
    color: #6a6a6a;
`

const StyledTransactionStatusItemSubtitle = styled.div`
`

const MAX_REFRESH_COUNT = 10
const REFRESH_INTERVAL = 1000

export const TransactionStatus = ({ location }) => {
    const history = useHistory()
    const [isTxnError, setIsTxnError] = useState(false)
    const [txnStatusMessage, setTxnStatusMessage] = useState("")
    const [txnEvents, setTxnEvents] = useState(null) 

    const qp = new URLSearchParams(location.search)
    const qpString = qp.toString()
    const txnId = qp.get("txnId")
    const origin = qp.get("origin") ? atob(qp.get("origin")) : null
    const txnStatus = qp.get("txnStatus") || 1

    const { transaction, refresh: refreshTransaction } = useTransaction(txnId)
    const { block, refresh: refreshBlock } = useBlockAtId(transaction ? transaction.referenceBlockId : "")

    useEffect(() => {
        let refreshCount = 0
        let i = setInterval(() => {
            if (refreshCount <= MAX_REFRESH_COUNT) {
                refreshTransaction()
                refreshCount++
            }
        }, REFRESH_INTERVAL)
        return () => clearInterval(i)
    }, [])

    useEffect(() => {
        let refreshCount = 0
        let i = setInterval(() => {
            if (refreshCount <= MAX_REFRESH_COUNT) {
                refreshBlock()
                refreshCount++
            }
        }, REFRESH_INTERVAL)
        return () => clearInterval(i)
    }, [])

    useEffect(() => {
        if (txnId) {
            return fcl.tx(txnId).subscribe((tx) => {
                if (tx.errorMessage) {
                    setIsTxnError(true)
                    setTxnStatusMessage("Error: " + tx.errorMessage)
                    return
                } 

                if (tx?.events?.length > 0) {
                    setTxnEvents(tx.events)
                }

                switch(true) {
                    case fcl.tx.isSealed(tx): 
                        history.replace("/transaction-status?" + ( () => {qp.set("txnStatus",  4); return qp.toString();} )())
                        return setTxnStatusMessage("Transaction Sealed - Transaction Complete")
                    case fcl.tx.isExecuted(tx):
                        history.replace("/transaction-status?" + ( () => {qp.set("txnStatus",  3); return qp.toString();} )())
                        return setTxnStatusMessage("Transaction Executed - Awaiting Sealing")
                    case fcl.tx.isFinalized(tx):
                        history.replace("/transaction-status?" + ( () => {qp.set("txnStatus",  2); return qp.toString();} )())
                        return setTxnStatusMessage("Transaction Finalized - Awaiting Execution")
                    case fcl.tx.isPending(tx):
                        history.replace("/transaction-status?" + ( () => {qp.set("txnStatus",  1); return qp.toString();} )())
                        return setTxnStatusMessage("Transaction Pending - Awaiting Finalization")
                }
            })
        }
    }, [txnId])

    return (
        <StyledTransactionStatus>
            <StyledTransactionStatusContainer>
                <StyledTransactionStatusTop>
                    <StyledTransactionStatusTitle>Transaction Status</StyledTransactionStatusTitle>
                    <StyledTransactionStatusIDContainer>
                        <StyledTransactionStatusIDTitle>Transaction ID</StyledTransactionStatusIDTitle>
                        <StyledTransactionStatusID>{ txnId }</StyledTransactionStatusID>
                    </StyledTransactionStatusIDContainer>
                </StyledTransactionStatusTop>
                
                <StyledTransactionStatusVisualizer txnStatus={txnStatus} isTxnError={isTxnError}>
                    <StyledTransactionStatusVisualizerBar txnStatus={txnStatus} isTxnError={isTxnError} />
                </StyledTransactionStatusVisualizer>

                <StyledTransactionStatusMessage>{txnStatusMessage}</StyledTransactionStatusMessage>

                { txnStatus === "4" && (
                    <Button 
                        style={{marginTop: "2rem"}}
                        title="Continue" 
                        onClick={() => origin ? history.push(origin) : history.push("/")}
                        isActive={txnStatus === "4"}
                    />
                )}

            </StyledTransactionStatusContainer>

            { transaction && 
                <StyledTransactionStatusContainer>
                    <StyledTransactionStatusTop>
                        <StyledTransactionStatusTitle>Details</StyledTransactionStatusTitle>
                    </StyledTransactionStatusTop>

                    <StyledTransactionStatusItems>

                        <StyledTransactionStatusItem>
                            <StyledTransactionStatusItemTitle>Timestamp</StyledTransactionStatusItemTitle>
                            <StyledTransactionStatusItemSubtitle>{ block && `${new Date(block.timestamp).toLocaleString("en-US", { timeZone: 'PST' }) } PST` }</StyledTransactionStatusItemSubtitle>
                        </StyledTransactionStatusItem>

                        <StyledTransactionStatusItem>
                            <StyledTransactionStatusItemTitle>Block Height</StyledTransactionStatusItemTitle>
                            <StyledTransactionStatusItemSubtitle>{ block && block.height }</StyledTransactionStatusItemSubtitle>
                        </StyledTransactionStatusItem>

                    </StyledTransactionStatusItems>

                </StyledTransactionStatusContainer>
            }

            { transaction && 
                <StyledTransactionStatusContainer>
                    <StyledTransactionStatusTop>
                        <StyledTransactionStatusTitle>Signers</StyledTransactionStatusTitle>
                    </StyledTransactionStatusTop>

                    <StyledTransactionStatusItems>

                        { transaction && transaction.authorizers.map((authorizer, index) => 
                            <StyledTransactionStatusItem key={index}>
                                <StyledTransactionStatusItemTitle>Authorizer {index+1}</StyledTransactionStatusItemTitle>
                                <StyledTransactionStatusItemSubtitle>{fcl.withPrefix(authorizer)}</StyledTransactionStatusItemSubtitle>
                            </StyledTransactionStatusItem>
                        )}

                        { transaction && transaction.payer &&
                            <StyledTransactionStatusItem>
                                <StyledTransactionStatusItemTitle>Payer</StyledTransactionStatusItemTitle>
                                <StyledTransactionStatusItemSubtitle>{fcl.withPrefix(transaction.payer)}</StyledTransactionStatusItemSubtitle>
                            </StyledTransactionStatusItem>
                        }

                        { transaction && transaction.proposalKey &&
                            <StyledTransactionStatusItem>
                                <StyledTransactionStatusItemTitle>Proposer</StyledTransactionStatusItemTitle>
                                <StyledTransactionStatusItemSubtitle>{fcl.withPrefix(transaction.proposalKey.address)}</StyledTransactionStatusItemSubtitle>
                            </StyledTransactionStatusItem>
                        }

                    </StyledTransactionStatusItems>
                </StyledTransactionStatusContainer>
            }

            { transaction && txnEvents && 
                <StyledTransactionStatusContainer>
                    <StyledTransactionStatusTop>
                        <StyledTransactionStatusTitle>Events</StyledTransactionStatusTitle>
                    </StyledTransactionStatusTop>
                    <AceEditor 
                        width="100%"
                        height="500px"
                        mode="rust"
                        theme="github"
                        value={JSON.stringify(txnEvents, null, 2)}
                        name="events"
                        tabSize={2}
                        highlightActiveLine={false}
                        showPrintMargin={false}
                        setOptions={{
                            displayIndentGuides: false,
                        }}
                        readOnly={true}
                        placeholder="..."
                    />
                </StyledTransactionStatusContainer>
            }

            { transaction && 
                <StyledTransactionStatusContainer>
                    <StyledTransactionStatusTop>
                        <StyledTransactionStatusTitle>Code</StyledTransactionStatusTitle>
                    </StyledTransactionStatusTop>
                    <AceEditor 
                        width="100%"
                        height="500px"
                        mode="rust"
                        theme="github"
                        value={transaction.script}
                        name="script"
                        tabSize={2}
                        highlightActiveLine={false}
                        showPrintMargin={false}
                        setOptions={{
                            displayIndentGuides: false,
                        }}
                        readOnly={true}
                        placeholder="..."
                    />
                </StyledTransactionStatusContainer>
            }

            { transaction && 
                <StyledTransactionStatusContainer>
                    <StyledTransactionStatusTop>
                        <StyledTransactionStatusTitle>Arguments</StyledTransactionStatusTitle>
                    </StyledTransactionStatusTop>
                    <AceEditor 
                        width="100%"
                        height="500px"
                        mode="rust"
                        theme="github"
                        value={JSON.stringify(transaction.args, null, 2)}
                        name="script"
                        tabSize={2}
                        highlightActiveLine={false}
                        showPrintMargin={false}
                        setOptions={{
                            displayIndentGuides: false,
                        }}
                        readOnly={true}
                        placeholder="..."
                    />
                </StyledTransactionStatusContainer>
            }
        </StyledTransactionStatus>
    )
}