import React, {useState, useEffect} from "react"
import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import { Transaction } from "../../transaction.js"

import {
  template as stakingCollectionSetupStakingCollectionTemplate,
  CODE as stakingCollectionSetupStakingCollectionCode,
  HASH as stakingCollectionSetupStakingCollectionHash,
  DESCRIPTION as stakingCollectionSetupStakingCollectionDescription,
} from "@onflow/six-stakingcollection-setup-staking-collection"

import { TRANSACTION_MANIFEST_VALUE } from "../../transaction-manifest-constructors.js"

export const Manifest = ({ 
  qp,
  hash,
  txn,
  showCode,
  consent,
  qpString,
  submitTransaction,
  submitTransactionLocked,
  currentUserAddr,
  hiddenArgs,
  lockedArgs,
}) => {
  const [manifestValue, setManifestValue] = useState(new TRANSACTION_MANIFEST_VALUE({
    hash: stakingCollectionSetupStakingCollectionHash,
    title: "Transaction - Setup Staking Collection",
    description: stakingCollectionSetupStakingCollectionDescription,
    code: stakingCollectionSetupStakingCollectionCode,
    args: {},
    isSix: true,
    transactionFunction: () => {}
  }))

  const computeManifestValue = async () => {
    setManifestValue(
      new TRANSACTION_MANIFEST_VALUE({
        hash: stakingCollectionSetupStakingCollectionHash,
        disclaimer: null,
        error: null,
        title: "Transaction - Setup Staking Collection",
        description: stakingCollectionSetupStakingCollectionDescription,
        code: stakingCollectionSetupStakingCollectionCode,
        args: {},
        isSix: true,
        transactionFunction: async (hash, txn, args) => {
            return await fcl.decode(await fcl.send([
                stakingCollectionSetupStakingCollectionTemplate({
                    proposer: fcl.currentUser().authorization,
                    authorization: fcl.currentUser().authorization,     
                    payer: fcl.currentUser().authorization  
                }),
                fcl.limit(9999)
            ]))
        },
      })
    )
  }

  useEffect(() => {
    computeManifestValue()
  }, [qpString])

  return (
    <Transaction 
      title={manifestValue?.title}
      description={manifestValue?.description}
      disclaimer={manifestValue?.disclaimer}
      error={manifestValue?.error}
      code={manifestValue?.code}
      isSix={manifestValue?.isSix}
      args={manifestValue?.args}
      transactionFunction={manifestValue?.transactionFunction}
      submitTransaction={submitTransaction}
      submitTransactionLocked={submitTransactionLocked}
      consent={consent}
      showCode={showCode}
      hash={hash}
      txn={txn}
      qp={qp}
    />
  )
}
