import React from "react"
import styled from "styled-components"
import { HASH_ALGORITHMS, SIGNATURE_ALGORITHMS } from "../../global/constants.js"

const StyledKey = styled.div`
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;
    border: 0.1rem solid #E8E8E8;
    background-color: #f8f8f8;
    border-radius: 0.5rem;
    box-sizing: border-box;
    word-break: break-all;
`

const StyledKeyRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const StyledKeyProperty = styled.div`
    margin-bottom: 0.5rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
`

const StyledKeyPropertyTitle = styled.div`
    margin-right: 0.5rem;
    word-break: keep-all;
    font-weight: bold; 
    color: ${({isRevoked}) => isRevoked ? "#6a6a6a" : "black"}; 
`

const StyledKeyPropertyContent = styled.div`
    color: ${({isRevoked}) => isRevoked ? "#6a6a6a" : "black"}; 
`

export const AccountKeys = ({
    address,
    account,
    accountKeys,
    showRevokedKeys,
}) => {

    return accountKeys ? Object.values(accountKeys).filter(k => !(k.isRevoked)).map(key => 
        <StyledKey key={key.keyIndex}>
            <StyledKeyRow>
                <StyledKeyProperty>
                    <StyledKeyPropertyTitle isRevoked={key.isRevoked} >Index</StyledKeyPropertyTitle>
                    <StyledKeyPropertyContent isRevoked={key.isRevoked}>{key.keyIndex}</StyledKeyPropertyContent>
                </StyledKeyProperty>
                <StyledKeyProperty>
                    <StyledKeyPropertyTitle isRevoked={key.isRevoked}>Sign Algo</StyledKeyPropertyTitle>
                    <StyledKeyPropertyContent isRevoked={key.isRevoked}>{SIGNATURE_ALGORITHMS[key.publicKey.signatureAlgorithm.rawValue]}</StyledKeyPropertyContent>
                </StyledKeyProperty>
                <StyledKeyProperty>
                    <StyledKeyPropertyTitle isRevoked={key.isRevoked}>Hash Algo</StyledKeyPropertyTitle>
                    <StyledKeyPropertyContent isRevoked={key.isRevoked}>{HASH_ALGORITHMS[key.hashAlgorithm.rawValue]}</StyledKeyPropertyContent>
                </StyledKeyProperty>
                <StyledKeyProperty>
                    <StyledKeyPropertyTitle isRevoked={key.isRevoked}>Weight</StyledKeyPropertyTitle>
                    <StyledKeyPropertyContent isRevoked={key.isRevoked}>{key.weight}</StyledKeyPropertyContent>
                </StyledKeyProperty>
                { account.keys[key.keyIndex] && 
                    <StyledKeyProperty>
                        <StyledKeyPropertyTitle isRevoked={key.isRevoked}>Sequence Number</StyledKeyPropertyTitle>
                        <StyledKeyPropertyContent isRevoked={key.isRevoked}>{account.keys[key.keyIndex].sequenceNumber}</StyledKeyPropertyContent>
                    </StyledKeyProperty>
                }
                { showRevokedKeys &&
                    <StyledKeyProperty>
                        <StyledKeyPropertyTitle isRevoked={key.isRevoked}>Revoked</StyledKeyPropertyTitle>
                        <StyledKeyPropertyContent isRevoked={key.isRevoked}>{key.isRevoked ? "Yes" : "No"}</StyledKeyPropertyContent>
                    </StyledKeyProperty>
                }
            </StyledKeyRow>
            <StyledKeyProperty>
                <StyledKeyPropertyTitle isRevoked={key.isRevoked}>Public Key</StyledKeyPropertyTitle>
                <StyledKeyPropertyContent isRevoked={key.isRevoked}>{key.publicKey.publicKey}</StyledKeyPropertyContent>
            </StyledKeyProperty>
        </StyledKey>    
    ) : null
}