import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import {config} from "@onflow/config"

export const getBlockAtId = async (blockId) => {
    return await fcl.decode(await fcl.send([
        fcl.getBlock(),
        fcl.atBlockId(blockId)
    ]))
}
