import React, {useState, useEffect} from "react"
import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import { Transaction } from "../transaction.js"

import {
  template as withdrawUnlockedFlowTemplate,
  CODE as withdrawUnlockedFlowCode,
  HASH as withdrawUnlockedFlowHash,
  DESCRIPTION as withdrawUnlockedFlowDescription,
} from "@onflow/six-withdraw-unlocked-flow"

import { TRANSACTION_MANIFEST_VALUE, TRANSACTION_MANIFEST_ARG } from "../transaction-manifest-constructors.js"

import {
  invalidUFix64ArgError,
} from "../transaction-errors.js"

import {
  validateUFix64
} from "../transaction-validators.js"

import {
  xformUFix64
} from "../transaction-xforms.js"

export const Manifest = ({ 
  qp,
  hash,
  txn,
  showCode,
  consent,
  qpString,
  submitTransaction,
  submitTransactionLocked,
  currentUserAddr,
  hiddenArgs,
  lockedArgs,
}) => {
  const [manifestValue, setManifestValue] = useState(new TRANSACTION_MANIFEST_VALUE({
    hash: withdrawUnlockedFlowHash,
    title: "Transaction - Withdraw Unlocked Flow",
    description: withdrawUnlockedFlowDescription,
    code: withdrawUnlockedFlowCode,
    args: {},
    isSix: true,
    transactionFunction: () => {}
  }))

  const computeManifestValue = async () => {
    setManifestValue(
      new TRANSACTION_MANIFEST_VALUE({
        hash: withdrawUnlockedFlowHash,
        disclaimer: null,
        error: null,
        title: "Transaction - Withdraw Unlocked Flow",
        description: withdrawUnlockedFlowDescription,
        code: withdrawUnlockedFlowCode,
        args: {
          0: new TRANSACTION_MANIFEST_ARG({
            title: "Amount",
            qp: "amount",
            validate: validateUFix64,
            xform: xformUFix64,
            description: "The amount of Flow tokens to withdraw.",
            placeholder: "1.00",
            type: types.UFix64,
            value: qp.get("amount")
              ? qp.get("amount")
              : "",
            warnings: [].filter((w) => w !== null),
            errors: [
              invalidUFix64ArgError(qp.get("amount")),
            ].filter((w) => w !== null),
          }),
        },
        isSix: true,
        transactionFunction: async (hash, txn, args) => {
          return await fcl.decode(
            await fcl.send([
              withdrawUnlockedFlowTemplate({
                proposer: fcl.currentUser().authorization,
                authorization: fcl.currentUser().authorization,
                payer: fcl.currentUser().authorization,
                amount: args[0].xform(args[0].value),
              }),
              fcl.limit(9999),
            ])
          )
        },
      })
    )
  }

  useEffect(() => {
    computeManifestValue()
  }, [qpString])

  return (
    <Transaction 
      title={manifestValue?.title}
      description={manifestValue?.description}
      disclaimer={manifestValue?.disclaimer}
      error={manifestValue?.error}
      code={manifestValue?.code}
      isSix={manifestValue?.isSix}
      args={manifestValue?.args}
      transactionFunction={manifestValue?.transactionFunction}
      submitTransaction={submitTransaction}
      submitTransactionLocked={submitTransactionLocked}
      consent={consent}
      showCode={showCode}
      hash={hash}
      txn={txn}
      qp={qp}
    />
  )
} 
