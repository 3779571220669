import React from "react"
import {Helmet} from "react-helmet"
import styled from "styled-components"
import {animated} from 'react-spring'
import * as fcl from "@onflow/fcl"
import {useHistory, Link} from "react-router-dom"
import {FaExclamationCircle,FaExclamationTriangle} from "react-icons/fa"
import {Button} from "../../components/button.js"
import {doNoArgumentErrorsExist, doAllArgumentsPassValidation} from "./transaction-utils.js"
import { TRANSACTION_MANIFEST_ARG_INPUT_TYPES } from "./transaction-manifest-constructors.js"


const StyledTransaction = styled.div`
    margin: 0rem 2rem 2rem 2rem;
    padding: 2rem;
    border-radius: 1rem;
    background-color: white;
    border: 0.1rem solid #E8E8E8;

    display: flex;
    flex-direction: column;

    box-sizing: border-box;
`

const StyledTransactionTitle = styled.h2`
    margin-bottom: 1rem;
    position: relative;
    font-weight: bold;
`

const StyledTransactionDescription = styled.div`
    margin-bottom: 0.5rem;
    position: relative;
    color: #6a6a6a;
    font-size: 1rem;
    font-weight: normal;
`

const StyledTransactionError = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    position: relative;
    color: white;
    font-size: 1rem;
    font-weight: normal;
    background-color: #e9593c;
    border-radius: 0.25rem;
`

const StyledTransactionDisclaimer = styled.div`
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-color: #3745f5;
    width: fit-content;
    color: white;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
        color: white;
    }
`

const StyledTransactionDisclaimerIcon = styled(FaExclamationCircle)`
    margin-right: 0.5rem;
    min-width: 1rem;
`

const StyledTransactionDisclaimerLink = styled(Link)`
    color: white;
`

const StyledTransactionToggle = styled.div`
    width: fit-content;
    margin-bottom: 0.5rem;
    position: relative;
    color: #6a6a6a;
    cursor: pointer;
`

const StyledTransactionInputTitle = styled.div`
    margin-bottom: 0.5rem;
    position: relative;
    color: #6a6a6a;
    font-weight: bold;
    &:after {
        opacity: 0.5;
        content: "";
        position: absolute;
        display: block;
        bottom: calc(0rem - 3px);
        background: #00ff76;
        height: 3px;
        width: 1rem;
        border-radius: 3px;    
    }
`

const StyledTransactionTextArea = styled(animated.textarea)`
    margin-bottom: 1rem;
    padding: 0.5rem;
    color: #6a6a6a;
    border: 0.1rem solid white;
    background: #f3f3f3;

    &:focus {
        border: 0.1rem solid #6a6a6a;
        outline: none;
    }
`

const StyledTransactionInputWrapper = styled.div`
    width: 100%;
`

const StyledTransactionInput = styled.input`
    margin-bottom: 1rem;
    width: 100%;
    padding: 0.5rem;
    color: #6a6a6a;
    background: #f3f3f3;

    font-size: 1rem;

    border-radius: 0.25rem;
    border: 0.1rem solid white;

    -webkit-appearance: none;
    -moz-appearance: none;

    box-sizing: border-box;

    &:focus {
        border: 0.1rem solid #6a6a6a;
        outline: none;
    }

    &::placeholder {
        color: #D0D0D0;
    }
`

const StyledTransactionSelect = styled.select`
    margin-bottom: 1rem;
    width: 100%;
    padding: 0.5rem;
    color: #6a6a6a;
    background: #f3f3f3;

    font-size: 1rem;

    border-radius: 0.25rem;
    border: 0.1rem solid white;

    -webkit-appearance: none;
    -moz-appearance: none;

    box-sizing: border-box;

    &:focus {
        border: 0.1rem solid #6a6a6a;
        outline: none;
    }

    &::placeholder {
        color: #D0D0D0;
    }
`

const StyledTransactionSelectOption = styled.option`
    
`

const StyledTransactionIssue = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const StyledTransactionInputWarningIcon = styled(FaExclamationCircle)`
    margin-right: 1rem;
    min-width: 1rem;
`

const StyledTransactionInputWarning = styled(StyledTransactionIssue)`
    margin-bottom: 1rem;
    margin-top: -0.5rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    position: relative;
    color: var(--text-primary);
    font-weight: normal;
    background-color: #e5d747;
    border-radius: 0.25rem;
`

const StyledTransactionInputErrorIcon = styled(FaExclamationTriangle)`
    margin-right: 1rem;
    min-width: 1rem;
`

const StyledTransactionInputError = styled(StyledTransactionIssue)`
    margin-bottom: 1rem;
    margin-top: -0.5rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    position: relative;
    color: white;
    font-weight: normal;
    background-color: #e9593c;
    border-radius: 0.25rem;
`

const StyledTransactionCheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
`

const StyledTransactionCheckbox = styled.input`
    padding: 0.5rem;
    color: #6a6a6a;
    background: #f3f3f3;

    min-height: 1rem;
    min-width: 1rem;

    border-radius: 0.25rem;
    border: 0.1rem solid white;

    box-sizing: border-box;
`

const StyledTransactionCheckboxLabel = styled.label`
    margin-left: 1rem;
    color: #6a6a6a;
    word-break: keep-all;
`

const updateHistory = (history, qp, params = []) => 
    history.replace("/transaction?" + ( () => {params.forEach(([param, value]) => qp.set(param,  value)); return qp.toString();} )())

export const Transaction = ({ 
    location,
    title,
    description,
    disclaimer,
    error,
    code,
    isSix,
    args,
    showCode,
    consent,
    hash,
    submitTransaction,
    transactionFunction,
    submitTransactionLocked,
    qp
}) => {
    const history = useHistory()

    const isSubmittable = 
        (!args || (
            doNoArgumentErrorsExist(args) && doAllArgumentsPassValidation(args)
        ))
        && !error 
        && consent
        && !submitTransactionLocked

    return (
        <StyledTransaction>
            <Helmet><title>Flow Port | {title}</title></Helmet>
            <StyledTransactionTitle>{title}</StyledTransactionTitle>
            <StyledTransactionDescription>{description}</StyledTransactionDescription>
            { disclaimer && <StyledTransactionDisclaimer><StyledTransactionDisclaimerIcon />{disclaimer}</StyledTransactionDisclaimer> }
            {
                Object.values(args).map((arg, i) => (
                    arg.hidden ? null : <StyledTransactionInputWrapper key={i}>
                        <StyledTransactionInputTitle>{arg.title}</StyledTransactionInputTitle>
                        <StyledTransactionDescription>{arg.description}</StyledTransactionDescription>
                        {(() => {
                            switch(arg.inputType) {
                                case TRANSACTION_MANIFEST_ARG_INPUT_TYPES.SELECT: 
                                    return (
                                        <StyledTransactionSelect onChange={e => updateHistory(history, qp, [[arg.qp, e.target.value]])} defaultValue={arg.value} placeholder={arg.placeholder}>
                                            {Object.values(arg.options).map(option => 
                                                <StyledTransactionSelectOption value={option.value}>{option.title}</StyledTransactionSelectOption>
                                            )}
                                        </StyledTransactionSelect>
                                    )
                                default:
                                    return <StyledTransactionInput onChange={e => updateHistory(history, qp, [[arg.qp, e.target.value]])} defaultValue={arg.value} placeholder={arg.placeholder}/>
                            }
                        })()}
                        {
                            arg.warnings && arg.warnings.map((warning, index) => 
                                <StyledTransactionInputWarning key={index}>
                                    <StyledTransactionInputWarningIcon/>{warning}
                                </StyledTransactionInputWarning>
                            )
                        }
                        {
                            arg.errors && arg.errors.map((error, index) => 
                                <StyledTransactionInputError key={index}>
                                    <StyledTransactionInputErrorIcon/>{error}
                                </StyledTransactionInputError>
                            )
                        }
                    </StyledTransactionInputWrapper>
                ))
            }
            <StyledTransactionInputTitle>Transaction Code</StyledTransactionInputTitle>
            <StyledTransactionToggle onClick={() => updateHistory(history, qp, [["showcode", !showCode]])}>{ showCode ? "Hide Code" : "Show Code" }</StyledTransactionToggle>
            { showCode && <StyledTransactionTextArea rows="15" readOnly={isSix} onChange={e => history.replace("/transaction?" + ( () => {qp.set("txn",  btoa(e.target.value)); return qp.toString();} )())} value={code} /> } 
            <StyledTransactionCheckboxWrapper>
                <StyledTransactionCheckbox id="transaction-consent" type="checkbox" checked={consent} onClick={() => updateHistory(history, qp, [["consent", !consent]])} />
                <StyledTransactionCheckboxLabel htmlFor="transaction-consent">I have reviewed the above information for correctness. I am aware that submitting a transaction may be an irreversible operation.</StyledTransactionCheckboxLabel>
            </StyledTransactionCheckboxWrapper>
            { error && <StyledTransactionError><StyledTransactionInputErrorIcon/>{error}</StyledTransactionError> }
            { !error && !doNoArgumentErrorsExist(args) && <StyledTransactionError><StyledTransactionInputErrorIcon/>Resolve all errors before submitting.</StyledTransactionError> }
            <Button 
                title="Submit" 
                onClick={() => isSubmittable && submitTransaction(hash, code, args)(transactionFunction)}
                isActive={isSubmittable}
            />
        </StyledTransaction>
    )
}