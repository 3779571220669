import React, {useState, useEffect} from "react"
import styled from "styled-components"
import {Helmet} from "react-helmet";
import * as fcl from "@onflow/fcl"
import {useTransition, animated, config} from 'react-spring'
import {FaCopy, FaCloud, FaWallet} from "react-icons/fa"
import {useParams} from "react-router-dom"
import {screenMinWidth} from "../../mixins/breakpoints.js"
import logomark from "../../assets/logomark.svg"
import stakeIconGreen from "../../assets/stake-icon-green.png"
import {KeyIcon} from "../../assets/key-icon.js"
import {LockIcon} from "../../assets/lock-icon.js"
import {PageSectionDivider} from "../../components/page-section-divider.js"
import {Banner} from "../../components/banner.js"

import {useAccountKeys} from "../../hooks/use-account-keys.js"
import {useAccount} from "../../hooks/use-account.js"
import {useAccountStorageInfo} from "../../hooks/use-account-storage-info.js"
import { ERROR, INIT_PROCESSING } from "../../global/constants.js"

import {AccountKeys} from "./account-keys.js"
import {AccountStorage} from "./account-storage.js"
import {AccountContracts} from "./account-contracts.js"
import {
    StyledAccountItemFullwidth,
    StyledAccountItemTitle,
    StyledAccountItemContent,
    StyledAccountItemHalfwidth,
    StyledAccountItemFullwidthIcon,
    StyledAccountItemFullwidthBottom,
    StyledAccountItemFullwidthTop,
    StyledAccountItemFullwidthTopButtons,
    StyledAccountItemFullwidthButton
} from "./account-components.js"
import { CheckNetwork } from "../checkNetwork.js";

const StyledAccountContainer = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background-color: ${props => props.isLoading ? "#f8f8f8" : "#f8f8f8300" };

    transition: background-color 0.2s;
    overflow-y: ${props => props.isError || props.isLoading ? "hidden" : "auto" };

    display: flex;
    justify-content: center;
`

const StyledLoadingAccount = styled(animated.div)`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
`

const StyledLoadingAccountError = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #919191;
    background-color: #fafafa;
    font-size: 1.5rem;
    text-align: center;
    z-index:2;
`

const StyledLoadingAccountImg = styled.img`
    @keyframes spin {
        0%   {transform: rotate(0deg);}
        25%  {transform: rotate(810deg);}
        50% {transform: rotate(700deg);}
        60% {transform: rotate(725deg);}
        65% {transform: rotate(720deg);}
        100% {transform: rotate(720deg);}
    }

    margin-bottom: 2rem;
    height: 10rem;
    width: auto;
    animation-name: spin;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: forwards;
    animation-delay: 1s;
`

const StyledAccount = styled(animated.div)`
    padding: 1rem 2rem 1rem 2rem;
    max-width: 60rem;
    width: 100%;

    display: flex;
    flex-direction: column;

    box-sizing: border-box;
`

const StyledAccountTitle = styled.div`
    font-size: 3rem;
    line-height: 3.5rem;
    width: 100%;
    font-weight: bold;
    word-break: keep-all;

    transform: scale(1, 0.8);

    ${screenMinWidth.sm`
        width: 50%;
    `}
`

const StyledAccountRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
`

const StyledAccountTop = styled(StyledAccountRow)`
    flex-wrap: wrap;
    align-items: center;
    min-height: 2.5rem;
`
const StyledAccountAddressItem = styled(StyledAccountItemHalfwidth)`
    height: 2.5rem;
    padding: 0rem;
`

const StyledAccountAddressItemContent = styled(StyledAccountItemContent)`
    position: relative;
`

const StyledAccountAddress = styled(animated.div)`
    width: 100%;
    box-sizing: border-box;
    background: #eaeaea;
    border-radius: 2rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
`

const StyledAccountCopy = styled.div`
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: fit-content;
    cursor: pointer;
    background: transparent;

    transition: color 0.2s;

    svg {
        margin-right: 0.5rem;
        path {
            transition: fill 0.2s;
            fill: #3745f5;
        }
    }

    &:hover {
        color: black;
        svg {
            margin-right: 0.5rem;
            path {
                fill: black;
            }
        }
    }
`

const StyledFaCopy = styled(FaCopy)`
    margin-left: 0.5rem;
    cursor: pointer;
`

const StyledStorageIcon = styled(FaCloud)`
    width: 1.25rem;
    height: auto;
`

const StyledAccountWalletActionLink = styled.a`
    border: 0.1rem solid #E8E8E8;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: var(--text-primary);
    cursor: pointer;
    text-decoration: none;

    transition: background-color 0.2s;

    &:hover {
        background-color: #E8E8E8;
    }
`

export const Account = ({ location }) => {
    const { address } = useParams()

    const [currentUserAddr, setCurrentUserAddr] = useState(null)
    const [currentUser, setCurrentUser] = useState(null)
    const [showStorageBreakdown, setShowStorageBreakdown] = useState(false)
    const [showKeys, setShowKeys] = useState(false)
    const [showRevokedKeys, setShowRevokedKeys] = useState(false) 
    const [showCode, setShowCode] = useState(false)
    const [isCopying, setIsCopying] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const { account, status: accountStatus } = useAccount(address)
    const { accountKeys, status: accountKeysStatus } = useAccountKeys(address)
    const { accountStorageInfo, status: accountStorageInfoStatus } = useAccountStorageInfo(address)

    const qp = new URLSearchParams(location.search)
    const contract = account && account.contracts && Object.keys(account.contracts).length > 0 && !qp.get("contract") ? Object.keys(account.contracts)[0] : (qp.get("contract") || null)

    const isCurrentUsersAccount = address && currentUserAddr && account && fcl.withPrefix(account.address) === currentUserAddr

    const showLedgerWalletActions = currentUser?.services[0]?.uid === "fcl-ledger-authz"

    useEffect(() => {
        setIsLoading(accountStorageInfoStatus === INIT_PROCESSING || accountStatus === INIT_PROCESSING)
        setIsError( accountStorageInfoStatus === ERROR || accountStatus === ERROR)
    }, [accountStorageInfoStatus, accountStatus ])

    useEffect(() => {
        return fcl.currentUser().subscribe(async currentUser => {
            setCurrentUser(currentUser)
            setCurrentUserAddr(currentUser.addr ? fcl.withPrefix(currentUser.addr) : null)
        })
    }, [])

    const handleCopyAddress = () => {
        if (account) {
            const textField = document.createElement('textarea')
            textField.innerText = fcl.withPrefix(account.address)
            document.body.appendChild(textField)
            textField.select()
            document.execCommand('copy')
            textField.remove()
        }
        setIsCopying(true)
        setTimeout(() => setIsCopying(false), 1500)
    }

    const copyTransitions = useTransition(isCopying, null, {
        from: { 
            position: 'absolute', opacity: 0
        },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.default,
    })

    const loadingTransitions = useTransition(isLoading, null, {
        from: { 
            position: 'absolute', opacity: 0, width: "100%"
        },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.default,
    })

    const storageTransitions = useTransition(showStorageBreakdown, null, {
        from: {  
            opacity: 0, width: "100%", marginTop: 0
        },
        enter: { opacity: 1, display: "block", marginTop: 0 },
        leave: { opacity: 0, display: "none", marginTop: 0 },
        config: config.default,
    })

    const keysTransitions = useTransition(showKeys, null, {
        from: { 
            opacity: 0, width: "100%"
        },
        enter: { opacity: 1, display: "block" },
        leave: { opacity: 0, display: "none" },
        config: config.default,
    })

    const codeTransitions = useTransition(showCode, null, {
        from: { 
            opacity: 0, width: "100%"
        },
        enter: { opacity: 1, display: "block" },
        leave: { opacity: 0, display: "none" },
        config: config.default,
    })

    return (
        <StyledAccountContainer isLoading={isLoading} isError={isError}>
            <Helmet><title>Flow Port | Dashboard</title></Helmet>
            { isError && <CheckNetwork /> }
            { !isError &&
                loadingTransitions.map(({ item, key, props }) => 
                    item ? <StyledLoadingAccount style={props} key={key}><StyledLoadingAccountImg src={logomark}/>Loading...</StyledLoadingAccount> 
                    : (
                    <StyledAccount style={props} key={key}>
                        { process.env.REACT_APP_ENABLE_ACCOUNT_BANNER === "true" && <Banner content={process.env.REACT_APP_ACCOUNT_BANNER_TEXT ? <span dangerouslySetInnerHTML={{__html: process.env.REACT_APP_ACCOUNT_BANNER_TEXT}}/> : ""}/> }
                        { 
                            isCurrentUsersAccount && (
                                <StyledAccountTop>
                                    <StyledAccountTitle>Your Dashboard</StyledAccountTitle>
                                    <StyledAccountAddressItem>
                                        <StyledAccountAddressItemContent>
                                            { copyTransitions.map(({ item, key, props }) => 
                                                item ? (<StyledAccountAddress style={props} key={key}>Copied to Clipboard</StyledAccountAddress>)
                                                : (<StyledAccountAddress style={props} key={key}>Your Address: <b>{account && fcl.withPrefix(account.address)}</b></StyledAccountAddress>)
                                            )}
                                            <StyledAccountCopy onClick={handleCopyAddress}><StyledFaCopy /></StyledAccountCopy>
                                        </StyledAccountAddressItemContent>
                                    </StyledAccountAddressItem>
                                </StyledAccountTop>
                            )
                        }                        

                        <PageSectionDivider title="Account Properties" icon={stakeIconGreen} />

                        <StyledAccountItemFullwidth>
                            <StyledAccountItemFullwidthIcon><StyledStorageIcon /></StyledAccountItemFullwidthIcon>
                            <StyledAccountItemFullwidthTop>
                                <StyledAccountItemTitle>Storage</StyledAccountItemTitle>
                                <StyledAccountItemFullwidthTopButtons>
                                    <StyledAccountItemFullwidthButton onClick={() => setShowStorageBreakdown(!showStorageBreakdown)}>{ showStorageBreakdown ? "Hide Storage Breakdown" : "Show Storage Breakdown" }</StyledAccountItemFullwidthButton>
                                </StyledAccountItemFullwidthTopButtons>
                            </StyledAccountItemFullwidthTop>                    
                            {
                                storageTransitions.map(({ item, key, props }) => 
                                    item ? (
                                        <StyledAccountItemContent style={props} key={key}>
                                            <AccountStorage 
                                                accountStorageInfo={accountStorageInfo}
                                                isCurrentUsersAccount={isCurrentUsersAccount}
                                                showExpanded={true}
                                            />
                                        </StyledAccountItemContent>
                                    ) : (
                                        <StyledAccountItemContent style={props} key={key}>
                                            <AccountStorage 
                                                accountStorageInfo={accountStorageInfo}
                                                isCurrentUsersAccount={isCurrentUsersAccount}
                                                showExpanded={false}
                                            />
                                        </StyledAccountItemContent>
                                    )
                                )}      
                        </StyledAccountItemFullwidth>

                        <StyledAccountItemFullwidth>
                            <StyledAccountItemFullwidthIcon><KeyIcon /></StyledAccountItemFullwidthIcon>
                            <StyledAccountItemFullwidthTop>
                                <StyledAccountItemTitle>Keys / {accountKeys && Object.values(accountKeys).filter(k => !(k.isRevoked)).length}</StyledAccountItemTitle>
                                <StyledAccountItemFullwidthTopButtons>
                                    <StyledAccountItemFullwidthButton onClick={() => setShowKeys(!showKeys)}>{ showKeys ? "Hide Keys" : "Show Keys" }</StyledAccountItemFullwidthButton>
                                    {/* { showKeys && <StyledAccountItemKeysTopButton>Add Key</StyledAccountItemKeysTopButton> } */}
                                </StyledAccountItemFullwidthTopButtons>
                            </StyledAccountItemFullwidthTop>                            
                            {
                                keysTransitions.map(({ item, key, props }) => 
                                    item ? (
                                        <StyledAccountItemContent style={props} key={key}>
                                            {
                                                accountKeys && account && (
                                                    <AccountKeys
                                                        address={address}
                                                        account={account}
                                                        accountKeys={accountKeys}
                                                        showRevokedKeys={showRevokedKeys}
                                                    />
                                                )
                                            }
                                        </StyledAccountItemContent>
                                    ) : <StyledAccountItemContent style={props} key={key}>There are {account &&  account.keys && account.keys.filter(k => !(k.revoked)).length} active keys on this account.</StyledAccountItemContent>
                            )}   
                            { showKeys && account && account.keys && account.keys.length > account.keys.filter(k => !(k.revoked)).length && 
                                <StyledAccountItemFullwidthBottom>
                                    <StyledAccountItemFullwidthButton onClick={() => setShowRevokedKeys(!showRevokedKeys)}>{ showRevokedKeys ? "Hide Revoked Keys" : "Show Revoked Keys" }</StyledAccountItemFullwidthButton>
                                </StyledAccountItemFullwidthBottom>
                            }
                        </StyledAccountItemFullwidth>

                        <StyledAccountItemFullwidth>
                            <StyledAccountItemFullwidthIcon><LockIcon /></StyledAccountItemFullwidthIcon>
                            <StyledAccountItemFullwidthTop>
                                <StyledAccountItemTitle>Contracts / {account && account.contracts && Object.keys(account.contracts).length}</StyledAccountItemTitle>
                                <StyledAccountItemFullwidthTopButtons>
                                    { account && account.contracts && Object.keys(account.contracts).length > 0 && <StyledAccountItemFullwidthButton onClick={() => setShowCode(!showCode)}>{ showCode ? "Hide Contracts" : "Show Contracts" }</StyledAccountItemFullwidthButton> }
                                </StyledAccountItemFullwidthTopButtons>
                            </StyledAccountItemFullwidthTop>                            
                            {
                                codeTransitions.map(({ item, key, props }) => 
                                    item ? (
                                        <StyledAccountItemContent style={props} key={key}>
                                            { account && account.contracts && (
                                                <AccountContracts 
                                                    account={account}
                                                    address={address}
                                                    selectedContract={contract}
                                                />
                                            )}
                                        </StyledAccountItemContent>
                                    ) : <StyledAccountItemContent style={props} key={key}>There are{account && account.contracts && Object.keys(account.contracts).length > 0 ? ` ${Object.keys(account.contracts).length} ` : " no "}contracts set on this account.</StyledAccountItemContent>
                            )}   
                        </StyledAccountItemFullwidth>

                        { showLedgerWalletActions && <PageSectionDivider title="Wallet Actions" icon={stakeIconGreen} />}

                        { showLedgerWalletActions && (
                            <StyledAccountItemFullwidth>
                                <StyledAccountItemFullwidthIcon><FaWallet /></StyledAccountItemFullwidthIcon>
                                <StyledAccountItemFullwidthTop>
                                    <StyledAccountItemTitle>Ledger Wallet Actions</StyledAccountItemTitle>
                                </StyledAccountItemFullwidthTop>  
                                <StyledAccountItemContent>
                                    <StyledAccountWalletActionLink href="https://fcl-ledger.onflow.org/showkey" target="_blank">View Public Key on Device</StyledAccountWalletActionLink>
                                </StyledAccountItemContent>
                            </StyledAccountItemFullwidth>
                        )}
                        
                    </StyledAccount>
                ))
            }
        </StyledAccountContainer>
    )
}