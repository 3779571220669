import React, {useState, useEffect} from "react"
import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import { Transaction } from "../../transaction.js"

import {
  template as topshotTransferMomentTemplate,
  CODE as topshotTransferMomentCode,
  HASH as topshotTransferMomentHash,
  DESCRIPTION as topshotTransferMomentDescription,
} from "@onflow/six-topshot-transfer-moment"

import { TRANSACTION_MANIFEST_VALUE, TRANSACTION_MANIFEST_ARG } from "../../transaction-manifest-constructors.js"

import {
  invalidAccountError,
  invalidUFix64ArgError,
} from "../../transaction-errors.js"

import {
    validateAddress,
    validateUInt64
} from "../../transaction-validators.js"

import {
    xformAddress,
    xformUInt64
} from "../../transaction-xforms.js"

export const Manifest = ({ 
  qp,
  hash,
  txn,
  showCode,
  consent,
  qpString,
  submitTransaction,
  submitTransactionLocked,
  currentUserAddr,
  hiddenArgs,
  lockedArgs,
}) => {
  const [manifestValue, setManifestValue] = useState(new TRANSACTION_MANIFEST_VALUE({
    hash: topshotTransferMomentHash,
    title: "Transaction - NBA Top Shot Transfer Moment",
    description: topshotTransferMomentDescription,
    code: topshotTransferMomentCode,
    args: {},
    isSix: true,
    transactionFunction: () => {}
  }))

  const computeManifestValue = async () => {
    setManifestValue(
      new TRANSACTION_MANIFEST_VALUE({
        hash: topshotTransferMomentHash,
        disclaimer: null,
        error: null,
        title: "Transaction - NBA Top Shot Transfer Moment",
        description: topshotTransferMomentDescription,
        code: topshotTransferMomentCode,
        args: {
            0: new TRANSACTION_MANIFEST_ARG({
                title: "Recipient",
                qp: "recipient",
                validate: validateAddress,
                xform: xformAddress,
                placeholder: "0xABC123DEF456",
                description: "The Flow account the moment will be transferred to.",
                type: types.Address,
                value: qp.get("recipient") || "",
                errors: [
                  await invalidAccountError(qp.get("recipient")),
                ].filter((w) => w !== null),
            }),
            1: new TRANSACTION_MANIFEST_ARG({
                title: "Withdraw ID",
                qp: "withdrawid",
                validate: validateUInt64,
                xform: xformUInt64,
                placeholder: "123",
                description: "The ID of the moment to transfer.",
                type: types.UInt64,
                value: qp.get("withdrawid") || "",
                errors: [
                  await invalidUFix64ArgError(qp.get("withdrawid"))
                ].filter((w) => w !== null),
            })
        },
        isSix: true,
        transactionFunction: async (hash, txn, args) => {
          return await fcl.decode(
            await fcl.send([
              topshotTransferMomentTemplate({
                proposer: fcl.currentUser().authorization,
                authorization: fcl.currentUser().authorization,
                payer: fcl.currentUser().authorization,
                recipient: args[0].xform(args[0].value),
                withdrawID: args[1].xform(args[1].value)
              }),
              fcl.limit(9999),
            ])
          )
        },
      })
    )
  }

  useEffect(() => {
    computeManifestValue()
  }, [qpString])

  return (
    <Transaction 
      title={manifestValue?.title}
      description={manifestValue?.description}
      disclaimer={manifestValue?.disclaimer}
      error={manifestValue?.error}
      code={manifestValue?.code}
      isSix={manifestValue?.isSix}
      args={manifestValue?.args}
      transactionFunction={manifestValue?.transactionFunction}
      submitTransaction={submitTransaction}
      submitTransactionLocked={submitTransactionLocked}
      consent={consent}
      showCode={showCode}
      hash={hash}
      txn={txn}
      qp={qp}
    />
  )
}
