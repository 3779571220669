import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import LockedTokens from 0xLOCKEDTOKENADDRESS
pub fun main(address: Address): UFix64 {
    let account = getAccount(address)

    let unlockedVault = account
      .getCapability(/public/flowTokenBalance)!
      .borrow<&FlowToken.Vault{FungibleToken.Balance}>()
        ?? panic("Could not borrow Balance reference to the Vault")
    let unlockedBalance = unlockedVault.balance
    
    return unlockedBalance
}
`

export const getTotalUnlockedAccountFlowBalance = async (address) => {
    return await fcl.decode(await fcl.send([
        fcl.script(CODE),
        fcl.args([fcl.arg(address, t.Address)])
    ]))
}
