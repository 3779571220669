import React from 'react'
import { Banner } from "../components/banner.js"

const monthNames = ["Jan", "Feb", "March", "April", "May", "June",
    "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
const WED_OF_WEEK = 3;

export const StakeDeletgateBanner = () => {

    const dayWithEnding = (day) => {
        if (day === 2 || day === 22) {
            return `${day}nd`;
        }
        if (day === 3 || day === 23) {
            return `${day}rd`;
        }
        if(day === 1 || day === 21 || day === 31) {
            return `${day}st`;
        }
        return `${day}th`;
    }
    const getDayLabels = (d) => {
        let mon = d.getMonth();
        let day = d.getDate();
        const nextWed = `${monthNames[mon]} ${dayWithEnding(day)}`;
        d.setDate(d.getDate() + 1);
        mon = d.getMonth();
        day = d.getDate();
        const nextThurs = `${monthNames[mon]} ${dayWithEnding(day)}`;

        return {nextWed, nextThurs}
    }
    // example of existing banner
    // Any staking/delegating requests made between today and <b>July 19th</b> will be processed at the next Epoch on <b>July 20th</b>.
    const d = new Date();
    const dayOfWeek = d.getDay()
    if (dayOfWeek < WED_OF_WEEK) {
        d.setDate((d.getDate() - dayOfWeek) + (WED_OF_WEEK - 1));
    } else {
        d.setDate(d.getDate() + (((WED_OF_WEEK - dayOfWeek) % 7 + 1) % 7) + 5);
    }
    const { nextWed, nextThurs } = getDayLabels(d)

    return (

        <Banner content={
                <span>
                    Any staking/delegating requests made between today and <b>{nextWed}</b> will be processed at the next Epoch on <b>{nextThurs}</b>
                </span>
        } />

    )
}
