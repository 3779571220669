import {
    UFIX64_ACCT_MIN_STORAGE_BALANCE,
    UFIX64_GAS_FEE_INCLUSION_RATE,
  } from "../../global/constants.js"

export const calculateTokensAvailableAfterAccountStorage = (accountStorageInfo, lockedBalance = 0, unlockedAccountReserve = 0) => {
    return accountStorageInfo ?
        Intl.NumberFormat(
            "en-ca",
            {
                maximumFractionDigits: 8,
                minimumFractionDigits: 8,
                style: 'decimal',
                useGrouping: false
            }
        ).format(Number(Math.max(
            Number(
                (Number(accountStorageInfo.useableAccountBalance) - Number(unlockedAccountReserve))
                +
                Number(lockedBalance)
            )
            ,
            0
        )))
        :
        null
}

export const calculateAvailableFLOWTokens = (useableUnlockedAccountBalance, lockedBalance = 0, unlockedAccountReserve = 0) => {
    return useableUnlockedAccountBalance ?
        Intl.NumberFormat(
            "en-ca",
            {
                maximumFractionDigits: 8,
                minimumFractionDigits: 8,
                style: 'decimal',
                useGrouping: false
            }
        ).format(Number(Math.max(
            Number(
                (
                    Number(useableUnlockedAccountBalance) - 
                    Number(unlockedAccountReserve) -
                    Number(UFIX64_GAS_FEE_INCLUSION_RATE)
                )
                +
                Number(lockedBalance)
            )
            ,
            0
        )))
        :
        null
}

export const doAllArgumentsPassValidation = (args) => Object.values(args).reduce((acc, curr) => acc + curr.hidden ? 0 : (curr.validate(curr.value) ? 0 : 1), 0) === 0
export const doNoArgumentErrorsExist = (args) => Object.values(args).reduce((acc, curr) => acc + curr.hidden ? 0 : (curr.errors && curr.errors.length > 0 ? 1 : 0), 0) === 0
