import {useEffect} from "react"
import * as fcl from "@onflow/fcl"
import {atomFamily, selectorFamily, useRecoilState, useSetRecoilState} from "recoil"
import {getEpochCurrentMetadata} from "../interactions/get-epoch-current-metadata.js"
import {INIT, INIT_PROCESSING, IDLE, PROCESSING, ERROR} from "../global/constants.js"

export const valueAtom = atomFamily({
    key: "epoch-metadata::state",
    default: null
})

export const statusAtom = atomFamily({
    key: "epoch-metadata::status",
    default: INIT,
})

export function useEpochCurrentMetadata() {
    const [epochCurrentMetadata, setEpochCurrentMetadata] = useRecoilState(valueAtom("current"))
    const [status, setStatus] = useRecoilState(statusAtom("current"))

    async function refresh() {
        try {
            setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
            await getEpochCurrentMetadata().then(setEpochCurrentMetadata)
            setStatus(IDLE)
        } catch (e) {
            setStatus(ERROR)
        }
    }

    useEffect(() => {
        async function load() {
            try {
                setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
                await getEpochCurrentMetadata().then(_ecm => {
                    setEpochCurrentMetadata(_ecm)
                    setStatus(IDLE)
                })
            } catch (e) {
                setStatus(ERROR)
            }
        }
        load()
    }, [])

    return {
        epochCurrentMetadata,
        status,
        fetch: refresh,
        refresh
    }
}