import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `
import FlowStorageFees from 0xSTORAGEFEESADDRESS
import FlowServiceAccount from 0xFLOWSERVICEACCOUNT

pub struct StorageInfo {
    pub let storageUsed: UInt64
    pub let storageCapacity: UInt64

    // From FlowStorageFees contract
    pub let minimumStorageReservation: UFix64
    pub let storageMegaBytesPerReservedFLOW: UFix64
    pub let useableAccountBalance: UFix64

    init(address: Address) {
        let acct = getAccount(address)
            
        self.storageUsed = acct.storageUsed
        self.storageCapacity = acct.storageCapacity

        self.minimumStorageReservation = FlowStorageFees.minimumStorageReservation
        self.storageMegaBytesPerReservedFLOW = FlowStorageFees.storageMegaBytesPerReservedFLOW

        let balance = FlowServiceAccount.defaultTokenBalance(acct)
        var reserved = UFix64(acct.storageUsed)/UFix64(1000000) / FlowStorageFees.storageMegaBytesPerReservedFLOW
        if reserved < FlowStorageFees.minimumStorageReservation {
            reserved = FlowStorageFees.minimumStorageReservation
        }
        if balance < reserved {
            self.useableAccountBalance = UFix64(0)
        } else {
            self.useableAccountBalance = balance - reserved
        }
    }
}

pub fun main(address: Address): StorageInfo {
    return StorageInfo(address: address)
}
`

export const getAccountStorageInfo = async (address) => {
    const storageInfo = await fcl.decode(await fcl.send([
        fcl.script(CODE),
        fcl.args([fcl.arg(address, t.Address)])
    ]))

    return {
        ...storageInfo,
        ratioUsed: Number(storageInfo.storageCapacity) > 0 ?
            Intl.NumberFormat(
                "en-ca",
                {
                    maximumFractionDigits: 8,
                    minimumFractionDigits: 8,
                    style: 'decimal',
                    useGrouping: false
                }
            ).format(
                Number(storageInfo.storageUsed) / Number(storageInfo.storageCapacity)
            )
            :
            0,
        percentUsed: Number(storageInfo.storageCapacity) > 0 ?
            Intl.NumberFormat(
                "en-ca",
                {
                    maximumFractionDigits: 8,
                    minimumFractionDigits: 8,
                    style: 'decimal',
                    useGrouping: false
                }
            ).format(
                Number((Number(storageInfo.storageUsed) / Number(storageInfo.storageCapacity)) * 100)
            )
            :
            0,
    }
}
