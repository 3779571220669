import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `
pub struct _PublicKey {
  
  pub var signatureAlgorithm: SignatureAlgorithm
  pub var publicKey: String

  init(pubKey: PublicKey) {
    
    self.signatureAlgorithm = pubKey.signatureAlgorithm
    self.publicKey = String.encodeHex(pubKey.publicKey)
  }
}

pub struct _AccountKey {
  pub var hashAlgorithm: HashAlgorithm
  pub var isRevoked: Bool
  pub var weight: UFix64
  pub var publicKey: _PublicKey
  pub var keyIndex: Int
 
  init(acctKey: AccountKey) {
     self.hashAlgorithm = acctKey.hashAlgorithm
     self.isRevoked = acctKey.isRevoked
     self.weight = acctKey.weight
     self.keyIndex = acctKey.keyIndex
     self.publicKey = _PublicKey(pubKey: acctKey.publicKey)
  }
}

pub fun main(addr: Address): AnyStruct {
  let acct = getAccount(addr)

  let keys: {Int: AnyStruct} = {}

  var keyIndex: Int = 0
  var didNotFindKey: Bool = false

  while(!didNotFindKey) {
    let currKey = acct.keys.get(keyIndex: keyIndex)
    if let _currKey = currKey {
        keys[keyIndex] = _AccountKey(acctKey: _currKey)
    } else {
        didNotFindKey = true
    }
    keyIndex = keyIndex + 1
  }

  return keys
}
`

export const getAcctKeys = async (address) => {
    return await fcl.decode(await fcl.send([
        fcl.script(CODE),
        fcl.args([fcl.arg(address, t.Address)])
    ]))
}
