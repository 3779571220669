import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { useSpring, animated, config } from 'react-spring'
import { Redirect } from "react-router-dom"
import * as fcl from "@onflow/fcl"
import logogif from "../assets/logogif.gif"
import { LinkArrow } from "../assets/link-arrow.js"
import { screenMinWidth } from "../mixins/breakpoints.js"
import { NetworkPicker } from "../components/Network-picker"
import { LogoWithNetwork } from "../components/Logo"

const StyledAuth = styled.div`
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    box-sizing: border-box;
`

const StyledAuthTop = styled(animated.div)`
    position: absolute;
    height: 60vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: #F4C6FA;
`

const StyledAuthBottom = styled(animated.div)`
    position: absolute;
    height: 40vh;
    width: 100vw;
    bottom: 0;
    left: 0;
    background-color: #71DFE3;
`

const StyledAuthInner = styled(animated.div)`
    width: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
`

const StyledAuthInnerCard = styled.div`
    padding: 2rem;
    margin-bottom: 2rem;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border-radius: 0.5rem;
    background-color: white;
`

const StyledAuthLink = styled.a`
    margin: 1rem;
    font-size: 1rem;
    line-height: 1rem;
    word-break: keep-all;
    color: var(--text-secondary);
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const StyledAuthLinkImg = styled.div`
    margin-left: 0.5rem;

    svg {
        path {
            fill: var(--text-secondary);
        }
    }
`

const StyledLogoGifWrapper = styled(animated.div)`
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
`

const StyledLogoGif = styled.img`
    margin: auto;
    max-height: unset;
    max-width: 100vw;

    ${screenMinWidth.sm`
        max-height: 15rem;
    `}
`

const StyledHeaderDescription = styled.div`
    width: 25rem;
    max-width: 100%;
    text-align: center;
    font-size: 1rem;
    margin-bottom: 2rem;
    color: black;
`

const StyledButtonWrapper = styled.div`
    margin-bottom: 1rem;
    width: 25rem;
    max-width: 100%;
    position: relative;
`

const StyledButton = styled.button`
    width: 100%;
    padding: 0.5rem 2rem 0.5rem 2rem;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 0.5rem;
    border: 0.1rem solid black;
    background-color: ${props => props.isSolid ? "black" : "white"};
    color: ${props => props.isSolid ? "white" : "black"};
    cursor: pointer;
    word-break: keep-all;
    font-weight: bold;

    transition: background-color 0.2s, color 0.2s;

    -webkit-appearance: none;
    -moz-appearance: none;
`

const StyledDisclaimer = styled.span`
    width: 16rem;
    text-align: center;
    font-size: 0.75rem;
    margin-bottom: 1rem;
    color: black;
`

const StyledDisclaimerLink = styled.a`
    text-decoration: underline;
    color: black;
    cursor: pointer;
`

const StyledNetworkPicker = styled(NetworkPicker)`
    margin: 1rem;
    justify-content: center;
`

export const Auth = () => {
    const [showLogoGif, setShowLogoGif] = useState(true)
    const [currentUserAddr, setCurrentUserAddr] = useState(null)

    useEffect(() => fcl.currentUser().subscribe(currentUser => setCurrentUserAddr(currentUser.addr)), [])

    useEffect(() => {
        setTimeout(() => setShowLogoGif(false), 4000)
    }, [])

    const gifTransitions = useSpring({
        to: {
            opacity: showLogoGif ? 1 : 0,
            transform: !showLogoGif ? "translateY(0%)" : "translateY(2%)",
            zIndex: !showLogoGif ? "-1" : "10"
        },
        config: config.gentle,
    })

    const topTransitions = useSpring({
        to: {
            height: !showLogoGif ? "60vh" : "0vh",
            opacity: !showLogoGif ? 1 : 0,
            transform: !showLogoGif ? "translateY(0%)" : "translateY(2%)"
        },
        config: config.slow,
    })

    const bottomTransitions = useSpring({
        to: {
            height: !showLogoGif ? "40vh" : "0vh",
            opacity: !showLogoGif ? 1 : 0,
            transform: !showLogoGif ? "translateY(0%)" : "translateY(2%)"
        },
        config: config.slow,
    })

    const innerTransitions = useSpring({
        to: {
            opacity: !showLogoGif ? 1 : 0,
            transform: !showLogoGif ? "translateY(0%)" : "translateY(8%)"
        },
        config: config.slow,
    })

    const StyledSection = styled.div`
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        align-self: center;
        width: 100%;
        margin: 0.5rem;
    `
    const StyledText = styled.div`
        inline-size: auto;
        overflow-wrap: break-word;
        color: var(--text-normal);
        font-size: 0.75rem;
    `

    return currentUserAddr ? <Redirect to={`/account/${fcl.withPrefix(currentUserAddr)}`} /> : (
        <StyledAuth>
            <Helmet><title>Flow Port</title></Helmet>
            <StyledLogoGifWrapper style={gifTransitions}><StyledLogoGif src={logogif} /></StyledLogoGifWrapper>
            <StyledAuthInner style={innerTransitions}>
                <StyledAuthInnerCard>
                    <LogoWithNetwork centered={true} />
                    <StyledHeaderDescription>
                        Welcome to Flow Port, your portal to the decentralized world of Flow. Sign in or sign up to access your Flow account, interact with the blockchain, manage your assets and more.
                    </StyledHeaderDescription>
                    <StyledButtonWrapper>
                        <StyledButton isSolid={false} style={{ marginBottom: "1rem" }} onClick={fcl.authenticate}>Sign In</StyledButton>
                        <StyledButton isSolid={true} onClick={fcl.authenticate}>Sign Up</StyledButton>
                    </StyledButtonWrapper>
                    <StyledAuthLink href="https://docs.onflow.org/flow-port/" target="_blank">Learn more about Flow Port<StyledAuthLinkImg><LinkArrow /></StyledAuthLinkImg></StyledAuthLink>
                    <StyledSection>
                        <StyledNetworkPicker />
                        <StyledText>Looking to connect to different network?</StyledText>
                    </StyledSection>
                </StyledAuthInnerCard>
                <StyledDisclaimer>By signing up or signing in, you agree to the <StyledDisclaimerLink href="/terms" target="_blank">Terms of Service</StyledDisclaimerLink> and <StyledDisclaimerLink href="/privacy" target="_blank">Privacy Policy</StyledDisclaimerLink>.</StyledDisclaimer>
            </StyledAuthInner>
            <StyledAuthTop style={topTransitions} />
            <StyledAuthBottom style={bottomTransitions} />
        </StyledAuth>
    )
}