import React, {useState, useEffect} from "react"
import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import { Transaction } from "../../transaction.js"

import { useAllStakeInfo } from "../../../../hooks/use-all-stake-info.js"

import {
  template as stakingCollectionUpdateNetworkingAddressTemplate,
  CODE as stakingCollectionUpdateNetworkingAddressCode,
  HASH as stakingCollectionUpdateNetworkingAddressHash,
  DESCRIPTION as stakingCollectionUpdateNetworkingAddressDescription,
} from "@onflow/six-stakingcollection-update-networking-address"

import { TRANSACTION_MANIFEST_VALUE, TRANSACTION_MANIFEST_ARG } from "../../transaction-manifest-constructors.js"

import {
  validateString,
} from "../../transaction-validators.js"

import {
  xformString,
} from "../../transaction-xforms.js"

export const Manifest = ({ 
  qp,
  hash,
  txn,
  showCode,
  consent,
  qpString,
  submitTransaction,
  submitTransactionLocked,
  currentUserAddr,
  hiddenArgs,
  lockedArgs,
}) => {
  const [manifestValue, setManifestValue] = useState(new TRANSACTION_MANIFEST_VALUE({
    hash: stakingCollectionUpdateNetworkingAddressHash,
    title: "Transaction - Update Networking Address",
    description: stakingCollectionUpdateNetworkingAddressDescription,
    code: stakingCollectionUpdateNetworkingAddressCode,
    args: {},
    isSix: true,
    transactionFunction: () => {}
  }))

  const { allStakeInfo } = useAllStakeInfo(currentUserAddr)

  const computeManifestValue = async () => {
    setManifestValue(
      new TRANSACTION_MANIFEST_VALUE({
        hash: stakingCollectionUpdateNetworkingAddressHash,
        disclaimer: null,
        error: null,
        title: "Transaction - Update Networking Address",
        description: stakingCollectionUpdateNetworkingAddressDescription,
        code: stakingCollectionUpdateNetworkingAddressCode,
        args: {
          0: new TRANSACTION_MANIFEST_ARG({
            title: "Node ID",
            qp: "nodeid",
            xform: xformString,
            validate: validateString,
            description: "The Node ID.",
            type: types.String,
            value: qp.get("nodeid") || "",
          }),
          1: new TRANSACTION_MANIFEST_ARG({
            title: "Networking Address",
            description: (
              <span>
                {"The new Networking Address for this node."}
                {qp.get("nodeid") &&
                allStakeInfo?.[qp.get("nodeid")] ? (
                  <span>
                    {" This nodes current Networking Address is: "}
                    <b>
                      {
                        allStakeInfo?.[qp.get("nodeid")]
                          ?.networkingAddress
                      }
                    </b>
                  </span>
                ) : (
                  ""
                )}
              </span>
            ),
            qp: "networkingaddress",
            xform: xformString,
            validate: validateString,
            value: qp.get("networkingaddress") || "",
            type: types.String,
            hidden:
              hiddenArgs.includes("networkingaddress"),
          }),
        },
        isSix: true,
        transactionFunction: async (hash, txn, args) => {
            return await fcl.decode(await fcl.send([
                stakingCollectionUpdateNetworkingAddressTemplate({
                    proposer: fcl.currentUser().authorization,
                    authorization: fcl.currentUser().authorization,     
                    payer: fcl.currentUser().authorization,
                    nodeId: args[0].xform(args[0].value),
                    newAddress: args[1].xform(args[1].value)
                }),
                fcl.limit(9999)
            ]))
        },
      })
    )
  }

  useEffect(() => {
    computeManifestValue()
  }, [qpString, allStakeInfo])

  return (
    <Transaction 
      title={manifestValue?.title}
      description={manifestValue?.description}
      disclaimer={manifestValue?.disclaimer}
      error={manifestValue?.error}
      code={manifestValue?.code}
      isSix={manifestValue?.isSix}
      args={manifestValue?.args}
      transactionFunction={manifestValue?.transactionFunction}
      submitTransaction={submitTransaction}
      submitTransactionLocked={submitTransactionLocked}
      consent={consent}
      showCode={showCode}
      hash={hash}
      txn={txn}
      qp={qp}
    />
  )
}
