import React, {useState, useEffect} from "react"
import styled from "styled-components"
import {Link} from "react-router-dom"
import * as fcl from "@onflow/fcl"
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import {screenMinWidth} from "../../mixins/breakpoints.js"
import { UserBalancesChip } from "../../components/user-balances-chip.js"
import {DelegationGraph} from "./delegation-graph.js"
import {StakingGraph} from "./staking-graph.js"
import { getNodeInfo } from "../../interactions/get-node-info.js"
import { useAllStakeInfo } from "../../hooks/use-all-stake-info.js"
import { useTotalUnlockedFlowBalance } from "../../hooks/use-total-unlocked-flow-balance.js"
import { useTotalFlowBalance } from "../../hooks/use-total-flow-balance.js"
import { useAccountStorageInfo } from "../../hooks/use-account-storage-info.js"
import { useTotalLockedAccountFlowBalance } from "../../hooks/use-total-locked-account-flow-balance.js"
import { doesAmountReduceAcctBelowMinForStorageFees } from "../../interactions/does-amount-reduce-account-below-min-for-storage-fees"
import { LogoWithNetwork } from "../../components/Logo.js"

const StyledStake = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    word-break: break-all;

    background-color: white;

    ${screenMinWidth.sm`
        flex-direction: row;
    `}
`

const StyledStakeSidebar = styled.div`
    padding: 1.5rem 2rem 2rem 2rem;
    background-color: #f8f8f8;

    max-width: 100%;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;

    display: flex;
    flex-direction: column;

    word-break: keep-all;

    box-sizing: border-box;

    ${screenMinWidth.sm`
        border-right: 1px solid #e8e8e8;
        max-width: 25%;
        width: 25rem;
    `}
`

const StyledStakeSidebarLogoWrapper = styled(Link)`
    margin-bottom: 1rem;
`
const StyledStakeSidebarLogo = styled.img`
    height: 3rem;
    cursor: pointer;
`

const StyledStakeSidebarBackButton = styled(Link)`
    margin-bottom: 0.5rem;
    color: #6a6a6a;
    cursor: pointer;
    text-decoration: none;
`

const StyledStakeSidebarTitle = styled.h1`
    margin-bottom: 2rem;
    font-weight: bold;
    font-size: 2rem;
    line-height: 3rem;
`

const StyledStakeHeader = styled.div`
    width: 100%;
    height: 5.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0rem 1rem 0rem 1rem;
`

const StyledStakeHeaderUserWrapper = styled.div`
    margin-top: 1.5rem;
    width: 100%;
    position: relative;
    display: none;

    ${screenMinWidth.sm`
        display: flex;
        align-items: center;
    `}
`

const StyledStakeContent = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const StyledStakeOption = styled.div`
    width: 100%;
    height: 100%;
    padding: 2rem;
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const StyledAnswers = styled.div`
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
`

const StyledNavButtons = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 2rem;

    border-top: 1px solid #e8e8e8;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const StyledNavButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;

    border: none;
    border-radius: 0.5rem;
    background-color: ${props => props.isActive ? "#3745f5" : "#b3b9fd"};
    color: ${props => props.isActive ? "#ffffff" : "#ffffff61"};
    font-weight: bold;

    -webkit-appearance: none;
    -moz-appearance: none;

    cursor: pointer;
`

const Graph = {
    "1": StakingGraph,
    "2": DelegationGraph,
    // "3": StakingHelperStakerGraph,
    // "4": StakingHelperNodeOperatorGraph,
}

const _updateURL = (history, qp) => ({ param, val }) => {
    history.push("/stake-delegate-wizard?" + ( () => {qp.set(param,  val); return qp.toString();} )())
}

const getMinRequiredStake = (nodetype) => {
    switch(nodetype) {
        case ("Execution"):
            return "1250000.00"
        case ("Consensus"):
            return "500000.00"
        case ("Collection"):
            return "250000.00"
        case ("Verification"):
            return "135000.00"
        default:
            return "0.00"
    }
}

export const StakeDelegateWizard = ({ history, location }) => {
    const [currentUserAddr, setCurrentUserAddr] = useState(null)
    const [nodeInfo, setNodeInfo] = useState(null)
    const [getNodeInfoError, setGetNodeInfoError] = useState(false)
    const [isStorageFeeError, setIsStorageFeeError] = useState(false)

    const { accountStorageInfo } = useAccountStorageInfo(currentUserAddr)
    const { totalFlowBalance } = useTotalFlowBalance(currentUserAddr)
    const { totalUnlockedFlowBalance } = useTotalUnlockedFlowBalance(currentUserAddr)
    const { totalLockedAccountFlowBalance } = useTotalLockedAccountFlowBalance(currentUserAddr)
    const { allStakeInfo } = useAllStakeInfo(currentUserAddr)

    useEffect(() => {
        return fcl.currentUser().subscribe(currentUser => setCurrentUserAddr(currentUser.addr ? fcl.withPrefix(currentUser.addr) : null))
    }, [])

    const qp = new URLSearchParams(location.search)
    const updateURL = _updateURL(history, qp)

    if (!qp.get("qid") || qp.get("qid") === "null") {
        updateURL({ param: "qid", val: "1" })
    } 

    let typeId = qp.get("tid") ? qp.get("tid").toString() : "1"
    const graph = Graph[typeId]

    let questionId = qp.get("qid") ? qp.get("qid").toString() : "1"

    const isNew = qp.get("isnew") ? (qp.get("isnew") === "false" ? false : true) : null
    const hasStake = qp.get("hasstake") ? (qp.get("hasstake") === "false" ? false : true) : null
    const nodeRole = qp.get("noderole") ? qp.get("noderole") : null
    const stakeAmount = qp.get("stakeamount") ? qp.get("stakeamount") : null

    const needMinStake = qp.get("needminstake") ? (qp.get("needminstake") === "false" ? false : true) : null
    const stakeForOperator = qp.get("stakeforoperator") ? (qp.get("stakeforoperator") === "false" ? false : true) : null

    if (nodeRole && stakeAmount === null) updateURL({ param: "stakeamount", val: getMinRequiredStake(nodeRole) })

    const operatorId = qp.get("operatorid") ? qp.get("operatorid") : null
    const operatorAddress = qp.get("operatoraddress") ? qp.get("operatoraddress") : null

    const networkAddress = qp.get("networkaddress") ? qp.get("networkaddress") : null
    const networkingKey = qp.get("networkingkey") ? qp.get("networkingkey") : null
    const stakingKey = qp.get("stakingkey") ? qp.get("stakingkey") : null
    const machineAccountPublicKey = qp.get("machineaccountpublickey") ? qp.get("machineaccountpublickey") : null

    const nodeId = qp.get("nodeid") ? qp.get("nodeid") : null

    useEffect(() => {
        async function getNodeInfoByOperatorId(operatorId) {
            if (operatorId && operatorId.length === 64) {
                try {
                    setGetNodeInfoError(false)
                    if (nodeInfo) setNodeInfo(null)
                    let info = await getNodeInfo(operatorId)
                    if (info) setNodeInfo(info)
                } catch (e) {
                    setGetNodeInfoError(true)
                }   
            } else {
                setGetNodeInfoError(false)
            }
        }
        getNodeInfoByOperatorId(operatorId)
    }, [operatorId])

    useEffect(() => {
        const asyncUseEffect = async () => {
            try {
                let amt = worldState.stakeAmount.includes(".") ? worldState.stakeAmount.trim() : worldState.stakeAmount.trim() + ".0"
                if (worldState.currentUserAddr && worldState.stakeAmount) {
                    setIsStorageFeeError(await doesAmountReduceAcctBelowMinForStorageFees(worldState.currentUserAddr, amt))
                }
            } catch (e) {
                setIsStorageFeeError(false)
            }
        }
        asyncUseEffect()
    }, [stakeAmount])

    const worldState = {
        graph,
        updateURL,
        nodeInfo,
        nodeId,
        allStakeInfo,
        getNodeInfoError,
        totalFlowBalance,
        totalUnlockedFlowBalance,
        totalLockedAccountFlowBalance,
        questionId,
        isNew,
        hasStake,
        nodeRole,
        stakeAmount,
        needMinStake,
        stakeForOperator,
        operatorAddress,
        operatorId,
        networkAddress,
        networkingKey,
        stakingKey,
        machineAccountPublicKey,
        currentUserAddr,
        accountStorageInfo,
        isStorageFeeError
    }

    let _qid = questionId;
    while(graph[_qid] && graph[_qid].previous) {
        if (graph[graph[_qid].previous(worldState)] && !graph[graph[_qid].previous(worldState)].postCondition(worldState)) {
            _qid = graph[_qid].previous(worldState)
        } else {
            break;
        }
    }
    if (_qid !== questionId) updateURL({ param: "qid", val: _qid })

    return (
        <StyledStake>
            <StyledStakeSidebar>
                <StyledStakeSidebarLogoWrapper to="/">
                    <LogoWithNetwork />
                </StyledStakeSidebarLogoWrapper>
                <StyledStakeSidebarBackButton to={`/stake-delegate/${fcl.withPrefix(currentUserAddr)}`}>{'< Cancel'}</StyledStakeSidebarBackButton>
                <StyledStakeSidebarTitle>
                    { typeId == "1" && "Run your own node" }
                    { typeId == "2" && "Start Delegating" }
                    {/* { typeId == "3" && "Start delegating" }
                    { typeId == "4" && "Start delegating" } */}
                </StyledStakeSidebarTitle>
                <StyledAnswers>
                    {(() => {
                        const answers = [];
                        let curr = graph[questionId]
                        while(true) {
                            if (curr.previous(worldState) == null) break;
                            curr = graph[curr.previous(worldState)]
                        }
                        while(curr) {
                            answers.push(curr.answer(worldState))
                            curr = graph[curr.next(worldState)]
                        }
                        return answers;
                    })()}
                </StyledAnswers>
            </StyledStakeSidebar>
            <StyledStakeContent>
                <StyledStakeHeader>
                    <StyledStakeHeaderUserWrapper>
                        <UserBalancesChip currentUserAddr={currentUserAddr} flowBalance={totalFlowBalance} fatBalance={null} />
                    </StyledStakeHeaderUserWrapper>
                </StyledStakeHeader>
                <StyledStakeOption>
                    {graph[questionId].question(worldState)}
                </StyledStakeOption>
                <StyledNavButtons>
                    { graph[questionId].previous(worldState) && <StyledNavButton style={{ width: "20rem", marginRight: "2rem" }} isActive={graph[questionId].previous(worldState)} onClick={() => updateURL({ param: "qid", val: graph[questionId].previous(worldState) }) }><FaArrowLeft style={{ marginRight: "0.5rem "}} /> Back</StyledNavButton> }
                    { !graph[questionId].next(worldState) ?  
                        <StyledNavButton style={{ width: "20rem", marginRight: "2rem" }} isActive={graph[questionId].postCondition(worldState)} onClick={() => graph[questionId].postCondition(worldState) ? window.location = graph[questionId].redirect(worldState) : null}>Continue</StyledNavButton>
                        :
                        <StyledNavButton style={{ width: "20rem" }} isActive={graph[questionId].postCondition(worldState)} onClick={() => graph[questionId].postCondition(worldState) ? updateURL({ param: "qid", val: graph[questionId].next(worldState) }) : null }>Next <FaArrowRight style={{ marginLeft: "0.5rem "}} /></StyledNavButton>
                    }
                </StyledNavButtons>
            </StyledStakeContent>
        </StyledStake>
    )
}