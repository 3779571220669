import {useEffect} from "react"
import * as fcl from "@onflow/fcl"
import {atomFamily, selectorFamily, useRecoilState, useSetRecoilState} from "recoil"
import {getUnlockedLockedAccountFlowBalance} from "../interactions/get-unlocked-locked-account-flow-balance.js"
import {INIT, INIT_PROCESSING, ERROR, IDLE, PROCESSING} from "../global/constants.js"

export const valueAtom = atomFamily({
    key: "get-unlocked-locked-account-flow-balance::state",
    default:  null
})

export const statusAtom = atomFamily({
    key: "get-unlocked-locked-account-flow-balance::status",
    default: INIT,
})

export function useUnlockedLockedAccountFlowBalance(address) {
    const [unlockedLockedAccountFlowBalance, setUnlockedLockedAccountFlowBalance] = useRecoilState(valueAtom(address))
    const [status, setStatus] = useRecoilState(statusAtom(address))

    async function refresh() {
        try {
            setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
            await getUnlockedLockedAccountFlowBalance(address).then(setUnlockedLockedAccountFlowBalance)
            setStatus(IDLE)
        } catch (e) {
            setStatus(ERROR)
        }
    }

    useEffect(() => {
        async function load({ address }) {
            if (address) {
                try {
                    setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
                    await getUnlockedLockedAccountFlowBalance(address).then(_unlockedLockedAccountFlowBalance => {
                        setUnlockedLockedAccountFlowBalance(_unlockedLockedAccountFlowBalance)
                        setStatus(IDLE)
                    })
                } catch (e) {
                    setStatus(ERROR)
                }
            }
        }
        load({ address })
    }, [address])

    return {
        unlockedLockedAccountFlowBalance,
        status,
        fetch: refresh,
        refresh
    }
}