import React from "react"
import styled from "styled-components"
import {Link} from "react-router-dom"
import {FaInfoCircle, FaLock} from "react-icons/fa"
import {Tooltip} from "../../components/tooltip.js"
import {GetFlowIcon} from "../../assets/get-flow-icon.js"
import {SendFlowIcon} from "../../assets/send-flow-icon.js"
import {
    StyledAccountItemFullwidth,
    StyledAccountItemTitle,
    StyledAccountItemTitleImage,
    StyledAccountItemHalfwidth,
} from "./account-components.js"

const StyledAccountBalanceItemFullwidth = styled(StyledAccountItemFullwidth)`
    position: relative;
    color: white;

    @keyframes animatebg {
        0% {
            background-position: 0% 0;
        }
        50% {
            background-position: 300% 0;
        }
        100% {
            background-position: 0% 0;
        }
    }

    background: ${props => {
        switch (props.type) {
            case "FUSD": 
                return `linear-gradient(
                    120deg,
                    rgba(36, 183, 31, 1),
                    rgba(31, 183, 127, 1),
                    rgba(31, 170, 183, 1),
                    rgba(31, 183, 127, 1),
                    rgba(36, 183, 31, 1)
                )`
            case "USDC": 
                return `linear-gradient(
                    120deg,
                    rgba(113,223,227,1),
                    rgba(113,223,227,1),
                    rgba(52,161,184,1),
                    rgba(44,168,215,1),
                    rgba(55,69,245,1)
                )`
            case "FLOW":
                return `linear-gradient(
                    120deg,
                    rgba(89,100,227,1),
                    rgba(141,89,227,1),
                    rgba(152,89,227,1),
                    rgba(141,89,227,1),
                    rgba(89,100,227,1)
                )`
            default:
                return `linear-gradient(
                    120deg,
                    rgba(89,100,227,1),
                    rgba(141,89,227,1),
                    rgba(152,89,227,1),
                    rgba(141,89,227,1),
                    rgba(89,100,227,1)
                )`
        }
    }};
    background-size: 400%;

    animation: animatebg 28s ease-in-out 0s infinite normal;    
`

const StyledAccountBalanceItemHalfwidth = styled(StyledAccountItemHalfwidth)`
    position: relative;
    color: white;

    @keyframes animatebg {
        0% {
            background-position: 0% 0;
        }
        50% {
            background-position: 300% 0;
        }
        100% {
            background-position: 0% 0;
        }
    }

    background: ${props => {
        switch (props.type) {
            case "FUSD": 
                return `linear-gradient(
                    120deg,
                    rgba(36, 183, 31, 1),
                    rgba(31, 183, 127, 1),
                    rgba(31, 170, 183, 1),
                    rgba(31, 183, 127, 1),
                    rgba(36, 183, 31, 1)
                )`
            case "USDC": 
                return `linear-gradient(
                    120deg,
                    rgba(55,69,245,1) 0%,
                    rgba(87,99,251,1) 25%,
                    rgba(99,142,254,1) 50%,
                    rgba(87,99,251,1) 75%,
                    rgba(55,69,245,1) 100%
                )`
            case "FLOW":
                return `linear-gradient(
                    120deg,
                    rgba(55,69,245,1),
                    rgba(44,168,215,1),
                    rgba(81,197,221,1),
                    rgba(44,168,215,1),
                    rgba(55,69,245,1)
                )`
            default:
                return `linear-gradient(
                    120deg,
                    rgba(89,100,227,1),
                    rgba(141,89,227,1),
                    rgba(152,89,227,1),
                    rgba(141,89,227,1),
                    rgba(89,100,227,1)
                )`
        }
    }};
    background-size: 400%;

    animation: animatebg 28s ease-in-out 0s infinite normal;    
`

const StyledAccountBalanceItemTitle = styled(StyledAccountItemTitle)`
    &:after {
        left: 1.5rem; 
    }
`

const StyledAccountUserBalances = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

const StyledAccountUserBalanceItem = styled.div`
    position: relative;
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: black;
    box-sizing: border-box;
    flex-wrap: wrap;
`

const StyledAccountUserClaimableBalanceItem = styled(StyledAccountUserBalanceItem)`
    padding-bottom: 0.5rem; 
    padding-left:  2rem;
` 

const StyledAccountUserTotalBalanceItem = styled(StyledAccountUserBalanceItem)`
    padding-top: 0.5rem; 
    border-top: 2px solid black;
` 

const StyledAccountUserBalanceItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
`

const StyledAccountUserBalanceItemAction = styled(Link)`
    color: #3745f5;
    border: 1px solid #3745f5;
    text-decoration: none;
    cursor: pointer;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.75rem;
    line-height: 0.75rem;

    &:hover {
        background-color: #3745f5;
        color: white;
    }

    transition: background-color 0.2s, color 0.2s;
`

const StyledAccountUserBalanceItemTitle = styled.div`
    margin-right: 1rem;
    color: black;
`

const StyledAccountUserBalanceItemContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
`

const StyledAccountUserBalanceTotalWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0.5rem 0rem 0.5rem 0rem;
`

const StyledAccountUserBalanceQuantity = styled.div`
    margin-right: 0.5rem;
    color: ${({color}) => color ? color: "black"};
    font-size: 1.25rem;
`

const StyledAccountUserBalanceCurrency = styled.div`
    color: ${({color}) => color ? color: "black"};
    font-size: 0.75rem;
    font-weight: bold;
`

const StyledAccountUserBalancesActions = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
`

const StyledAccountUserBalancesAction = styled(Link)`
    margin-top: 1rem;
    margin-right: 1rem;
    border: 1px solid white;
    border-radius: 2rem;
    padding: 0.25rem 1rem 0.25rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    text-decoration: none;
    color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;

    transition: color 0.2s, background 0.2s, border 0.2s;

    svg {
        margin-right: 0.5rem;
        path {
            transition: fill 0.2s;
            fill: white;
        }
    }

    &:hover {
        color: black;
        border: 1px solid white;
        background: white;
        svg {
            margin-right: 0.5rem;
            path {
                fill: black;
            }
        }
    }
`

const StyledAccountUserBalancesLink = styled.a`
    margin-top: 1rem;
    margin-right: 1rem;
    border: 1px solid white;
    border-radius: 2rem;
    padding: 0.25rem 1rem 0.25rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    text-decoration: none;
    color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;

    transition: color 0.2s, background 0.2s, border 0.2s;

    svg {
        margin-right: 0.5rem;
        path {
            transition: fill 0.2s;
            fill: white;
        }
    }

    &:hover {
        color: black;
        border: 1px solid white;
        background: white;
        svg {
            margin-right: 0.5rem;
            path {
                fill: black;
            }
        }
    }
`

export const AccountBalanceItem = ({
    balanceType = "FLOW",
    symbol = null,
    title = "",
    balance,
    tooltipTitle = "",
    tooltipSubtitle = "",
    sendLink = "",
    enableSend = false,
    getLink = "",
    enableGet = false,
    isGetExternalLink = false,
    setupLink = "",
    enableSetup = false,
    enableShowBreakdown = false,
    showBreakdownTitle = "",
    showBreakdownLink = "",
    fullwidth = false,
}) => {
    const Wrapper = fullwidth ? StyledAccountBalanceItemFullwidth : StyledAccountBalanceItemHalfwidth
    return (
        <Wrapper type={balanceType}>
            <StyledAccountBalanceItemTitle><StyledAccountItemTitleImage src={symbol} />{balanceType} Balance</StyledAccountBalanceItemTitle>
            <StyledAccountUserBalances style={{marginTop: "1rem"}}>
                <StyledAccountUserBalanceItem style={{marginTop: "0rem"}}>
                    <StyledAccountUserBalanceItemContent>
                        <StyledAccountUserBalanceTotalWrapper>
                            <StyledAccountUserBalanceQuantity color="white">
                                { !enableSetup ? balance : "Not Configured" }
                            </StyledAccountUserBalanceQuantity>
                            <StyledAccountUserBalanceCurrency color="white">
                                { !enableSetup && balanceType }
                            </StyledAccountUserBalanceCurrency>
                        </StyledAccountUserBalanceTotalWrapper>
                        { tooltipTitle && tooltipSubtitle && 
                            <StyledAccountUserBalanceTotalWrapper>
                                <Tooltip 
                                    title={tooltipTitle}
                                    subtitle={tooltipSubtitle}
                                    TitleIcon={FaLock}
                                    Icon={FaInfoCircle} 
                                />
                            </StyledAccountUserBalanceTotalWrapper>
                        }
                    </StyledAccountUserBalanceItemContent>
                </StyledAccountUserBalanceItem>
            </StyledAccountUserBalances>
            <StyledAccountUserBalancesActions>
                { enableSend && <StyledAccountUserBalancesAction to={sendLink}><SendFlowIcon />Send</StyledAccountUserBalancesAction> }
                { enableGet && (
                    isGetExternalLink ? 
                        <StyledAccountUserBalancesLink href={getLink} target="_blank"><GetFlowIcon />Get</StyledAccountUserBalancesLink> 
                        : <StyledAccountUserBalancesAction to={getLink}><GetFlowIcon />Get</StyledAccountUserBalancesAction> 
                )}
                { enableSetup && <StyledAccountUserBalancesAction to={setupLink}><GetFlowIcon />Setup</StyledAccountUserBalancesAction> }
                { enableShowBreakdown && <StyledAccountUserBalancesAction to={showBreakdownLink}>{showBreakdownTitle}</StyledAccountUserBalancesAction> }
            </StyledAccountUserBalancesActions>
        </Wrapper>
    )
}