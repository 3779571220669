import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import LockedTokens from 0xLOCKEDTOKENADDRESS
pub fun main(address: Address): UFix64 {
    let account = getAccount(address)

    let lockedAccountInfoCap = account
      .getCapability
      <&LockedTokens.TokenHolder{LockedTokens.LockedAccountInfo}>
      (LockedTokens.LockedAccountInfoPublicPath)
    if lockedAccountInfoCap == nil || !(lockedAccountInfoCap!.check()) {
        return UFix64(0)
    }
    
    let lockedAccountInfoRef = lockedAccountInfoCap!.borrow()!
    let lockedBalance = lockedAccountInfoRef.getLockedAccountBalance()
    let lockedBalanceUnlocked = lockedAccountInfoRef.getUnlockLimit()

    if lockedBalance == UFix64(0) {
        return UFix64(0)
    }

    if lockedBalanceUnlocked > lockedBalance {
        return lockedBalance
    }

    return lockedBalanceUnlocked
}
`

export const getUnlockedLockedAccountFlowBalance = async (address) => {
    return await fcl.decode(await fcl.send([
        fcl.script(CODE),
        fcl.args([fcl.arg(address, t.Address)])
    ]))
}
