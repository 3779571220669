import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { screenMinWidth } from "../mixins/breakpoints.js"

const StyledHeaderChip = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 1rem;
    border: 0.1rem solid #E8E8E8;
    background-color: #ffffff;
    white-space: nowrap;
    margin: 0 0.5rem;
    display: none;

    ${screenMinWidth.sm`
        display: flex;
    `}
`

const StyledStatusLink = styled.div`
    display: flex;
    cursor: pointer;
    margin-right: 0.5rem;
    opacity: 0.85;
    a:link, 
    a:visited {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 400;
        margin-left: 0.25rem;
        color: ${props =>
        props.state === "warning" ? "orange" :
            props.state === "error" ? "#e95f43" : "#2fcc66"};
        margin-right: 0.5rem;
        display: inherit;
        > div {
            margin-left: 0.5rem;
            display: block;
        }
    }
    color: #000000;     
`
const STATES = {
    "none": {
        message: "Healthy",
        state: "info"
    },
    "operational": {
        message: "Healthy",
        state: "info"
    },
    "major_outage": {
        message: "Outage",
        state: "error"
    },
    "under_maintenance": {
        message: "Under Maintenance",
        state: "error"
    }
}
/**
 * 
 * @param {string} type
 * "chip" is for chip in header
 * "banner" is for banner to tell user there is a network issue 
 * @returns 
 */
export const NetworkStatus = ({ type = "chip" }) => {
    const [status, setStatus] = useState({ message: "Loading ...", state: "info" })
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        setMounted(true)
        fetch("https://status.onflow.org/api/v2/status.json")
            .then(response => response.json())
            .then(data => {
                let message = "Unknown";
                let state = "warning";
                const indicator = data["status"]?.indicator
                const networkState = STATES[indicator]
                if (networkState) {
                    message = networkState.message
                    state = networkState.state
                } else if (indicator) {
                    message = indicator.replace("_", " ");
                }
                if (mounted) {
                    setStatus({ message, state })
                }
            })
        return () => setMounted(false)
    }, [mounted])

    if (type === "chip") {
        return (
            <StyledHeaderChip state={status.state}>
                <StyledStatusLink state={status.state}>
                    Status: <a href={"https://status.onflow.org/"} target="_blank" rel="noopener noreferrer">
                        {status.message}
                    </a>
                </StyledStatusLink>
            </StyledHeaderChip>
        )
    } else {
        return (
            <>
                <div>Network Status Banner </div>
            </>
        )
    }
}