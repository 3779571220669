import React from "react"
import styled from "styled-components"
import * as fcl from "@onflow/fcl"
import { HASH as stakingCollectionRegisterNodeHash } from "@onflow/six-stakingcollection-register-node"
import { HASH as sendFlowTokenHash } from "@onflow/six-transfer-tokens"
import {Button} from "../../components/button.js"
import circleCheck from "../../assets/circle-check.svg"
import ExecutionIcon from "../../assets/execution-icon.png"
import CollectionIcon from "../../assets/collection-icon.png"
import VerificationIcon from "../../assets/verification-icon.png"
import ConsensusIcon from "../../assets/consensus-icon.png"
import AccessIcon from "../../assets/access-icon.png"
import logomark from "../../assets/logomark.svg"
import {Lock} from "../../assets/lock.js"
import {Unlock} from "../../assets/unlock.js"
import {FaExclamationCircle,FaExclamationTriangle} from "react-icons/fa"
import {
    COLLECTION_NODE_RECCOMENDED_FLOW_BALANCE,
    CONSENSUS_NODE_RECCOMENDED_FLOW_BALANCE,
} from "../../global/constants.js"
import {
    StyledAnswer,
    StyledAnswerNumber,
    StyledAnswerText,
    StyledAnswerTitle,
    StyledAnswerSubtitle,
    StyledAnswerImg,
    StyledQuestion,
    StyledQuestionTitle,
    StyledQuestionAnswersRow,
    StyledQuestionAnswersColumn,
    StyledQuestionDescription,
    StyledQuestionText,
    StyledQuestionAnswerButton,
    StyledQuestionAnswerRadioWrapper,
    StyledQuestionAnswerInputRadio,
    StyledQuestionAnswerInputRadioLabelText,
    StyledQuestionAnswerInputRadioLabelTitle,
    StyledQuestionAnswerInputRadioLabelSubtitle,
    StyledQuestionAnswerInputRadioLabelImage,
    StyledQuestionAnswerInputRadioLabel,
    StyledQuestionAnswerInputTitle,
    StyledQuestionAnswerInputFlow,
    StyledQuestionAnswerInputImg,
    StyledQuestionAnswerInputWrapper,
    StyledQuestionAnswerInputError,
    StyledQuestionAnswerInputResponse,
    StyledQuestionAnswerInputResponseLeft,
    StyledQuestionAnswerInputResponseRight,
    StyledQuestionAnswerInputResponseIcon,
    StyledQuestionAnswerInput,
    StyledQuestionAnswerInputWarning,
    StyledQuestionAnswerInputWarningLink
} from "./graph-components.js"

const getMinRequiredStake = (nodetype) => {
    switch(nodetype) {
        case ("Execution"):
            return "1250000.00"
        case ("Consensus"):
            return "500000.00"
        case ("Collection"):
            return "250000.00"
        case ("Verification"):
            return "135000.00"
        case ("Access"):
                return "0.00"
        default:
            return "0.00"
    }
}

export const StakingGraph = {
    "1": {
        id: "1",
        _description: "What type of now will the user be running?",
        postCondition: worldState => typeof worldState.nodeRole !== "" && worldState.nodeRole !== null,
        next: worldState => worldState.nodeRole === "Access" ? "3-terminal" : "2",
        previous: worldState => null,
        question: worldState => (
            <StyledQuestion>
                <StyledQuestionTitle>Which role of node will you be running?</StyledQuestionTitle>
                <StyledQuestionAnswersColumn>
                    <StyledQuestionAnswerRadioWrapper onClick={() => { worldState.updateURL({ param: "noderole", val: "Collection" }); worldState.updateURL({ param: "stakeamount", val: getMinRequiredStake("Collection") }); }}>
                        <StyledQuestionAnswerInputRadio type="radio" name="noderole" id="collection" checked={worldState.nodeRole === "Collection"}/>
                        <StyledQuestionAnswerInputRadioLabel htmlFor="collection">
                            <StyledQuestionAnswerInputRadioLabelText>
                                <StyledQuestionAnswerInputRadioLabelTitle>Collection</StyledQuestionAnswerInputRadioLabelTitle>
                                <StyledQuestionAnswerInputRadioLabelSubtitle>Min Stake: 250,000</StyledQuestionAnswerInputRadioLabelSubtitle>
                            </StyledQuestionAnswerInputRadioLabelText>
                            <StyledQuestionAnswerInputRadioLabelImage src={CollectionIcon} />
                        </StyledQuestionAnswerInputRadioLabel>
                    </StyledQuestionAnswerRadioWrapper>
                    <StyledQuestionAnswerRadioWrapper onClick={() => { worldState.updateURL({ param: "noderole", val: "Consensus" }); worldState.updateURL({ param: "stakeamount", val: getMinRequiredStake("Consensus") });}}>
                        <StyledQuestionAnswerInputRadio type="radio" name="noderole" id="consensus" checked={worldState.nodeRole === "Consensus"}/>
                        <StyledQuestionAnswerInputRadioLabel htmlFor="consensus">
                            <StyledQuestionAnswerInputRadioLabelText>
                                <StyledQuestionAnswerInputRadioLabelTitle>Consensus</StyledQuestionAnswerInputRadioLabelTitle>
                                <StyledQuestionAnswerInputRadioLabelSubtitle>Min Stake: 500,000</StyledQuestionAnswerInputRadioLabelSubtitle>
                            </StyledQuestionAnswerInputRadioLabelText>
                            <StyledQuestionAnswerInputRadioLabelImage src={ConsensusIcon} />
                        </StyledQuestionAnswerInputRadioLabel>
                    </StyledQuestionAnswerRadioWrapper>
                    <StyledQuestionAnswerRadioWrapper onClick={() => { worldState.updateURL({ param: "noderole", val: "Execution" }); worldState.updateURL({ param: "stakeamount", val: getMinRequiredStake("Execution") }); }}>
                        <StyledQuestionAnswerInputRadio type="radio" name="noderole" id="execution" checked={worldState.nodeRole === "Execution"}/>
                        <StyledQuestionAnswerInputRadioLabel htmlFor="execution">
                            <StyledQuestionAnswerInputRadioLabelText>
                                <StyledQuestionAnswerInputRadioLabelTitle>Execution</StyledQuestionAnswerInputRadioLabelTitle>
                                <StyledQuestionAnswerInputRadioLabelSubtitle>Min Stake: 1,250,000</StyledQuestionAnswerInputRadioLabelSubtitle>
                            </StyledQuestionAnswerInputRadioLabelText>
                            <StyledQuestionAnswerInputRadioLabelImage src={ExecutionIcon} />
                        </StyledQuestionAnswerInputRadioLabel>
                    </StyledQuestionAnswerRadioWrapper>
                    <StyledQuestionAnswerRadioWrapper onClick={() => { worldState.updateURL({ param: "noderole", val: "Verification" }); worldState.updateURL({ param: "stakeamount", val: getMinRequiredStake("Verification") }); }}>
                        <StyledQuestionAnswerInputRadio type="radio" name="noderole" id="verification" checked={worldState.nodeRole === "Verification"}/>
                        <StyledQuestionAnswerInputRadioLabel htmlFor="verification">
                            <StyledQuestionAnswerInputRadioLabelText>
                                <StyledQuestionAnswerInputRadioLabelTitle>Verification</StyledQuestionAnswerInputRadioLabelTitle>
                                <StyledQuestionAnswerInputRadioLabelSubtitle>Min Stake: 135,000</StyledQuestionAnswerInputRadioLabelSubtitle>
                            </StyledQuestionAnswerInputRadioLabelText>
                            <StyledQuestionAnswerInputRadioLabelImage src={VerificationIcon} />
                        </StyledQuestionAnswerInputRadioLabel>
                    </StyledQuestionAnswerRadioWrapper>
                    <StyledQuestionAnswerRadioWrapper onClick={() => { worldState.updateURL({ param: "noderole", val: "Access" }); worldState.updateURL({ param: "stakeamount", val: getMinRequiredStake("Access") }); }}>
                        <StyledQuestionAnswerInputRadio type="radio" name="noderole" id="access" checked={worldState.nodeRole === "Access"}/>
                        <StyledQuestionAnswerInputRadioLabel htmlFor="access">
                            <StyledQuestionAnswerInputRadioLabelText>
                                <StyledQuestionAnswerInputRadioLabelTitle>Access</StyledQuestionAnswerInputRadioLabelTitle>
                                <StyledQuestionAnswerInputRadioLabelSubtitle>Min Stake: 0</StyledQuestionAnswerInputRadioLabelSubtitle>
                            </StyledQuestionAnswerInputRadioLabelText>
                            <StyledQuestionAnswerInputRadioLabelImage src={AccessIcon} />
                        </StyledQuestionAnswerInputRadioLabel>
                    </StyledQuestionAnswerRadioWrapper>
                </StyledQuestionAnswersColumn>
                <StyledQuestionDescription>Not sure which role to choose? <a target="_blank" href="https://docs.onflow.org/node-operation/node-roles/">Read here</a></StyledQuestionDescription>
            </StyledQuestion>
        ),
        answer: worldState => (
            <StyledAnswer key="answer-1">
                <StyledAnswerNumber isActive={worldState.questionId === "1"}>1.</StyledAnswerNumber>
                <StyledAnswerText>
                    <StyledAnswerTitle isActive={worldState.questionId === "1"}>Stake type</StyledAnswerTitle>
                    { worldState.questionId === "1" && <StyledAnswerSubtitle>Select the node that you will be running.</StyledAnswerSubtitle> }
                </StyledAnswerText>
                <StyledAnswerImg showImage={worldState.graph["1"].postCondition(worldState)} src={circleCheck} />
            </StyledAnswer>
        )
    },
    "2": {
        id: "2",
        _description: "How much stake does the user want to input?",
        postCondition: worldState => {
            return worldState.graph["1"].postCondition(worldState) && (
                worldState.stake !== null && worldState.stake !== "" && worldState.stake !== "unset"
            )
        },
        next: worldState => "3-terminal",
        previous: worldState => "1",
        question: worldState => {
            let minStakeRequired = getMinRequiredStake(worldState.nodeRole)
            let isMinStakeError = minStakeRequired > Number(worldState.stakeAmount)
            let isInsufficientFundsError = Number(worldState.stakeAmount) > Number(worldState.totalFlowBalance)

            const isGasFeeWarning = worldState.totalFlowBalance && Number(worldState.totalFlowBalance) - Number(worldState.stakeAmount) < 0.005 

            let isError = isMinStakeError || isInsufficientFundsError || worldState.isStorageFeeError

            let lockedUsed = Math.min(Number(worldState.stakeAmount), Number(worldState.totalFlowBalance) - Number(worldState.totalUnlockedFlowBalance)).toFixed(8)
            let unlockedUsed = Math.max(Number(worldState.stakeAmount) - Number(lockedUsed), 0).toFixed(8)

            return (
                <StyledQuestion>
                    <StyledQuestionTitle>Stake Amount</StyledQuestionTitle>
                    <StyledQuestionDescription>How many tokens would you like to stake?</StyledQuestionDescription>
                    <StyledQuestionAnswerInputWrapper>
                        <StyledQuestionAnswerInputImg src={logomark} alt="Flow Logo" />
                        <StyledQuestionAnswerInputFlow type="number" isError={isError} value={worldState.stakeAmount ? (worldState.stakeAmount === "unset" ? "" : worldState.stakeAmount) : getMinRequiredStake(worldState.nodeRole)} placeholder={getMinRequiredStake(worldState.nodeRole)} onChange={e => worldState.updateURL({ param: "stakeamount", val: e.target.value || "unset" })}/>
                        { isMinStakeError &&
                            <StyledQuestionAnswerInputError>
                                <StyledQuestionAnswerInputResponseIcon><FaExclamationCircle /></StyledQuestionAnswerInputResponseIcon>
                                The minimum stake for a {worldState.nodeRole} node is {minStakeRequired} FLOW
                            </StyledQuestionAnswerInputError>
                        }
                        { isInsufficientFundsError && !isMinStakeError &&
                            <StyledQuestionAnswerInputError>
                                <StyledQuestionAnswerInputResponseIcon><FaExclamationCircle /></StyledQuestionAnswerInputResponseIcon>
                                You do not have sufficient FLOW
                            </StyledQuestionAnswerInputError>
                        }
                        { worldState.isStorageFeeError && !isInsufficientFundsError&& !isMinStakeError &&
                            <StyledQuestionAnswerInputError>
                                <StyledQuestionAnswerInputResponseIcon><FaExclamationCircle /></StyledQuestionAnswerInputResponseIcon>
                                <span>
                                    The amount input may cause this transaction to fail because it will may reduce the amount of FLOW in your account below whats needed to pay for your accounts storage usage. 
                                    <StyledQuestionAnswerInputWarningLink target="_blank" href="https://docs.onflow.org/concepts/storage#why-is-there-an-account-minimum-balance"> Learn more about storage fees here.</StyledQuestionAnswerInputWarningLink>
                                </span>
                            </StyledQuestionAnswerInputError>
                        }
                        { !isError && worldState.stakeAmount && worldState.stakeAmount !== "unset" && worldState.totalFlowBalance && 
                            <>
                                <StyledQuestionAnswerInputResponse>
                                    <StyledQuestionAnswerInputResponseLeft>
                                        <StyledQuestionAnswerInputResponseIcon><Lock /></StyledQuestionAnswerInputResponseIcon> {lockedUsed}<br/>From your Locked FLOW
                                    </StyledQuestionAnswerInputResponseLeft>
                                    <StyledQuestionAnswerInputResponseRight>
                                        {lockedUsed} / {Number(worldState.totalFlowBalance) - Number(worldState.totalUnlockedFlowBalance)}
                                    </StyledQuestionAnswerInputResponseRight>
                                </StyledQuestionAnswerInputResponse>
                                { (unlockedUsed > 0) && 
                                    <StyledQuestionAnswerInputResponse isBottom={!isGasFeeWarning}>
                                        <StyledQuestionAnswerInputResponseLeft>
                                            <StyledQuestionAnswerInputResponseIcon><Unlock /></StyledQuestionAnswerInputResponseIcon> {unlockedUsed}<br/>From your Unlocked FLOW
                                        </StyledQuestionAnswerInputResponseLeft>
                                        <StyledQuestionAnswerInputResponseRight>
                                            {unlockedUsed} / {Number(worldState.totalUnlockedFlowBalance)}
                                        </StyledQuestionAnswerInputResponseRight>
                                    </StyledQuestionAnswerInputResponse>
                                }
                                {/* { isGasFeeWarning &&
                                    <StyledQuestionAnswerInputWarning isBottom={!isStorageFeeWarning}>
                                        <StyledQuestionAnswerInputResponseIcon><FaExclamationTriangle /></StyledQuestionAnswerInputResponseIcon>
                                        <span>
                                            Warning: The amount input may cause this transaction to fail because it will may reduce the amount of FLOW in your account below whats needed to pay for this transactions gas fees.
                                            <StyledQuestionAnswerInputWarningLink> Learn more about gas fees here.</StyledQuestionAnswerInputWarningLink>
                                        </span>
                                    </StyledQuestionAnswerInputWarning>
                                } */}
                            </>
                        }
                    </StyledQuestionAnswerInputWrapper>
                    <StyledQuestionDescription>{ getMinRequiredStake(worldState.nodeRole) > parseFloat(worldState.stakeAmount) && worldState.hasStake && "You must stake the minimum amount for this node for it to be a successful request. You can input this stake now, or at a later date. Another option is to answer 'no' to being fully responsible for your node and the required stake (question 1)."}</StyledQuestionDescription>
                </StyledQuestion>
            )
        },
        answer: worldState => (
            <StyledAnswer key="answer-2">
                <StyledAnswerNumber isActive={worldState.questionId === "2"}>2.</StyledAnswerNumber>
                <StyledAnswerText>
                    <StyledAnswerTitle isActive={worldState.questionId === "2"}>Stake details</StyledAnswerTitle>
                    { worldState.questionId === "2" && <StyledAnswerSubtitle>Adjust the amount of Tokens you’d like to stake.</StyledAnswerSubtitle> }
                </StyledAnswerText>
                <StyledAnswerImg showImage={worldState.graph["2"].postCondition(worldState)} src={circleCheck} />
            </StyledAnswer>
        )
    },
    "3-terminal": {
        id: "3-terminal",
        _description: "What are the node details?",
        postCondition: worldState => {
            return worldState.graph["2"].postCondition(worldState) && (
                (worldState.operatorId !== null && worldState.operatorId !== "" && worldState.operatorId.length === 64) &&
                (worldState.networkingKey !== null && worldState.networkingKey !== "" && worldState.networkingKey.length === 128) &&
                (worldState.stakingKey !== null && worldState.stakingKey !== "" && worldState.stakingKey.length === 192) &&
                (worldState.networkAddress !== null && worldState.networkAddress !== "")
            )
        },
        next: worldState => null,
        previous: worldState => worldState.nodeRole === "Access" ? "1" : "2",
        question: worldState => (
            <StyledQuestion>
                <StyledQuestionTitle>Node Details</StyledQuestionTitle>
                <StyledQuestionDescription>Follow these <a target="_blank" href="https://docs.onflow.org/concepts/node-operation/quickstart/#step-1---run-genesis-bootstrap">directions</a> and then fill in the information below:</StyledQuestionDescription>
                <StyledQuestionAnswersColumn>
                    <StyledQuestionAnswerInputWrapper>
                        <StyledQuestionAnswerInputTitle>Node ID</StyledQuestionAnswerInputTitle>
                        <StyledQuestionAnswerInput isError={worldState.operatorId && worldState.operatorId.length !== 64 && worldState.operatorId !== ""} value={worldState.operatorId} placeholder="" onChange={e => worldState.updateURL({ param: "operatorid", val: e.target.value })}/>
                        { worldState.operatorId && worldState.operatorId.length !== 64 && worldState.operatorId !== "" &&
                            <StyledQuestionAnswerInputError>
                                Node ID must be 32 bytes (64 hex characters)
                            </StyledQuestionAnswerInputError>
                        }
                    </StyledQuestionAnswerInputWrapper>
                </StyledQuestionAnswersColumn>
                <StyledQuestionAnswersColumn>
                    <StyledQuestionAnswerInputTitle>Network Address</StyledQuestionAnswerInputTitle>
                    <StyledQuestionAnswerInput value={worldState.networkAddress} placeholder="" onChange={e => worldState.updateURL({ param: "networkaddress", val: e.target.value })}/>
                </StyledQuestionAnswersColumn>
                <StyledQuestionAnswersColumn>
                    <StyledQuestionAnswerInputWrapper>
                        <StyledQuestionAnswerInputTitle>Networking Key</StyledQuestionAnswerInputTitle>
                        <StyledQuestionAnswerInput isError={worldState.networkingKey && worldState.networkingKey !== "" && (worldState.networkingKey.length !== 128 || !(/[0-9A-Fa-f]{128}/g.test(worldState.networkingKey)))} value={worldState.networkingKey} placeholder="" onChange={e => worldState.updateURL({ param: "networkingkey", val: String(e.target.value).toLowerCase() })}/>
                        { (worldState.networkingKey && worldState.networkingKey !== "" && (worldState.networkingKey.length !== 128 || !(/[0-9A-Fa-f]{128}/g.test(worldState.networkingKey)))) &&
                            <StyledQuestionAnswerInputError>
                                Networking Key must be 64 bytes (128 hex characters)
                            </StyledQuestionAnswerInputError>
                        }
                    </StyledQuestionAnswerInputWrapper>
                </StyledQuestionAnswersColumn>
                <StyledQuestionAnswersColumn>
                    <StyledQuestionAnswerInputWrapper>
                        <StyledQuestionAnswerInputTitle>Staking Key</StyledQuestionAnswerInputTitle>
                        <StyledQuestionAnswerInput isError={worldState.stakingKey && worldState.stakingKey !== "" && (worldState.stakingKey.length !== 192 || !(/[0-9A-Fa-f]{192}/g.test(worldState.stakingKey)))} value={worldState.stakingKey} placeholder="" onChange={e => worldState.updateURL({ param: "stakingkey", val: String(e.target.value).toLowerCase() })}/>
                        { (worldState.stakingKey && worldState.stakingKey !== "" && (worldState.stakingKey.length !== 192 || !(/[0-9A-Fa-f]{192}/g.test(worldState.stakingKey)))) &&
                            <StyledQuestionAnswerInputError>
                                Staking Key must be 96 bytes (192 hex characters)
                            </StyledQuestionAnswerInputError>
                        }
                    </StyledQuestionAnswerInputWrapper>
                </StyledQuestionAnswersColumn>
                { (worldState.nodeRole === "Collection" || worldState.nodeRole === "Consensus") &&
                    <StyledQuestionAnswersColumn>
                        <StyledQuestionAnswerInputWrapper>
                            <StyledQuestionAnswerInputTitle>Machine Account Public Key</StyledQuestionAnswerInputTitle>
                            <StyledQuestionAnswerInput isError={worldState.machineAccountPublicKey && worldState.machineAccountPublicKey === ""} value={worldState.machineAccountPublicKey} placeholder="" onChange={e => worldState.updateURL({ param: "machineaccountpublickey", val: String(e.target.value).toLowerCase() })}/>
                            {/* { (worldState.machineAccountPublicKey && worldState.machineAccountPublicKey !== "" && (worldState.machineAccountPublicKey.length !== 128 || !(/[0-9A-Fa-f]{128}/g.test(worldState.machineAccountPublicKey)))) &&
                                <StyledQuestionAnswerInputError>
                                    Machine Account Public Key must be 64 bytes (128 hex characters)
                                </StyledQuestionAnswerInputError>
                            } */}
                        </StyledQuestionAnswerInputWrapper>
                    </StyledQuestionAnswersColumn>
                }
            </StyledQuestion>
        ),
        answer: worldState => (
            <StyledAnswer key="answer-3">
                <StyledAnswerNumber isActive={worldState.questionId === "3-terminal"}>{ worldState.nodeRole === "Access" ? "2." : "3." }</StyledAnswerNumber>
                <StyledAnswerText>
                    <StyledAnswerTitle isActive={worldState.questionId === "3-terminal"}>Node details</StyledAnswerTitle>
                    { worldState.questionId === "3-terminal" && <StyledAnswerSubtitle>Gather your node technical details and submit your stake request.</StyledAnswerSubtitle> }
                </StyledAnswerText>
                <StyledAnswerImg showImage={worldState.graph["3-terminal"].postCondition(worldState)} src={circleCheck} />
            </StyledAnswer>
        ),
        redirect: worldState => {
            let nodeRole = -1;
            switch (worldState.nodeRole) {
                case "Collection": 
                    nodeRole = 1
                    break
                case "Consensus": 
                    nodeRole = 2
                    break
                case "Execution": 
                    nodeRole = 3
                    break
                case "Verification": 
                    nodeRole = 4
                    break
                case "Access": 
                    nodeRole = 5
                default: 
                    break
            }
            const returnURL = (nodeRole === 1 || nodeRole === 2) ? `/stake-delegate-wizard?tid=1&qid=4-terminal&nodeid=${worldState.operatorId}` : `/stake-delegate/${fcl.withPrefix(worldState.currentUserAddr)}`
            return `/transaction?hash=${stakingCollectionRegisterNodeHash}&nodeid=${worldState.operatorId}&noderole=${nodeRole}&amount=${worldState.stakeAmount.charAt(0) === "." ? "0" + worldState.stakeAmount : worldState.stakeAmount}&networkingaddress=${worldState.networkAddress}&networkingkey=${worldState.networkingKey}&stakingkey=${worldState.stakingKey}${worldState.machineAccountPublicKey ? `&machineaccountpublickey=${worldState.machineAccountPublicKey}` : ""}&showcode=false&origin=${btoa(returnURL)}`
        }
    },
    "4-terminal": {
        id: "4-terminal",
        _description: "Review the machine account address",
        postCondition: worldState => true,
        next: worldState => null,
        previous: worldState => null,
        question: worldState => (
            <StyledQuestion style={{width: "auto"}}>
                <StyledQuestionTitle>Machine Account Details</StyledQuestionTitle>
                <StyledQuestionDescription>Learn more about <a target="_blank" href="https://docs.onflow.org/staking/machine-account">Machine Accounts</a>.</StyledQuestionDescription>
                <StyledQuestionDescription>Machine Account Address: <b>{worldState.allStakeInfo && worldState.allStakeInfo[worldState.nodeId] ? worldState.allStakeInfo[worldState.nodeId].machineAccountAddress : ""}</b></StyledQuestionDescription>
                <StyledQuestionDescription>Review your machine account details before proceeding. Next,</StyledQuestionDescription>
                <StyledQuestionDescription>1. You must follow the <a target="_blank" href="https://docs.onflow.org/node-operation/node-bootstrap">finalize Machine Account setup instructions</a>.</StyledQuestionDescription>
                <StyledQuestionDescription>2. Press continue to be directed to the transaction to fund your new machine account.</StyledQuestionDescription>
            </StyledQuestion>
        ),
        answer: worldState => (
            <StyledAnswer key="answer-4">
                <StyledAnswerNumber isActive={worldState.questionId === "4-terminal"}>4.</StyledAnswerNumber>
                <StyledAnswerText>
                    <StyledAnswerTitle isActive={worldState.questionId === "4-terminal"}>Machine Account Details</StyledAnswerTitle>
                    { worldState.questionId === "4-terminal" && <StyledAnswerSubtitle>Review your nodes machine account details.</StyledAnswerSubtitle> }
                </StyledAnswerText>
                <StyledAnswerImg showImage={worldState.graph["4-terminal"].postCondition(worldState)} src={circleCheck} />
            </StyledAnswer>
        ),
        redirect: worldState => {
            const returnURL = `/stake-delegate/${fcl.withPrefix(worldState.currentUserAddr)}`
            const to = worldState.allStakeInfo && worldState.allStakeInfo[worldState.nodeId] ? worldState.allStakeInfo[worldState.nodeId].machineAccountAddress : ""
            const amount = worldState.allStakeInfo && worldState.allStakeInfo[worldState.nodeId] && worldState.allStakeInfo[worldState.nodeId].role === 1 ? COLLECTION_NODE_RECCOMENDED_FLOW_BALANCE : CONSENSUS_NODE_RECCOMENDED_FLOW_BALANCE
            return `/transaction?hash=${sendFlowTokenHash}&to=${to}&amount=${amount}&showcode=false&origin=${btoa(returnURL)}`
        }
    },
}