import React, {useState} from "react"
import {Link} from "react-router-dom"
import styled from "styled-components"
import { useHistory } from "react-router-dom";
import * as fcl from "@onflow/fcl"
import { HASH as transferFlowTokenHash} from "@onflow/six-transfer-tokens"
import {useTransition, animated, config} from "react-spring"
import {Button} from "./button.js"
import {DropDown} from "./dropdown.js"

const StyledHeaderUser = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
`

const StyledHeaderUserBalances = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    background: white;
    text-decoration: none;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 1rem;
    border: 0.1rem solid #E8E8E8;
`

const StyledHeaderUserBalanceItem = styled.div`
    position: relative;
    color: #6a6a6a;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
`

const StyledHeaderUserBalanceTotalWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`

const StyledHeaderUserBalanceQuantity = styled.div`
    margin-right: 0.5rem;
    color: black;
    font-size: 1rem;
`

const StyledHeaderUserBalanceCurrency = styled.div`
    color: #c8c8c8;
    font-size: 0.75rem;
    font-weight: bold;
`

export const UserBalancesChip = ({currentUserAddr = null, flowBalance = null}) => {
    const [isFlowDropdownOpen, setIsFlowDropdownOpen] = useState(false)

    let history = useHistory();

    const accountTransition = useTransition(currentUserAddr, null, {
        from: { 
            position: "absolute", opacity: 0, transform: "translateY(10%)", width: "100%"
        },
        enter: { opacity: 1, transform: "translateY(0%)" },
        leave: { opacity: 0, transform: "translateY(0%)"},
        config: config.default,
    })

    return (
        <>
            {
                accountTransition.map(({ item, key, props }) => 
                    item ? 
                    <animated.div style={props} key={key}>
                        <StyledHeaderUser>
                            <StyledHeaderUserBalances onClick={() => currentUserAddr ? history.push(`/account/${fcl.withPrefix(currentUserAddr)}`) : null} >
                                <StyledHeaderUserBalanceItem onMouseLeave={() => setIsFlowDropdownOpen(false)}>
                                    <StyledHeaderUserBalanceTotalWrapper onMouseEnter={() => setIsFlowDropdownOpen(true)}>
                                        <StyledHeaderUserBalanceQuantity>
                                            { flowBalance || "Loading..." }
                                        </StyledHeaderUserBalanceQuantity>
                                        <StyledHeaderUserBalanceCurrency>
                                            FLOW
                                        </StyledHeaderUserBalanceCurrency>
                                    </StyledHeaderUserBalanceTotalWrapper>
                                    <DropDown 
                                        onMouseLeave={() => setIsFlowDropdownOpen(false)}
                                        style={{width: "100%", left: 0}}
                                        isOpen={isFlowDropdownOpen}
                                        options={[
                                            <Link to="/get-flow">
                                                Get FLOW
                                            </Link>,
                                            <Link to={`/transaction?hash=${transferFlowTokenHash}&showcode=false`}>
                                                Send FLOW
                                            </Link>
                                        ]}
                                        anchor="right"
                                    />
                                </StyledHeaderUserBalanceItem>
                            </StyledHeaderUserBalances>
                        </StyledHeaderUser>
                    </animated.div>
                    : <animated.div style={props} key={key}>
                        <StyledHeaderUser>
                            <Button style={{ maxWidth: "20rem" }} onClick={fcl.authenticate} title="Sign In" />
                        </StyledHeaderUser>
                    </animated.div>
            )}
        </>
    )
}