import React, {useState, useEffect} from "react"
import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import { Transaction } from "../../transaction.js"

import { useAllStakeInfo } from "../../../../hooks/use-all-stake-info.js"
import { useAllDelegateInfo } from "../../../../hooks/use-all-delegate-info.js"

import {
  template as stakingCollectionWithdrawRewardedTokensTemplate,
  CODE as stakingCollectionWithdrawRewardedTokensCode,
  HASH as stakingCollectionWithdrawRewardedTokensHash,
  DESCRIPTION as stakingCollectionWithdrawRewardedTokensDescription,
} from "@onflow/six-stakingcollection-withdraw-rewarded-tokens"

import { TRANSACTION_MANIFEST_VALUE, TRANSACTION_MANIFEST_ARG } from "../../transaction-manifest-constructors.js"

import {
  invalidUFix64ArgError,
} from "../../transaction-errors.js"

import {
  validateUFix64,
  validateString,
  validateUInt32,
} from "../../transaction-validators.js"

import {
  xformUInt32,
  xformString,
  xformUFix64,
} from "../../transaction-xforms.js"

export const Manifest = ({ 
  qp,
  hash,
  txn,
  showCode,
  consent,
  qpString,
  submitTransaction,
  submitTransactionLocked,
  currentUserAddr,
  hiddenArgs,
  lockedArgs,
}) => {
  const [manifestValue, setManifestValue] = useState(new TRANSACTION_MANIFEST_VALUE({
    hash: stakingCollectionWithdrawRewardedTokensHash,
    title: "Transaction - Withdraw Rewarded Tokens",
    description: stakingCollectionWithdrawRewardedTokensDescription,
    code: stakingCollectionWithdrawRewardedTokensCode,
    args: {},
    isSix: true,
    transactionFunction: () => {}
  }))
  
  const { allStakeInfo } = useAllStakeInfo(currentUserAddr)
  const { allDelegateInfo } = useAllDelegateInfo(currentUserAddr)

  const computeManifestValue = async () => {
    setManifestValue(
      new TRANSACTION_MANIFEST_VALUE({
        hash: stakingCollectionWithdrawRewardedTokensHash,
        disclaimer: null,
        error: null,
        title: "Transaction - Withdraw Rewarded Tokens",
        description: stakingCollectionWithdrawRewardedTokensDescription,
        code: stakingCollectionWithdrawRewardedTokensCode,
        args: {
          0: new TRANSACTION_MANIFEST_ARG({
            title: "Node ID",
            qp: "nodeid",
            xform: xformString,
            validate: validateString,
            description: "The Node ID.",
            type: types.String,
            value: qp.get("nodeid") || "",
          }),
          1: new TRANSACTION_MANIFEST_ARG({
            title: "Delegator ID",
            description: "The Delegator ID.",
            qp: "delegatorid",
            xform: xformUInt32,
            validate: validateUInt32,
            type: types.UInt32,
            value: qp.get("delegatorid") || "",
            hidden: hiddenArgs.includes("delegatorid"),
          }),
          2: new TRANSACTION_MANIFEST_ARG({
            title: "Amount",
            qp: "amount",
            xform: xformUFix64,
            validate: validateUFix64,
            description: (
              <span>
                {"The amount of rewarded tokens to withdraw."}
                {!qp.get("delegatorid") &&
                qp.get("nodeid") &&
                allStakeInfo ? (
                  <span>
                    {" You have "}
                    <b>
                      {
                        allStakeInfo[qp.get("nodeid")]
                          ?.tokensRewarded
                      }
                    </b>
                    {" tokens available to withdraw."}
                  </span>
                ) : qp.get("delegatorid") &&
                  qp.get("nodeid") &&
                  allDelegateInfo ? (
                  <span>
                    {" You have "}
                    <b>
                      {
                        allDelegateInfo[qp.get("nodeid")][
                          qp.get("delegatorid")
                        ]?.tokensRewarded
                      }
                    </b>
                    {" tokens available to withdraw."}
                  </span>
                ) : (
                  ""
                )}
              </span>
            ),
            value: qp.get("amount") || "",
            type: types.UFix64,
            warnings: [].filter((w) => w !== null),
            errors: [invalidUFix64ArgError(qp.get("amount"))].filter(
              (w) => w !== null
            ),
          }),
        },
        isSix: true,
        transactionFunction: async (hash, txn, args) => {
          return await fcl.decode(
            await fcl.send([
              stakingCollectionWithdrawRewardedTokensTemplate({
                proposer: fcl.currentUser().authorization,
                authorization: fcl.currentUser().authorization,
                payer: fcl.currentUser().authorization,
                nodeId: args[0].xform(args[0].value),
                delegatorId: args[1].xform(args[1].value),
                amount: args[2].xform(args[2].value),
              }),
              fcl.limit(9999),
            ])
          )
        },
      })
    )
  }

  useEffect(() => {
    computeManifestValue()
  }, [qpString, allStakeInfo, allDelegateInfo])

  return (
    <Transaction 
      title={manifestValue?.title}
      description={manifestValue?.description}
      disclaimer={manifestValue?.disclaimer}
      error={manifestValue?.error}
      code={manifestValue?.code}
      isSix={manifestValue?.isSix}
      args={manifestValue?.args}
      transactionFunction={manifestValue?.transactionFunction}
      submitTransaction={submitTransaction}
      submitTransactionLocked={submitTransactionLocked}
      consent={consent}
      showCode={showCode}
      hash={hash}
      txn={txn}
      qp={qp}
    />
  )
}
