import React from "react"
import styled from "styled-components"
import * as fcl from "@onflow/fcl"
import {Link} from "react-router-dom"
import AceEditor from "react-ace"
import "ace-builds/src-noconflict/theme-github"
import "ace-builds/src-noconflict/mode-rust"

const StyledAccountContractLinks = styled.div`
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
`

const StyledAccountContractLink = styled(Link)`
    margin-right: 0.5rem;
    padding: 0.25rem 1rem 0.25rem 1rem;
    border-radius: 0.5rem;
    text-decoration: none;
    color: white;
    background-color: ${({isActive}) => isActive ? "#3745f5" : "#b9bdfc"};
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
`

export const AccountContracts = ({
    address,
    account,
    selectedContract,
}) => {
    return Object.keys(account?.contracts).length > 0 ?
        <>
            <StyledAccountContractLinks>{ Object.keys(account.contracts).map((key) => <StyledAccountContractLink to={`/account/${fcl.withPrefix(address)}?contract=${key}`} isActive={selectedContract === key}>{key}</StyledAccountContractLink>) }</StyledAccountContractLinks>
            <AceEditor 
                width="100%"
                height="500px"
                mode="rust"
                theme="github"
                value={selectedContract ? account.contracts[selectedContract] : "Select an available contract to view."}
                name={selectedContract}
                tabSize={2}
                highlightActiveLine={false}
                showPrintMargin={false}
                setOptions={{
                displayIndentGuides: false,
                }}
                readOnly={true}
                placeholder="..."
            />
        </>
        : `There are no contracts set on this account.`
}