import React, {useState, useEffect} from "react"
import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import { Transaction } from "../../transaction.js"

import { useTotalUnlockedAccountFlowBalance } from "../../../../hooks/use-total-unlocked-account-flow-balance.js"
import { useAccountStorageInfo } from "../../../../hooks/use-account-storage-info.js"

import {
  template as stakingCollectionCreateMachineAccountTemplate,
  CODE as stakingCollectionCreateMachineAccountCode,
  HASH as stakingCollectionCreateMachineAccountHash,
  DESCRIPTION as stakingCollectionCreateMachineAccountDescription,
} from "@onflow/six-stakingcollection-create-machine-account"

import { TRANSACTION_MANIFEST_VALUE, TRANSACTION_MANIFEST_ARG } from "../../transaction-manifest-constructors.js"

import {
  sufficientTokensError,
  argumentExistsError,
} from "../../transaction-errors.js"

import {
  validateString,
  validateArrayOfStrings,
} from "../../transaction-validators.js"

import {
  xformString,
  xformArrayOfStrings,
} from "../../transaction-xforms.js"

export const Manifest = ({ 
  qp,
  hash,
  txn,
  showCode,
  consent,
  qpString,
  submitTransaction,
  submitTransactionLocked,
  currentUserAddr,
  hiddenArgs,
  lockedArgs,
}) => {
  const [currentUser, setCurrentUser] = useState(null)

  const [manifestValue, setManifestValue] = useState(new TRANSACTION_MANIFEST_VALUE({
    hash: stakingCollectionCreateMachineAccountHash,
    title: "Transaction - Create Machine Account",
    description: stakingCollectionCreateMachineAccountDescription,
    code: stakingCollectionCreateMachineAccountCode,
    args: {},
    isSix: true,
    transactionFunction: () => {}
  }))

  useEffect(() => fcl.currentUser().subscribe(setCurrentUser), [])

  const isLedgerUser = currentUser?.services[0]?.uid === "fcl-ledger-authz"

  const { accountStorageInfo } = useAccountStorageInfo(currentUserAddr)
  const { totalUnlockedAccountFlowBalance } = useTotalUnlockedAccountFlowBalance(currentUserAddr)

  const computeManifestValue = async () => {
    setManifestValue(
      new TRANSACTION_MANIFEST_VALUE({
        hash: stakingCollectionCreateMachineAccountHash,
        disclaimer: (
          <span>
            {"Refer to the "}
            <a
              href="https://docs.onflow.org/node-operation/machine-existing-operator"
              target="_blank"
            >
              {"Flow Docs"}
            </a>
            {" for details on machine account creation and key generation."}
          </span>
        ),
        error: isLedgerUser ? 
        <span>This transaction is not available for Ledger users at this time. Ledger support coming soon.</span> 
        : await sufficientTokensError(
          totalUnlockedAccountFlowBalance,
          accountStorageInfo?.minimumStorageReservation *
            2,
          `Error: Not enough FLOW tokens available to register a Machine Account. You unlocked account needs at least ${
            accountStorageInfo?.minimumStorageReservation *
            2
          } FLOW to pay for the Machine Account's storage deposit.`
        ),
        title: "Transaction - Create Machine Account",
        description: stakingCollectionCreateMachineAccountDescription,
        code: stakingCollectionCreateMachineAccountCode,
        args: {
          0: new TRANSACTION_MANIFEST_ARG({
            title: "Node ID",
            qp: "nodeid",
            xform: xformString,
            validate: validateString,
            type: types.String,
            description: "The Node ID.",
            value: qp.get("nodeid") || "",
          }),
          1: new TRANSACTION_MANIFEST_ARG({
            title: "Public Keys",
            qp: "pks",
            xform: xformArrayOfStrings,
            validate: validateArrayOfStrings,
            description: "Public Keys for the Machine Account (comma separated)",
            value: qp.get("pks") || "",
            type: types.Array(types.String),
            warnings: [].filter((w) => w !== null),
            errors: [
              argumentExistsError(
                qp.get("pks") != "",
                "Error: At least one Public Key must be defined."
              ),
            ].filter((w) => w !== null),
          }),
        },
        isSix: true,
        transactionFunction: async (hash, txn, args) => {
          return await fcl.decode(
            await fcl.send([
              stakingCollectionCreateMachineAccountTemplate({
                proposer: fcl.currentUser().authorization,
                authorization: fcl.currentUser().authorization,
                payer: fcl.currentUser().authorization,
                nodeId: qp.get("nodeid") || "",
                publicKeys: qp.get("pks") ? qp.get("pks").split(",") : [],
              }),
              fcl.limit(9999),
            ])
          )
        },
      })
    )
  }

  useEffect(() => {
    computeManifestValue()
  }, [qpString, accountStorageInfo, totalUnlockedAccountFlowBalance])

  return (
    <Transaction 
      title={manifestValue?.title}
      description={manifestValue?.description}
      disclaimer={manifestValue?.disclaimer}
      error={manifestValue?.error}
      code={manifestValue?.code}
      isSix={manifestValue?.isSix}
      args={manifestValue?.args}
      transactionFunction={manifestValue?.transactionFunction}
      submitTransaction={submitTransaction}
      submitTransactionLocked={submitTransactionLocked}
      consent={consent}
      showCode={showCode}
      hash={hash}
      txn={txn}
      qp={qp}
    />
  )
}
