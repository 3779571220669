import {useEffect} from "react"
import * as fcl from "@onflow/fcl"
import {atomFamily, selectorFamily, useRecoilState, useSetRecoilState} from "recoil"
import {getAcctKeys} from "../interactions/get-acct-keys.js"
import {ERROR, INIT_PROCESSING, INIT, IDLE, PROCESSING} from "../global/constants.js"

export const valueAtom = atomFamily({
    key: "account-keys::state",
    default: null
})

export const statusAtom = atomFamily({
    key: "account-keys::status",
    default: INIT,
})

export function useAccountKeys(address) {
    const [accountKeys, setAccountKeys] = useRecoilState(valueAtom(address))
    const [status, setStatus] = useRecoilState(statusAtom(address))

    async function refresh() {
        try {
            setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
            await getAcctKeys(address).then(setAccountKeys)
            setStatus(IDLE)
        } catch (e) {
            setStatus(ERROR)
        }
    }

    useEffect(() => {
        async function load({ address }) {
            if (address) {
                try {
                    setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
                    await getAcctKeys(address).then(_accountKeys => {
                        setAccountKeys(_accountKeys)
                        setStatus(IDLE)
                    })
                } catch (e) {
                    setStatus(ERROR)
                }
            }
        }
        load({ address })
    }, [address])

    return {
        accountKeys,
        status,
        fetch: refresh,
        refresh
    }
}