export const IDLE = "IDLE"
export const INIT = "INIT"
export const INIT_PROCESSING = "INIT_PROCESSING"
export const PROCESSING = "PROCESSING"
export const ERROR = "ERROR"

export const COLLECTION_NODE_MIN_FLOW_BALANCE = 0.001
export const CONSENSUS_NODE_MIN_FLOW_BALANCE = 0.1

export const COLLECTION_NODE_RECCOMENDED_FLOW_BALANCE = 0.005
export const CONSENSUS_NODE_RECCOMENDED_FLOW_BALANCE = 0.25

export const UFIX64_ACCT_MIN_STORAGE_BALANCE = "0.001"
export const UFIX64_GAS_FEE_INCLUSION_RATE = "0.00001"

export const HASH_ALGORITHMS = {
    1: "SHA2_256",
    2: "SHA2_384",
    3: "SHA3_256",
    4: "SHA3_384",
    5: "KMAC128_BLS_BLS12_381",
    6: "KECCAK_256"
}

export const SIGNATURE_ALGORITHMS = {
    1: "ECDSA_P256",
    2: "ECDSA_secp256k1",
    3: "BLS_BLS12_381"
}

export const LOCAL_STORAGE = {
    CONFIG_ENV: "flow-port:config.env"
}

export const MAINNET = "mainnet";
export const TESTNET = "testnet";
export const SANDBOXNET = "sandboxnet";
export const CANARYNET = "canarynet";
export const LOCAL = "local";

export const getStoredItem = (host, itemName) => {
    return `${host}:${itemName}`
}

export const getSupportedNetworks = () => {
    const networks =
        [MAINNET,
            TESTNET,
            CANARYNET,
        ]
    if (process.env.REACT_APP_SUPPORT_SANDBOX === "true") {
        networks.push(SANDBOXNET)
    }
    if (process.env.REACT_APP_SUPPORT_LOCAL === "true") {
        networks.push(LOCAL);
    }
    return networks;
};

export const getCurrentNetwork = (hostname = "", pathname = "") => {
    let network = networkInString(pathname);
    if (network) return network;

    network = networkInString(hostname);
    if (network) return network;

    return null;
}

const networkInString = (pathname) => {
    if (pathname.indexOf(MAINNET) !== -1) return MAINNET;
    if (pathname.indexOf(TESTNET) !== -1) return TESTNET;
    if (pathname.indexOf(CANARYNET) !== -1) return CANARYNET;
    if (pathname.indexOf(SANDBOXNET) !== -1 && process.env.REACT_APP_SUPPORT_SANDBOX === "true") return SANDBOXNET;
    if (pathname.indexOf(LOCAL) !== -1 && process.env.REACT_APP_SUPPORT_LOCAL === "true") return LOCAL;
    
    return null;
}
export const FAUCET = "faucet"
export const ACCT_CREATION = "account creation"