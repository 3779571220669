import React from "react";
import styled from "styled-components";
import logo from "../assets/logo.svg"
import { useNetwork } from "../hooks/use-network";

const StyledHeaderLogoWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    justify-content: ${({ centered }) => centered ? "center" : "left"};
    align-items: ${({ centered }) => centered ? "center" : "left"};
    margin-bottom: 2rem;
    position: relative;
    width: 400px;
`

const StyledHeaderLogo = styled.img`
    margin-right: 0.5rem;
    height: ${({ medium }) => medium ? "3.5rem" : "4rem"};
`

const StyledHeaderTitle = styled.span`
    transform: translateY(4px);
    font-weight: 400;
    font-size: 2rem;
    align-self: center;
    margin-bottom: 0.25rem;
    color: var(--text-secondary);
`

const StyledNetwork = styled.span`
    font-size: 0.75rem;
    color: var(--text-highlight);
    position: absolute;
    bottom: -7px;
    left: ${({ medium, centered }) => medium ? centered ? "154px" : "66px" : centered ? "163px" : "75px"};
`

export const LogoWithNetwork = ({ medium = false, short = false, centered = false }) => {
    const { network } = useNetwork();
    return (
        <StyledHeaderLogoWrapper short={short} centered={centered}>
            <StyledHeaderLogo medium={medium} src={logo} alt="Flow logo" />
            {!short && <StyledHeaderTitle>port</StyledHeaderTitle>}
            <StyledNetwork medium={medium} centered={centered}>{network}</StyledNetwork>
        </StyledHeaderLogoWrapper>
    )
}