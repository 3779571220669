import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `
import FlowStorageFees from 0xSTORAGEFEESADDRESS
import FlowServiceAccount from 0xFLOWSERVICEACCOUNT
import LockedTokens from 0xLOCKEDTOKENADDRESS

pub fun main(address: Address, amount: UFix64, gasFeeEstimate: UFix64, lockedAmountToUse: UFix64?, additionalReserveAmount: UFix64?): Bool {
    let acct = getAccount(address)

    let unlockedBalance = FlowServiceAccount.defaultTokenBalance(acct)
    var reserved = UFix64(acct.storageUsed)/UFix64(1000000) / FlowStorageFees.storageMegaBytesPerReservedFLOW
    if reserved < FlowStorageFees.minimumStorageReservation {
        reserved = FlowStorageFees.minimumStorageReservation
    }

    reserved = reserved + gasFeeEstimate

    if let _additionalReserveAmount = additionalReserveAmount {
        reserved = reserved + _additionalReserveAmount
    } 

    if let _lockedAmountToUse = lockedAmountToUse {
        if (_lockedAmountToUse > amount) {
            return false
        }
        if (reserved > unlockedBalance) {
            return true
        }
        
        return amount - _lockedAmountToUse > unlockedBalance - reserved
    } else {
        let lockedAccountInfoCap = acct
            .getCapability
            <&LockedTokens.TokenHolder{LockedTokens.LockedAccountInfo}>
            (LockedTokens.LockedAccountInfoPublicPath)
        if lockedAccountInfoCap == nil || !(lockedAccountInfoCap!.check()) {
            return amount > unlockedBalance - reserved
        }
        let lockedAccountInfoRef = lockedAccountInfoCap!.borrow()!
        let lockedBalance = lockedAccountInfoRef.getLockedAccountBalance()

        if (lockedBalance > amount) {
            return false
        }
        if (reserved > unlockedBalance) {
            return true
        }

        return amount - lockedBalance > unlockedBalance - reserved        
    }

}
`

export const doesAmountReduceAcctBelowMinForTxnFees = async (address, amount, gasFeeEstimate = "0.0", lockedBalanceToUse = null, reserveAmount = null) => {
    return fcl.decode(await fcl.send([
        fcl.script(CODE),
        fcl.args([fcl.arg(address, t.Address), fcl.arg(amount, t.UFix64), fcl.arg(gasFeeEstimate, t.UFix64), fcl.arg(lockedBalanceToUse, t.Optional(t.UFix64)), fcl.arg(reserveAmount, t.Optional(t.UFix64))])
    ]))
}
