import { HASH as sendFlowTokenHash } from "@onflow/six-transfer-tokens"
import { HASH as addNewKeyHash } from "@onflow/six-add-new-key"
import { HASH as createAccountHash } from "@onflow/six-create-account"
import { HASH as withdrawUnlockedFlowHash } from "@onflow/six-withdraw-unlocked-flow"
import { HASH as stakingCollectionCreateMachineAccountHash } from "@onflow/six-stakingcollection-create-machine-account"
import { HASH as stakingCollectionRegisterDelegatorHash } from "@onflow/six-stakingcollection-register-delegator"
import { HASH as stakingCollectionRegisterNodeHash } from "@onflow/six-stakingcollection-register-node"
import { HASH as stakingCollectionRequestUnstakingHash } from "@onflow/six-stakingcollection-request-unstaking"
import { HASH as stakingCollectionCloseStakeHash } from "@onflow/six-stakingcollection-close-stake"
import { HASH as stakingCollectionSetupStakingCollectionHash } from "@onflow/six-stakingcollection-setup-staking-collection"
import { HASH as stakingCollectionStakeNewTokensHash } from "@onflow/six-stakingcollection-stake-new-tokens"
import { HASH as stakingCollectionStakeRewardedTokensHash } from "@onflow/six-stakingcollection-stake-rewarded-tokens"
import { HASH as stakingCollectionStakeUnstakedTokensHash } from "@onflow/six-stakingcollection-stake-unstaked-tokens"
import { HASH as stakingCollectionTransferDelegatorHash } from "@onflow/six-stakingcollection-transfer-delegator"
import { HASH as stakingCollectionTransferNodeHash } from "@onflow/six-stakingcollection-transfer-node"
import { HASH as stakingCollectionUnstakeAllHash } from "@onflow/six-stakingcollection-unstake-all"
import { HASH as stakingCollectionWithdrawFromMachineAccountHash } from "@onflow/six-stakingcollection-withdraw-from-machine-account"
import { HASH as stakingCollectionWithdrawRewardedTokensHash } from "@onflow/six-stakingcollection-withdraw-rewarded-tokens"
import { HASH as stakingCollectionWithdrawUnstakedTokensHash } from "@onflow/six-stakingcollection-withdraw-unstaked-tokens"
import { HASH as stakingCollectionUpdateNetworkingAddressHash } from "@onflow/six-stakingcollection-update-networking-address"
import { HASH as fusdTransferHash } from "@onflow/six-fusd-transfer"
import { HASH as fusdSetupHash } from "@onflow/six-fusd-setup"
import { HASH as usdcTransferHash } from "@onflow/six-usdc-transfer"
import { HASH as usdcSetupHash } from "@onflow/six-usdc-setup"
import { HASH as topshotSetupCollectionHash } from "@onflow/six-topshot-setup-collection"
import { HASH as topshotTransferMomentHash } from "@onflow/six-topshot-transfer-moment"

// General Txns
import { Manifest as SendFlowTokenManifest } from "./transactions/send-flow-token.js"
import { Manifest as AddNewKeyManifest } from "./transactions/add-new-key.js"
import { Manifest as CreateAccountManifest } from "./transactions/create-account.js"
import { Manifest as WithdrawUnlockedFlowManifest } from "./transactions/withdraw-unlocked-flow.js"

// Staking Collection
import { Manifest as StakingCollectionSetupStakingCollectionManifest } from "./transactions/stakingcollection/setup-staking-collection.js"
import { Manifest as StakingCollectionCreateMachineAccountManifest } from "./transactions/stakingcollection/create-machine-account.js"
import { Manifest as StakingCollectionRegisterDelegatorManifest } from "./transactions/stakingcollection/register-delegator.js"
import { Manifest as StakingCollectionRegisterNodeManifest } from "./transactions/stakingcollection/register-node.js"
import { Manifest as StakingCollectionTransferDelegatorManifest } from "./transactions/stakingcollection/transfer-delegator.js"
import { Manifest as StakingCollectionTransferNodeManifest } from "./transactions/stakingcollection/transfer-node.js"
import { Manifest as StakingCollectionWithdrawFromMachineAccountManifest } from "./transactions/stakingcollection/withdraw-from-machine-account.js"
import { Manifest as StakingCollectionUnstakeAllManifest } from "./transactions/stakingcollection/unstake-all.js"
import { Manifest as StakingCollectionRequestUnstakingManifest } from "./transactions/stakingcollection/request-unstaking.js"
import { Manifest as StakingCollectionStakeNewTokensManifest } from "./transactions/stakingcollection/stake-new-tokens.js"
import { Manifest as StakingCollectionStakeUnstakedTokensManifest } from "./transactions/stakingcollection/stake-unstaked-tokens.js"
import { Manifest as StakingCollectionWithdrawUnstakedTokensManifest } from "./transactions/stakingcollection/withdraw-unstaked-tokens.js"
import { Manifest as StakingCollectionWithdrawRewardedTokensManifest } from "./transactions/stakingcollection/withdraw-rewarded-tokens.js"
import { Manifest as StakingCollectionStakeRewardedTokensManifest } from "./transactions/stakingcollection/stake-rewarded-tokens.js"
import { Manifest as StakingCollectionCloseStakeManifest } from "./transactions/stakingcollection/close-stake.js"
import { Manifest as StakingCollectionUpdateNetworkingAddressManifest } from "./transactions/stakingcollection/update-networking-address.js"

// FUSD
import { Manifest as FusdTransferManifest } from "./transactions/fusd/fusd-transfer.js"
import { Manifest as FusdSetupManifest } from "./transactions/fusd/fusd-setup.js"

// USDC
import { Manifest as UsdcTransferManifest } from "./transactions/usdc/usdc-transfer.js"
import { Manifest as UsdcSetupManifest } from "./transactions/usdc/usdc-setup.js"

// TopShot
import { Manifest as TopshotSetupCollectionManifest } from "./transactions/topshot/setup-collection.js"
import { Manifest as TopshotTransferMomentManifest } from "./transactions/topshot/transfer-moment.js"

export const TRANSACTION_MANIFEST = {
    [sendFlowTokenHash]: SendFlowTokenManifest,
    [addNewKeyHash]: AddNewKeyManifest,
    [createAccountHash]: CreateAccountManifest,
    [withdrawUnlockedFlowHash]: WithdrawUnlockedFlowManifest,
    [stakingCollectionSetupStakingCollectionHash]: StakingCollectionSetupStakingCollectionManifest,
    [stakingCollectionCreateMachineAccountHash]: StakingCollectionCreateMachineAccountManifest,
    [stakingCollectionRegisterDelegatorHash]: StakingCollectionRegisterDelegatorManifest,
    [stakingCollectionRegisterNodeHash]: StakingCollectionRegisterNodeManifest,
    [stakingCollectionTransferDelegatorHash]: StakingCollectionTransferDelegatorManifest,
    [stakingCollectionTransferNodeHash]: StakingCollectionTransferNodeManifest,
    [stakingCollectionWithdrawFromMachineAccountHash]: StakingCollectionWithdrawFromMachineAccountManifest,
    [stakingCollectionUnstakeAllHash]: StakingCollectionUnstakeAllManifest,
    [stakingCollectionRequestUnstakingHash]: StakingCollectionRequestUnstakingManifest,
    [stakingCollectionStakeNewTokensHash]: StakingCollectionStakeNewTokensManifest,
    [stakingCollectionStakeUnstakedTokensHash]: StakingCollectionStakeUnstakedTokensManifest,
    [stakingCollectionWithdrawUnstakedTokensHash]: StakingCollectionWithdrawUnstakedTokensManifest,
    [stakingCollectionWithdrawRewardedTokensHash]: StakingCollectionWithdrawRewardedTokensManifest,
    [stakingCollectionStakeRewardedTokensHash]: StakingCollectionStakeRewardedTokensManifest,
    [stakingCollectionCloseStakeHash]: StakingCollectionCloseStakeManifest,
    [stakingCollectionUpdateNetworkingAddressHash]: StakingCollectionUpdateNetworkingAddressManifest,
    [fusdTransferHash]: FusdTransferManifest,
    [fusdSetupHash]: FusdSetupManifest,
    [usdcTransferHash]: UsdcTransferManifest,
    [usdcSetupHash]: UsdcSetupManifest,
    [topshotSetupCollectionHash]: TopshotSetupCollectionManifest,
    [topshotTransferMomentHash]: TopshotTransferMomentManifest,
}
