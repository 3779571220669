import { css } from 'styled-components';

export const breakpoints = {
    xs: '480px',
    sm: '868px',
    md: '1092px',
    lg: '1300px'
}

export const screenMinWidth = Object.keys(breakpoints).reduce(
    (accumulator, label) => {
      accumulator[label] = (...args) => css`
        @media (min-width: ${breakpoints[label]}) {
          ${css(...args)};
        }
      `;
      return accumulator;
    },
    {}
);