import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FiatToken from 0xUSDCADDRESS

pub fun main(address: Address): UFix64 {
    let account = getAccount(address)

    let usdcBalanceCap = account
      .getCapability
      <&FiatToken.Vault{FungibleToken.Balance}>
      (FiatToken.VaultBalancePubPath)

    if usdcBalanceCap == nil || !(usdcBalanceCap!.check()) {
        return UFix64(0.0)
    }

    let usdcBalanceRef = usdcBalanceCap!.borrow()!

    return usdcBalanceRef.balance
}`

export const getUSDCBalance = async (address) =>
  fcl.decode(
    await fcl.send([fcl.script(CODE), fcl.args([fcl.arg(address, t.Address)])])
  )
