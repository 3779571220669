import React from "react";

export const Lock = () => {
    return (
        <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 12C1.5 13.4587 2.07946 14.8576 3.11091 15.8891C4.14236 16.9205 5.54131 17.5 7 17.5C8.45869 17.5 9.85764 16.9205 10.8891 15.8891C11.9205 14.8576 12.5 13.4587 12.5 12C12.5 10.5413 11.9205 9.14236 10.8891 8.11091C9.85764 7.07946 8.45869 6.5 7 6.5C5.54131 6.5 4.14236 7.07946 3.11091 8.11091C2.07946 9.14236 1.5 10.5413 1.5 12V12Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.75 12C4.75 12.5967 4.98705 13.169 5.40901 13.591C5.83097 14.0129 6.40326 14.25 7 14.25C7.59674 14.25 8.16903 14.0129 8.59099 13.591C9.01295 13.169 9.25 12.5967 9.25 12C9.25 11.4033 9.01295 10.831 8.59099 10.409C8.16903 9.98705 7.59674 9.75 7 9.75C6.40326 9.75 5.83097 9.98705 5.40901 10.409C4.98705 10.831 4.75 11.4033 4.75 12Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10"/>
            <path d="M10 7.5V4.5C10 3.70435 9.68393 2.94129 9.12132 2.37868C8.55871 1.81607 7.79565 1.5 7 1.5C6.20435 1.5 5.44129 1.81607 4.87868 2.37868C4.31607 2.94129 4 3.70435 4 4.5V6.75667" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}