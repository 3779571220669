import React from "react"
import styled from "styled-components"
import {FaBell} from "react-icons/fa"

const StyledBanner = styled.div`
    position: relative;
    @keyframes animatebg {
        0% {
            background-position: 0% 0;
        }
        50% {
            background-position: 300% 0;
        }
        100% {
            background-position: 0% 0;
        }
    }

    background: linear-gradient(
            120deg,
            rgba(89,100,227,1),
            rgba(141,89,227,1),
            rgba(152,89,227,1),
            rgba(141,89,227,1),
            rgba(89,100,227,1)
        );
    background-size: 400%;
    animation: animatebg 28s ease-in-out 0s infinite normal;
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 100%;
    background-color: white;
    padding: 1rem 2rem 1rem 2rem;
    box-sizing: border-box;
    border-radius: 1rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`

const StyledBannerIcon = styled(FaBell)`
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    padding: 0.5rem;
    color: #231f20;
    transform: translate(-50%, -50%);
    background-color: #f8f8f8;
    padding: 0.5rem;
    border-radius: 1rem;
`

export const Banner = ({ content }) => {
    return (
        <StyledBanner>
            <StyledBannerIcon/>
            { content }
        </StyledBanner> 
    )
}
