export const getMinRequiredStake = (nodetype) => {
    switch(nodetype) {
        case (3):
            return 500000
        case (2):
            return 200000
        case (1):
            return 250000
        case (4):
            return 135000
        default:
            return 0
    }
}