import React, {useState, useEffect} from "react"
import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import { Transaction } from "../../transaction.js"

import { useDoesAccountHaveFUSDSupport } from "../../../../hooks/use-does-account-have-fusd-support.js"

import {
  template as fusdSetupTemplate,
  CODE as fusdSetupCode,
  HASH as fusdSetupHash,
  DESCRIPTION as fusdSetupDescription,
} from "@onflow/six-fusd-setup"

import { TRANSACTION_MANIFEST_VALUE } from "../../transaction-manifest-constructors.js"

export const Manifest = ({ 
  qp,
  hash,
  txn,
  showCode,
  consent,
  qpString,
  submitTransaction,
  submitTransactionLocked,
  currentUserAddr,
  hiddenArgs,
  lockedArgs,
}) => {
  const [manifestValue, setManifestValue] = useState(new TRANSACTION_MANIFEST_VALUE({
    hash: fusdSetupHash,
    title: "Transaction - FUSD Setup",
    description: fusdSetupDescription,
    code: fusdSetupCode,
    args: {},
    isSix: true,
    transactionFunction: () => {}
  }))

  const { doesAccountHaveFUSDSupport } = useDoesAccountHaveFUSDSupport(currentUserAddr)

  const computeManifestValue = async () => {
    setManifestValue(
      new TRANSACTION_MANIFEST_VALUE({
        hash: fusdSetupHash,
        disclaimer: null,
        error: doesAccountHaveFUSDSupport ? (
          <span>Your account is already configured for FUSD.</span>
        ) : null,
        title: "Transaction - FUSD Setup",
        description: fusdSetupDescription,
        code: fusdSetupCode,
        args: {},
        isSix: true,
        transactionFunction: async (hash, txn, args) => {
          return await fcl.decode(
            await fcl.send([
              fusdSetupTemplate({
                proposer: fcl.currentUser().authorization,
                authorization: fcl.currentUser().authorization,
                payer: fcl.currentUser().authorization,
              }),
              fcl.limit(9999),
            ])
          )
        },
      })
    )
  }

  useEffect(() => {
    computeManifestValue()
  }, [qpString, doesAccountHaveFUSDSupport])

  return (
    <Transaction 
      title={manifestValue?.title}
      description={manifestValue?.description}
      disclaimer={manifestValue?.disclaimer}
      error={manifestValue?.error}
      code={manifestValue?.code}
      isSix={manifestValue?.isSix}
      args={manifestValue?.args}
      transactionFunction={manifestValue?.transactionFunction}
      submitTransaction={submitTransaction}
      submitTransactionLocked={submitTransactionLocked}
      consent={consent}
      showCode={showCode}
      hash={hash}
      txn={txn}
      qp={qp}
    />
  )
}
