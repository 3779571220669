import React, {useState, useEffect} from "react"
import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import { Transaction } from "../../transaction.js"

import { useTotalUnlockedAccountFlowBalance } from "../../../../hooks/use-total-unlocked-account-flow-balance.js"
import { useTotalFlowBalance } from "../../../../hooks/use-total-flow-balance.js"
import { useAccountStorageInfo } from "../../../../hooks/use-account-storage-info.js"
import { useTotalLockedAccountFlowBalance } from "../../../../hooks/use-total-locked-account-flow-balance.js"

import {
  template as stakingCollectionRegisterDelegatorTemplate,
  CODE as stakingCollectionRegisterDelegatorCode,
  HASH as stakingCollectionRegisterDelegatorHash,
  DESCRIPTION as stakingCollectionRegisterDelegatorDescription,
} from "@onflow/six-stakingcollection-register-delegator"

import { TRANSACTION_MANIFEST_VALUE, TRANSACTION_MANIFEST_ARG } from "../../transaction-manifest-constructors.js"

import {
  calculateAvailableFLOWTokens,
} from "../../transaction-utils.js"

import {
  invalidUFix64ArgError,
  storageFeeError,
  sufficientTokensError
} from "../../transaction-errors.js"

import {
  validateUFix64,
  validateString,
} from "../../transaction-validators.js"

import {
  xformString,
  xformUFix64,
} from "../../transaction-xforms.js"

export const Manifest = ({ 
  qp,
  hash,
  txn,
  showCode,
  consent,
  qpString,
  submitTransaction,
  currentUserAddr,
  submitTransactionLocked,
  hiddenArgs,
  lockedArgs,
}) => {
  const [manifestValue, setManifestValue] = useState(new TRANSACTION_MANIFEST_VALUE({
    hash: stakingCollectionRegisterDelegatorHash,
    title: "Transaction - Register Delegator",
    description: stakingCollectionRegisterDelegatorDescription,
    code: stakingCollectionRegisterDelegatorCode,
    args: {},
    isSix: true,
    transactionFunction: () => {}
  }))

  const { accountStorageInfo } = useAccountStorageInfo(currentUserAddr)
  const { totalFlowBalance } = useTotalFlowBalance(currentUserAddr)
  const { totalUnlockedAccountFlowBalance } = useTotalUnlockedAccountFlowBalance(currentUserAddr)
  const { totalLockedAccountFlowBalance } = useTotalLockedAccountFlowBalance(currentUserAddr)

  const computeManifestValue = async () => {
    setManifestValue(
      new TRANSACTION_MANIFEST_VALUE({
        hash: stakingCollectionRegisterDelegatorHash,
        disclaimer: null,
        error: null,
        title: "Transaction - Register Delegator",
        description: stakingCollectionRegisterDelegatorDescription,
        code: stakingCollectionRegisterDelegatorCode,
        args: {
          0: new TRANSACTION_MANIFEST_ARG({
            title: "ID",
            qp: "nodeid",
            xform: xformString,
            validate: validateString,
            type: types.String,
            description: "The ID to delegate to.",
            value: qp.get("nodeid") || "",
          }),
          1: new TRANSACTION_MANIFEST_ARG({
            title: "Amount",
            qp: "amount",
            validate: validateUFix64,
            xform: xformUFix64,
            description: (
              <span>
                {"The amount of FLOW tokens to delegate."}
                {(totalFlowBalance && accountStorageInfo && totalLockedAccountFlowBalance) ? (
                  <span>
                    {" You have "}
                    {totalFlowBalance}
                    {" tokens available to delegate. After considering your accounts storage"}
                    {" requirements, you have "}
                    <b>
                      {calculateAvailableFLOWTokens(
                        accountStorageInfo?.useableAccountBalance || "0.0",
                        totalLockedAccountFlowBalance
                      )}
                    </b>
                    {" tokens available to delegate."}
                  </span>
                ) : (
                  ""
                )}
              </span>
            ),
            placeholder: "1.00",
            type: types.UFix64,
            value: qp.get("amount") ? qp.get("amount") : "",
            warnings: [].filter((w) => w !== null),
            errors: [
              invalidUFix64ArgError(qp.get("amount")),
              await storageFeeError(currentUserAddr, qp.get("amount")),
              await sufficientTokensError(
                totalFlowBalance,
                qp.get("amount"),
                "Error: Not enough FLOW tokens available to delegate. Reduce the amount of tokens to delegate."
              ),
            ].filter((w) => w !== null),
          }),
        },
        isSix: true,
        transactionFunction: async (hash, txn, args) => {
          return await fcl.decode(
            await fcl.send([
              stakingCollectionRegisterDelegatorTemplate({
                proposer: fcl.currentUser().authorization,
                authorization: fcl.currentUser().authorization,
                payer: fcl.currentUser().authorization,
                nodeId: args[0].xform(args[0].value),
                amount: args[1].xform(args[1].value),
              }),
              fcl.limit(9999),
            ])
          )
        },
      })
    )
  }

  useEffect(() => {
    computeManifestValue()
  }, [qpString, accountStorageInfo, totalFlowBalance, totalUnlockedAccountFlowBalance, totalLockedAccountFlowBalance])

  return (
    <Transaction 
      title={manifestValue?.title}
      description={manifestValue?.description}
      disclaimer={manifestValue?.disclaimer}
      error={manifestValue?.error}
      code={manifestValue?.code}
      isSix={manifestValue?.isSix}
      args={manifestValue?.args}
      transactionFunction={manifestValue?.transactionFunction}
      submitTransaction={submitTransaction}
      submitTransactionLocked={submitTransactionLocked}
      consent={consent}
      showCode={showCode}
      hash={hash}
      txn={txn}
      qp={qp}
    />
  )
}
