import React, {useState, useEffect} from "react"
import {Helmet} from "react-helmet"
import styled from "styled-components"
import {screenMinWidth} from "../mixins/breakpoints.js"
import {DropDown} from "../components/dropdown.js"

const StyledGetFlowContainer = styled.div`
    padding: 0rem 2rem 2rem 2rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
`

const StyledGetFlowCard = styled.a`
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    padding: 2rem;
    box-shadow: 0 0px 0px rgba(0,0,0,0.19), 0 0px 0px rgba(0,0,0,0.23);
    background-color: white;
    text-align: left;
    cursor: pointer;
    text-decoration: none;
    border: 0.1rem solid #E8E8E8;

    transition: box-shadow 0.2s;

    &:hover {
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }

    -webkit-appearance: none;
    -moz-appearance: none;
`

const StyledGetFlowCardTitle = styled.div`
    margin-bottom: 1rem;
    border-radius: 2rem;
    font-weight: bold;
    color: #2a2825;

    &:after {
        opacity: 0.5;
        content: "";
        display: block;
        left: 1rem;
        bottom: calc(1rem - 3px - 6px);
        background: #00ff76;
        height: 3px;
        width: 1rem;
        border-radius: 3px;    
    }
`

const StyledGetFlowCardSubtitle = styled.div`
    border-radius: 2rem;
    color: #6a6a6a;
`

export const GetFlow = () => {

    return (
        <StyledGetFlowContainer>
            <Helmet><title>Flow Port | Get Flow</title></Helmet>
            <StyledGetFlowCard href="https://docs.onflow.org/flow-token/backers/" target="_blank">
                <StyledGetFlowCardTitle>Claim FLOW as a Backer</StyledGetFlowCardTitle>
                <StyledGetFlowCardSubtitle>Existing Flow backers can claim their FLOW.</StyledGetFlowCardSubtitle>
            </StyledGetFlowCard>
            <StyledGetFlowCard href="https://docs.onflow.org/staking/" target="_blank">
                <StyledGetFlowCardTitle>Earn FLOW as a Validator</StyledGetFlowCardTitle>
                <StyledGetFlowCardSubtitle>Receive newly minted FLOW as a reward for running a node.</StyledGetFlowCardSubtitle>
            </StyledGetFlowCard>
        </StyledGetFlowContainer>
    )
}
