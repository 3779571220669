import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FUSD from 0xFUSDADDRESS

pub fun main(address: Address): UFix64 {
  let account = getAccount(address)

  let fusdBalanceCap = account
    .getCapability
    <&FUSD.Vault{FungibleToken.Balance}>
    (/public/fusdBalance)

  if fusdBalanceCap == nil || !(fusdBalanceCap!.check()) {
    return UFix64(0.0)
  }

  let fusdBalanceRef = fusdBalanceCap!.borrow()!

  return fusdBalanceRef.balance
}`

export const getFUSDBalance = async (address) => {
    return await fcl.decode(await fcl.send([
        fcl.script(CODE),
        fcl.args([fcl.arg(address, t.Address)])
    ]))
}
