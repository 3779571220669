import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { Button } from "../components/button.js"
import { useNetwork } from "../hooks/use-network.js"
import * as fcl from "@onflow/fcl"

const StyledContainer = styled.div`
    height: 100%;
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #f3f3f3;
`

const StyledTitle = styled.div`
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #919191;
`

const StyledNetwork = styled.span`
    color: var(--text-highlight);
`

export const CheckNetwork = () => {
    const { network } = useNetwork();
    return (
        <StyledContainer>
            <StyledTitle>Oops! Error has occured. Maybe this account could not be found on <StyledNetwork>{network}</StyledNetwork>.</StyledTitle>
            <StyledTitle>Logout and select another network.</StyledTitle>
            <Link to="/" onClick={fcl.unauthenticate}><Button title="Go Home" /></Link>
        </StyledContainer>
    )
}