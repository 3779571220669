import {useEffect} from "react"
import {atom, useRecoilState} from "recoil"
import {getIsStakingEnabled} from "../interactions/get-is-staking-enabled.js"
import {INIT, INIT_PROCESSING, IDLE, PROCESSING, ERROR} from "../global/constants.js"

export const valueAtom = atom({
    key: "is-staking-enabled::state",
    default: false
})

export const statusAtom = atom({
    key: "is-staking-enabled::status",
    default: INIT,
})

export function useIsStakingEnabled() {
    const [isStakingEnabled, setIsStakingEnabled] = useRecoilState(valueAtom)
    const [status, setStatus] = useRecoilState(statusAtom)

    async function refresh() {
        try {
            setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
            await getIsStakingEnabled().then(setIsStakingEnabled)
            setStatus(IDLE)
        } catch (e) {
            setStatus(ERROR)
        }
    }

    useEffect(() => {
        async function load() {
            try {
                setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
                await getIsStakingEnabled().then(_stakingEnabled => {
                    setIsStakingEnabled(_stakingEnabled)
                    setStatus(IDLE)
                })
            } catch (e) {
                setStatus(ERROR)
            }
        }
        load()
    }, [])

    return {
        isStakingEnabled,
        status,
        fetch: refresh,
        refresh
    }
}