import React from "react"
import * as fcl from "@onflow/fcl"
import { HASH as transferFlowTokenHash} from "@onflow/six-transfer-tokens"
import { HASH as transferFUSDHash} from "@onflow/six-fusd-transfer"
import { HASH as setupFUSDHash} from "@onflow/six-fusd-setup"
import { HASH as setupUSDCHash} from "@onflow/six-usdc-setup"
import { HASH as transferUSDCHash} from "@onflow/six-usdc-transfer"
import flowSymbolPrimary from "../../assets/flow-symbol-primary.png"
import fusdSymbolPrimary from "../../assets/fusd-symbol-primary.png"
import usdcIconSmall from "../../assets/usdc-icon-small.png"
import { AccountBalanceItem } from "./account-balance-item.js"

export const AccountBalances = ({
    address,
    currentUser,
    totalFlowBalance,
    fusdBalance,
    usdcBalance,
    doesAccountHaveFUSDSupport,
    doesAccountHaveUSDCSupport,
    doesAccountHaveLockedTokenSupport,
    doesAccountHaveLockedTokenSupportWithDapperlabsKey,
    showBalanceBreakdown
}) => (
    <>
        <AccountBalanceItem 
            balanceType="FLOW"
            symbol={flowSymbolPrimary}
            balance={totalFlowBalance}
            tooltipTitle="Locked Tokens"
            tooltipSubtitle={
                doesAccountHaveLockedTokenSupportWithDapperlabsKey ? 
                    "Your account is set up for Locked Tokens"
                    :
                    ( currentUser?.services[0]?.uid === "blocto#authn" ?
                        <span>
                            Your account is not yet set up for Locked Tokens. Go to the Blocto app on your phone, open the web browser within the app, and navigate to: 
                            <a target="_blank" href="https://flow-wallet.blocto.app/lock-account"> https://flow-wallet.blocto.app/lock-account </a>
                            to set up Locked Token support.
                        </span>
                        :
                        <span>
                            Please contact the Flow Team for further instructions on how to get Locked Token support.
                        </span>
                    )
            }
            sendLink={`/transaction?hash=${transferFlowTokenHash}&showcode=false`}
            enableSend={process.env.REACT_APP_ENABLE_SEND_FLOW === "true"}
            getLink="/get-flow"
            enableGet={true}
            isGetExternalLink={false}
            setupLink=""
            enableSetup={false}
            showBreakdownLink={`/balances/${fcl.withPrefix(address)}?showbalancebreakdown=${(!showBalanceBreakdown).toString()}`}
            showBreakdownTitle={`${showBalanceBreakdown ? "Hide" : "Show"} Breakdown`}
            enableShowBreakdown={doesAccountHaveLockedTokenSupport}
            fullwidth={true}
        />
        <AccountBalanceItem 
            balanceType="FUSD"
            symbol={fusdSymbolPrimary}
            balance={fusdBalance}
            sendLink={`/transaction?hash=${transferFUSDHash}&showcode=false`}
            enableSend={process.env.REACT_APP_ENABLE_SEND_FLOW === "true" && doesAccountHaveFUSDSupport}
            getLink="https://docs.onflow.org/fusd/providers"
            isGetExternalLink={true}
            enableGet={doesAccountHaveFUSDSupport}
            setupLink={`/transaction?hash=${setupFUSDHash}&showcode=false`}
            enableSetup={!doesAccountHaveFUSDSupport}
            fullwidth={false}
        />
        <AccountBalanceItem 
            balanceType="USDC"
            symbol={usdcIconSmall}
            balance={usdcBalance}
            sendLink={`/transaction?hash=${transferUSDCHash}&showcode=false`}
            enableSend={process.env.REACT_APP_ENABLE_SEND_FLOW === "true" && doesAccountHaveUSDCSupport}
            getLink="https://docs.onflow.org/fusd/providers"
            isGetExternalLink={true}
            enableGet={doesAccountHaveUSDCSupport}
            setupLink={`/transaction?hash=${setupUSDCHash}&showcode=false`}
            enableSetup={!doesAccountHaveUSDCSupport }
            fullwidth={false}
        />
    </>
)