import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import LockedTokens from 0xLOCKEDTOKENADDRESS
pub fun main(address: Address): UFix64 {
    let account = getAccount(address)

    let unlockedVault = account
      .getCapability(/public/flowTokenBalance)!
      .borrow<&FlowToken.Vault{FungibleToken.Balance}>()
        ?? panic("Could not borrow Balance reference to the Vault")
    let unlockedBalance = unlockedVault.balance

    let lockedAccountInfoCap = account
      .getCapability
      <&LockedTokens.TokenHolder{LockedTokens.LockedAccountInfo}>
      (LockedTokens.LockedAccountInfoPublicPath)
    if lockedAccountInfoCap == nil || !(lockedAccountInfoCap!.check()) {
        return unlockedBalance
    }
    
    let lockedAccountInfoRef = lockedAccountInfoCap!.borrow()!
    let lockedBalance = lockedAccountInfoRef.getLockedAccountBalance()
    let lockedBalanceUnlocked = lockedAccountInfoRef.getUnlockLimit()

    if lockedBalance == UFix64(0) {
        return unlockedBalance
    }

    if lockedBalanceUnlocked > lockedBalance {
        return unlockedBalance + lockedBalance
    }

    return lockedBalanceUnlocked + unlockedBalance
}
`

export const getTotalUnlockedFlowBalance = async (address) => {
    return await fcl.decode(await fcl.send([
        fcl.script(CODE),
        fcl.args([fcl.arg(address, t.Address)])
    ]))
}
