import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `  
import FlowEpoch from 0xEPOCHADDRESS

pub fun main(): FlowEpoch.EpochMetadata {
    return FlowEpoch.getEpochMetadata(FlowEpoch.currentEpochCounter)!
}`

export const getEpochCurrentMetadata = async (epochCounter) => {
    return await fcl.decode(await fcl.send([
        fcl.script(CODE)
    ]))
}
