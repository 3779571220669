export const xformUFix64 = (x) => x ? (x.includes(".") ? x.trim() : x.trim() + ".0") : null

export const xformUInt64 = (x) => x ? Number(x.trim()) : null

export const xformUInt32 = (x) => x ? Number(x.trim()) : null

export const xformUInt8 = (x) => x ? Number(x.trim()) : null

export const xformAddress = (x) => x ? x.trim() : null

export const xformString = (x) => x ? x.trim() : null

export const xformArrayOfStrings = (x) => x ? x.trim().split(",") : null