import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { RecoilRoot } from 'recoil'
import { AnimatedSwitch } from 'react-router-transition'
import { createGlobalStyle } from "styled-components"
import * as fcl from "@onflow/fcl"
import * as sdk from "@onflow/sdk"
import * as types from "@onflow/types"
import { AppStructure } from "./components/app-structure.js"
import { Auth } from "./pages/auth.js"
import { Account } from "./pages/account/account.js"
import { Balances } from "./pages/account/balances.js"
import { TransactionContainer } from "./pages/transaction/transaction-container.js"
import { TransactionStatus } from "./pages/transaction-status.js"
import { StakeDelegateWizard } from "./pages/stake-delegate-wizard/stake-delegate-wizard.js"
import { StakeDelegate } from "./pages/stake-delegate.js"
import { GetFlow } from "./pages/get-flow.js"
import { FourOhFour } from "./pages/fourohfour.js"
import { Terms } from "./pages/terms.js"
import { Privacy } from "./pages/privacy.js"
import { Maintenance } from "./pages/maintenance.js"
import { Helmet } from "react-helmet";
import { SetupFclConfiguration } from "./global/configurations.js";
import { useNetwork } from "./hooks/use-network.js";
import { MAINNET } from "./global/constants.js";
import { init } from '@onflow/fcl-wc'

window.fcl = fcl
window.sdk = sdk
window.types = types
window.t = types

const GlobalStyle = createGlobalStyle`
  * { 
    margin:0;
    padding:0;
    font-variant-ligatures: common-ligatures;
  }
  :root {
    --text-primary: #0F0F0F;
    --text-secondary: #0B0B0B;
    --text-highlight: #00EF8B;
    --text-normal: #6a6a6a;
    --font-family:"Inter",sans-serif;
  }
  body {
    background-color: white;
    color: var(--text-primary);
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 22px;
    // padding: 22px;
  }
  .switch-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .switch-wrapper > div {
    height: 100%;
    width: 100%;
  }
`

export const Main = () => {
  const { network } = useNetwork();
  SetupFclConfiguration(fcl, network);

  useEffect(() => {

    const setup = () => {
      init({
        projectId: process.env.REACT_APP_WALLET_CONNECT,
        includeBaseWC: true,
        metadata: {
          name: 'Flow Port',
          description: 'Flow Port is your portal to the decentralized world of Flow',
          url: 'https://port.onflow.org',
          icons: ['https://port.onflow.org/static/media/logo.3034b04f.svg'],
          // wcRequestHook: (data) => { // called on WC session/signing requests and passed relevant data
            // showRequestModal(data)
          // }
        }
      }).then(({ FclWcServicePlugin }) => {
        fcl.pluginRegistry.add(FclWcServicePlugin)
      })
    }

    // TODO: make available when there are wallets that support wallet connect
    // setup()
  }, [])

  return (
    <React.StrictMode>
      <React.Suspense fallback={null}>
        <RecoilRoot>
          <GlobalStyle />
          {process.env.REACT_APP_GA &&
            <Helmet>
              <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA}`}></script>
              <script>
                {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${process.env.REACT_APP_GA}');
              `}
              </script>
            </Helmet>
          }
          {process.env.REACT_APP_ENABLE_MAINTENANCE === "true" && <Maintenance />}
          <Router>
            <Switch>
              <Route path="/" component={Auth} exact />
              <Route path="/terms" component={Terms} exact />
              <Route path="/privacy" component={Privacy} exact />
              <AppStructure>
                <React.Suspense fallback={null}>
                  <AnimatedSwitch
                    atEnter={{ opacity: 0 }}
                    atLeave={{ opacity: 1 }}
                    atActive={{ opacity: 1 }}
                    className="switch-wrapper"
                  >
                    <Route path="/account/:address" component={Account} exact />
                    <Route path="/balances/:address" component={Balances} exact />
                    <Route path="/transaction" component={TransactionContainer} />
                    <Route path="/transaction-status" component={TransactionStatus} />
                    <Route path="/stake-delegate-wizard" component={StakeDelegateWizard} />
                    <Route path="/stake-delegate/:address" component={StakeDelegate} />
                    <Route path="/get-flow" component={GetFlow} exact />
                    <Route component={FourOhFour} />
                  </AnimatedSwitch>
                </React.Suspense>
              </AppStructure>
            </Switch>
          </Router>

        </RecoilRoot>
      </React.Suspense>
    </React.StrictMode>);

}

ReactDOM.render(<Main />, document.getElementById('root'));
