import React, {useState, useEffect} from "react"
import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import { Transaction } from "../../transaction.js"

import {
  template as stakingCollectionCloseStakeTemplate,
  CODE as stakingCollectionCloseStakeCode,
  HASH as stakingCollectionCloseStakeHash,
  DESCRIPTION as stakingCollectionCloseStakeDescription,
} from "@onflow/six-stakingcollection-close-stake"

import { TRANSACTION_MANIFEST_VALUE, TRANSACTION_MANIFEST_ARG } from "../../transaction-manifest-constructors.js"

import {
  validateString,
  validateUInt32,
} from "../../transaction-validators.js"

import {
  xformUInt32,
  xformString,
} from "../../transaction-xforms.js"

export const Manifest = ({ 
  qp,
  hash,
  txn,
  showCode,
  consent,
  qpString,
  submitTransaction,
  submitTransactionLocked,
  currentUserAddr,
  hiddenArgs,
  lockedArgs,
}) => {
  const [manifestValue, setManifestValue] = useState(new TRANSACTION_MANIFEST_VALUE({
    hash: stakingCollectionCloseStakeHash,
    title: "Transaction - Close Stake",
    description: stakingCollectionCloseStakeDescription,
    code: stakingCollectionCloseStakeCode,
    args: {},
    isSix: true,
    transactionFunction: () => {}
  }))

  const computeManifestValue = async () => {
    setManifestValue(
      new TRANSACTION_MANIFEST_VALUE({
        hash: stakingCollectionCloseStakeHash,
        disclaimer: null,
        error: null,
        title: "Transaction - Close Stake",
        description: stakingCollectionCloseStakeDescription,
        code: stakingCollectionCloseStakeCode,
        args: {
          0: new TRANSACTION_MANIFEST_ARG({
            title: "Node ID",
            qp: "nodeid",
            xform: xformString,
            validate: validateString,
            description: "The Node ID.",
            type: types.String,
            value: qp.get("nodeid") || "",
          }),
          1: new TRANSACTION_MANIFEST_ARG({
            title: "Delegator ID",
            description: "The Delegator ID.",
            qp: "delegatorid",
            xform: xformUInt32,
            validate: validateUInt32,
            type: types.UInt32,
            value: qp.get("delegatorid") || "",
            hidden: hiddenArgs.includes("delegatorid"),
          }),
        },
        isSix: true,
        transactionFunction: async (hash, txn, args) => {
          return await fcl.decode(
            await fcl.send([
              stakingCollectionCloseStakeTemplate({
                proposer: fcl.currentUser().authorization,
                authorization: fcl.currentUser().authorization,
                payer: fcl.currentUser().authorization,
                nodeId: args[0].xform(args[0].value),
                delegatorId: args[1].xform(args[1].value),
              }),
              fcl.limit(9999),
            ])
          )
        },
      })
    )
  }

  useEffect(() => {
    computeManifestValue()
  }, [qpString])

  return (
    <Transaction 
      title={manifestValue?.title}
      description={manifestValue?.description}
      disclaimer={manifestValue?.disclaimer}
      error={manifestValue?.error}
      code={manifestValue?.code}
      isSix={manifestValue?.isSix}
      args={manifestValue?.args}
      transactionFunction={manifestValue?.transactionFunction}
      submitTransaction={submitTransaction}
      submitTransactionLocked={submitTransactionLocked}
      consent={consent}
      showCode={showCode}
      hash={hash}
      txn={txn}
      qp={qp}
    />
  )
}

