import { useState } from "react";
import * as fcl from "@onflow/fcl"
import { getCurrentNetwork, getStoredItem, LOCAL_STORAGE, MAINNET } from "../global/constants";

export const useNetwork = () => {
    const location = window.location;
    const host = location.host;
    const storedItem = getStoredItem(host, LOCAL_STORAGE.CONFIG_ENV);
    const net = window.localStorage.getItem(storedItem) || getCurrentNetwork(location?.hostname, location?.pathname) ||  MAINNET;
    const [network, setNetwork] = useState(net)

    const updateNetwork = async (newNetwork) => {
        if (network === newNetwork) return;
        setNetwork(newNetwork);
        window.localStorage.setItem(storedItem, newNetwork);
        // log user out so they can sign into new network
        fcl.unauthenticate();
        window.location.href = `/`;
    }

    return {
        network,
        updateNetwork
    }
}