import React, {useState, useEffect, useMemo} from "react"
import * as fcl from "@onflow/fcl"
import {TRANSACTION_MANIFEST} from "./transaction-manifest.js"
import {useHistory} from "react-router-dom"
import { Transaction } from "./transaction.js"

export const TransactionContainer = ({ location }) => {
    const [currentUserAddr, setCurrentUserAddr] = useState(null)
    const [currentUser, setCurrentUser] = useState(null)
    const [defaultTransactionFunction, setDefaultTransactionFunction] = useState(null)
    const [submitTransactionLocked, setSubmitTransactionLocked] = useState(false)
    const [title, setTitle] = useState("Transaction")
    const [disclaimer, setDisclaimer] = useState(null)
    const [description, setDescription] = useState("Create a new Transaction to send to Flow")
    const [error, setError] = useState(null)
    const [code, setCode] = useState(null)
    const [isSix, setIsSix] = useState(false)
    const [args, setArgs] = useState({})
    const [txnId, setTxnId] = useState(null)
    const history = useHistory()

    const qp = new URLSearchParams(location.search)
    const qpString = qp.toString()
    const txn = qp.get("txn") ? atob(qp.get("txn")) : ""
    const hash = qp.get("hash")
    const origin = qp.get("origin")
    const showCode = qp.get("showcode") === "false" ? false : true
    const consent = qp.get("consent") ? (qp.get("consent") === "false" ? false : true) : false
    
    const hiddenArgs = qp.getAll("meta_hide_arg") || []
    const lockedArgs = qp.getAll("meta_lock_arg") || []

    const isCurrentUserBlockedFromSubmittingTxns = process.env.REACT_APP_TX_ACCOUNT_BLOCK_LIST && process.env.REACT_APP_TX_ACCOUNT_BLOCK_LIST.split(",").map(fcl.withPrefix).includes(currentUserAddr)

    const submitTransaction = (hash, txn, args) => async (transactionFunction) => {
        if (submitTransactionLocked) return
        setSubmitTransactionLocked(true)

        if (isCurrentUserBlockedFromSubmittingTxns) {
            setError("Your account is temporarily blocked from sending transactions on Flow Port. This will be resolved soon. Please contact hello@onflow.org for further assistance.")
            return null
        }
        
        let response = null
        try {
            response = transactionFunction ? await transactionFunction(hash, txn, args) : null
        } catch(e) {
            console.error(e)
        }

        setTxnId(response)
        setSubmitTransactionLocked(false)
    }

    useEffect(() => {
        try {
            if (!TRANSACTION_MANIFEST[hash]) {
                setArgs({})
                setIsSix(false)
                setCode(txn)
                setDefaultTransactionFunction(() => async (hash, txn, args) => {
                    setTxnId(await fcl.decode(await fcl.send([
                        fcl.transaction(txn),
                        fcl.args(Object.values(args).map(arg => fcl.arg(arg.xform(arg.value), arg.type))),
                        fcl.proposer(fcl.currentUser().authorization),
                        fcl.payer(fcl.currentUser().authorization),
                        fcl.authorizations([ fcl.currentUser().authorization ])
                    ])))
                })
            }
        } catch(e) {
            console.error("Error setting default transaction: ", e)
        }
    }, [hash, txn, qpString, currentUserAddr])

    useEffect(() => {
        if (txnId) {
            history.push(`/transaction-status?txnId=${txnId}${origin ? `&origin=${origin}` : ""}`)
        }
    }, [txnId])

    useEffect(() => {
        return fcl.currentUser().subscribe(currentUser => {
            setCurrentUser(currentUser)
            setCurrentUserAddr(currentUser.addr ? fcl.withPrefix(currentUser.addr) : null)
        })
    }, [])

    let TransactionManifestComp = TRANSACTION_MANIFEST[hash]

    return (
        TransactionManifestComp ? 
            <TransactionManifestComp
                qp={qp}
                hash={hash}
                txn={txn}
                showCode={showCode}
                consent={consent}
                hiddenArgs={hiddenArgs}
                lockedArgs={lockedArgs}
                qpString={qpString}
                currentUserAddr={currentUserAddr}
                submitTransaction={submitTransaction}
                submitTransactionLocked={submitTransactionLocked}
            />
            :
            <Transaction 
                title={title}
                description={description}
                disclaimer={disclaimer}
                error={error}
                code={code}
                isSix={isSix}
                args={args}
                showCode={showCode}
                consent={consent}
                transactionFunction={defaultTransactionFunction}
                submitTransaction={submitTransaction}
                submitTransactionLocked={submitTransactionLocked}
                qp={qp}
            />
    )
}