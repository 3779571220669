import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `
import FlowIDTableStaking from 0xIDENTITYTABLEADDRESS
import LockedTokens from 0xLOCKEDTOKENADDRESS

pub fun main(nodeID: String): FlowIDTableStaking.NodeInfo? {
    return FlowIDTableStaking.NodeInfo(nodeID: nodeID)
}
`

export const getNodeInfo = async (nodeId) => {
    return await fcl.decode(await fcl.send([
        fcl.script(CODE),
        fcl.args([fcl.arg(nodeId, t.String)])
    ]))
}
