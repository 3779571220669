import React, { useRef } from 'react';
import { FaNetworkWired } from 'react-icons/fa';
import styled from "styled-components";
import { getSupportedNetworks } from '../global/constants';
import { useNetwork } from "../hooks/use-network"
import Select from 'react-select';

const StyledContainer = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    width: 150px;
`

const StyledNetworkSelect = styled(Select)`
    position: relative;
    text-transform: capitalize;
    width: 100%;
    z-index: 2;

    color: #6a6a6a;
    background: #f3f3f3;

    font-size: 0.75rem;

    border-radius: 0.25rem;
    border: 0.1rem solid white;

    -webkit-appearance: none;
    -moz-appearance: none;

    &:focus {
        border: none;
        outline: none;
    }
    &:after { 
        pointer-events: none;   
    }
`

const NetworkWired = styled(FaNetworkWired)`
    color: #6a6a6a;  
    height: 1.75em;
    width: 1.75em;
    margin-right: 0.25rem;
`

export const NetworkPicker = () => {
    const { network, updateNetwork } = useNetwork();
    const selectRef = useRef(null);
    const options = getSupportedNetworks().map(n => ({ value: n, label: n }));

    return (
        <StyledContainer>
            <NetworkWired />
            <StyledNetworkSelect
                value={
                    options.filter(option =>
                        option.label === network)
                }

                openMenuOnFocus
                ref={selectRef}
                controlShouldRenderValue={true}
                menuPlacement={"top"}
                onChange={item => updateNetwork(item.value)}
                options={options}
            >
            </StyledNetworkSelect>
        </StyledContainer>
    )
}