import styled from "styled-components"
import {Button} from "../../components/button.js"

export const StyledAnswer = styled.div`
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    border-bottom: 0.1rem solid #e8e8e8;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    word-break: keep-all;
    cursor: pointer;

    &:last-child {
        border-bottom: none;
        padding-bottom: 0rem;
    }
`

export const StyledAnswerNumber = styled.div`
    margin-right: 0.5rem;
    font-weight: bold;
`

export const StyledAnswerText = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

export const StyledAnswerTitle = styled.div`
    text-align: left;
    font-weight: bold;
`

export const StyledAnswerSubtitle = styled.div`
    color: #6a6a6a;
`

export const StyledAnswerImg = styled.img`
    min-width: 1rem;
    min-height: 1rem;
    transition: opacity 0.2s;
    opacity: ${({showImage}) => showImage ? "1" : "0"};
`

export const StyledQuestion = styled.div`
    display: flex;
    flex-direction: column;
    word-break: keep-all;

    width: 40rem;
    max-width: 100%;
`

export const StyledQuestionTitle = styled.h2`
    margin-bottom: 1rem;
`

export const StyledQuestionAnswersRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
`

export const StyledQuestionAnswersColumn = styled.div`
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const StyledQuestionDescription = styled.div`
    margin-bottom: 1rem;
    width: 100%;
    text-align: left;
    color: #6a6a6a;
`

export const StyledQuestionText = styled.div`
    margin-bottom: 1rem;
    width: 100%;
    text-align: left;
    color: #6a6a6a;
    font-size: 0.75rem;
`

export const StyledQuestionAnswerButton = styled(Button)`
    margin-bottom: 1rem;
    width: 100%;
`

export const StyledQuestionAnswerRadioWrapper = styled.div`
    margin-bottom: 0.5rem;
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #e5e5e5;
    border-radius: 1rem;
    cursor: pointer;
`

export const StyledQuestionAnswerInputRadio = styled.input`
    cursor: pointer;
`

export const StyledQuestionAnswerInputRadioLabel = styled.label`
    width: 100%;
    margin-left: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`

export const StyledQuestionAnswerInputRadioLabelText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const StyledQuestionAnswerInputRadioLabelTitle = styled.span`
    color: black;
`

export const StyledQuestionAnswerInputRadioLabelSubtitle = styled.span`
    color: #6a6a6a;
`

export const StyledQuestionAnswerInputRadioLabelImage = styled.img`
    height: 1rem;
`

export const StyledQuestionAnswerInputTitle = styled.div`
    margin-bottom: 0.5rem;
    text-decoration: none;
    color: #808080;
`

export const StyledQuestionAnswerInput = styled.input`
    width: 100%;
    margin-bottom: ${({isError}) => isError ? "0rem" : "1rem"};

    padding: 1rem;
    color: black;

    border-radius: 0.25rem;
    border-top: 0.1rem solid ${({isError}) => isError ? "#e95a3c" : "#e5e5e5"};
    border-right: 0.1rem solid ${({isError}) => isError ? "#e95a3c" : "#e5e5e5"};
    border-left: 0.1rem solid ${({isError}) => isError ? "#e95a3c" : "#e5e5e5"};
    border-bottom: 0.1rem solid ${({isError}) => isError ? "#e95a3c" : "#e5e5e5"};
    /* border: 0.1rem solid #f8f8f8; */
    background-color: #f8f8f8;

    font-size: 1.25rem;

    -webkit-appearance: none;
    -moz-appearance: none;

    box-sizing: border-box;

    &:focus {
        border: 0.1rem solid #6a6a6a;
        outline: none;
    }

    &::placeholder {
        color: #D0D0D0;
    }
`

export const StyledQuestionAnswerInputWrapper = styled.div`
    position: relative;
    width: 100%;
`

export const StyledQuestionAnswerInputError = styled.div`
    margin-top: -1rem;
    padding: 1.5rem 1rem 0.5rem 1rem;
    min-height: 4rem;
    background-color: #e95a3c;
    border-radius: 0.25rem;
    color: white;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`

export const StyledQuestionAnswerInputWarning = styled.div`
    padding: 1rem;
    height: 4rem;
    background-color: #e5d747;
    color: var(--text-primary);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-right: 0.1rem solid #e5e5e5;
    border-left: 0.1rem solid #e5e5e5;
    border-bottom: 0.1rem solid #e5e5e5;

    border-radius: 0rem 0rem ${({isBottom}) => isBottom ? "0.25rem" : "0rem"} ${({isBottom}) => isBottom ? "0.25rem" : "0rem"};
`

export const StyledQuestionAnswerInputWarningLink = styled.a`
    color: var(--text-primary);
    text-decoration: none;
    cursor: pointer;
`

export const StyledQuestionAnswerInputResponse = styled.div`
    padding: 0rem 1rem 0rem 1rem;
    height: 4rem;
    background-color: white;
    
    color: black;
    background-color: white;

    border-right: 0.1rem solid #e5e5e5;
    border-left: 0.1rem solid #e5e5e5;
    border-bottom: 0.1rem solid #e5e5e5;

    border-radius: 0rem 0rem ${({isBottom}) => isBottom ? "0.25rem" : "0rem"} ${({isBottom}) => isBottom ? "0.25rem" : "0rem"};

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const StyledQuestionAnswerInputResponseLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
export const StyledQuestionAnswerInputResponseRight = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    color: #6a6a6a;
    font-size: 1rem;
`

export const StyledQuestionAnswerInputResponseIcon = styled.div`
    margin-right: 1rem;
`

export const StyledQuestionAnswerInputFlow = styled.input`
    width: 100%;
    padding: 1rem 1rem 1rem 4rem;
    color: black;
    border-radius: 0.25rem;
    border-top: 0.1rem solid ${({isError}) => isError ? "#e95a3c" : "#e5e5e5"};
    border-right: 0.1rem solid ${({isError}) => isError ? "#e95a3c" : "#e5e5e5"};
    border-left: 0.1rem solid ${({isError}) => isError ? "#e95a3c" : "#e5e5e5"};
    border-bottom: 0.1rem solid ${({isError}) => isError ? "#e95a3c" : "#e5e5e5"};
    background-color: #f8f8f8;
    box-sizing: border-box;
    font-size: 1.5rem;

    -webkit-appearance: none;
    -moz-appearance: none;

    &:focus {
        border: 0.1rem solid #6a6a6a;
        outline: none;
    }

    &::placeholder {
        color: #D0D0D0;
    }
`

export const StyledQuestionAnswerInputImg = styled.img`
    position: absolute;
    top: 0;
    left: 1rem;
    transform: translateY(50%);
    height: 2rem;
`