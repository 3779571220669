import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `  
// Returns the view of the current block

pub fun main(): UInt64 {
    let currentBlock = getCurrentBlock()
    return currentBlock.view
}`

export const getEpochCurrentView = async () => {
    return await fcl.decode(await fcl.send([
        fcl.script(CODE),
    ]))
}
