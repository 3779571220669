import React from "react"
import styled from "styled-components"
import * as fcl from "@onflow/fcl"
import { HASH as stakingCollectionRegisterDelegatorHash } from "@onflow/six-stakingcollection-register-delegator"
import circleCheck from "../../assets/circle-check.svg"
import logomark from "../../assets/logomark.svg"
import {Button} from "../../components/button.js"
import {Lock} from "../../assets/lock.js"
import {Unlock} from "../../assets/unlock.js"
import {getMinRequiredStake} from "../../global/get-min-required-stake.js"
import {FaExclamationCircle,FaExclamationTriangle} from "react-icons/fa"
import {
    StyledAnswer,
    StyledAnswerNumber,
    StyledAnswerText,
    StyledAnswerTitle,
    StyledAnswerSubtitle,
    StyledAnswerImg,
    StyledQuestion,
    StyledQuestionTitle,
    StyledQuestionAnswersRow,
    StyledQuestionAnswersColumn,
    StyledQuestionDescription,
    StyledQuestionAnswerButton,
    StyledQuestionAnswerInputTitle,
    StyledQuestionAnswerInput,
    StyledQuestionAnswerInputFlow,
    StyledQuestionAnswerInputImg,
    StyledQuestionAnswerInputError,
    StyledQuestionAnswerInputResponse,
    StyledQuestionAnswerInputResponseLeft,
    StyledQuestionAnswerInputResponseRight,
    StyledQuestionAnswerInputResponseIcon,
    StyledQuestionAnswerInputWrapper,
    StyledQuestionAnswerInputWarning,
    StyledQuestionAnswerInputWarningLink
} from "./graph-components.js"

export const DelegationGraph = {
    "1": {
        id: "1",
        _description: "What is the node operator ID?",
        postCondition: worldState =>
            ((worldState.operatorId !== null && worldState.operatorId !== "" && worldState.operatorId.length === 64) &&
            (worldState.nodeInfo ? ((parseFloat(worldState.nodeInfo.tokensCommitted) + parseFloat(worldState.nodeInfo.tokensStaked) - parseFloat(worldState.nodeInfo.tokensRequestedToUnstake)) >= getMinRequiredStake(worldState.nodeInfo.role)) : Boolean(worldState.nodeInfo))),
        next: worldState => "2-terminal",
        previous: worldState => null,
        question: worldState => {
            let nodeOperatorMinStakeError = false;
            let invalidNodeError = worldState.getNodeInfoError
            let minLengthError = worldState.operatorId !== null && worldState.operatorId !== "" && worldState.operatorId.length !== 64

            if (worldState.nodeInfo) {
                let min = getMinRequiredStake(worldState.nodeInfo.role)
                if ((parseFloat(worldState.nodeInfo.tokensCommitted) + parseFloat(worldState.nodeInfo.tokensStaked) - parseFloat(worldState.nodeInfo.tokensRequestedToUnstake)) < min) {
                    nodeOperatorMinStakeError = true;
                }
            }

            let isError = nodeOperatorMinStakeError || minLengthError || invalidNodeError
        
            return (
                <StyledQuestion>
                    <StyledQuestionTitle>Node Operator ID</StyledQuestionTitle>
                    <StyledQuestionDescription>Input the ID of the node operator you would like to delegate to.</StyledQuestionDescription>
                    <StyledQuestionAnswerInputWrapper>
                        <StyledQuestionAnswerInput isError={isError} value={worldState.operatorId} placeholder="123" onChange={e => worldState.updateURL({ param: "operatorid", val: e.target.value })}/>
                        { invalidNodeError &&
                            <StyledQuestionAnswerInputError>
                                <StyledQuestionAnswerInputResponseIcon><FaExclamationCircle /></StyledQuestionAnswerInputResponseIcon>
                                This node does not exist.
                            </StyledQuestionAnswerInputError>
                        }
                        { nodeOperatorMinStakeError &&
                            <StyledQuestionAnswerInputError>
                                <StyledQuestionAnswerInputResponseIcon><FaExclamationCircle /></StyledQuestionAnswerInputResponseIcon>
                                This node operator is not sufficiently staked to accept delegators.
                            </StyledQuestionAnswerInputError>
                        }
                        { minLengthError &&
                            <StyledQuestionAnswerInputError>
                                <StyledQuestionAnswerInputResponseIcon><FaExclamationCircle /></StyledQuestionAnswerInputResponseIcon>
                                Node ID must be 32 bytes (64 hex characters)
                            </StyledQuestionAnswerInputError>
                        }
                    </StyledQuestionAnswerInputWrapper>
                    <StyledQuestionDescription style={{marginTop: "0.5rem", marginBottom: "2rem"}}>Find a list <a target="_blank" href="https://github.com/onflow/flow/blob/master/nodeoperators/NodeOperatorList.md">here</a> of available node operators to delegate to.</StyledQuestionDescription>
                </StyledQuestion>
            )
        },
        answer: worldState => (
            <StyledAnswer key="answer-1">
                <StyledAnswerNumber isActive={worldState.questionId === "1"}>1.</StyledAnswerNumber>
                <StyledAnswerText>
                    <StyledAnswerTitle isActive={worldState.questionId === "1"}>Node operator</StyledAnswerTitle>
                    { worldState.questionId === "1" && <StyledAnswerSubtitle>Determine to which node operator you will delegate your FLOW.</StyledAnswerSubtitle> }
                </StyledAnswerText>
                <StyledAnswerImg showImage={worldState.graph["1"].postCondition(worldState)} src={circleCheck} />
            </StyledAnswer>
        )
    },
    "2-terminal": {
        id: "2-terminal",
        _description: "Delegation Amount",
        postCondition: worldState => {
            return worldState.graph["1"].postCondition(worldState) && (
                worldState.stakeAmount !== null && worldState.stakeAmount !== "" && worldState.stakeAmount !== "unset"
            )
        },
        next: worldState => null,
        previous: worldState => "1",
        question: worldState => {
            let isInsufficientFundsError = Number(worldState.stakeAmount) > Number(worldState.totalFlowBalance)

            const isGasFeeWarning = worldState.totalFlowBalance && Number(worldState.totalFlowBalance) - Number(worldState.stakeAmount) < 0.005

            let isError = isInsufficientFundsError || worldState.isStorageFeeError

            let lockedUsed = Math.min(Number(worldState.stakeAmount), Number(worldState.totalFlowBalance) - Number(worldState.totalUnlockedFlowBalance))
            let unlockedUsed = Math.max(Number(worldState.stakeAmount) - Number(lockedUsed, 0))

            return (
                <StyledQuestion>
                    <StyledQuestionTitle>Stake Amount</StyledQuestionTitle>
                    <StyledQuestionDescription>How many tokens would you like to stake?</StyledQuestionDescription>
                    <StyledQuestionAnswerInputWrapper>
                        <StyledQuestionAnswerInputImg src={logomark} alt="Flow Logo" />
                        <StyledQuestionAnswerInputFlow type="number" value={worldState.stakeAmount ? (worldState.stakeAmount === "unset" ? "" : worldState.stakeAmount) : "0.00"} placeholder={"0.00"} onChange={e => worldState.updateURL({ param: "stakeamount", val: e.target.value || "unset" })}/>
                    </StyledQuestionAnswerInputWrapper>
                    { isInsufficientFundsError &&
                        <StyledQuestionAnswerInputError>
                            <StyledQuestionAnswerInputResponseIcon><FaExclamationTriangle /></StyledQuestionAnswerInputResponseIcon>
                            You do not have a sufficient FLOW
                        </StyledQuestionAnswerInputError>
                    }
                    { worldState.isStorageFeeError && !isInsufficientFundsError &&
                        <StyledQuestionAnswerInputError>
                            <StyledQuestionAnswerInputResponseIcon><FaExclamationTriangle /></StyledQuestionAnswerInputResponseIcon>
                            <span>
                                Warning: The amount input may cause this transaction to fail because it will may reduce the amount of FLOW in your account below whats needed to pay for your accounts storage usage. 
                                <StyledQuestionAnswerInputWarningLink target="_blank" href="https://docs.onflow.org/concepts/storage#why-is-there-an-account-minimum-balance"> Learn more about storage fees here.</StyledQuestionAnswerInputWarningLink>
                            </span>
                        </StyledQuestionAnswerInputError>
                    }
                    { !isError && worldState.stakeAmount && worldState.stakeAmount !== "unset" && worldState.totalFlowBalance && 
                        <>
                            <StyledQuestionAnswerInputResponse>
                                <StyledQuestionAnswerInputResponseLeft>
                                    <StyledQuestionAnswerInputResponseIcon><Lock /></StyledQuestionAnswerInputResponseIcon> {lockedUsed} from your Locked FLOW
                                </StyledQuestionAnswerInputResponseLeft>
                                <StyledQuestionAnswerInputResponseRight>
                                    {lockedUsed} / {Number(worldState.totalFlowBalance) - Number(worldState.totalUnlockedFlowBalance)}
                                </StyledQuestionAnswerInputResponseRight>
                            </StyledQuestionAnswerInputResponse>
                            { (unlockedUsed > 0) && 
                                <StyledQuestionAnswerInputResponse isBottom={!worldState.isStorageFeeError}>
                                    <StyledQuestionAnswerInputResponseLeft>
                                        <StyledQuestionAnswerInputResponseIcon><Unlock /></StyledQuestionAnswerInputResponseIcon> {unlockedUsed} from your Unlocked FLOW
                                    </StyledQuestionAnswerInputResponseLeft>
                                    <StyledQuestionAnswerInputResponseRight>
                                        {unlockedUsed} / {Number(worldState.totalUnlockedFlowBalance)}
                                    </StyledQuestionAnswerInputResponseRight>
                                </StyledQuestionAnswerInputResponse>
                            }
                        </>
                    }
                    {/* { isGasFeeWarning &&
                        <StyledQuestionAnswerInputWarning isBottom={!isStorageFeeError}>
                            <StyledQuestionAnswerInputResponseIcon><FaExclamationTriangle /></StyledQuestionAnswerInputResponseIcon>
                            <span>
                                Warning: The amount input may cause this transaction to fail because it will may reduce the amount of FLOW in your account below whats needed to pay for this transactions gas fees.
                                <StyledQuestionAnswerInputWarningLink> Learn more about gas fees here.</StyledQuestionAnswerInputWarningLink>
                            </span>
                        </StyledQuestionAnswerInputWarning>
                    } */}
                </StyledQuestion>
            )
        },
        answer: worldState => (
            <StyledAnswer key="answer-2">
                <StyledAnswerNumber isActive={worldState.questionId === "2-terminal"}>2.</StyledAnswerNumber>
                <StyledAnswerText>
                    <StyledAnswerTitle isActive={worldState.questionId === "2-terminal"}>Stake details</StyledAnswerTitle>
                    { worldState.questionId === "2-terminal" && <StyledAnswerSubtitle>Adjust the amount of Token you’d like to stake.</StyledAnswerSubtitle> }
                </StyledAnswerText>
                <StyledAnswerImg showImage={worldState.graph["2-terminal"].postCondition(worldState)} src={circleCheck} />
            </StyledAnswer>
        ),
        redirect: worldState => `/transaction?hash=${stakingCollectionRegisterDelegatorHash}&nodeid=${worldState.operatorId}&amount=${worldState.stakeAmount.charAt(0) === "." ? "0" + worldState.stakeAmount : worldState.stakeAmount}&showcode=false&origin=${btoa(`/stake-delegate/${fcl.withPrefix(worldState.currentUserAddr)}`)}`,
    }
}