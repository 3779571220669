import React from "react"
import styled from "styled-components"

const StyledStorageStatusVisualizer = styled.div`
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(38,255,118);
        background: linear-gradient(90deg, rgb(45,201,103) 0%, rgb(38,255,118) 100%);   
    }

    margin-top: 1rem;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 2rem;
    border-radius: 2rem;
`

const StyledStorageStatusVisualizerBar = styled.div`
    position: absolute;
    right: 0;
    width: ${({amountUsed}) => `${Math.min((1 - amountUsed) * 100, 99)}%`};
    height: 2rem;
    background-color: #6a6a6a;
    transition: width 0.5s;
    z-index: 2;
`

const StyledStorageDescription = styled.div`
    margin-top: 1rem;
`

const StyledStorageDescriptionLink = styled.a`
    color: #3745f5;
    text-decoration: none;
    cursor: pointer;
`

export const AccountStorage = ({
    accountStorageInfo,
    isCurrentUsersAccount,
    showExpanded = false
}) => {

    return showExpanded ? (
        <>
            { isCurrentUsersAccount
                ? <StyledStorageDescription>
                    You have used ~{accountStorageInfo ? accountStorageInfo.percentUsed : 0}% of your accounts storage (~{ accountStorageInfo ? accountStorageInfo.storageUsed / ((accountStorageInfo.storageMegaBytesPerReservedFLOW) * 1e+6) : 0} MiB used of ~{ accountStorageInfo ? accountStorageInfo.storageCapacity / ((accountStorageInfo.storageMegaBytesPerReservedFLOW) * 1e+6) : 0} MiB total).
                </StyledStorageDescription>
                : <StyledStorageDescription>This account has used ~{accountStorageInfo ? accountStorageInfo.percentUsed : 0}% of its storage (~{ accountStorageInfo ? accountStorageInfo.storageCapacity / ((accountStorageInfo.storageMegaBytesPerReservedFLOW) * 1e+6) : 0} MiB).</StyledStorageDescription>
            }    
            <StyledStorageStatusVisualizer amountUsed={accountStorageInfo ? accountStorageInfo.ratioUsed : 0}>
                <StyledStorageStatusVisualizerBar amountUsed={accountStorageInfo ? accountStorageInfo.ratioUsed : 0} />
            </StyledStorageStatusVisualizer>
            { isCurrentUsersAccount
                ? <StyledStorageDescription>
                    Your account must maintain a minimum unlocked FLOW balance of { accountStorageInfo ? Math.max( accountStorageInfo.storageUsed / ((accountStorageInfo.storageMegaBytesPerReservedFLOW) * 1e+6) , Number(accountStorageInfo.minimumStorageReservation) ) : "0" } FLOW to cover your storage usage.
                </StyledStorageDescription>
                : <StyledStorageDescription>This account must maintain a minimum unlocked FLOW balance of { accountStorageInfo ? Math.max( accountStorageInfo.storageUsed / ((accountStorageInfo.storageMegaBytesPerReservedFLOW) * 1e+6) , Number(accountStorageInfo.minimumStorageReservation) ) : "0" } FLOW to cover its storage usage.</StyledStorageDescription>
            }    
            <StyledStorageDescription style={{fontSize: "0.8rem"}}>Accounts have storage by having unlocked FLOW. The more unlocked FLOW an account has, the more storage it has. 1 FLOW = {accountStorageInfo ? Number(accountStorageInfo.storageMegaBytesPerReservedFLOW).toFixed(2) : "1"}MiB of storage. <StyledStorageDescriptionLink target="_blank" href="https://docs.onflow.org/concepts/storage#why-is-there-an-account-minimum-balance">Learn more about Storage.</StyledStorageDescriptionLink></StyledStorageDescription> 
        </>
    ) : (
        isCurrentUsersAccount ?
            <StyledStorageDescription>You have used ~{accountStorageInfo ? accountStorageInfo.percentUsed : 0}% of your accounts storage.</StyledStorageDescription>
            :
            <StyledStorageDescription>This account has used ~{accountStorageInfo ? accountStorageInfo.percentUsed : 0}% of its storage.</StyledStorageDescription>
    )
}