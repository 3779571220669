import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FiatToken from 0xUSDCADDRESS

pub fun main(address: Address): Bool {
    let account = getAccount(address)

    let usdcBalanceCap = account
      .getCapability
      <&FiatToken.Vault{FungibleToken.Balance}>
      (FiatToken.VaultBalancePubPath)

    if usdcBalanceCap == nil || !(usdcBalanceCap!.check()) {
        return false
    }
    return true
}
`

export const doesAcctHaveUSDCSupport = async (address) => {
    return fcl.decode(await fcl.send([
        fcl.script(CODE),
        fcl.args([fcl.arg(address, t.Address)])
    ]))
}
