class UndefinedTransactionManifestArgArgumentError extends Error {
    constructor(argumentName) {
      const msg = `Transaction Manifest Error: Missing argument ${argumentName}.`
      super(msg)
      this.name = "Undefined Transaction Manifest Arg Argument Error"
    }
}

class UndefinedTransactionManifestValueArgumentError extends Error {
    constructor(argumentName) {
      const msg = `Transaction Manifest Error: Missing argument ${argumentName}.`
      super(msg)
      this.name = "Undefined Transaction Manifest Value Argument Error"
    }
}

const requiredTransactionManifestArgArgument = (arg, argumentName) => {
    if (typeof arg === "undefined" || arg === null) throw new UndefinedTransactionManifestArgArgumentError(argumentName)
    return arg
}
const requiredTransactionManifestValueArgument = (arg, argumentName) => {
    if (typeof arg === "undefined" || arg === null) throw new UndefinedTransactionManifestValueArgumentError(argumentName)
    return arg
}

export const TRANSACTION_MANIFEST_ARG_INPUT_TYPES = {
    "TEXT": "TEXT",
    "SELECT": "SELECT",
}

export function TRANSACTION_MANIFEST_ARG({
    title,
    qp,
    xform,
    validate,
    type,
    description,
    placeholder,
    value,
    errors,
    warnings,
    hidden,
    options,
    inputType,
}) {
    this.title = requiredTransactionManifestArgArgument(title, "title")
    this.qp = requiredTransactionManifestArgArgument(qp, "qp")
    this.xform = requiredTransactionManifestArgArgument(xform, "xform")
    this.validate = requiredTransactionManifestArgArgument(validate, "validate")
    this.description = requiredTransactionManifestArgArgument(description, "description")
    this.value = requiredTransactionManifestArgArgument(value, "value")
    this.placeholder = placeholder ?? ""
    this.type = type ?? null
    this.errors = errors ?? []
    this.warnings = warnings ?? []
    this.hidden = hidden || false
    this.options = options || {}
    this.inputType = inputType || TRANSACTION_MANIFEST_ARG_INPUT_TYPES.TEXT
}

export function TRANSACTION_MANIFEST_VALUE({
    disclaimer,
    error,
    title,
    description,
    code,
    args,
    isSix,
    transactionFunction,
}) {
    this.title = requiredTransactionManifestValueArgument(title, "title")
    this.description = requiredTransactionManifestValueArgument(description, "description")
    this.code = requiredTransactionManifestValueArgument(code, "code")
    this.args = requiredTransactionManifestValueArgument(args, "args") || {}
    this.transactionFunction = requiredTransactionManifestValueArgument(transactionFunction, "transactionFunction")
    this.disclaimer = disclaimer || null
    this.error = error || null
    this.isSix = isSix || false
}
