import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `
import LockedTokens from 0xLOCKEDTOKENADDRESS
pub fun main(address: Address): [AnyStruct] {
    let account = getAccount(address)
    let lockedAccountInfoCap = account
      .getCapability
      <&LockedTokens.TokenHolder{LockedTokens.LockedAccountInfo}>
      (LockedTokens.LockedAccountInfoPublicPath)
    if lockedAccountInfoCap == nil || !(lockedAccountInfoCap!.check()) {
        return [false, "no locked account info capability"]
    }

    let lockedAccountAddress: Address = lockedAccountInfoCap!.borrow()!.getLockedAccountAddress()

    let lockedAccount: PublicAccount = getAccount(lockedAccountAddress)

    var keyIndex = 0
    let lockedAccountKeys: [AccountKey] = []
    while (true) {
        let lockedAccountKey = lockedAccount.keys.get(keyIndex: keyIndex)
        if (lockedAccountKey == nil) {
            break
        } else {
            lockedAccountKeys.append(lockedAccountKey!)
        }

        keyIndex = keyIndex + 1
    }

    let lockedTokenAdminAddress: Address = Address(0xLOCKEDTOKENADMIN)
    let lockedTokenAdminAccount: PublicAccount = getAccount(lockedTokenAdminAddress)
    let lockedTokenAdminKey: AccountKey? = lockedTokenAdminAccount.keys.get(keyIndex: 0)
    let lockedTokenAdminPublicKeyHex: String = String.encodeHex(lockedTokenAdminKey!.publicKey.publicKey)

    if (lockedAccountKeys.length == 1) {
        var lockedAccountKey: AccountKey = lockedAccountKeys[0]

        let lockedAccountKeyHex: String = String.encodeHex(lockedAccountKey.publicKey.publicKey)

        if (lockedAccountKeyHex != lockedTokenAdminPublicKeyHex) {
            return [false, "invalid lockedAccountKeyHex on lockedAccountKey"]
        }

        if (lockedAccountKey.weight != UFix64(1000.0) || lockedAccountKey.isRevoked) {
            return [false, "invalid lockedAccountKey.weight on lockedAccountKey"]
        }

        if (lockedAccountKey.hashAlgorithm != HashAlgorithm.SHA2_256) {
            return [false, "invalid hashAlgorithm on lockedAccountKey"]
        }

        if (lockedAccountKey.publicKey.signatureAlgorithm != SignatureAlgorithm.ECDSA_P256) {
            return [false, "invalid publicKey.signatureAlgorithm on lockedAccountKey"]
        }

        return [true, "properly configured locked tokens lease account"]
    }

    if (lockedAccountKeys.length >= 2) {
        var userPublicKey: AccountKey? = nil
        var adminPublicKey: AccountKey? = nil

        for lockedAccountKey in lockedAccountKeys {
            if (lockedAccountKey.weight == UFix64(900.0) && !lockedAccountKey.isRevoked) {
                userPublicKey = lockedAccountKey
            }
            if (lockedAccountKey.weight == UFix64(100.0) && !lockedAccountKey.isRevoked) {
                adminPublicKey = lockedAccountKey
            }
        }

        if (userPublicKey == nil || adminPublicKey == nil) {
            return [false, "nil userPublicKey or adminPublicKey"]
        }
        
        let adminPublicKeyHex: String = String.encodeHex(adminPublicKey!.publicKey.publicKey)

        if (adminPublicKeyHex != lockedTokenAdminPublicKeyHex) {
            return [false, "invalid adminPublicKeyHex on adminPublicKey"]
        }

        if (adminPublicKey!.hashAlgorithm != HashAlgorithm.SHA2_256) {
            return [false, "invalid hashAlgorithm on adminPublicKey"]
        }

        if (adminPublicKey!.publicKey.signatureAlgorithm != SignatureAlgorithm.ECDSA_P256) {
            return [false, "invalid publicKey.signatureAlgorithm on adminPublicKey"]
        }

        return [true, "properly configured locked tokens"]
    } else {
        return [false, "locked account only has one key"]
    }
}
`

export const doesAcctHaveLockedTokenSupportWithDapperlabsKey = async (address) => {
    const response = await fcl.decode(await fcl.send([
        fcl.script(CODE),
        fcl.args([fcl.arg(address, t.Address)])
    ]))
    return response[0]
}
