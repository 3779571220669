import React from "react"
import {Helmet} from "react-helmet"
import styled from "styled-components"
import {Link} from "react-router-dom"
import { LogoWithNetwork } from "../components/Logo"

const PrivacyWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const PrivacyInner = styled.div`
    max-width: 100%;
    width: 60rem;

    padding: 2rem;
    box-sizing: border-box;
`

const PrivacyLogo = styled.img`
    height: 4rem;
    margin-bottom: 2rem;
`

const PrivacyH1 = styled.h1`
    margin-bottom: 1rem;
`

const PrivacyH2 = styled.h2`
    margin-bottom: 1rem;
`

const PrivacyH3 = styled.h3`
    margin-bottom: 1rem;
`

const PrivacyParagraph = styled.p`
    margin-bottom: 1rem;
`

const PrivacyUL = styled.ul`
    margin-bottom: 1rem;
    margin-left: 3rem;
`

const PrivacyOL = styled.ol`
    margin-bottom: 1rem;
    margin-left: 3rem;
`

const PrivacyLI = styled.li``

const PrivacyBold = styled.b``

export const Privacy = () => {

    return (
        <PrivacyWrapper>
            <Helmet><title>Flow Port | Privacy</title></Helmet>
            <PrivacyInner>
                <LogoWithNetwork />
                <PrivacyH1>Flow Port Privacy Policy</PrivacyH1>
                <PrivacyH3>Latest Update: October 22, 2020</PrivacyH3>

                <PrivacyParagraph>
                    Dapper prides itself on its relationship with its users. Dapper promises to protect the privacy of users as detailed in this privacy policy. The Flow Port Services (as defined below) are designed, among other things, to help users submit transactions to and interact with the Flow Blockchain. The Flow Port Services are proprietary to Dapper Labs Inc., a British Columbia company having its registered address at #600-565 Great Northern Way, Vancouver, British Columbia, Canada, V5T 0H8 (referred to in this privacy policy as <PrivacyBold>“Dapper”</PrivacyBold>, <PrivacyBold>“we”</PrivacyBold>, <PrivacyBold>“us”</PrivacyBold>, or <PrivacyBold>“our”</PrivacyBold>)
                </PrivacyParagraph>


                <PrivacyParagraph>
                    This privacy policy (this <PrivacyBold>“Policy”</PrivacyBold>) outlines how Dapper collects, maintains, processes, uses, discloses and protects your personal information. Personal information is any information that directly or indirectly identifies you or by which your identity could be deduced. Protecting your privacy is important to us, and our goal is to maintain your trust and confidence when we handle personal information about you.
                </PrivacyParagraph>


                <PrivacyParagraph>
                    This Policy will apply to you if you visit our website at https://port.onflow.org/ (the <PrivacyBold>“Site”</PrivacyBold>), connect a unique wallet account from an Approved Wallet Provider (an <PrivacyBold>“Account”</PrivacyBold>), register for, access or use the Services (as defined in the <Link to="/terms">Terms of Service</Link>), or when you otherwise do business or make contact with us (the Site, the Services, are hereinafter collectively referred to as the <PrivacyBold>“Flow Port Services”</PrivacyBold>.)
                </PrivacyParagraph>

                <PrivacyParagraph>
                    The Flow Port Services are intended for use by persons who are at least the age of majority in the jurisdiction where they reside. By visiting our Site, or registering for, accessing or using any of the Flow Port Services, you affirm (i) are eighteen (18) years of age or older, (ii) you have the legal capacity to consent to this Policy, and (iii) you agree to the terms of this Policy. If you do not agree to any part of this Policy, you should not use the Site or any of the Flow Port Services.
                </PrivacyParagraph>

                <PrivacyH3>What information do we collect?</PrivacyH3>

                <PrivacyParagraph>
                    Dapper collects data to enable us to operate the Flow Port Services effectively and to provide you with the best experience on the Site and with the Flow Port Services. You provide some of this data to us directly, such as when you register to use the Flow Port Services, subscribe to a newsletter, respond to a survey, make an enquiry through our Site, contact us for support, or contact us as a prospective user, vendor, supplier, or consultant. We get some of your data by recording how you interact with our Site and the Flow Port Services by, for example, using technologies like cookies. We also obtain and process data in the context of making the Flow Port Services available to you.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    You have choices about the data we collect. When you are asked to provide personal data, you may decline. But if you choose not to provide data that is necessary to enable us to make the Flow Port Services available to you, you may not be able to connect an Account or use the Flow Port Services.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    The data we collect depends on the context of your interactions with Dapper and the Flow Port Services and the choices you make (including your privacy settings). The personal information we or our third party partners collect about you may include: Account address, Flow Token addresses from which tokens are sent and to which they are sent, other Flow transaction information, usage logs, additional personal information (at the discretion of our compliance team), device information, screen information, browser information, operating system information, IP address, location information, time zone, log information, button clicks, page views, third party wallet information and transaction information, and correspondence.
                </PrivacyParagraph>

                <PrivacyH3>Why do we collect personal information, and what do we do with it?</PrivacyH3>

                <PrivacyParagraph>
                    We use the data we collect to operate our business and to make the Flow Port Services available to you. This includes using the data to improve the Flow Port Services and to personalize your experiences. We may use this data to comply with applicable law and regulations. We may also use the data to communicate with you to inform you about your transactions, provide security updates, and give you information about the Flow Port Services. We may also use the data to improve the relevance and security of our Site, and respond to user enquiries.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    Examples of such uses include the following:
                </PrivacyParagraph>

                <PrivacyUL>
                    <PrivacyLI>Facilitating your transactions</PrivacyLI>
                    <PrivacyLI>Facilitating the connection to your Account</PrivacyLI>
                    <PrivacyLI>Enabling you to view the status of your Flow tokens and secondary Flow tokens</PrivacyLI>
                    <PrivacyLI>Protecting you and other users and Dapper from error and fraud</PrivacyLI>
                    <PrivacyLI>Analyzing your use of the Flow Port Services to help make the Flow Port Services better for all users</PrivacyLI>
                    <PrivacyLI>Carrying out Dapper’s regular business activities related to the Flow Port Services</PrivacyLI>
                    <PrivacyLI>Communicating with you regarding the Flow Port Services</PrivacyLI>
                    <PrivacyLI>Complying with legal and regulatory requirements</PrivacyLI>
                    <PrivacyLI>Responding to your inquiries</PrivacyLI>
                    <PrivacyLI>Providing the best possible user experience</PrivacyLI>
                    <PrivacyLI>Improving the Flow Port Services</PrivacyLI>
                    <PrivacyLI>Providing you with technical support</PrivacyLI>
                    <PrivacyLI>Sending periodic emails to provide you with more details about the Flow Port Services that we think you may want to be aware of</PrivacyLI>
                    <PrivacyLI>Communicating with you, and personalizing your experience with us</PrivacyLI>
                </PrivacyUL>

                <PrivacyH3>How do we collect personal information?</PrivacyH3>

                <PrivacyParagraph>
                    We collect personal information directly from you, and we may receive certain personal information about you from third parties and our partners. We collect and update personal information throughout our relationship with you. The relationship begins the first time you visit our Site and continues through termination of your use of the Flow Port Services. As such, personal information may be collected any time you visit the Site, or use the Flow Port Services, communicate with our customer service team, and any other time you communicate with us.    
                </PrivacyParagraph>

                <PrivacyParagraph>
                    Each and every time you provide personal information to us though any means, you consent to the collection, use and disclosure of such information in accordance with this Policy. If you do not agree to the gathering of this information, you should not use the Flow Port Services or visit our Site.
                </PrivacyParagraph>

                <PrivacyH3>Cookies; Pixels; Beacons</PrivacyH3>

                <PrivacyParagraph>
                    The Flow Port Services use cookies - tiny data files placed on your device that contain a unique identifier that identify your browser. Cookies allow us to collect information about you as a user, to improve our platform, store preferences and settings, and help with sign-in. While you can manage cookies in your preferences settings, if you disable cookies you may not be able to use or access some or all of the Flow Port Services.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    Our web pages may contain electronic images known as web beacons (also called single-pixel gifs) that we use to help deliver cookies on our websites and to count users who have visited those websites. 
                </PrivacyParagraph>

                <PrivacyParagraph>
                    In addition to placing web beacons on our own websites, we sometimes work with other companies to place our web beacons on their websites or in their advertisements. This helps us to develop statistics on how often clicking on an advertisement on a Dapper website results in a purchase or other action on the advertiser's website.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    Finally, the Flow Port Services may contain web beacons or similar technologies from third-party analytics providers (like, for example, Google Analytics) that help us compile aggregated statistics about the effectiveness of our promotional campaigns or other operations. These technologies enable the analytics providers to set or read their own cookies or other identifiers on your device, through which they can collect information about your online activities across applications, websites or other products.
                </PrivacyParagraph>

                <PrivacyH3>Consent</PrivacyH3>

                <PrivacyParagraph>
                    Consent for the collection, use and disclosure of personal information may be expressly given or implied. Your express consent, if required, may be given in writing, verbally or through electronic means. Implied consent is given by your actions, such as instances when you visit our Site or and as otherwise described herein.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    Dapper can collect, use or disclose your personal information without your consent for legal, security or certain processing reasons as required by law to deter fraud and money-laundering, or for other legal purposes.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    Your consent can be withdrawn by contacting support@onflow.org. If you do not notify us of the withdrawal of your consent, we will consider your consent to be ongoing. Should you withdraw consent, we may not be able to provide all or some of the Flow Port Services to you and you are not permitted to access our Site.
                </PrivacyParagraph>

                <PrivacyH3>How to Access and Control Your Personal Data</PrivacyH3>

                <PrivacyParagraph>
                    You can view, access, edit, delete, or request a copy of your personal data for many aspects of the Flow Port Services via your settings. Within your user settings you can also make choices about Dapper’s collection and use of your data.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    <PrivacyBold>Data Access.</PrivacyBold> You can access your personal data in your settings.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    <PrivacyBold>Data Portability.</PrivacyBold> You can request a copy of your personal data by submitting an email to us at support@onflow.org and including “Please send me a copy of my personal data” in the “Subject” line. Dapper will verify your ability to access that email, then send you a digital export of the data we hold that is associated with your email address. We will use reasonable efforts to respond to your request within 14 days, but in all events within 30 days of our receipt of the request. Dapper may be limited in its ability to send certain personal data to you.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    <PrivacyBold>Data Erasure.</PrivacyBold> You can request that portions of your personal data be erased by submitting an email to us at support@onflow.org and including “Please delete my personal data” in the “Subject” line. Dapper will verify your ability to access that email, then use reasonable efforts to respond to your request within 14 days, but in all events within 30 days of our receipt of the request. Please be aware that we require certain information about you in order to make the Flow Port Services available to you, and we are required to maintain certain information as required by applicable law and regulation. This means that if you want to delete any of these critical pieces of personal data, we may not be able to delete the data right away, but only after the minimum retention period mandated by law. As a response to your request for data erasure, you may be required to delete your entire Account and no longer be able to access the Flow Port Services.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    <PrivacyBold>Data Correction.</PrivacyBold> You can modify your personal data in your settings. Note that some of the data we collect is specific to you, and you may not be able to modify this data without needing to create a new user profile, as applicable.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    <PrivacyBold>Your Communications Preferences.</PrivacyBold> You can choose whether you wish to receive marketing communications from us. If you receive marketing communications from us and would like to opt out, you can do so by following the directions in that communication. You can request that we withdraw your consent to use your personal data by submitting an email to us at support@onflow.org and including “Please withdraw my consent for marketing communications” in the “Subject” line. Dapper will verify your ability to access that email, then update our systems to remove your email address from the system we use to send marketing communications. We will use reasonable efforts to respond to your request within 14 days, but in all events within 30 days of our receipt of the request. Please note that these choices do not apply to mandatory communications that are part of the Flow Port Services or to surveys or other informational communications that have their own unsubscribe method.
                </PrivacyParagraph>

                <PrivacyH3>Data Retention</PrivacyH3>

                <PrivacyParagraph>
                    We may retain your personal information as long as you continue to use the Flow Port Services, as long as necessary to comply with applicable law or regulation or for as long as is necessary to fulfill the purposes outlined in this Policy. You can contact us as described above, and we will delete your personal information on request and as governed by the data erasure section of this policy.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    We may, however, retain personal information for an additional period as is permitted or required under applicable laws, for legal, tax, or regulatory reasons, or for legitimate and lawful business purposes.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    We will retain your personal data for as long as necessary to make the Flow Port Services available to you, or for other essential purposes such as complying with our legal obligations, resolving disputes, and enforcing our agreements. Because these needs can vary for different types of data, actual retention periods can vary significantly.
                </PrivacyParagraph>

                <PrivacyH3>How we share information and third-party servicers</PrivacyH3>

                <PrivacyParagraph>
                    We do not sell or trade your personal information to third parties. We share your personal data with your consent, or as necessary to make the Flow Port Services available to you. We also share your data with vendors working on our behalf; when required by law or to respond to legal process; when responding to audits; to protect our customers; to protect lives; to maintain the security and integrity of the Flow Port Services; and to protect our rights or our property.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    Companies we have hired to provide cloud hosting services, off-site backups, verifications, payment processing, and customer support may need access to personal data to provide those functions. In such cases, these companies are required to abide by our data privacy and security requirements and are not allowed to use personal data they receive from us for any other purpose. If you would like to consult a list of our current vendors, or have questions or concerns about the vendors, feel free to contact us at support@onflow.org.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    We may disclose your personal data as part of a corporate transaction such as a corporate sale, merger, reorganization, dissolution, or similar event.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    Finally, we will access, transfer, disclose, and/or preserve personal data, when we have a good faith belief that doing so is necessary to:
                </PrivacyParagraph>

                <PrivacyOL>
                    <PrivacyLI>comply with applicable law or respond to valid legal process, judicial orders, or subpoenas;</PrivacyLI>
                    <PrivacyLI>respond to requests from public or governmental authorities, including for national security or law enforcement purposes;</PrivacyLI>
                    <PrivacyLI>protect the vital interests of our users, customers, or other third parties (including, for example, to prevent spam or attempts to defraud users of our products, or to help prevent the loss of life or serious injury of anyone);</PrivacyLI>
                    <PrivacyLI>operate and maintain the security or integrity of the Flow Port Services, including to prevent or stop an attack on our computer systems or networks;</PrivacyLI>
                    <PrivacyLI>protect the rights, interests or property of Dapper or third parties;</PrivacyLI>
                    <PrivacyLI>prevent or investigate possible wrongdoing in connection with the Flow Port Services; or</PrivacyLI>
                    <PrivacyLI>enforce our Terms of Use <Link to="/terms">https://port.onflow.org/terms</Link>.</PrivacyLI>
                </PrivacyOL>

                <PrivacyParagraph>
                    We may use and share aggregated non-personal information with third parties for marketing, advertising, and analytics purposes.
                </PrivacyParagraph>

                <PrivacyH3>Where we Store and Process Personal Data; International Transfers</PrivacyH3>

                <PrivacyParagraph>
                    Personal data collected by Dapper may be stored and processed in Canada or in any other country where Dapper or its affiliates, subsidiaries or service providers maintain facilities. The storage location(s) are chosen in order to operate efficiently, to improve performance, and to create redundancies in order to protect the data in the event of an outage or other problem. We take steps to ensure that the data we collect is processed according to the provisions of this Policy, and the requirements of applicable law wherever the data is located.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    We transfer personal data from the European Economic Area and Switzerland to other countries, some of which have not been determined by the European Commission to have an adequate level of data protection. When we engage in such transfers, we use a variety of legal mechanisms, including contracts, to help ensure your rights and protections travel with your data. To learn more about the European Commission’s decisions on the adequacy of the protection of personal data in the countries where Dapper processes personal data, please visit: ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.htm
                </PrivacyParagraph>

                <PrivacyH3>Protection and Security of Information</PrivacyH3>

                <PrivacyParagraph>
                    We make reasonable efforts to protect your personal information using appropriate physical, technological and organizational safeguards. No security is foolproof, and the Internet is an insecure medium. However, we work hard to protect you from unauthorized access, alteration, disclosure and destruction of your personal information collected and stored by us. As such, we have policies, procedures, guidelines and safeguards in place to ensure your personal information is protected. Only authorized employees of Dapper have access to your information, and these employees are required to keep the information confidential. Additionally, we periodically review our practices related to collection, storage and use of personal information and how such practices are utilized by our employees, contractors and agents to ensure high levels of protection.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    Be aware that virtual currencies and digital assets are not necessarily truly anonymous. Generally, anyone can see the balance and transaction history of any public digital asset address. It may be possible to match your public digital asset address to other personal information about you and to identify you from a blockchain transaction. This is because, in some circumstances, personal information published on a blockchain (such as your digital asset address and IP address) can be correlated with personal information that we and others may have. This may be the case even if we, or they, were not involved in the blockchain transaction itself. Furthermore, by using data analysis techniques on a given blockchain, it may be possible to identify other personal information about you. You understand that we have no control over whether third parties utilize such techniques to identify you or obtain your personal information, and you agree that we will not be liable to you for any such activities. As part of our security, anti-fraud and/or identity verification and authentication checks, we may conduct such analysis to collect and process such personal information about you. You agree to allow us to perform such practices and understand that we do so.
                </PrivacyParagraph>
                
                <PrivacyH3>How do we Ensure that our Processing Systems remain Confidential, Resilient, and Available?</PrivacyH3>

                <PrivacyParagraph>
                    We implement a variety of measures to ensure that our processing systems remain confidential, resilient, and available. Specifically, we have implemented processes to help ensure high availability, business continuity, and prompt disaster recovery. We commit to using third party services that maintain strong physical and logical access controls.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    <PrivacyBold>High Availability.</PrivacyBold> Every part of the Flow Port Services utilizes properly-provisioned, redundant servers (e.g., multiple load balancers, web servers, replica databases) in case of failure. The third parties we use take servers out of operation as part of regular maintenance, without impacting availability.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    <PrivacyBold>Disaster Recovery.</PrivacyBold> In the event of a region-wide outage, we will bring up a duplicate environment in a different Google Cloud Platform region. Our operations team has extensive experience performing full region migrations.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    <PrivacyBold>Physical Access Controls.</PrivacyBold> The Flow Port Services are hosted on Vercel and Google Cloud Platform (“GCP”). These data centers feature a layered security model, including extensive safeguards such as custom-designed electronic access cards, alarms, vehicle access barriers, perimeter fencing, metal detectors, and biometrics. Dapper employees do not have physical access to these data centers, servers, network equipment, or storage.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    <PrivacyBold>Logical Access Controls.</PrivacyBold>  Dapper is the assigned administrator of its infrastructure on Vercel and GCP, and only designated authorized Dapper operations team members have access to configure the infrastructure on an as-needed basis.
                </PrivacyParagraph>

                <PrivacyH3>Third Party Links</PrivacyH3>

                <PrivacyParagraph>
                    The Site and the other elements of the Flow Port Services may contain links to other sites. The owners of the linked sites are solely responsible for their privacy practices and content. Dapper is not responsible and does not endorse or control the content and privacy practices of third-party websites. Anytime you access a third-party website, you will be subject to the terms of their applicable privacy policies and should review them carefully.
                </PrivacyParagraph>

                <PrivacyH3>Accessing and Keeping your Personal Information Accurate</PrivacyH3>

                <PrivacyParagraph>
                    We take reasonable and practical steps to ensure that your personal information is accurate with regards to the purpose for which it is used. It is your responsibility to provide accurate personal information about yourself. If any information that has been provided is no longer correct, it is your responsibility to contact us to update this information. Corrections can be made by contacting us at support@onflow.org.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    Subject to certain exceptions under applicable law, you may request access to the personal information that we hold about you in order to view, verify and correct such personal information by contacting us at support@onflow.org. When handing such requests, we will verify the identity of the requesting party to ensure that they are the person legally entitled to make the request. It is our policy to not charge for such requests, however, in the case that the requests become repetitive or unduly onerous, or there is request for copies of documents, a charge may apply. We will let you know at the time of the request if there will be a charge. Normally, such requests will be responded to within 30 days.
                </PrivacyParagraph>

                <PrivacyH3>Changes to this Policy</PrivacyH3>

                <PrivacyParagraph>
                    Dapper reserves the right to amend this Policy from time to time. The most up to date version will be available on our Site with the most recent version’s date listed clearly at the top of the page. Changes are effective at the time we post them on the Site. While Dapper may make reasonable attempts to notify active Flow Port Services users when this Policy is updated, we nonetheless recommend that you consult this Policy each time you visit our Site or use any of the Flow Port Services to be assured that you are familiar with the most current version of this Policy.
                </PrivacyParagraph>

                <PrivacyH3>Questions, Concerns and Complaints</PrivacyH3>

                <PrivacyParagraph>
                    If you have questions, concerns or complaints regarding this Policy, the handling of your personal information or Dapper’s privacy or data protection practices, including but not limited to the use of any service providers outside of Canada, please contact support@onflow.org. We investigate all complaints and will generally respond within 30 days of receipt of your complaint. If we find a complaint to be justified, we will take all appropriate measures, including, as necessary, amending our policies and practices.
                </PrivacyParagraph>

                <PrivacyParagraph>
                    Please note that when you send us a message, you will be providing us with personal information, including your email address, name, and any other information provided in the message. In some cases, additional personal information may be needed in order to answer your questions, concerns or requests. Such information will be handled in accordance with this Policy.
                </PrivacyParagraph>

                <PrivacyH3>Latest Update: October 22, 2020</PrivacyH3>
            </PrivacyInner>
        </PrivacyWrapper>
    )
}