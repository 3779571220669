import {useEffect} from "react"
import {atomFamily, useRecoilState} from "recoil"
import {doesAcctHaveLockedTokenSupportWithDapperlabsKey} from "../interactions/does-acct-have-locked-token-support-with-dapperlabs-key.js"
import {ERROR, INIT_PROCESSING, INIT, IDLE, PROCESSING} from "../global/constants.js"

export const valueAtom = atomFamily({
    key: "does-account-have-locked-token-support-with-dapperlabs-key::state",
    default: null
})

export const statusAtom = atomFamily({
    key: "does-account-have-locked-token-support-with-dapperlabs-key::status",
    default: INIT,
})

export function useDoesAccountHaveLockedTokenSupportWithDapperlabsKey(address) {
    const [doesAccountHaveLockedTokenSupportWithDapperlabsKey, setDoesAccountHaveLockedTokenSupportWithDapperlabsKey] = useRecoilState(valueAtom(address))
    const [status, setStatus] = useRecoilState(statusAtom(address))

    async function refresh() {
        try {
            setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
            await doesAcctHaveLockedTokenSupportWithDapperlabsKey(address).then(setDoesAccountHaveLockedTokenSupportWithDapperlabsKey)
            setStatus(IDLE)
        } catch (e) {
            setStatus(ERROR)
        }
    }

    useEffect(() => {
        async function load({ address }) {
            if (address) {
                try {
                    setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
                    await doesAcctHaveLockedTokenSupportWithDapperlabsKey(address).then(_doesAcctHaveLockedTokenSupportWithDapperlabsKey => {
                        setDoesAccountHaveLockedTokenSupportWithDapperlabsKey(_doesAcctHaveLockedTokenSupportWithDapperlabsKey)
                        setStatus(IDLE)
                    })
                } catch (e) {
                    setStatus(ERROR)
                }
            }
        }
        load({ address })
    }, [address])

    return {
        doesAccountHaveLockedTokenSupportWithDapperlabsKey,
        status,
        fetch: refresh,
        refresh
    }
}
