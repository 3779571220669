import React from "react"
import styled from "styled-components"
import {Link} from "react-router-dom"

const StyledPageSectionDivider = styled.div`
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding-top: 2rem;

    border-top: 0.1rem solid #E8E8E8;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const StyledPageSectionDividerItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const StyledPageSectionDividerItemIcon = styled.img`
    margin-right: 0.5rem;
    height: 2rem;
`

const StyledPageSectionDividerItemTitle = styled.span`
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
    word-break: keep-all;
`

const StyledPageSectionDividerItemLink = styled(Link)`
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
    word-break: keep-all;
    color: #3745f5;
    text-decoration: none;
`

export const PageSectionDivider = ({ title, icon, link, linkTitle }) => {

    return (
        <StyledPageSectionDivider>
            <StyledPageSectionDividerItem>
                { icon && <StyledPageSectionDividerItemIcon src={icon} /> }
                { title && <StyledPageSectionDividerItemTitle>{title}</StyledPageSectionDividerItemTitle> }
            </StyledPageSectionDividerItem>

            <StyledPageSectionDividerItem>
                { link && linkTitle && <StyledPageSectionDividerItemLink to={link}>{linkTitle}</StyledPageSectionDividerItemLink>}
            </StyledPageSectionDividerItem>
        </StyledPageSectionDivider>    
    )
}