import React from "react"
import styled from "styled-components"
import {Link} from "react-router-dom"
import { HASH as withdrawUnlockedFlowHash } from "@onflow/six-withdraw-unlocked-flow"
import { 
    AccountFlowBalanceBreakdownItem,
    AccountUserClaimableBalanceItem,
    AccountUserTotalBalanceItem,
 } from "./account-flow-balance-breakdown-item"

const StyledAccountUserBalances = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

export const AccountFlowBalanceBreakdown = ({
    address,
    unlockedLockedAccountFlowBalance,
    totalUnlockedAccountFlowBalance,
    totalLockedAccountFlowBalance,
    totalFlowBalance,
    isCurrentUsersAccount
}) => {

    return (
        <StyledAccountUserBalances>
            <AccountFlowBalanceBreakdownItem
                quantity={totalUnlockedAccountFlowBalance}
                currency="FLOW"
                label="Total Unlocked Account Balance:"
            />
            <AccountFlowBalanceBreakdownItem
                quantity={totalLockedAccountFlowBalance}
                currency="FLOW"
                label="Total Locked Account Balance:"
            />
            <AccountUserClaimableBalanceItem
                quantity={unlockedLockedAccountFlowBalance}
                currency="FLOW"
                label="Claimable Locked Account Balance:"
                link={`/transaction?hash=${withdrawUnlockedFlowHash}&amount=${unlockedLockedAccountFlowBalance}&showcode=false`}
                linkLabel="Claim Balance"
            />
            <AccountUserTotalBalanceItem
                quantity={totalFlowBalance}
                currency="FLOW"
                label="Total Balance:"
            />
        </StyledAccountUserBalances>
    )
}