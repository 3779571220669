import React, { useState, useEffect, useLayoutEffect } from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { FaBell, FaArrowAltCircleUp } from "react-icons/fa"
import * as fcl from "@onflow/fcl"
import { HASH as stakingCollectionSetupStakingCollectionHash } from "@onflow/six-stakingcollection-setup-staking-collection"
import { Link, useParams } from "react-router-dom"
import { useTransition, useSpring, animated, config } from 'react-spring'
import { Button } from "../components/button.js"
import { ActiveStakeDelegateCard } from "../components/active-stake-delegate-card.js"
import { SummaryStakeDelegateCard } from "../components/summary-stake-delegate-card.js"
import { PageSectionDivider } from "../components/page-section-divider.js"
import { Banner } from "../components/banner.js"
import { StakeDeletgateBanner } from "./stake-delegate-banner.js"
import { screenMinWidth } from "../mixins/breakpoints.js"
import logomark from "../assets/logomark.svg"
import delegateImg from "../assets/delegate.png"
import stakeImg from "../assets/stake.png"
import runImg from "../assets/run.png"
import stakingBubbleIcon from "../assets/staking-bubble-icon.png"
import operatorImg from "../assets/operator.png"
import { LinkArrow } from "../assets/link-arrow.js"
import { useAllStakeInfo } from "../hooks/use-all-stake-info.js"
import { useAllDelegateInfo } from "../hooks/use-all-delegate-info.js"
import { useSummaryStakeDelegateInfo } from "../hooks/use-summary-stake-delegate-info.js"
import { useDoesAccountHaveStakingCollectionSupport } from "../hooks/use-does-acount-have-stakingcollection-support.js"
import { useEpochCurrentView } from "../hooks/use-epoch-current-view.js"
import { useEpochCurrentCounter } from "../hooks/use-epoch-current-counter.js"
import { useEpochMetadata } from "../hooks/use-epoch-metadata.js"
import { useEpochCurrentMetadata } from "../hooks/use-epoch-current-metadata.js"
import { useIsStakingEnabled } from "../hooks/use-is-staking-enabled.js"
import { INIT_PROCESSING, ERROR } from "../global/constants.js"
import { CheckNetwork } from "./checkNetwork.js"

const StakeDelegateContainer = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background-color: ${props => props.isLoading ? "#f8f8f8" : "#f8f8f800"};

    transition: background-color 0.2s;
    overflow-y: ${props => props.isError || props.isLoading ? "hidden" : "auto"};

    display: flex;
    justify-content: center;
`

const StyledStakeDelegateBanner = styled.div`
    position: relative;
    @keyframes animatebg {
        0% {
            background-position: 0% 0;
        }
        50% {
            background-position: 300% 0;
        }
        100% {
            background-position: 0% 0;
        }
    }

    background: linear-gradient(
            120deg,
            rgba(89,100,227,1),
            rgba(141,89,227,1),
            rgba(152,89,227,1),
            rgba(141,89,227,1),
            rgba(89,100,227,1)
        );
    background-size: 400%;
    animation: animatebg 28s ease-in-out 0s infinite normal;
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 100%;
    background-color: white;
    padding: 1rem 2rem 1rem 2rem;
    box-sizing: border-box;
    border-radius: 1rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`

const StyledStakeDelegateBannerIcon = styled(FaBell)`
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    padding: 0.5rem;
    color: #231f20;
    transform: translate(-50%, -50%);
    background-color: #f8f8f8;
    padding: 0.5rem;
    border-radius: 1rem;
`

const StyledLoadingStakeDelegate = styled(animated.div)`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
`

const StyledLoadingStakeDelegateError = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #919191;
    background-color: #f8f8f8;
    font-size: 1.5rem;
    text-align: center;
    z-index:2;
`

const StyledLoadingStakeDelegateImg = styled.img`
    @keyframes spin {
        0%   {transform: rotate(0deg);}
        25%  {transform: rotate(810deg);}
        50% {transform: rotate(700deg);}
        65% {transform: rotate(720deg);}
        100% {transform: rotate(720deg);}
    }

    margin-bottom: 2rem;
    height: 10rem;
    width: auto;
    animation-name: spin;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: forwards;
    animation-delay: 1s;
`

const StyledStakeDelegate = styled(animated.div)`
    padding: 0rem 2rem 1rem 2rem;
    max-width: 60rem;
    width: 100%;

    display: flex;
    flex-direction: column;

    box-sizing: border-box;
`


const StyledStakeDelegateTitle = styled.div`
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 3.5rem;
    width: 100%;
    font-weight: bold;
    word-break: keep-all;
    transform: scale(1, 0.8);

    ${screenMinWidth.sm`
        width: 75%;
    `}
`

const StyledStakeDelegateSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const StyledStakeDelegateSectionTitle = styled.div`
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
    word-break: keep-all;
    font-weight: bold;

    ${screenMinWidth.sm`
        width: 50%;
    `}
`

const StyledStakeDelegateCardsSectionTitle = styled.div`
    margin-bottom: 2rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    width: 100%;
    word-break: keep-all;

    ${screenMinWidth.sm`
        width: 50%;
    `}
`

const StyledStakeDelegateSectionSubtitle = styled.div`
    margin-bottom: 0.25rem;
    font-size: 1rem;
    line-height: 1.25rem;
    width: 100%;
    word-break: keep-all;
    color: #636363;

    ${screenMinWidth.sm`
        width: 50%;
    `}
`

const StyledStakeDelegateSectionText = styled.div`
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1rem;
    width: 100%;
    word-break: keep-all;
    color: black;

    ${screenMinWidth.sm`
        width: 50%;
    `}
`

const StyledStakeDelegateSectionLink = styled.a`
    margin-bottom: 2rem;
    font-size: 1rem;
    line-height: 1rem;
    word-break: keep-all;
    color: #3745f5;

    text-decoration: none;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    ${screenMinWidth.sm`
        width: 50%;
    `}
`

const StyledLoadingStakeDelegateLinkImg = styled.div`
    margin-left: 0.5rem;
`

const ActiveStakeCardRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`

const ActiveStakeCardWrapper = styled.div`
    margin-bottom: 2rem;
`

const ActiveStakeCardHalfwidthWrapper = styled(ActiveStakeCardWrapper)`
    width: 100%;

    ${screenMinWidth.sm`
        width: calc(50% - 0.5rem);
    `}
`

const StyledStakeDelegateCards = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    &:nth-child(1) {
        margin-right: 0;
        ${screenMinWidth.sm`
            margin-right: 1rem;
        `}
    }
`

const StyledStakeDelegateUpdateCard = styled.div`
    margin-bottom: 2rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    word-break: keep-all;
    border: 0.1rem solid #e95f43;

    transition: box-shadow 0.2s;
    &:hover {
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }
`

const StyledStakeDelegateCard = styled.div`
    margin-bottom: 1rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    word-break: keep-all;
    border: 0.1rem solid #E8E8E8;

    transition: box-shadow 0.2s;
    &:hover {
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }

    ${screenMinWidth.sm`
        width: 27rem;
    `}
`

const StyledStakeDelegateCardTop = styled.div``
const StyledStakeDelegateCardBottom = styled.div``

const StyledStakeDelegateCardImg = styled.img`
    margin-bottom: 2rem;
    height: 3rem;
    object-position: top top;
`

const StyledStakeDelegateCardTitle = styled.div`
    margin-bottom: 2rem;
    font-weight: bold;
    color: black;
`

const StyledStakeDelegateCardDescription = styled.div`
    margin-bottom: 2rem;
    color: #6a6a6a;
`

const StyledStakeDelegateCardProperties = styled.div`
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const StyledStakeDelegateCardProperty = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(50% - 1rem);
    margin-bottom: 1rem;
    margin-right: 0.5rem;
`

const StyledStakeDelegateCardPropertyTitle = styled.div`
    margin-bottom: 0.5rem;
    color: #6a6a6a;
`

const StyledStakeDelegateCardPropertyContent = styled.div`
    color: black;
`

const StyledStakeDelegateCardLink = styled(Link)`
    width: 100%;
`

export const StakeDelegate = () => {
    const { address } = useParams()
    const [currentUserAddr, setCurrentUserAddr] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const { allStakeInfo, status: useAllStakeInfoStatus } = useAllStakeInfo(address)
    const { allDelegateInfo, status: useAllDelegateInfoStatus } = useAllDelegateInfo(address)
    const { summaryStakeDelegateInfo, status: useSummaryStakeDelegateInfoStatus } = useSummaryStakeDelegateInfo(address)
    const { doesAccountHaveStakingCollectionSupport, status: useDoesAccountHaveStakingCollectionSupportStatus } = useDoesAccountHaveStakingCollectionSupport(address)
    const { isStakingEnabled } = useIsStakingEnabled()

    const { epochCurrentView, status: useEpochCurrentViewStatus } = useEpochCurrentView()
    const { epochCurrentCounter, status: useEpochCurrentCounterStatus } = useEpochCurrentCounter()
    const { epochCurrentMetadata, status: useEpochCurrentMetadataStatus } = useEpochCurrentMetadata()

    const isCurrentUserAddr = currentUserAddr && address && fcl.withPrefix(currentUserAddr) === fcl.withPrefix(address)
    const isStakingWindowOpen = epochCurrentView && epochCurrentMetadata && epochCurrentView <= epochCurrentMetadata.stakingEndView

    const isStakingCollectionUpgradeRequired = !doesAccountHaveStakingCollectionSupport
    const isUpgradeRequired = isStakingCollectionUpgradeRequired

    useEffect(() => {
        setIsLoading(useAllStakeInfoStatus === INIT_PROCESSING || useAllDelegateInfoStatus === INIT_PROCESSING || useDoesAccountHaveStakingCollectionSupportStatus === INIT_PROCESSING || useSummaryStakeDelegateInfoStatus === INIT_PROCESSING || useEpochCurrentViewStatus === INIT_PROCESSING || useEpochCurrentCounterStatus === INIT_PROCESSING || useEpochCurrentMetadataStatus === INIT_PROCESSING)
        setIsError(useAllStakeInfoStatus === ERROR || useAllDelegateInfoStatus === ERROR || useDoesAccountHaveStakingCollectionSupportStatus === ERROR || useSummaryStakeDelegateInfoStatus === ERROR || useEpochCurrentViewStatus === ERROR || useEpochCurrentCounterStatus === ERROR || useEpochCurrentMetadataStatus === ERROR)
    }, [useAllStakeInfoStatus, useAllDelegateInfoStatus, useDoesAccountHaveStakingCollectionSupportStatus, useSummaryStakeDelegateInfoStatus, useEpochCurrentViewStatus, useEpochCurrentCounterStatus, useEpochCurrentMetadataStatus])

    useEffect(() => {
        return fcl.currentUser().subscribe(currentUser => setCurrentUserAddr(currentUser.addr ? fcl.withPrefix(currentUser.addr) : null))
    }, [])

    const loadingTransitions = useTransition(isLoading, null, {
        from: {
            position: 'absolute', opacity: 0, width: "100%"
        },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.default,
    })

    const overrideStakingMessage = process.env.REACT_APP_STAKE_DELEGATE_BANNER_TEXT !== "";
    const showBanner = process.env.REACT_APP_ENABLE_STAKE_DELEGATE_BANNER === "true";
    const showStakingSummary = summaryStakeDelegateInfo ? summaryStakeDelegateInfo.nodeCount > 0 : false;
    const showDelegationSummary = summaryStakeDelegateInfo ? summaryStakeDelegateInfo.delegateCount > 0 : false;

    return (
        <StakeDelegateContainer isLoading={isLoading} isError={isError}>
            <Helmet><title>Flow Port | Stake & Delegate</title></Helmet>
            {isError && <CheckNetwork />}
            {!isError &&
                loadingTransitions.map(({ item, key, props }) =>
                    item ? <StyledLoadingStakeDelegate style={props} key={key}><StyledLoadingStakeDelegateImg src={logomark} />Loading...</StyledLoadingStakeDelegate>
                        : (
                            <StyledStakeDelegate style={props} key={key}>
                                {overrideStakingMessage && showBanner && epochCurrentMetadata && epochCurrentView && (
                                    <Banner content={<span dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_STAKE_DELEGATE_BANNER_TEXT }} />} />
                                )}
                                {!overrideStakingMessage && showBanner && <StakeDeletgateBanner />}
                                <StyledStakeDelegateTitle>Stake and Delegate</StyledStakeDelegateTitle>

                                {isStakingCollectionUpgradeRequired && isCurrentUserAddr && (
                                    <StyledStakeDelegateSection>
                                        <StyledStakeDelegateSectionSubtitle>Upgrade</StyledStakeDelegateSectionSubtitle>
                                        <StyledStakeDelegateCardsSectionTitle>An upgrade to your account is needed.</StyledStakeDelegateCardsSectionTitle>
                                        <StyledStakeDelegateUpdateCard>
                                            <StyledStakeDelegateCardTop>
                                                <StyledStakeDelegateCardTitle>Staking Collection Upgrade</StyledStakeDelegateCardTitle>
                                                <StyledStakeDelegateCardDescription>
                                                    Your account is ready for an upgrade!
                                                    Run the upgrade transaction to get a new Staking Collection resource added to your account.
                                                    A Staking Collection will allow you to have multiple nodes and delegations managed by your single Flow account.
                                                    If you have existing nodes and delegations, they are safe, and will be migrated to your new Staking Collection during the transaction.
                                                </StyledStakeDelegateCardDescription>
                                                <StyledStakeDelegateSectionLink href="https://docs.onflow.org/staking/" target="_blank">Learn more about Staking Collection<StyledLoadingStakeDelegateLinkImg><LinkArrow /></StyledLoadingStakeDelegateLinkImg></StyledStakeDelegateSectionLink>
                                            </StyledStakeDelegateCardTop>
                                            <StyledStakeDelegateCardBottom>
                                                <StyledStakeDelegateCardLink to={`/transaction?hash=${stakingCollectionSetupStakingCollectionHash}&showcode=false`}><Button title="Upgrade Account" Icon={FaArrowAltCircleUp} /></StyledStakeDelegateCardLink>
                                            </StyledStakeDelegateCardBottom>
                                        </StyledStakeDelegateUpdateCard>
                                    </StyledStakeDelegateSection>
                                )}

                                {isStakingCollectionUpgradeRequired && !isCurrentUserAddr && (
                                    <StyledStakeDelegateSection>
                                        <StyledStakeDelegateSectionSubtitle>Upgrade</StyledStakeDelegateSectionSubtitle>
                                        <StyledStakeDelegateCardsSectionTitle>An upgrade is required for this account.</StyledStakeDelegateCardsSectionTitle>
                                        <StyledStakeDelegateUpdateCard>
                                            <StyledStakeDelegateCardTop>
                                                <StyledStakeDelegateCardTitle>Staking Collection Upgrade</StyledStakeDelegateCardTitle>
                                                <StyledStakeDelegateCardDescription>
                                                    This account is ready for an upgrade!
                                                    The account needs to run the upgrade transaction to get a new Staking Collection resource added to it.
                                                    A Staking Collection will allow this account to manage multiple nodes and delegations.
                                                    If it has existing nodes and delegations, they are safe, and will be migrated to it's new Staking Collection during the transaction.
                                                </StyledStakeDelegateCardDescription>
                                                <StyledStakeDelegateSectionLink href="https://docs.onflow.org/staking/" target="_blank">Learn more about Staking Collection<StyledLoadingStakeDelegateLinkImg><LinkArrow /></StyledLoadingStakeDelegateLinkImg></StyledStakeDelegateSectionLink>
                                            </StyledStakeDelegateCardTop>
                                        </StyledStakeDelegateUpdateCard>
                                    </StyledStakeDelegateSection>
                                )}

                                {summaryStakeDelegateInfo && isCurrentUserAddr &&
                                    <ActiveStakeCardRow>
                                        {showStakingSummary &&
                                            <ActiveStakeCardHalfwidthWrapper>
                                                <SummaryStakeDelegateCard currentUserAddr={currentUserAddr} summaryStakeDelegateInfo={summaryStakeDelegateInfo} showStakeSummary={true} />
                                            </ActiveStakeCardHalfwidthWrapper>}
                                        {showDelegationSummary &&
                                            <ActiveStakeCardHalfwidthWrapper>
                                                <SummaryStakeDelegateCard currentUserAddr={currentUserAddr} summaryStakeDelegateInfo={summaryStakeDelegateInfo} showDelegateSummary={true} />
                                            </ActiveStakeCardHalfwidthWrapper>}
                                    </ActiveStakeCardRow>
                                }

                                {allDelegateInfo && Object.keys(allDelegateInfo).length > 0 &&
                                    <StyledStakeDelegateSection>
                                        <a id="delegations" />
                                        <PageSectionDivider id="delegations" icon={stakingBubbleIcon} title={isCurrentUserAddr ? "Your Delegations" : "This Account's Delegations"} />
                                        {
                                            Object.keys(allDelegateInfo).map(nodeId => {
                                                return Object.keys(allDelegateInfo[nodeId]).map(degatorId => <ActiveStakeCardWrapper><ActiveStakeDelegateCard currentUserAddr={currentUserAddr} enableActions={isCurrentUserAddr && isStakingEnabled && process.env.REACT_APP_ENABLE_DELEGATING === "true"} isDelegate={true} nodeInfo={allDelegateInfo[nodeId][degatorId]} /></ActiveStakeCardWrapper>)
                                            })
                                        }
                                    </StyledStakeDelegateSection>
                                }

                                {allStakeInfo && Object.keys(allStakeInfo).length > 0 &&
                                    <StyledStakeDelegateSection>
                                        <a id="nodes" />
                                        <PageSectionDivider id="nodes" icon={stakingBubbleIcon} title={isCurrentUserAddr ? "Your Nodes" : "This Account's Nodes"} />
                                        {
                                            Object.keys(allStakeInfo).map(nodeId => <ActiveStakeCardWrapper><ActiveStakeDelegateCard currentUserAddr={currentUserAddr} enableActions={isCurrentUserAddr && isStakingEnabled && process.env.REACT_APP_ENABLE_STAKING === "true"} isStake={true} nodeInfo={allStakeInfo[nodeId]} /></ActiveStakeCardWrapper>)
                                        }
                                    </StyledStakeDelegateSection>
                                }

                                {!isCurrentUserAddr
                                    && ((!allDelegateInfo) || Object.keys(allDelegateInfo).length === 0)
                                    && ((!allStakeInfo) || Object.keys(allStakeInfo).length === 0)
                                    && (
                                        <StyledStakeDelegateSection>
                                            <StyledStakeDelegateSectionSubtitle>No Nodes or Delegations</StyledStakeDelegateSectionSubtitle>
                                            <StyledStakeDelegateCardsSectionTitle>This account has no Nodes or Delegations.</StyledStakeDelegateCardsSectionTitle>
                                        </StyledStakeDelegateSection>
                                    )}

                                {isCurrentUserAddr && !isUpgradeRequired && (
                                    <StyledStakeDelegateSection>
                                        <a id="stakedelegateoptions" />
                                        <PageSectionDivider />
                                        <StyledStakeDelegateSectionSubtitle>Get Started</StyledStakeDelegateSectionSubtitle>
                                        <StyledStakeDelegateCardsSectionTitle>Participate in Flow’s network your own way</StyledStakeDelegateCardsSectionTitle>
                                        <StyledStakeDelegateSectionText>Participate in the Flow network by staking or delegating.</StyledStakeDelegateSectionText>
                                        <StyledStakeDelegateSectionLink href="https://docs.onflow.org/staking/" target="_blank">Discover how to participate<StyledLoadingStakeDelegateLinkImg><LinkArrow /></StyledLoadingStakeDelegateLinkImg></StyledStakeDelegateSectionLink>
                                        <StyledStakeDelegateCards>

                                            <StyledStakeDelegateCard>
                                                <StyledStakeDelegateCardTop>
                                                    <StyledStakeDelegateCardImg src={runImg} />
                                                    <StyledStakeDelegateCardTitle>Stake a Node</StyledStakeDelegateCardTitle>
                                                    <StyledStakeDelegateCardDescription>Run a node while providing the minimum amount of FLOW tokens required to do so and earn FLOW as a reward for your participation.</StyledStakeDelegateCardDescription>
                                                </StyledStakeDelegateCardTop>
                                                <StyledStakeDelegateCardBottom>
                                                    <StyledStakeDelegateCardProperties>
                                                        <StyledStakeDelegateCardProperty style={{ height: "7rem" }}>
                                                            <StyledStakeDelegateCardPropertyTitle>Staking Amount</StyledStakeDelegateCardPropertyTitle>
                                                            <StyledStakeDelegateCardPropertyContent>135,000 min</StyledStakeDelegateCardPropertyContent>
                                                        </StyledStakeDelegateCardProperty>
                                                        <StyledStakeDelegateCardProperty style={{ height: "7rem" }}>
                                                            <StyledStakeDelegateCardPropertyTitle>Requirements</StyledStakeDelegateCardPropertyTitle>
                                                            <StyledStakeDelegateCardPropertyContent>FLOW Tokens & Infrastructure to run node.</StyledStakeDelegateCardPropertyContent>
                                                        </StyledStakeDelegateCardProperty>
                                                        <StyledStakeDelegateCardProperty>
                                                            <StyledStakeDelegateCardPropertyTitle>Earnings</StyledStakeDelegateCardPropertyTitle>
                                                            <StyledStakeDelegateCardPropertyContent>100% Rewards</StyledStakeDelegateCardPropertyContent>
                                                        </StyledStakeDelegateCardProperty>
                                                    </StyledStakeDelegateCardProperties>
                                                    {process.env.REACT_APP_ENABLE_STAKING === "true" ? <StyledStakeDelegateCardLink to="/stake-delegate-wizard?tid=1"><Button title={allStakeInfo && Object.keys(allStakeInfo).length > 0 ? "Stake Again" : "Start Staking"} /></StyledStakeDelegateCardLink> : <Button title="Coming Soon" isActive={false} />}
                                                </StyledStakeDelegateCardBottom>
                                            </StyledStakeDelegateCard>

                                            <StyledStakeDelegateCard>
                                                <StyledStakeDelegateCardTop>
                                                    <StyledStakeDelegateCardImg src={delegateImg} />
                                                    <StyledStakeDelegateCardTitle>Delegate</StyledStakeDelegateCardTitle>
                                                    <StyledStakeDelegateCardDescription>Delegate any amount of FLOW tokens to a Node Operator in return for a proportion of rewards for your participation.</StyledStakeDelegateCardDescription>
                                                </StyledStakeDelegateCardTop>
                                                <StyledStakeDelegateCardBottom>
                                                    <StyledStakeDelegateCardProperties>
                                                        <StyledStakeDelegateCardProperty style={{ height: "7rem" }}>
                                                            <StyledStakeDelegateCardPropertyTitle>Staking Amount</StyledStakeDelegateCardPropertyTitle>
                                                            <StyledStakeDelegateCardPropertyContent>No minimum</StyledStakeDelegateCardPropertyContent>
                                                        </StyledStakeDelegateCardProperty>
                                                        <StyledStakeDelegateCardProperty style={{ height: "7rem" }}>
                                                            <StyledStakeDelegateCardPropertyTitle>Requirements</StyledStakeDelegateCardPropertyTitle>
                                                            <StyledStakeDelegateCardPropertyContent>FLOW tokens</StyledStakeDelegateCardPropertyContent>
                                                        </StyledStakeDelegateCardProperty>
                                                        <StyledStakeDelegateCardProperty style={{ width: "calc(100% - 2rem)" }}>
                                                            <StyledStakeDelegateCardPropertyTitle>Earnings</StyledStakeDelegateCardPropertyTitle>
                                                            <StyledStakeDelegateCardPropertyContent>92% Rewards (8% fees to Node Operator)</StyledStakeDelegateCardPropertyContent>
                                                        </StyledStakeDelegateCardProperty>
                                                    </StyledStakeDelegateCardProperties>
                                                    {process.env.REACT_APP_ENABLE_DELEGATING === "true" ? <StyledStakeDelegateCardLink to="/stake-delegate-wizard?tid=2"><Button title={allDelegateInfo && Object.keys(allDelegateInfo).length > 0 ? "Delegate Again" : "Start Delegating"} /></StyledStakeDelegateCardLink> : <Button title="Coming Soon" isActive={false} />}
                                                </StyledStakeDelegateCardBottom>
                                            </StyledStakeDelegateCard>

                                        </StyledStakeDelegateCards>
                                    </StyledStakeDelegateSection>
                                )}
                            </StyledStakeDelegate>
                        ))
            }
        </StakeDelegateContainer>

    )
}