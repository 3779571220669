import React, {useState, useEffect} from "react"
import {Link} from "react-router-dom"
import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import { Transaction } from "../../transaction.js"

import { useUSDCBalance } from "../../../../hooks/use-usdc-balance.js"
import { useDoesAccountHaveUSDCSupport } from "../../../../hooks/use-does-account-have-usdc-support.js"

import {
  template as usdcTransferTemplate,
  CODE as usdcTransferCode,
  HASH as usdcTransferHash,
  DESCRIPTION as usdcTransferDescription,
} from "@onflow/six-usdc-transfer"
import { HASH as usdcSetupHash } from "@onflow/six-usdc-setup"

import { TRANSACTION_MANIFEST_VALUE, TRANSACTION_MANIFEST_ARG } from "../../transaction-manifest-constructors.js"

import {
  invalidAccountError,
  invalidUFix64ArgError,
  sufficientTokensError,
  accountUSDCConfiguredError,
} from "../../transaction-errors.js"

import {
    validateUFix64,
    validateAddress,
} from "../../transaction-validators.js"

import {
    xformUFix64,
    xformAddress,
} from "../../transaction-xforms.js"

export const Manifest = ({ 
  qp,
  hash,
  txn,
  showCode,
  consent,
  qpString,
  submitTransaction,
  submitTransactionLocked,
  currentUserAddr,
  hiddenArgs,
  lockedArgs,
}) => {
  const [currentUser, setCurrentUser] = useState(null)

  const [manifestValue, setManifestValue] = useState(new TRANSACTION_MANIFEST_VALUE({
    hash: usdcTransferHash,
    title: "Transaction - USDC Transfer",
    description: usdcTransferDescription,
    code: usdcTransferCode,
    args: {},
    isSix: true,
    transactionFunction: () => {}
  }))

  const { usdcBalance } = useUSDCBalance(currentUserAddr)
  const { doesAccountHaveUSDCSupport } = useDoesAccountHaveUSDCSupport(currentUserAddr)

  useEffect(() => fcl.currentUser().subscribe(setCurrentUser), [])

  const isLedgerUser = currentUser?.services[0]?.uid === "fcl-ledger-authz"

  const computeManifestValue = async () => {
    setManifestValue(
      new TRANSACTION_MANIFEST_VALUE({
        hash: usdcTransferHash,
        disclaimer: null,
        error: !doesAccountHaveUSDCSupport ? (
          <span>
            Your account is not yet configured for USDC. Configure your account{" "}
            <Link to={`/transaction?hash=${usdcSetupHash}&showcode=false`}>
              here.
            </Link>
          </span>
        ) : (isLedgerUser ? <span>This transaction is not available for Ledger users at this time. Ledger support coming soon.</span> : null),
        title: "Transaction - USDC Transfer",
        description: usdcTransferDescription,
        code: usdcTransferCode,
        args: {
          0: new TRANSACTION_MANIFEST_ARG({
            title: "To",
            qp: "to",
            validate: validateAddress,
            xform: xformAddress,
            placeholder: "0xABC123DEF456",
            description: "The Flow account the USDC tokens will go to.",
            type: types.Address,
            value: qp.get("to") || "",
            errors: [
              await invalidAccountError(qp.get("to")),
              await accountUSDCConfiguredError(
                qp.get("to")
              ),
            ].filter((w) => w !== null),
          }),
          1: new TRANSACTION_MANIFEST_ARG({
            title: "Amount",
            qp: "amount",
            validate: validateUFix64,
            xform: xformUFix64,
            description: (
              <span>
                {`The amount of USDC tokens to send.`}
                {usdcBalance ? (
                  <span>
                    {" "}
                    You have <b>{usdcBalance}</b> USDC
                    tokens to send.
                  </span>
                ) : (
                  ""
                )}
              </span>
            ),
            placeholder: "1.00",
            type: types.UFix64,
            value: qp.get("amount") || "",
            warnings: [].filter((w) => w !== null),
            errors: [
              invalidUFix64ArgError(qp.get("amount")),
              await sufficientTokensError(
                usdcBalance,
                qp.get("amount"),
                "Error: Not enough USDC tokens available to send. Reduce the amount of tokens to send."
              ),
            ].filter((w) => w !== null),
          }),
        },
        isSix: true,
        transactionFunction: async (hash, txn, args) => {
          return await fcl.decode(
            await fcl.send([
              usdcTransferTemplate({
                proposer: fcl.currentUser().authorization,
                authorization: fcl.currentUser().authorization,
                payer: fcl.currentUser().authorization,
                amount: args[1].xform(args[1].value),
                to: args[0].xform(args[0].value),
              }),
              fcl.limit(1000),
            ])
          )
        },
      })
    )
  }

  useEffect(() => {
    computeManifestValue()
  }, [qpString, usdcBalance, doesAccountHaveUSDCSupport])

  return (
    <Transaction 
      title={manifestValue?.title}
      description={manifestValue?.description}
      disclaimer={manifestValue?.disclaimer}
      error={manifestValue?.error}
      code={manifestValue?.code}
      isSix={manifestValue?.isSix}
      args={manifestValue?.args}
      transactionFunction={manifestValue?.transactionFunction}
      submitTransaction={submitTransaction}
      submitTransactionLocked={submitTransactionLocked}
      consent={consent}
      showCode={showCode}
      hash={hash}
      txn={txn}
      qp={qp}
    />
  )
}
