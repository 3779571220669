import React, {useState, useEffect} from "react"
import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import { Transaction } from "../../transaction.js"

import { useTotalUnlockedAccountFlowBalance } from "../../../../hooks/use-total-unlocked-account-flow-balance.js"
import { useTotalFlowBalance } from "../../../../hooks/use-total-flow-balance.js"
import { useAccountStorageInfo } from "../../../../hooks/use-account-storage-info.js"
import { useTotalLockedAccountFlowBalance } from "../../../../hooks/use-total-locked-account-flow-balance.js"

import {
  template as stakingCollectionRegisterNodeTemplate,
  CODE as stakingCollectionRegisterNodeCode,
  HASH as stakingCollectionRegisterNodeHash,
  DESCRIPTION as stakingCollectionRegisterNodeDescription,
} from "@onflow/six-stakingcollection-register-node"

import { TRANSACTION_MANIFEST_VALUE, TRANSACTION_MANIFEST_ARG } from "../../transaction-manifest-constructors.js"

import {
  calculateAvailableFLOWTokens,
} from "../../transaction-utils.js"

import {
  invalidUFix64ArgError,
  storageFeeError,
  sufficientTokensError,
  argumentExistsError,
} from "../../transaction-errors.js"

import {
  validateUFix64,
  validateString,
  validateArrayOfStrings,
  validateUInt8,
} from "../../transaction-validators.js"

import {
  xformString,
  xformArrayOfStrings,
  xformUFix64,
  xformUInt8,
} from "../../transaction-xforms.js"

export const Manifest = ({ 
  qp,
  hash,
  txn,
  showCode,
  consent,
  qpString,
  submitTransaction,
  submitTransactionLocked,
  currentUserAddr,
  hiddenArgs,
  lockedArgs,
}) => {
  const [currentUser, setCurrentUser] = useState(null)

  const [manifestValue, setManifestValue] = useState(new TRANSACTION_MANIFEST_VALUE({
    hash: stakingCollectionRegisterNodeHash,
    title: "Transaction - Register Node",
    description: stakingCollectionRegisterNodeDescription,
    code: stakingCollectionRegisterNodeCode,
    args: {},
    isSix: true,
    transactionFunction: () => {}
  }))
  
  useEffect(() => fcl.currentUser().subscribe(setCurrentUser), [])

  const isLedgerUser = currentUser?.services[0]?.uid === "fcl-ledger-authz"

  const { accountStorageInfo } = useAccountStorageInfo(currentUserAddr)
  const { totalFlowBalance } = useTotalFlowBalance(currentUserAddr)
  const { totalUnlockedAccountFlowBalance } = useTotalUnlockedAccountFlowBalance(currentUserAddr)
  const { totalLockedAccountFlowBalance } = useTotalLockedAccountFlowBalance(currentUserAddr)

  const computeManifestValue = async () => {
    setManifestValue(
      new TRANSACTION_MANIFEST_VALUE({
        hash: stakingCollectionRegisterNodeHash,
        disclaimer: null,
        error: null,
        title: "Transaction - Register Node",
        description: stakingCollectionRegisterNodeDescription,
        code: stakingCollectionRegisterNodeCode,
        args: {
          0: new TRANSACTION_MANIFEST_ARG({
            title: "Node ID",
            qp: "nodeid",
            xform: xformString,
            validate: validateString,
            description: "The Node ID.",
            type: types.String,
            value: qp.get("nodeid") || "",
          }),
          1: new TRANSACTION_MANIFEST_ARG({
            title: "Node Role",
            qp: "noderole",
            validate: validateUInt8,
            xform: xformUInt8,
            description: "The node role to register.",
            placeholder: "Node Role",
            type: types.UInt8,
            value: qp.get("noderole") || "",
          }),
          2: new TRANSACTION_MANIFEST_ARG({
            title: "Networking Address",
            qp: "networkingaddress",
            xform: xformString,
            validate: validateString,
            type: types.String,
            description: "The Networking Address.",
            value: qp.get("networkingaddress") || "",
          }),
          3: new TRANSACTION_MANIFEST_ARG({
            title: "Networking Key",
            qp: "networkingkey",
            xform: xformString,
            validate: validateString,
            type: types.String,
            description: "The Networking Key.",
            value: qp.get("networkingkey") || "",
          }),
          4: new TRANSACTION_MANIFEST_ARG({
            title: "Staking Key",
            qp: "stakingkey",
            xform: xformString,
            validate: validateString,
            description: "The Staking Key.",
            value: qp.get("stakingkey") || "",
          }),
          5: new TRANSACTION_MANIFEST_ARG({
            title: "Amount",
            qp: "amount",
            validate: validateUFix64,
            xform: xformUFix64,
            description: (
              <span>
                {"The amount of FLOW tokens to stake."}
                {(totalFlowBalance && accountStorageInfo && totalLockedAccountFlowBalance) ? (
                  <span>
                    {" You have "}
                    {totalFlowBalance}
                    {" tokens available to stake. After considering your"}
                    {" account's storage requirements,"}
                    {qp.get("noderole") == "1" || qp.get("noderole") == "2"
                      ? " and this node's Machine Account storage deposit, "
                      : " "}
                    {" you have "}
                    <b>
                      {calculateAvailableFLOWTokens(
                        accountStorageInfo?.useableAccountBalance || "0.0",
                        totalLockedAccountFlowBalance,
                        qp.get("noderole") == "1" || qp.get("noderole") == "2"
                          ? Number(
                              accountStorageInfo?.minimumStorageReservation
                            )
                          : 0
                      )}
                    </b>
                    {" tokens available to stake."}
                  </span>
                ) : (
                  ""
                )}
              </span>
            ),
            placeholder: "1.00",
            type: types.UFix64,
            value: qp.get("amount") ? qp.get("amount") : "",
            warnings: [].filter((w) => w !== null),
            errors: [
              invalidUFix64ArgError(qp.get("amount")),
              await storageFeeError(currentUserAddr, qp.get("amount")),
              await sufficientTokensError(
                totalFlowBalance,
                qp.get("noderole") == "1" || qp.get("noderole") == "2"
                  ? Number(
                      Number(qp.get("amount")) +
                        Number(
                          accountStorageInfo?.minimumStorageReservation
                        ) *
                          2
                    )
                  : 0,
                `Error: Not enough unlocked FLOW tokens to stake and register a Machine Account. You unlocked account needs at least ${
                  accountStorageInfo?.minimumStorageReservation * 2
                } FLOW (in addition to the balance from your unlocked account that will be used for this staking request) to account for the Machine Account's storage deposit.`
              ),
              await sufficientTokensError(
                totalFlowBalance,
                qp.get("amount"),
                "Error: Not enough FLOW tokens available to stake. Reduce the amount of tokens to stake."
              ),
            ].filter((w) => w !== null),
          }),
          6: new TRANSACTION_MANIFEST_ARG({
            title: "Public Keys",
            qp: "machineaccountpublickey",
            xform: xformArrayOfStrings,
            validate: (v) =>
              qp.get("noderole") == "3" || qp.get("noderole") == "4" || qp.get("noderole") == "5" || validateArrayOfStrings(v),
            description:
              "Public Keys for the Machine Account (comma separated). Only required when registering Consensus or Collection nodes.",
            value: qp.get("machineaccountpublickey") || "",
            hidden: hiddenArgs.includes("machineaccountpublickey"),
            type: types.Array(types.String),
            warnings: [].filter((w) => w !== null),
            errors: [
              argumentExistsError(
                qp.get("noderole") == "3" ||
                  qp.get("noderole") == "4" ||
                  qp.get("noderole") == "5" ||
                  qp.get("machineaccountpublickey") != "",
                "Error: At least one Public Key must be defined."
              ),
            ].filter((w) => w !== null),
          }),
        },
        isSix: true,
        transactionFunction: async (hash, txn, args) => {
          return await fcl.decode(
            await fcl.send([
              stakingCollectionRegisterNodeTemplate({
                proposer: fcl.currentUser().authorization,
                authorization: fcl.currentUser().authorization,
                payer: fcl.currentUser().authorization,
                nodeID: args[0].xform(args[0].value),
                nodeRole: args[1].xform(args[1].value),
                networkingAddress: args[2].xform(args[2].value),
                networkingKey: args[3].xform(args[3].value),
                stakingKey: args[4].xform(args[4].value),
                amount: args[5].xform(args[5].value),
                publicKeys: args[6].xform(args[6].value),
              }),
              fcl.limit(9999),
            ])
          )
        },
      })
    )
  }

  useEffect(() => {
    computeManifestValue()
  }, [qpString, accountStorageInfo, totalFlowBalance, totalUnlockedAccountFlowBalance, totalLockedAccountFlowBalance])

  return (
    <Transaction 
      title={manifestValue?.title}
      description={manifestValue?.description}
      disclaimer={manifestValue?.disclaimer}
      error={manifestValue?.error}
      code={manifestValue?.code}
      isSix={manifestValue?.isSix}
      args={manifestValue?.args}
      transactionFunction={manifestValue?.transactionFunction}
      submitTransaction={submitTransaction}
      submitTransactionLocked={submitTransactionLocked}
      consent={consent}
      showCode={showCode}
      hash={hash}
      txn={txn}
      qp={qp}
    />
  )
}
