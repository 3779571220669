import React, {useState} from "react"
import styled from "styled-components"
import * as fcl from "@onflow/fcl"
import { HASH as transferFlowTokenHash } from "@onflow/six-transfer-tokens"

import { HASH as stakingCollectionCreateMachineAccountHash } from "@onflow/six-stakingcollection-create-machine-account"
import { HASH as stakingCollectionRequestUnstakingHash } from "@onflow/six-stakingcollection-request-unstaking"
import { HASH as stakingCollectionCloseStakeHash } from "@onflow/six-stakingcollection-close-stake"
import { HASH as stakingCollectionStakeNewTokensHash } from "@onflow/six-stakingcollection-stake-new-tokens"
import { HASH as stakingCollectionStakeRewardedTokensHash } from "@onflow/six-stakingcollection-stake-rewarded-tokens"
import { HASH as stakingCollectionStakeUnstakedTokensHash } from "@onflow/six-stakingcollection-stake-unstaked-tokens"
import { HASH as stakingCollectionTransferDelegatorHash } from "@onflow/six-stakingcollection-transfer-delegator"
import { HASH as stakingCollectionTransferNodeHash } from "@onflow/six-stakingcollection-transfer-node"
import { HASH as stakingCollectionUnstakeAllHash } from "@onflow/six-stakingcollection-unstake-all"
import { HASH as stakingCollectionWithdrawFromMachineAccountHash } from "@onflow/six-stakingcollection-withdraw-from-machine-account"
import { HASH as stakingCollectionWithdrawRewardedTokensHash } from "@onflow/six-stakingcollection-withdraw-rewarded-tokens"
import { HASH as stakingCollectionWithdrawUnstakedTokensHash } from "@onflow/six-stakingcollection-withdraw-unstaked-tokens"
import { HASH as stakingCollectionUpdateNetworkingAddressHash } from "@onflow/six-stakingcollection-update-networking-address"

import {Link} from "react-router-dom"
import {FaRedo, FaTimes, FaTimesCircle, FaExclamationCircle, FaQuestionCircle, FaArrowAltCircleUp} from "react-icons/fa"
import {DropDown} from "./dropdown.js"
import {Button} from "./button.js"
import {
    COLLECTION_NODE_MIN_FLOW_BALANCE,
    CONSENSUS_NODE_MIN_FLOW_BALANCE,
    COLLECTION_NODE_RECCOMENDED_FLOW_BALANCE,
    CONSENSUS_NODE_RECCOMENDED_FLOW_BALANCE,
} from "../global/constants.js"
import ActiveStakeImg from "../assets/active-stake.svg"
import Clock from "../assets/clock.svg"
import ExecutionIcon from "../assets/execution-icon.png"
import CollectionIcon from "../assets/collection-icon.png"
import VerificationIcon from "../assets/verification-icon.png"
import ConsensusIcon from "../assets/consensus-icon.png"
import AccessIcon from "../assets/access-icon.png"
import {Dots} from "../assets/dots.js"
import {screenMinWidth} from "../mixins/breakpoints.js"
import {getMinRequiredStake} from "../global/get-min-required-stake.js"

const ActiveStakeCardWrapper = styled.div`
    background-color: white;
    border-radius: 0.5rem;
    background: white;
    border: 1px solid #E8E8E8;

    box-sizing: border-box;

    display: flex;
    flex-direction: column;

    div:last-child {
        border-radius: 0rem 0rem 0.5rem 0.5rem;
        border-bottom: none;
    }
`

const ActiveStakeCardRow = styled.div`
    padding: 1rem 1.5rem 1rem 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    border-bottom: 1px solid #E8E8E8;

    div:last-child {
        margin-bottom: 0;
    }
`

const ActiveStakeCardWarningIcon = styled(FaExclamationCircle)`
    margin-right: 1rem;
    min-width: 1rem;
`

const ActiveStakeCardRowStakeInProgress = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    
    background-color: #00ef8c;
    color: black;
    border-bottom: none;
`

const ActiveStakeCardWarning = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    color: #fc4b2e;
`

const ActiveStakeCardWarningLeft = styled.div`
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;

    ${screenMinWidth.sm`
        margin-bottom: 0rem;
        width: 66%;
    `}
`

const ActiveStakeCardWarningRight = styled.div`
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    ${screenMinWidth.sm`
        margin-bottom: 0rem;
    `}
`

const ActiveStakeCardRowUnstakeInProgress = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    
    background-color: #f4c6fa;
    color: black;
    border-bottom: none;
`

const ActiveStakeCardRowUnstakeInProgressLeft = styled.div`
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    ${screenMinWidth.sm`
        margin-bottom: 0rem;
    `}
`

const ActiveStakeCardRowUnstakeInProgressRight = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
    font-size: 0.75rem;
`

const ActiveStakeCardNodeInfo = styled.div`
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    ${screenMinWidth.sm`
        margin-bottom: 0rem;
    `}
`

const ActiveStakeCardNodeInfoImg = styled.img`
    margin-right: 1rem;
    width: 2rem;
`

const ActiveStakeCardNodeTitleSubtitle = styled.div`
    max-width: 25rem;
    display: flex;
    flex-direction: column;
`

const ActiveStakeCardNodeTitle = styled.div`
    color: #808080;
`

const ActiveStakeCardNodeSubtitle = styled.div`
    color: black;
    font-weight: bold;
    word-break: break-all;
`

const ActiveStakeCardSection = styled.div`
    margin-bottom: 2rem;   
    min-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    ${screenMinWidth.sm`
        margin-bottom: 0rem;
        min-width: 16rem;
    `}
`

const DotsWrapper = styled.div`

`

const ActiveStakeCardSectionDropDownWrapper = styled.div`
    position: relative;
    cursor: pointer;
`

const ActiveStakeCardSectionTitleSubtitle = styled.div`
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
`

const ActiveStakeCardSectionTitle = styled.div`
    color: black;
    font-weight: bold;
`

const ActiveStakeCardSectionSubitle = styled.div`
    color: #808080;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`

const ActiveStakeCardSectionFlow = styled.div`
    margin-left: 0.25rem;
    line-height: 1rem;
    font-size: 0.5rem;
    font-weight: bold;
`

const CancelStakeButton = styled(Link)`
    margin-left: 1rem;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

const CancelStakeIcon = styled(FaTimes)`
    padding: 0.2rem;
    color: black;
`

const ChangeNodeButton = styled(Link)`
    margin-right: 1rem;
    font-size: 1rem;
    line-height: 2rem;
    cursor: pointer;
    min-width: 12rem;
    text-decoration: none;
    color: #808080;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

const ChangeNodeButtonIcon = styled(FaRedo)`
    margin-right: 0.5rem;
    height: 1rem;
`

const LearnMoreButton = styled.a`
    font-size: 0.75rem;
    line-height: 2rem;
    cursor: pointer;
    width: 100%;
    text-decoration: none;
    color: #808080;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

const LearnMoreButtonIcon = styled(FaQuestionCircle)`
    margin-right: 0.5rem;
    height: 1rem;
`

const ActiveStakeCardAmountButton = styled(Link)`
    font-size: 1rem;
    line-height: 2rem;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
`

const ActiveStakeCardRowStakeInProgressImg = styled.img`
    margin-right: 1rem;
    height: 1.5rem;
`

const ActiveStakeCardRowStakeInProgressTitle = styled.div`
    margin-right: 1rem;
    color: black;
`

const ActiveStakeCardRowStakeInProgressAmount = styled.div`
    margin-right: 1rem;
    color: black;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`

const NodeProperties = {
    "1": {
        nodeRole: "Collection",
        nodeImg: CollectionIcon
    },
    "2": {
        nodeRole: "Consensus",
        nodeImg: ConsensusIcon
    },
    "3": {
        nodeRole: "Execution",
        nodeImg: ExecutionIcon
    },
    "4": {
        nodeRole: "Verification",
        nodeImg: VerificationIcon
    },
    "5": {
        nodeRole: "Access",
        nodeImg: AccessIcon
    }
}

export const ActiveStakeDelegateCard = ({currentUserAddr = "", enableActions = true, isStake = false, isDelegate = false, nodeInfo = null}) => {
    const [isMachineAccountDropdownOpen, setIsMachineAccountDropdownOpen] = useState(false)
    const [isStakeAmountDropdownOpen, setIsStakeAmountDropdownOpen] = useState(false)
    const [isUnstakedAmountDropdownOpen, setIsUnstakedAmountDropdownOpen] = useState(false)
    const [isRewardsAmountDropdownOpen, setIsRewardsAmountDropdownOpen] = useState(false)
    const [isOptionsDropdownOpen, setIsOptionsDropdownOpen] = useState(false)

    let delegatedNodeMinStakeError = false;
    if (nodeInfo && isDelegate) {
        let min = getMinRequiredStake(nodeInfo.role)
        delegatedNodeMinStakeError = ((parseFloat(nodeInfo.delegatedNodeInfo.tokensCommitted) + parseFloat(nodeInfo.delegatedNodeInfo.tokensStaked) - parseFloat(nodeInfo.delegatedNodeInfo.tokensRequestedToUnstake)) < min)
    }

    const isNodeEpochUpdateRequired = (nodeInfo.role == 1 || nodeInfo.role == 2) && isStake && !nodeInfo?.machineAccountAddress;
    const isMachineAccountBelowReccomendedBalance = (() => {
        if (!nodeInfo) return false
        if (!(nodeInfo?.machineAccountBalance)) return false

        switch(nodeInfo?.role.toString()) {
            case "1":
                return nodeInfo?.machineAccountBalance < COLLECTION_NODE_MIN_FLOW_BALANCE
            case "2":
                return nodeInfo?.machineAccountBalance < CONSENSUS_NODE_MIN_FLOW_BALANCE
            default: 
                return false
        }
    })()

    const showStakeRow = nodeInfo && nodeInfo.role !== 5
    const showDelegatorsRow = nodeInfo && isStake && nodeInfo.role !== 5
    const showMachineAccountRow = nodeInfo && isStake && nodeInfo.machineAccountAddress

    if (!nodeInfo) return null

    let nodeRole = NodeProperties?.[nodeInfo.role.toString()]?.nodeRole || ""
    let nodeImg = NodeProperties?.[nodeInfo.role.toString()]?.nodeImg || null

    return (
        <ActiveStakeCardWrapper>
            
            {isNodeEpochUpdateRequired && isStake && 
                <ActiveStakeCardRow>
                    <ActiveStakeCardWarning>
                        <ActiveStakeCardWarningLeft>
                            <ActiveStakeCardWarningIcon />This node requires an upgrade to support Flow Epochs.{ enableActions && " Press the Upgrade Node button below to run the required transaction." }
                        </ActiveStakeCardWarningLeft>
                        <ActiveStakeCardWarningRight>
                            <LearnMoreButton href="https://docs.onflow.org/node-operation/machine-existing-operator" target="_blank"><LearnMoreButtonIcon />Learn More About Epochs</LearnMoreButton>
                        </ActiveStakeCardWarningRight>
                    </ActiveStakeCardWarning>
                </ActiveStakeCardRow>
            }

            {isMachineAccountBelowReccomendedBalance && isStake && 
                <ActiveStakeCardRow>
                    <ActiveStakeCardWarning>
                        <ActiveStakeCardWarningLeft>
                            <ActiveStakeCardWarningIcon />This node's machine account has fallen below its reccomended minimum balance. For your {nodeRole} node, your machine account's reccomended balance is {nodeInfo.role == 1 ? COLLECTION_NODE_RECCOMENDED_FLOW_BALANCE : CONSENSUS_NODE_RECCOMENDED_FLOW_BALANCE} FLOW.
                        </ActiveStakeCardWarningLeft>
                        <ActiveStakeCardWarningRight>
                            <LearnMoreButton href="https://docs.onflow.org/staking/machine-account" target="_blank"><LearnMoreButtonIcon />Learn More About Machine Accounts</LearnMoreButton>
                        </ActiveStakeCardWarningRight>
                    </ActiveStakeCardWarning>
                </ActiveStakeCardRow>
            }

            <ActiveStakeCardRow>
                <ActiveStakeCardNodeInfo>
                    <ActiveStakeCardNodeInfoImg src={nodeImg} />
                    <ActiveStakeCardNodeTitleSubtitle>
                        <ActiveStakeCardNodeTitle>{isDelegate ? `Delegated to ${nodeRole} node` : `${nodeRole} node`}</ActiveStakeCardNodeTitle>
                        <ActiveStakeCardNodeSubtitle>{ nodeInfo && nodeInfo.id }</ActiveStakeCardNodeSubtitle>
                    </ActiveStakeCardNodeTitleSubtitle>
                </ActiveStakeCardNodeInfo>
                <ActiveStakeCardSection
                    style={{justifyContent: "flex-end"}}
                >
                    { 
                        enableActions && isNodeEpochUpdateRequired && isStake
                            ? <ChangeNodeButton to={`/transaction?hash=${stakingCollectionCreateMachineAccountHash}&nodeid=${nodeInfo.nodeID}&origin=${btoa(`/stake-delegate-wizard?tid=1&qid=4-terminal&nodeid=${nodeInfo.nodeID}`)}`}><Button isActive={true} title="Upgrade Node" Icon={FaArrowAltCircleUp}></Button></ChangeNodeButton> : null
                    }
                    { enableActions &&
                        <ActiveStakeCardSectionDropDownWrapper 
                            onMouseLeave={() => setIsOptionsDropdownOpen(false)}
                        >
                            <DotsWrapper onMouseEnter={() => setIsOptionsDropdownOpen(true)}><Dots /></DotsWrapper>
                            <DropDown
                                style={{
                                    width: "15rem"
                                }}  
                                anchor="right"
                                isOpen={isOptionsDropdownOpen}
                                onMouseLeave={() => setIsOptionsDropdownOpen(false)}
                                options={[
                                    <ActiveStakeCardAmountButton to={`/transaction?hash=${isStake ? stakingCollectionTransferNodeHash : stakingCollectionTransferDelegatorHash}&nodeid=${nodeInfo.nodeID}${nodeInfo.delegatorID ? `&delegatorid=${nodeInfo.delegatorID}` : "&meta_hide_arg=delegatorid"}&showcode=false&origin=${btoa(`/stake-delegate/${fcl.withPrefix(currentUserAddr)}`)}`} isActive={true} >Transfer</ActiveStakeCardAmountButton>,
                                    isStake ? <ActiveStakeCardAmountButton to={`/transaction?hash=${stakingCollectionUpdateNetworkingAddressHash}&nodeid=${nodeInfo.nodeID}&showcode=false&origin=${btoa(`/stake-delegate/${fcl.withPrefix(currentUserAddr)}`)}`} isActive={true} >Update Networking Address</ActiveStakeCardAmountButton> : null,
                                    (((Number(nodeInfo.tokensStaked) !== 0) ||
                                    (Number(nodeInfo.tokensCommitted) !== 0) || 
                                    (Number(nodeInfo.tokensRequestedToUnstake) !== 0) ||
                                    (Number(nodeInfo.tokensUnstaking) !== 0 ) || isNodeEpochUpdateRequired)) 
                                        ? null : <ActiveStakeCardAmountButton to={`/transaction?hash=${stakingCollectionCloseStakeHash}&nodeid=${nodeInfo.nodeID}${nodeInfo.delegatorID ? `&delegatorid=${nodeInfo.delegatorID}` : "&meta_hide_arg=delegatorid"}&showcode=false&origin=${btoa(`/stake-delegate/${fcl.withPrefix(currentUserAddr)}`)}`}>Close {isStake? "Node" : "Delegation"}</ActiveStakeCardAmountButton>
                                ]}
                            />
                        </ActiveStakeCardSectionDropDownWrapper>
                    }
                </ActiveStakeCardSection>
            </ActiveStakeCardRow>

            { showMachineAccountRow && (
                <ActiveStakeCardRow style={{backgroundColor: "#f8f8f8"}}>
                    <ActiveStakeCardSection>
                        <ActiveStakeCardSectionTitleSubtitle>
                            <ActiveStakeCardSectionTitle>Machine Account Address</ActiveStakeCardSectionTitle>
                            <ActiveStakeCardSectionSubitle>{nodeInfo.machineAccountAddress}</ActiveStakeCardSectionSubitle>
                        </ActiveStakeCardSectionTitleSubtitle>
                    </ActiveStakeCardSection>
                    <ActiveStakeCardSection>
                        <ActiveStakeCardSectionTitleSubtitle>
                            <ActiveStakeCardSectionTitle>Machine Account Balance</ActiveStakeCardSectionTitle>
                            <ActiveStakeCardSectionSubitle>{nodeInfo.machineAccountBalance}<ActiveStakeCardSectionFlow>FLOW</ActiveStakeCardSectionFlow></ActiveStakeCardSectionSubitle>
                        </ActiveStakeCardSectionTitleSubtitle>
                        { enableActions &&
                            <ActiveStakeCardSectionDropDownWrapper 
                                onMouseLeave={() => setIsMachineAccountDropdownOpen(false)}
                            >
                                <DotsWrapper onMouseEnter={() => setIsMachineAccountDropdownOpen(true)}><Dots /></DotsWrapper>
                                <DropDown 
                                    isOpen={isMachineAccountDropdownOpen}
                                    onMouseLeave={() => setIsMachineAccountDropdownOpen(false)}
                                    options={[
                                        <ActiveStakeCardAmountButton to={`/transaction?hash=${transferFlowTokenHash}&to=${nodeInfo.machineAccountAddress}&showcode=false&origin=${btoa(`/stake-delegate/${fcl.withPrefix(currentUserAddr)}`)}`} isActive={true} >Fund Account</ActiveStakeCardAmountButton>,
                                        <ActiveStakeCardAmountButton to={`/transaction?hash=${stakingCollectionWithdrawFromMachineAccountHash}&nodeid=${nodeInfo.nodeID}&showcode=false&origin=${btoa(`/stake-delegate/${fcl.withPrefix(currentUserAddr)}`)}`} isActive={true} >Withdraw</ActiveStakeCardAmountButton>
                                    ]}
                                />
                            </ActiveStakeCardSectionDropDownWrapper>
                        }
                    </ActiveStakeCardSection>
                    <ActiveStakeCardSection>
                        <LearnMoreButton href="https://docs.onflow.org/staking/machine-account" target="_blank"><LearnMoreButtonIcon />Learn More About Machine Accounts</LearnMoreButton>
                    </ActiveStakeCardSection>
                </ActiveStakeCardRow>
            )}
            
            { showStakeRow &&
                <ActiveStakeCardRow>
                    <ActiveStakeCardSection>
                        <ActiveStakeCardSectionTitleSubtitle>
                            <ActiveStakeCardSectionTitle>Staked Amount</ActiveStakeCardSectionTitle>
                            <ActiveStakeCardSectionSubitle>
                                {(() => {
                                    if (Number(nodeInfo.tokensStaked) === 0 && Number(nodeInfo.tokensCommitted) !== 0) return "Pending"
                                    if (Number(nodeInfo.tokensStaked) === 0 && Number(nodeInfo.tokensRequestedToUnstake) !== 0) return "Pending"
                                    return <>{nodeInfo.tokensStaked}<ActiveStakeCardSectionFlow>FLOW</ActiveStakeCardSectionFlow></>
                                })()}
                            </ActiveStakeCardSectionSubitle>
                        </ActiveStakeCardSectionTitleSubtitle>
                        { enableActions && (!delegatedNodeMinStakeError || (Number(nodeInfo.tokensStaked) > 0 || Number(nodeInfo.tokensCommitted) > 0)) &&
                            <ActiveStakeCardSectionDropDownWrapper 
                                onMouseLeave={() => setIsStakeAmountDropdownOpen(false)}
                            >
                                <DotsWrapper onMouseEnter={() => setIsStakeAmountDropdownOpen(true)}><Dots /></DotsWrapper>
                                <DropDown 
                                    isOpen={isStakeAmountDropdownOpen}
                                    onMouseLeave={() => setIsStakeAmountDropdownOpen(false)}
                                    options={[
                                        !delegatedNodeMinStakeError ? <ActiveStakeCardAmountButton to={`/transaction?hash=${stakingCollectionStakeNewTokensHash}&nodeid=${nodeInfo.nodeID}${nodeInfo.delegatorID ? `&delegatorid=${nodeInfo.delegatorID}` : "&meta_hide_arg=delegatorid"}&showcode=false&origin=${btoa(`/stake-delegate/${fcl.withPrefix(currentUserAddr)}`)}`} isActive={true} >Add FLOW</ActiveStakeCardAmountButton> : null,
                                        (Number(nodeInfo.tokensStaked) > 0 || Number(nodeInfo.tokensCommitted) > 0) ? <ActiveStakeCardAmountButton to={`/transaction?hash=${stakingCollectionRequestUnstakingHash}&nodeid=${nodeInfo.nodeID}${nodeInfo.delegatorID ? `&delegatorid=${nodeInfo.delegatorID}` : "&meta_hide_arg=delegatorid"}&showcode=false&origin=${btoa(`/stake-delegate/${fcl.withPrefix(currentUserAddr)}`)}`} isActive={true} >Unstake</ActiveStakeCardAmountButton> : null,
                                        isStake && (Number(nodeInfo.tokensStaked) > 0 || Number(nodeInfo.tokensCommitted) > 0) ? <ActiveStakeCardAmountButton to={`/transaction?hash=${stakingCollectionUnstakeAllHash}&nodeid=${nodeInfo.nodeID}${nodeInfo.delegatorID ? `&delegatorid=${nodeInfo.delegatorID}` : "&meta_hide_arg=delegatorid"}&showcode=false&origin=${btoa(`/stake-delegate/${fcl.withPrefix(currentUserAddr)}`)}`} isActive={true} >Unstake All</ActiveStakeCardAmountButton> : null
                                    ]}
                                />
                            </ActiveStakeCardSectionDropDownWrapper>
                        }
                    </ActiveStakeCardSection>

                    { nodeInfo.tokensUnstaked && Number(nodeInfo.tokensUnstaked) !== 0 &&
                        <ActiveStakeCardSection>
                            <ActiveStakeCardSectionTitleSubtitle>
                                <ActiveStakeCardSectionTitle>Unstaked Amount</ActiveStakeCardSectionTitle>
                                <ActiveStakeCardSectionSubitle>{nodeInfo && nodeInfo.tokensUnstaked}<ActiveStakeCardSectionFlow>FLOW</ActiveStakeCardSectionFlow></ActiveStakeCardSectionSubitle>
                            </ActiveStakeCardSectionTitleSubtitle>
                            <ActiveStakeCardSectionDropDownWrapper 
                                onMouseLeave={() => setIsUnstakedAmountDropdownOpen(false)}
                            >
                                <DotsWrapper onMouseEnter={() => setIsUnstakedAmountDropdownOpen(true)}><Dots /></DotsWrapper>
                                <DropDown 
                                    isOpen={isUnstakedAmountDropdownOpen}
                                    onMouseLeave={() => setIsUnstakedAmountDropdownOpen(false)}
                                    options={[
                                        (enableActions && !delegatedNodeMinStakeError) ? <ActiveStakeCardAmountButton to={`/transaction?hash=${stakingCollectionStakeUnstakedTokensHash}&nodeid=${nodeInfo.nodeID}${nodeInfo.delegatorID ? `&delegatorid=${nodeInfo.delegatorID}` : "&meta_hide_arg=delegatorid"}&showcode=false&origin=${btoa(`/stake-delegate/${fcl.withPrefix(currentUserAddr)}`)}`} isActive={true} >Restake</ActiveStakeCardAmountButton> : null,
                                        <ActiveStakeCardAmountButton to={`/transaction?hash=${stakingCollectionWithdrawUnstakedTokensHash}&nodeid=${nodeInfo.nodeID}${nodeInfo.delegatorID ? `&delegatorid=${nodeInfo.delegatorID}` : "&meta_hide_arg=delegatorid"}&showcode=false&origin=${btoa(`/stake-delegate/${fcl.withPrefix(currentUserAddr)}`)}`} isActive={true} >Withdraw</ActiveStakeCardAmountButton>
                                    ]}
                                />
                            </ActiveStakeCardSectionDropDownWrapper>
                        </ActiveStakeCardSection>
                    }

                    <ActiveStakeCardSection>
                        <ActiveStakeCardSectionTitleSubtitle>
                            <ActiveStakeCardSectionTitle>Rewards Amount</ActiveStakeCardSectionTitle>
                            <ActiveStakeCardSectionSubitle>{ nodeInfo && nodeInfo.tokensRewarded }<ActiveStakeCardSectionFlow>FLOW</ActiveStakeCardSectionFlow></ActiveStakeCardSectionSubitle>
                        </ActiveStakeCardSectionTitleSubtitle>
                        { Number(nodeInfo.tokensRewarded) > 0 && 
                            <ActiveStakeCardSectionDropDownWrapper 
                                onMouseLeave={() => setIsRewardsAmountDropdownOpen(false)}   
                            >
                                <DotsWrapper onMouseEnter={() => setIsRewardsAmountDropdownOpen(true)} ><Dots /></DotsWrapper>
                                <DropDown 
                                    isOpen={isRewardsAmountDropdownOpen}
                                    onMouseLeave={() => setIsRewardsAmountDropdownOpen(false)}
                                    options={[
                                        (enableActions && !delegatedNodeMinStakeError) ? <ActiveStakeCardAmountButton to={`/transaction?hash=${stakingCollectionStakeRewardedTokensHash}&nodeid=${nodeInfo.nodeID}${nodeInfo.delegatorID ? `&delegatorid=${nodeInfo.delegatorID}` : "&meta_hide_arg=delegatorid"}&showcode=false&origin=${btoa(`/stake-delegate/${fcl.withPrefix(currentUserAddr)}`)}`} isActive={true} >Restake</ActiveStakeCardAmountButton> : null,
                                        <ActiveStakeCardAmountButton to={`/transaction?hash=${stakingCollectionWithdrawRewardedTokensHash}&nodeid=${nodeInfo.nodeID}${nodeInfo.delegatorID ? `&delegatorid=${nodeInfo.delegatorID}` : "&meta_hide_arg=delegatorid"}&showcode=false&origin=${btoa(`/account/${fcl.withPrefix(currentUserAddr)}?showbalancebreakdown=true`)}`} isActive={true} >Withdraw</ActiveStakeCardAmountButton>
                                    ]}
                                />
                            </ActiveStakeCardSectionDropDownWrapper>
                        }
                    </ActiveStakeCardSection>
                </ActiveStakeCardRow>
            }

            { showDelegatorsRow && isStake && (
                <ActiveStakeCardRow>
                    <ActiveStakeCardSection>
                        <ActiveStakeCardSectionTitleSubtitle>
                            <ActiveStakeCardSectionTitle>Delegators</ActiveStakeCardSectionTitle>
                            <ActiveStakeCardSectionSubitle>{nodeInfo.delegators.length}</ActiveStakeCardSectionSubitle>
                        </ActiveStakeCardSectionTitleSubtitle>
                    </ActiveStakeCardSection>
                    <ActiveStakeCardSection>
                        <ActiveStakeCardSectionTitleSubtitle>
                            <ActiveStakeCardSectionTitle>Delegated Amount</ActiveStakeCardSectionTitle>
                            <ActiveStakeCardSectionSubitle>{nodeInfo.tokensDelegated}<ActiveStakeCardSectionFlow>FLOW</ActiveStakeCardSectionFlow></ActiveStakeCardSectionSubitle>
                        </ActiveStakeCardSectionTitleSubtitle>
                    </ActiveStakeCardSection>
                    <ActiveStakeCardSection />
                </ActiveStakeCardRow>
            )}

            {nodeInfo && (Number(nodeInfo.tokensCommitted) > 0) && (
                <ActiveStakeCardRowStakeInProgress>
                    <ActiveStakeCardRowUnstakeInProgressLeft>
                        <ActiveStakeCardRowStakeInProgressImg src={Clock} />
                        <ActiveStakeCardRowStakeInProgressTitle>Stake Committed</ActiveStakeCardRowStakeInProgressTitle>
                        <ActiveStakeCardRowStakeInProgressAmount>+{nodeInfo.tokensCommitted}<ActiveStakeCardSectionFlow>FLOW</ActiveStakeCardSectionFlow></ActiveStakeCardRowStakeInProgressAmount>
                    </ActiveStakeCardRowUnstakeInProgressLeft>
                    <ActiveStakeCardRowUnstakeInProgressRight>
                        Will be processed at the next epoch
                        { enableActions && <CancelStakeButton to={`/transaction?hash=${stakingCollectionRequestUnstakingHash}&amount=${nodeInfo.tokensCommitted}&nodeid=${nodeInfo.nodeID}${nodeInfo.delegatorID ? `&delegatorid=${nodeInfo.delegatorID}` : "&meta_hide_arg=delegatorid"}&showcode=false&origin=${btoa(`/stake-delegate/${fcl.withPrefix(currentUserAddr)}`)}`}><CancelStakeIcon /></CancelStakeButton> }
                    </ActiveStakeCardRowUnstakeInProgressRight>
                </ActiveStakeCardRowStakeInProgress>
            )}

            {nodeInfo && (Number(nodeInfo.tokensUnstaking) > 0) && (
                <ActiveStakeCardRowUnstakeInProgress>
                    <ActiveStakeCardRowUnstakeInProgressLeft>
                        <ActiveStakeCardRowStakeInProgressImg src={Clock} />
                        <ActiveStakeCardRowStakeInProgressTitle>Unstake In Progress</ActiveStakeCardRowStakeInProgressTitle>
                        <ActiveStakeCardRowStakeInProgressAmount>-{nodeInfo.tokensUnstaking}<ActiveStakeCardSectionFlow>FLOW</ActiveStakeCardSectionFlow></ActiveStakeCardRowStakeInProgressAmount>
                    </ActiveStakeCardRowUnstakeInProgressLeft>
                    <ActiveStakeCardRowUnstakeInProgressRight>Will be processed at the next epoch</ActiveStakeCardRowUnstakeInProgressRight>
                </ActiveStakeCardRowUnstakeInProgress>
            )}

            {nodeInfo && (Number(nodeInfo.tokensRequestedToUnstake) > 0) && (
                <ActiveStakeCardRowUnstakeInProgress>
                    <ActiveStakeCardRowUnstakeInProgressLeft>
                        <ActiveStakeCardRowStakeInProgressImg src={Clock} />
                        <ActiveStakeCardRowStakeInProgressTitle>Requested unstake In Progress</ActiveStakeCardRowStakeInProgressTitle>
                        <ActiveStakeCardRowStakeInProgressAmount>-{nodeInfo.tokensRequestedToUnstake}<ActiveStakeCardSectionFlow>FLOW</ActiveStakeCardSectionFlow></ActiveStakeCardRowStakeInProgressAmount>
                    </ActiveStakeCardRowUnstakeInProgressLeft>
                    <ActiveStakeCardRowUnstakeInProgressRight>
                        Will be processed two epochs from now
                        { enableActions && <CancelStakeButton to={`/transaction?hash=${stakingCollectionStakeUnstakedTokensHash}&amount=${nodeInfo.tokensRequestedToUnstake}&nodeid=${nodeInfo.nodeID}${nodeInfo.delegatorID ? `&delegatorid=${nodeInfo.delegatorID}` : "&meta_hide_arg=delegatorid"}&showcode=false&origin=${btoa(`/stake-delegate/${fcl.withPrefix(currentUserAddr)}`)}`}><CancelStakeIcon /></CancelStakeButton> }
                    </ActiveStakeCardRowUnstakeInProgressRight>
                </ActiveStakeCardRowUnstakeInProgress>
            )}

            { delegatedNodeMinStakeError &&
                <ActiveStakeCardRow>
                    <ActiveStakeCardWarning>
                        <ActiveStakeCardWarningLeft>
                            <ActiveStakeCardWarningIcon />This node you're delegating to is no longer properly staked.
                        </ActiveStakeCardWarningLeft>
                    </ActiveStakeCardWarning>
                </ActiveStakeCardRow>
            }
            
        </ActiveStakeCardWrapper>
    )
}