import React from "react"
import {Helmet} from "react-helmet"
import {Link} from "react-router-dom"
import styled from "styled-components"
import { LogoWithNetwork } from "../components/Logo"

const TermsWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const TermsInner = styled.div`
    max-width: 100%;
    width: 60rem;

    padding: 2rem;
    box-sizing: border-box;
`

const TermsLogo = styled.img`
    height: 4rem;
    margin-bottom: 2rem;
`

const TermsH1 = styled.h1`
    margin-bottom: 1rem;
`

const TermsH2 = styled.h2`
    margin-bottom: 1rem;
`

const TermsH3 = styled.h3`
    margin-bottom: 1rem;
`

const TermsParagraph = styled.p`
    margin-bottom: 1rem;
`

const TermsUL = styled.ul`
    margin-bottom: 1rem;
    margin-left: 3rem;
`

const TermsOL = styled.ol`
    margin-bottom: 1rem;
    margin-left: 3rem;
`

const TermsLI = styled.li``

const TermsBold = styled.b``

const TermsCentered = styled.div`
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: bold;
`

export const Terms = () => {

    return (
        <TermsWrapper>
            <Helmet><title>Flow Port | Terms</title></Helmet>
            <TermsInner>
                <LogoWithNetwork />
                <TermsH1>Flow Port Terms of Service</TermsH1>
                <TermsH3>Latest Update: October 22, 2020</TermsH3>

                <TermsParagraph>
                    Thank you for visiting https://port.onflow.org/ (the <TermsBold>“Site”</TermsBold>). These terms of service (this <TermsBold>“Agreement”</TermsBold>) are an important legally binding agreement between you (<TermsBold>“you”</TermsBold>, <TermsBold>“your”</TermsBold> or <TermsBold>“User”</TermsBold>) and Dapper Labs Inc., a British Columbia corporation (<TermsBold>“Dapper”</TermsBold>, <TermsBold>“we”</TermsBold>, <TermsBold>“us”</TermsBold> or <TermsBold>“our”</TermsBold>).
                </TermsParagraph>

                <TermsParagraph>
                    Flow Port is a decentralized application that runs on the Flow Blockchain, that provides an interface by which you can submit transactions to the Flow Blockchain. We do not control the Flow Blockchain.  By using Flow Port, you are choosing to use the Flow Blockchain - which you are doing of your own volition. There are other ways to engage with the Flow Blockchain other than by using Flow Port.  You are entirely responsible for the transactions that you choose to submit for execution through the Flow Port, as well as any risks associated with your use of the Flow Blockchain (see the “Risks” section below for additional information). This Agreement does not have any relation to your decision to use the Flow Blockchain.
                </TermsParagraph>

                <TermsParagraph>
                    This Agreement, the <Link to="/privacy">Flow Port Privacy Policy</Link>, your Settings and such other terms as may be included in any of them by reference (collectively, the <TermsBold>“Agreement”</TermsBold>) govern your use of the Site, and the Flow Port services (collectively, the <TermsBold>“Services”</TermsBold>).
                </TermsParagraph>

                <TermsParagraph>
                    BY CLICKING ‘I AGREE’ OR ‘ACCEPT’, USING YOUR APPROVED WALLET IN CONJUNCTION WITH THE SERVICES, OR OTHERWISE UTILIZING THE SERVICES, YOU ARE AGREEING TO BE LEGALLY BOUND BY THIS AGREEMENT, SO PLEASE READ IT CAREFULLY PRIOR TO DOING SO. We strongly recommend that you frequently review this Agreement to ensure that you understand it, and how it applies to your access to and use of the Services.
                </TermsParagraph>

                <TermsParagraph>
                    Please refer to the Glossary at the end of this Agreement for definitions of capitalized terms that are not defined in the text of the Agreement.
                </TermsParagraph>

                <TermsH3>Users and Accounts</TermsH3>

                <TermsParagraph>
                    A User is prohibited from accessing or using the Services unless the User: (i) has accepted this Agreement; (ii) is located and domiciled in an Approved Region; (iii) is eighteen (18) years of age or older; (iv) is not a Prohibited User (as defined below); and (v) is not otherwise prohibited from using the Services. 
                </TermsParagraph>

                <TermsParagraph>
                    The Account is not created or hosted by us. Instead, it represents the User’s own Transactions on the Flow Blockchain, over which we have no control. 
                </TermsParagraph>

                <TermsParagraph>
                    Subject to operation of both the Flow Blockchain and your Approved Wallet, a User can access the Services to retrieve and send data concerning their Transactions. A User has full responsibility for use of their Account, and shall not disclose their password or permit any third party to use their Account. Users will be given the ability to adjust Settings in their Account, which selected Settings are incorporated into this Agreement. Users are exclusively responsible for those selections, even if they contain errors on the part of the User or cause losses to the User. Users are exclusively responsible for all Transactions in their Accounts. Dapper will not grant any third-party access to the User’s Account; such access, if any, is controlled by the User through the Account.
                </TermsParagraph>

                <TermsParagraph>
                    For initial signup for an Account, all a User needs to do is to log in using one of Dapper’s Approved Wallet Providers.
                </TermsParagraph>

                <TermsParagraph>
                    User shall immediately notify Dapper if User suspects any unauthorized use or access of its Account or otherwise suspects any security breaches or attempted security breaches in the Services, Account, a User Device, any Wallet or those of any Other User.
                </TermsParagraph>

                <TermsParagraph>
                    User assumes exclusive responsibility for all Transactions and other activities that occur in User’s Account and through the Services and accepts all risks of any unauthorized access to User’s Account, to the maximum extent permitted by law.
                </TermsParagraph>

                <TermsParagraph>
                    Dapper may, but is not obligated to, offer or require enhanced security features for the Services, such as two-factor authentication. For any enhanced security features, User shall ensure the security of User’s Device or account (e.g. phone) that may be associated with the enhanced security features. Any mention of, or linking to, third parties by Dapper that provide such security features <TermsBold>(“2FA Provider”)</TermsBold> is offered as a convenience and shall not be considered an endorsement or requirement of Dapper to use such 2FA Provider.
                </TermsParagraph>

                <TermsH3>Prohibited Users</TermsH3>

                <TermsParagraph>
                    Services are not offered to absolutely everyone.
                </TermsParagraph>

                <TermsParagraph>
                    The following Persons are prohibited from using the Services (each, a <TermsBold>“Prohibited User”</TermsBold>): (i) Persons who appear on the United States Department of the Treasury, Office of Foreign Assets Control (OFAC), Specially Designated Nationals List (SDN), United States Commerce Department’s Denied Persons or other similar lists; (ii) Persons who are nationals or residents of Cuba, Iran, North Korea, Sudan, Syria or any other country, territory, or other jurisdiction that is embargoed by Canada, the United States or listed at <TermsBold>Exhibit “A”</TermsBold> of this Agreement; (iii) Persons who are less than 18 years of age; (iv) Persons who are not residents of and located in an Approved Region; (v) Persons, or their Affiliates, who have been subject to an Account termination for cause; and (vi) Persons that are not individual natural persons, unless such Persons pass additional KYC requirements as indicated by Dapper. If User is an entity and not an individual person, please contact support@onflow.org. Dapper reserves the right to add or remove Approved Regions at its sole discretion.
                </TermsParagraph>

                <TermsParagraph>
                    You are prohibited from accessing or using the Services in, from or through a location where your use of the Services would violate any Applicable Law. You represent and warrant that you are not a citizen or resident of any such jurisdiction, nor will you use the Services while located in any such jurisdiction.
                </TermsParagraph>

                <TermsParagraph>
                    The Services are not available in all jurisdictions. Dapper prohibits the use of the Services in certain jurisdictions, which jurisdictions may vary at Dapper’s sole discretion. Dapper may implement controls to restrict access to the Services from any jurisdiction from which use of the Services is prohibited. You shall comply with such restrictions, even if the methods imposed by Dapper to prevent the use of the Services are not communicated to you or are not effective or can be bypassed.
                </TermsParagraph>

                <TermsH3>Prohibited Activities</TermsH3>

                <TermsParagraph>
                    Services cannot be used for absolutely any purpose.
                </TermsParagraph>

                <TermsParagraph>
                    User shall use the Services only for good faith Transactions for its own purposes and not on behalf of third parties. User shall not use the Services to, directly or indirectly, assist in any activity that is illegal in Canada, the U.S. or the jurisdiction of User, or use the Services in relation to any other Prohibited Activity (all of which are listed below in the Glossary). User shall use the Services only for their own Account and their own Flow Tokens, and shall not use the Services to transact with the Account or Flow Tokens of any third party. Dapper reserves the right to add or remove Prohibited Activities at its sole discretion.
                </TermsParagraph>

                <TermsH3>Risks</TermsH3>

                <TermsParagraph>
                    By using the Services, you are accessing or using the Flow Blockchain.
                </TermsParagraph>

                <TermsParagraph>
                    You acknowledge and agree that there are inherent risks associated with Flow Tokens, Secondary Flow Tokens, and the Flow Blockchain more generally and that such risks extend to your use of the Services. These risks include, but are not limited to, the failure of hardware, software or internet connections, the risk of malicious software introduction and the risk of unauthorized access to your Account, Wallets or Flow Tokens. It is your responsibility to familiarize yourself with the risks involved with the Flow Blockchain. Use of the Services does not remove these risks. Dapper is not responsible for any communication failures, disruptions, errors, distortions or delays when using the Services, however they may be caused.
                </TermsParagraph>

                <TermsParagraph>
                    You acknowledge that we make no representation whatsoever as to the value, present or future, or any Flow Token, Secondary Flow Tokens or other property. Transactions can take time to be confirmed and may, in some cases, not be completed. For example, if you initiate a Transaction to delegate Flow Tokens to a third party and you provide an incorrect or incomplete address, the delegated Flow Tokens may be lost and may not be recoverable. If you attempt to send or receive tokens that are not supported by your Account, the tokens may be lost entirely and may not be recoverable. In no event will Dapper be liable to you or to any third party for any of the foregoing.
                </TermsParagraph>

                <TermsParagraph>
                    You agree that it is your responsibility to independently ascertain the value of your Flow Tokens and Secondary Flow Tokens, without relying on the Services. Dapper is not, itself, offering to purchase or sell any Flow Tokens or Secondary Flow Tokens from you or to you under this Agreement.
                </TermsParagraph>

                <TermsH3>Services</TermsH3>

                <TermsParagraph>
                    The Services include the right, subject to the terms of this Agreement, to:
                </TermsParagraph>

                <TermsUL>
                    <TermsLI>access the Site to use the Services;</TermsLI>
                    <TermsLI>send and receive User Data to and from Third Party Servicers, such as, for example, Approved Wallet Providers; and</TermsLI>
                    <TermsLI>send and receive User Data to and from the Flow Blockchain, in so far as it is operational.</TermsLI>
                </TermsUL>

                <TermsH3>Transactions</TermsH3>

                <TermsParagraph>
                    The Services allow Users to send and receive User Data in support of a variety of Transactions.
                </TermsParagraph>

                <TermsParagraph>
                    Transactions are subject to the following rules and disclaimers, without limitation:
                </TermsParagraph>

                <TermsUL>
                    <TermsLI>
                        Dapper makes no representations as to the Services being able to complete a given Transaction, notably because it is not capable of performing any Transaction. Transactions are completed by you in your Account using your Approved Wallet Provider;
                    </TermsLI>
                    <TermsLI>
                        Dapper shall have no liability if a Transaction is delayed or fails on account of errors, lack of processing capacity, or other failure of any third party;    
                    </TermsLI>
                    <TermsLI>
                        Dapper does not control Wallets, and shall have no liability to User for their failures, fees and other risks associated with User’s use of the same;
                    </TermsLI>
                    <TermsLI>
                        Without prior notice or consent of User, Dapper reserves the right to impose, in its sole discretion, User, geographic, quantum, velocity, kind and other limits on User Data concerning certain Transactions in order to protect User, Other Users, Dapper and its suppliers from legal, fraud, security or other risks;
                    </TermsLI>
                    <TermsLI>
                        Unless otherwise expressly set forth on the Site or the Services description, Dapper is not able to reverse any Transaction. Dapper bears no responsibility or liability for Transactions initiated by User that contain incorrect, erroneous or improperly formatted User Data or are intended for a different type or destination;
                    </TermsLI>
                    <TermsLI>
                        User has the exclusive responsibility to ensure that their Transactions are carried out: (i) in compliance with the requirements set out herein and in the Account; (ii) in compliance with any applicable Third Party Servicer requirements; (iii) using addresses and information that are true, correct, and free of error; and (iv) in compliance with any other applicable Third Party Servicer Agreement; and
                    </TermsLI>
                    <TermsLI>
                        Dapper reserves the right, in its sole discretion, to prohibit User Data transfers concerning any Transaction in order to reduce the risk of fraud, security breaches, or other risks and also to comply with Applicable Law.
                    </TermsLI>
                </TermsUL>

                <TermsParagraph>
                    Failure by User to adhere to the foregoing rules may result in the permanent loss of their Flow Tokens and/or Secondary Flow Tokens, for which Dapper shall have no liability.
                </TermsParagraph>

                <TermsH3>Dapper Regulatory Status</TermsH3>

                <TermsParagraph>
                    Dapper is not registered with the Financial Transactions and Reports Analysis Centre of Canada (FINTRAC) or U.S. Department of the Treasury Financial Crimes Enforcement Network (FinCEN) as a money services business.
                </TermsParagraph>

                <TermsParagraph>
                    The Services are not a cryptocurrency wallet, whether hosted or unhosted. Dapper never takes possession or control of any of your private keys associated with your Account or Wallet. Dapper is not a bank. Dapper is not a payment processor. Dapper is not a broker or investment advisor. There is no FDIC insurance on Flow Tokens or Secondary Flow Tokens. User acknowledges that Flow Tokens and Secondary Flow Tokens have no intrinsic value.
                </TermsParagraph>

                <TermsParagraph>
                    The Services may be unavailable or subject to certain restrictions in certain states or jurisdictions at the sole discretion of Dapper without prior notice to you and without your consent.
                </TermsParagraph>

                <TermsH3>Third Party Servicers</TermsH3>

                <TermsParagraph>
                    As per the Settings, User also authorizes Dapper to send and receive User Data to and from other Third Party Servicers.
                </TermsParagraph>

                <TermsParagraph>
                    If User authorizes the Services to take instructions from User, or in respect of an Account, via a Third Party Servicer, then such instructions shall be deemed delivered by User directly.
                </TermsParagraph>

                <TermsParagraph>
                    User represents that each Third Party Servicer is party to a Third Party Servicer Agreement with User and that User assumes exclusive responsibility for acts and omissions of all Third Party Servicers whether under the Third Party Servicer Agreement, with respect to User Data or otherwise. The use of a Third Party Servicer does not relieve User of any of its responsibilities or obligations under this Agreement. User is liable for any and all fees charged by Third Party Servicers. Unless indicated to the contrary in the Account, fees for Services do not include fees applicable under Third Party Servicer Agreements.
                </TermsParagraph>

                <TermsH3>Personal Information Consent</TermsH3>

                <TermsParagraph>
                    User authorizes Dapper to, directly or through third parties, make inquiries and take any other actions Dapper deems reasonably necessary to verify User identity, and the information User has provided. Where Dapper is not entirely satisfied that it has verified User identity, it reserves the right to limit User access to some or all Services.
                </TermsParagraph>

                <TermsParagraph>
                    User consents to Dapper collecting, storing, processing and disclosing information and other User Data as required to perform the Services and in accordance with the <Link to="/privacy">Flow Port Privacy Policy</Link>.
                </TermsParagraph>

                <TermsH3>Unacceptable use or behavior</TermsH3>

                <TermsParagraph>
                    User alone is responsible for User actions, conduct, and behavior while using the Services. User shall not:
                </TermsParagraph>

                <TermsUL>
                    <TermsLI>
                        Use or attempt to use any Other User’s account;
                    </TermsLI>
                    <TermsLI>
                        Engage in activity, or use the Services in any manner, that imposes an excessive burden on Dapper, or exposes Dapper to excessive security, financial or reputational risk, each as determined by Dapper in its sole discretion;
                    </TermsLI>
                    <TermsLI>
                        Engage in any behaviors that disrupt or negatively affect or inhibit Other Users from fully enjoying the Services or those of Third Party Servicers;
                    </TermsLI>
                    <TermsLI>
                        Detrimentally interfere with, intercept, or expropriate Dapper systems, data, or information;
                    </TermsLI>
                    <TermsLI>
                        Transmit or upload to Dapper or the Services any malware, virus, trojan horses, worms, or other harmful programs;
                    </TermsLI>
                    <TermsLI>
                        Circumvent, or attempt to circumvent, any content filtering techniques used by Dapper;
                    </TermsLI>
                    <TermsLI>
                        Access, or attempt to access, any feature of the Services that User is not authorized to access;
                    </TermsLI>
                    <TermsLI>
                        harass, stalk, threaten, defame, abuse, violate or infringe the legal rights of others, or otherwise be deceptive or objectionable, each as determined by Dapper in its sole discretion;
                    </TermsLI>
                    <TermsLI>
                        Post or distribute unsolicited content, promotions, campaigns, or commercial messages (SPAM), or any message or user content designed to advertise a third party product or service or deceive Other Users or other third parties;
                    </TermsLI>
                    <TermsLI>
                        Post content or collect content containing private information of any Other User or third party, including but not limited to phone numbers, email addresses, mailing addresses;
                    </TermsLI>
                    <TermsLI>
                        Violate any intellectual property rights; or
                    </TermsLI>
                    <TermsLI>
                        Induce or encourage any third party to engage in any activities or behaviors prohibited in this section.
                    </TermsLI>
                </TermsUL>

                <TermsH3>Fees</TermsH3>

                <TermsParagraph>
                    Dapper does not charge fees for its Services. Your consideration given to Dapper in exchange for Dapper performing under this Agreement is your use of an Approved Wallet Provider in conjunction with the Services.
                </TermsParagraph>

                <TermsParagraph>
                    Unless indicated otherwise in your Account or elsewhere in the Services, you are solely responsible for paying all network fees associated with Transactions and all fees of Third Party Servicers, as the case may be. Dapper will not advance nor fund network fees on your behalf, nor will Dapper be responsible should the network fee paid be insufficient or excessive. Your Wallet must hold sufficient funds to cover the Transaction and its associated network fees, failing which the Transaction may be blocked, might fail, or result in your value being suspended temporarily or forever.
                </TermsParagraph>

                <TermsH3>Term and Termination</TermsH3>

                <TermsParagraph>
                    User can terminate this Agreement at any time for any reason or for no reason by ceasing to use the Services. On termination, any User Data concerning then-pending Transactions may be subject to at least a 30 day fraud-prevention hold term, and such other restrictions as may be necessary to reduce the risk of fraud or security breaches.
                </TermsParagraph>

                <TermsParagraph>
                    Dapper may, at any time and in its sole discretion, without prior notice, and without any liability to you, modify or discontinue any portion of our Services, either temporarily or permanently.
                </TermsParagraph>

                <TermsParagraph>
                    At any time and in its sole discretion, without prior notice, and without any liability to you, Dapper may temporarily or permanently, suspend, restrict, or terminate access to any or all of the Services for any reason or for no reason. Dapper is under no obligations to disclose its reason for any termination or suspension of the Services for User or generally.  You will not rely on the Services as the sole means of communicating Transaction User Data with respect to the Flow Blockchain.
                </TermsParagraph>

                <TermsParagraph>
                    Dapper will not be liable for any losses suffered by User resulting from any suspension or termination of the Services. You agree that your Flow Tokens and Secondary Flow Tokens will not be possible to access via the Services during a suspension of Services.
                </TermsParagraph>

                <TermsParagraph>
                    In the event of any termination of this Agreement, discontinuation of all Services, or termination of your right to access all Services: (i) Dapper may cancel sending of User Data in respect of any Transactions that are pending at the time of the termination of your right to access all Services or discontinuation of the Services; and (ii) Dapper may delete User Data without any liability to User, provided that Dapper also reserves the right to retain User Data for seven (7) years following termination. Dapper reserves the right to retain aggregated anonymized User Data for longer periods in order to improve Services, prevent fraud, and produce aggregated statistics that do not identify you or your User Data.
                </TermsParagraph>

                <TermsH3>Third party content</TermsH3>

                <TermsParagraph>
                    In the Services, you may view or otherwise access Third Party Content. Dapper does not control or endorse any Third Party Content. You acknowledge that Third Party Content, when coupled with Third Party Servicer Services and your Approved Wallet Provider, may result in your carrying out Transactions. Transactions are not initiated by or controlled by Dapper.  Dapper shall have no liability for Third Party Content, including without limitation any material that may be erroneous, incomplete, misleading, offensive, or otherwise objectionable. Further, User interaction with such Third Party Content is between User and the third parties and shall be subject to the third parties’ terms of use. User acknowledges and agrees that use of any Third Party Content is at their own risk, and that Dapper is not responsible for any loss or damage in connection with such use.
                </TermsParagraph>

                <TermsH3>Taxes</TermsH3>

                <TermsParagraph>
                    It is your sole responsibility to comply with Applicable Laws related to your use of the Services including, without limitation, to pay any and all taxes that apply to any Transactions. It is your sole responsibility to report and remit the correct tax amount to any and all tax authorities. You acknowledge and agree that Dapper is not responsible for determining the amount of any taxes you owe, nor is Dapper responsible for determining if taxes apply to your use of the Services, or for collecting, reporting, or withholding any taxes arising from your use of the Services.
                </TermsParagraph>

                <TermsH3>Property disputes</TermsH3>

                <TermsParagraph>
                    If Dapper receives notice or allegation that any balances held in your Wallet are stolen or lawfully not yours to possess, Dapper may (but has no obligation, and potentially no ability, to) place an administrative hold of undetermined length on those balances or the whole of the Account. Such hold, if any, may include but may not be limited to a suspension of access to the Services. Dapper shall not be liable for any losses incurred by User that occur in the course of an administrative hold, suspension or termination of access to any portion of or all of the Services in a property dispute. Dapper retains the right to report illegal or suspicious activity to the appropriate Regulators.
                </TermsParagraph>

                <TermsH3>Electronic notices</TermsH3>

                <TermsParagraph>
                    User agrees to receive all communications, including but not limited to, this Agreement, other applicable policies, updates to the same, statements, reports, invoices, receipts, notices, disclosures, legal or regulatory statements, or documents (collectively, the <TermsBold>“Communications”</TermsBold>) with respect to User’s Account and User’s use of the Services via electronic delivery.
                </TermsParagraph>

                <TermsParagraph>
                    By consenting to electronic delivery, User authorizes Dapper to deliver Communications by (i) email notification, (ii) SMS or text message to a mobile phone number User has provided, or (iii) sending User an email message containing a link to, or a notice that directs User to, the Site where the communication or document can be read or printed.
                </TermsParagraph>

                <TermsParagraph>
                    Dapper shall have no liability and User shall fully indemnify Dapper for any and all losses, liabilities, costs, expenses, demands, charges and claims of any kind resulting from User’s reliance on email or User’s Devices or failures in User’s email systems or Devices. If User prefers not to accept these risks, then User must not create an Account nor use the Services.
                </TermsParagraph>

                <TermsParagraph>
                    It is the responsibility of User to provide Dapper with an up to date, true, accurate, and complete email address and to make sure Dapper emails are not deleted or otherwise missed on account of spam filters.
                </TermsParagraph>

                <TermsParagraph>
                    User’s consent to receipt of electronic statements, reports and other Communications shall be valid until User withdraws such consent by notifying Dapper in writing, in which case: (i) Dapper shall provide statements, reports and other Communications on paper; and (ii) Dapper may suspend or terminate the Account and access to the Services.
                </TermsParagraph>

                <TermsH3>Risk disclosures, assumption of risks, and release of Dapper</TermsH3>

                <TermsParagraph>
                    Other than as expressly set forth in this Agreement, Dapper shall not be liable for any risks inherent in the Services or losses that may be incurred by User as a result thereof. User agrees to and does hereby: (i) release Dapper from any and all liability related to or arising from risks inherent in the Services, Flow Tokens, and Secondary Flow Tokens, except as expressly set forth in this Agreement, and (ii) assume all risks inherent in the Services, Flow Tokens, and Secondary Flow Tokens, including, but not limited to, the following:
                </TermsParagraph>

                <TermsUL>
                    <TermsLI>
                        Risk of the network ceasing to operate rendering a Flow Token and/or Secondary Flow Token impossible to be subject to a Transaction and entirely without use. The market for Flow Tokens and/or Secondary Flow Tokens may also collapse without warning or discernible logic.
                    </TermsLI>
                    <TermsLI>
                        There is risk that changes in Applicable Law could adversely affect User’s right to legally use, transfer, exchange, and/or obtain value from Flow Tokens and/or Secondary Flow tokens. It may be illegal, now or in the future, to own, hold, sell or use Flow Tokens and/or Secondary Flow Tokens in one or more countries, including the United States.
                    </TermsLI>
                    <TermsLI>
                        Transactions are, as a rule, irreversible. Consequently, losses due to fraudulent or accidental Transactions are generally not recoverable. Once a Transaction has been recorded on the Flow Blockchain it is not reversible. It is possible that, through computer or human error, or through theft or fraud, User Flow Tokens and/or Secondary Flow Tokens could be transferred in incorrect amounts or to unauthorized third parties.
                    </TermsLI>
                    <TermsLI>
                        Some Transactions shall be deemed to be made when recorded on the network, which is not necessarily the date or time that the User initiated the Transaction.
                    </TermsLI>
                    <TermsLI>
                        Flow Tokens and/or Secondary Flow Tokens may be the target of fraud and cyber-attacks. On your Devices and Wallets, such fraud and attacks can result in total loss to the User.  Where User’s Account is compromised such efforts may fail and could result in total loss of User Flow Tokens and Secondary Flow Tokens. Attacks on the Flow Blockchain or on Dapper may also result in unplanned and significant Unscheduled Downtime during which Transactions will not be executed. Absent fraud or intentional wrongdoing on the part of Dapper, Dapper shall not be liable for User losses on account of any of the foregoing circumstances or compromised Account credentials.
                    </TermsLI>
                    <TermsLI>
                        The Services, Flow Tokens, and Secondary Flow Tokens rely on the internet, that includes third party hardware, software, and internet connections, all of which may suffer communication failures, disruptions, errors, distortions or delays that might affect User’s ability to access and use the Services.
                    </TermsLI>
                </TermsUL>

                <TermsParagraph>
                    User shall carry out their own independent analysis and assessment of the risks specific to the Flow Tokens, Secondary Flow Tokens, and the Services.
                </TermsParagraph>

                <TermsH3>Copyright; intellectual property rights; trademark</TermsH3>

                <TermsParagraph>
                    Unless otherwise expressly indicated by us, all copyright and intellectual property rights in all content and materials (including but not limited to our logo, designs, text, graphics, files, videos, and displayed text, collectively <TermsBold>“Dapper Materials”</TermsBold>) contained on our Site or affiliate sites provided in connection with our Services are the proprietary property of Dapper, or our suppliers, licensors, or third party affiliates, and are protected by U.S. and international property laws.
                </TermsParagraph>

                <TermsParagraph>
                    We grant you a limited, non-exclusive, non-sublicensable license to access and use the Dapper Materials for your personal use in the Services. Such license does not permit the: (i) distribution of Dapper Materials, (ii) resale of Dapper Materials, (iii) making derivative works of Dapper Materials, or (iv) decompilation, disassembling, reverse engineering, or any other attempt to discover the source code of the Site, Services, or Dapper Materials. This license shall automatically terminate if your access to the Services is suspended or terminated by us. “Dapper”, “Dapper Labs Inc.”, “Flow”, ”Flow Port”, ”Flow Blockchain”, any product or service names, logos, and other marks used on the Site or in Dapper Materials, or that we otherwise use in connection with the Services (collectively, the <TermsBold>“Dapper Marks”</TermsBold>) are trademarks owned by Dapper and our licensors. Without our prior written consent, you may not copy, imitate, or use Dapper trademarks.  User also agrees that it will not, both during the term of this Agreement and after it ends, either: (i) challenge, or assist others to challenge, any of the Dapper Marks or the registration thereof; or (ii) register, or attempt to register, any trade marks, service marks, trade names, or logos that are confusingly similar to the Dapper Marks.
                </TermsParagraph>

                <TermsParagraph>
                    We reserve the right to limit, deny, or terminate access to our Services with respect to any person who infringes on the intellectual property rights of others. If you knowingly misrepresent that any activity or Dapper materials are infringing on the intellectual property rights of another party, you may be liable to Dapper for certain costs or damages.
                </TermsParagraph>

                <TermsParagraph>
                    Dapper shall own and have exclusive rights (including but not limited to intellectual property rights) to any feedback, ideas, suggestions, or other materials or information that you provide through chat, email, posting through our Services, or otherwise (<TermsBold>“Feedback”</TermsBold>). You acknowledge and agree that any Feedback you submit is not confidential. You shall not be entitled to, and hereby waive any claim for, acknowledgement or compensation related to any Feedback you provide. You grant Dapper the right to unrestricted use and dissemination of any Feedback you provide, without acknowledgement or compensation to you.
                </TermsParagraph>

                <TermsParagraph>
                    You hereby grant Dapper a royalty free, irrevocable, perpetual, and exclusive worldwide license to use, copy, modify, display, publish, or distribute, in any form, any text, images, data, or materials or other content that you provide to Dapper using the Services, or submit, post, or otherwise send to Dapper that is not Feedback (the <TermsBold>“User Content”</TermsBold>). Dapper may remove any User Content from the Site or the Services at any time for any or no reason.
                </TermsParagraph>

                <TermsH3>Disclaimer of warranties</TermsH3>

                <TermsParagraph>
                    THE SERVICES ARE PROVIDED TO YOU ON A STRICTLY “AS IS,” “WHERE IS,” AND “WHERE AVAILABLE” BASIS. DAPPER EXPRESSLY REFUSES TO REPRESENT OR WARRANT THE ACCURACY, COMPLETENESS, CURRENTNESS, NONINFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE OF THE SERVICES, THE SITE, OR THE INFORMATION CONTAINED THEREIN OR ANY ASPECT OF ANY SERVICE CONTAINED THEREON.
                </TermsParagraph>

                <TermsParagraph>
                    YOU ACKNOWLEDGE THAT INFORMATION, FLOW TOKENS AND SECONDARY FLOW TOKENS THAT YOU STORE OR TRANSFER USING THE FLOW BLOCKCHAIN MAY BECOME IRRETRIEVABLY LOST OR CORRUPTED, OR TEMPORARILY UNAVAILABLE DUE TO A VARIETY OF CAUSES INCLUDING, BUT NOT LIMITED TO, INTERNET OUTAGES, FORCE MAJEURE EVENTS OR OTHER DISASTERS INCLUDING THIRD PARTY DOS ATTACKS, SOFTWARE FAILURES, PROTOCOL CHANGES BY THIRD PARTY SERVICERS, SCHEDULED OR UNSCHEDULED DOWNTIME OR MAINTENANCE, COMPUTER OR HUMAN ERROR, OR OTHER CAUSES EITHER WITHIN OR OUTSIDE OUR CONTROL. YOU ARE SOLELY RESPONSIBLE FOR BACKING UP AND MAINTAINING DUPLICATE COPIES AND RECORDS OF ANY INFORMATION YOU STORE OR TRANSFER THROUGH OUR SERVICES.
                </TermsParagraph>

                <TermsParagraph>
                    SOME JURISDICTIONS DO NOT PERMIT THE DISCLAIMER OF IMPLIED TERMS IN CONTRACTS; THEREFORE, SOME OR ALL OF THE DISCLAIMER OF IMPLIED WARRANTIES IN THIS SECTION MAY NOT APPLY TO YOU.
                </TermsParagraph>

                <TermsH3>Limitation of liability</TermsH3>

                <TermsParagraph>
                    IN NO EVENT SHALL DAPPER, ITS AFFILIATES OR SERVICE PROVIDERS, LICENSORS OR THEIR RESPECTIVE DIRECTORS, SHAREHOLDERS, MEMBERS, OFFICERS, EMPLOYEES, AGENTS, JOINT VENTURERS, OR REPRESENTATIVES, BE LIABLE FOR DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO: (I) ANY DAMAGES CAUSED BY OR RESULTING FROM RELIANCE BY ANY USER ON ANY INFORMATION OBTAINED FROM DAPPER, OR THAT RESULTS FROM MISTAKES, OMISSIONS, INTERRUPTIONS, ERRORS, DEFECTS, VIRUSES, OR DELAYS IN SERVICES OPERATION OR TRANSMISSION; (II) ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM A FORCE MAJEURE EVENT, COMMUNICATIONS FAILURE, THEFT, OR DESTRUCTION ARISING OUT OF OR IN CONNECTION WITH AUTHORIZED OR UNAUTHORIZED USE OF THE SERVICES, OR THIS AGREEMENT; OR (III) ANY LOST PROFITS, OR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES, WHETHER BASED IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE), NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE EVEN IF AN AUTHORIZED REPRESENTATIVE OF DAPPER HAS BEEN ADVISED OF, OR KNEW OF, OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.
                </TermsParagraph>

                <TermsParagraph>
                    SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES; THEREFORE, SOME OR ALL OF THE LIMITATIONS IN THIS SECTION MAY NOT APPLY TO USER WHERE THEIR APPLICATION IS REDUCED BY APPLICABLE LAW.
                </TermsParagraph>

                <TermsParagraph>
                    DAPPER LIABILITY TO YOU UNDER THIS AGREEMENT SHALL NOT, IN THE AGGREGATE, EXCEED ONE HUNDRED CANADIAN DOLLARS (CAD$100.00).
                </TermsParagraph>

                <TermsH3>Indemnification</TermsH3>

                <TermsParagraph>
                    You agree to indemnify and hold harmless Dapper, our officers, directors, shareholders, employees, agents, affiliates, attorneys, suppliers, contractors, representatives and service providers (collectively, <TermsBold>“Indemnified Parties”</TermsBold>), from any and all claims, demands, actions, lawsuits, proceedings, investigations, liabilities, damages, losses, costs, expenses, fines and penalties including, without limitation, reasonable attorney’s fees, arising out of or related to (i) your breach of this Agreement, (ii) your violation of any Applicable Law, or the rights, including intellectual property rights, of any other person or entity, (iii) your use or misuse of our Services, (iv) your conduct in connection with the use or misuse of our Services, (v) your negligence, fraud, willful misconduct or misrepresentations, and (vi) acts or omissions of Third Party Servicers. If you are obligated to indemnify us, we will have the right, in our sole discretion, to control any action or proceeding and determine if we wish to settle it, and if so, on what terms.
                </TermsParagraph>

                <TermsH3>Binding Arbitration</TermsH3>

                <TermsParagraph>
                    PLEASE READ THIS SECTION CAREFULLY, AS IT LIMITS THE MANNER IN WHICH YOU MAY SEEK RELIEF, AND REQUIRES YOU TO ARBITRATE DISPUTES WITH US. If you have a dispute with us, we will first seek to resolve such a dispute through our support team.
                </TermsParagraph>

                <TermsParagraph>
                    All disputes arising out of or in connection with this Agreement or the Services or in respect of any legal relationship associated therewith or derived therefrom will be referred exclusively to and finally resolved by arbitration administered by the British Columbia International Commercial Arbitration Centre (BCICAC) pursuant to its applicable Rules posted here: http://bcicac.com. The appointing authority will be the British Columbia International Commercial Arbitration Centre. There will be a single arbitrator who shall be a practicing B.C. lawyer in accordance with the applicable BCIAC rules. Each party will cover its own fees and costs associated with the arbitration proceedings; however, if the arbitrator finds that you cannot afford to pay the fees and costs reasonably associated with the arbitration proceedings, Dapper will pay them for you. Arbitration will take place in the English language in Vancouver, British Columbia, Canada. You may choose to have the arbitration conducted by telephone, based on written submissions. The award of the arbitrator will be final and binding, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. The parties agree that they will not appeal any arbitration decision to any court. Notwithstanding the foregoing, Dapper may seek and obtain injunctive relief in any jurisdiction in any court of competent jurisdiction, and you agree that this Agreement is specifically enforceable by Dapper through injunctive relief and other equitable remedies without proof of monetary damages. The parties expressly waive their right to a jury trial and also waive their right to participate in a class action claim related to this Agreement.
                </TermsParagraph>

                <TermsParagraph>
                    ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER USER.
                </TermsParagraph>

                <TermsParagraph>
                    NOTICE: BOTH YOU AND DAPPER ARE AGREEING TO HAVE ALL DISPUTES, CLAIMS OR CONTROVERSIES ARISING OUT OF OR RELATING TO THIS AGREEMENT DECIDED BY NEUTRAL BINDING ARBITRATION AND ARE GIVING UP ANY RIGHTS IT MIGHT POSSESS TO HAVE THOSE MATTERS LITIGATED IN A COURT OR JURY TRIAL. BY AGREEING TO THIS AGREEMENT, BOTH YOU AND DAPPER ARE GIVING UP THEIR RESPECTIVE JUDICIAL RIGHTS TO DISCOVERY AND APPEAL EXCEPT TO THE EXTENT THAT THEY ARE SPECIFICALLY PROVIDED FOR UNDER THIS AGREEMENT. IF ANY PARTY REFUSES TO SUBMIT TO ARBITRATION AFTER AGREEING TO THIS PROVISION, THAT PARTY MAY BE COMPELLED TO ARBITRATE UNDER FEDERAL OR PROVINCIAL LAW. BOTH YOU AND DAPPER CONFIRM THEIR AGREEMENT TO THIS ARBITRATION PROVISION IS VOLUNTARY.
                </TermsParagraph>

                <TermsH3>Governing law</TermsH3>

                <TermsParagraph>
                    These Terms shall be governed by and construed in accordance with British Columbia, Canada law, without regard to principles of conflict of laws.
                </TermsParagraph>

                <TermsH3>SEVERABILITY</TermsH3>

                <TermsParagraph>
                    This Agreement and any policies incorporated herein by reference shall be deemed severable. The invalidity or unenforceability of any of the terms or provisions of this Agreement, and any policies incorporated herein by reference, or the invalidity or unenforceability of the application thereof to any individual, entity or circumstance, shall not affect the application of such terms or provisions to individuals, entities or circumstances other than those as to which they are held invalid or unenforceable, and shall not affect the validity or enforceability of any other terms or provisions of this Agreement. Each term and provision of this Agreement and any policies incorporated herein by reference shall be valid and enforceable to the fullest extent permitted by law.
                </TermsParagraph>

                <TermsH3>Assignment</TermsH3>

                <TermsParagraph>
                    Dapper may assign any of its rights or obligations under this Agreement without your consent and without prior notice to you. You may not assign or transfer any of your rights or obligations under this Agreement, in whole or in part, by operation of law or otherwise, without the prior written consent of Dapper, which may be withheld in Dapper’s sole discretion. Any actual or attempted assignment hereof by User contrary to the terms of this Agreement shall be null and void. Dapper may, at its discretion, assign some or all of its rights or obligations to a third party, without consent or approval of User. In the event that Dapper is acquired by, sold to, or merged with a third party entity, Dapper reserves the right to transfer or assign all User Data as part of such merger, acquisition, sale, or change of control.
                </TermsParagraph>

                <TermsH3>Amendments</TermsH3>

                <TermsParagraph>
                    Dapper reserves the right to amend this Agreement at its sole discretion, without your consent. This Agreement may be amended by Dapper by Dapper providing notice of the amendment (i) by email to the email account used in connection with your Account; (ii) by posting a new version hereof to the Services; (iii) by your accepting a revised version of this Agreement by other electronic means; or (iv) by your delivering User Data in support of a Transaction. Where you have accepted the amendment or where the amendment is required by a change in Applicable Law, or to address a security or compliance requirement, as indicated in the notice of amendment contemplated above, the amendment shall take effect as of when this Agreement is updated or the notice contemplated above has been sent, otherwise, the amendment shall take effect if you do not close your Account within thirty (30) days following the notice contemplated above. If you do not agree with a proposed or actual amendment to this Agreement, your sole remedy is to discontinue use of the Services and close your Account.
                </TermsParagraph>

                <TermsH3>NON-WAIVER OF RIGHTS</TermsH3>

                <TermsParagraph>
                    Any failure or delay by Dapper to exercise any right, privilege, or power under this Agreement shall not operate as a waiver thereof. This Agreement shall not be construed to waive any rights that cannot be waived under Applicable Laws.
                </TermsParagraph>

                <TermsH3>Force majeure</TermsH3>

                <TermsParagraph>
                    Dapper shall have no liability or responsibility for: (i) failure in performance or interruption of service; (ii) any inaccuracy, error, delay in, or omission of information or transmission of information; or (iii) any loss or damage that you may incur, which results directly or indirectly from any force majeure event including, but not limited to flood, act of civil or military authorities, terrorist acts, extraordinary weather conditions, pandemic, fire, pandemic, war, strike or other labor dispute, interruption of telecommunication services, accident, power failure, hardware or software malfunction, any act of god, or any other act that is beyond our reasonable control.
                </TermsParagraph>

                <TermsH3>Independent relationship; no broker or agent</TermsH3>

                <TermsParagraph>
                    Dapper is an independent contractor, and nothing in the nature of the Services or any provisions in this Agreement shall be deemed, or cause, or is intended to cause User and Dapper to be treated as partners, joint venturers, or as an agent of the other party. Dapper is not User’s broker, intermediary, agent, or financial advisor, and Dapper has no fiduciary obligation to User in connection with any Transactions, or other acts or omissions of User with respect to the Services. For the avoidance of doubt, Dapper is in no manner providing investment advice, tax advice, legal advice, or other professional advice whether through the Services or otherwise. Dapper does not recommend or endorse that you purchase or sell any Flow Tokens and/or Secondary Flow Tokens, or that you make any investment or enter into any trade or Transaction.
                </TermsParagraph>

                <TermsParagraph>
                    Before engaging in any trading or investment activity, User should always consult a qualified professional.
                </TermsParagraph>

                <TermsH3>Survival</TermsH3>

                <TermsParagraph>
                    All provisions of this Agreement, and any policies incorporated herein by reference, which by their nature extend beyond the expiration or termination of this Agreement shall survive the termination or expiration of this Agreement. Such provisions include, but are not limited to, sections and provisions relating to Account termination or suspension, funds due to Dapper, disputes with Dapper, Risk Disclosures, Unclaimed Property, Copyrights, Trademarks, Eligibility, Disclaimer of Warranties, Limitation of Liability, Indemnification, Binding Arbitration, and Governing Law.
                </TermsParagraph>

                <TermsH3>Contact</TermsH3>

                <TermsParagraph>
                    If you have any feedback, comments, complaints or questions other than technical support, you can contact us using the contact information on our Site. When contacting us, please be sure to provide us with your name, contact information and any other information we may need to identify you, your Account and any transaction on which you have questions, comments, or complaints.
                </TermsParagraph>

                <TermsH3>Headings</TermsH3>

                <TermsParagraph>
                    Section headings and section labels are provided for convenience only, and do not form part of this Agreement and shall not affect in any way the meaning or interpretation of this Agreement.
                </TermsParagraph>

                <TermsH3>English</TermsH3>

                <TermsParagraph>
                    This Agreement is presented in the English language. Any translation of this Agreement is for your convenience, and any meanings of terms, conditions, or representations are subject to the definition and interpretation of the English language.
                </TermsParagraph>

                <TermsH3>Entire agreement</TermsH3>

                <TermsParagraph>
                    This Agreement, and the policies incorporated herein by reference, constitute the entire understanding and agreement between User and Dapper regarding the Services. This Agreement, and the policies incorporated herein by reference supersede all other representations, agreements and understandings, both written and oral, including without limitation any prior versions of this Agreement, among User and Dapper with respect to the Services. In the event of any conflict between this Agreement and any other agreement User may have with Dapper, unless the other agreement specifically references and identifies this Agreement, this Agreement shall control.
                </TermsParagraph>

                <TermsH3>Glossary</TermsH3>

                <TermsParagraph>
                    For the purposes of this Agreement, the terms below in this glossary have the meanings indicated below:
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Account</TermsBold> means a unique account created by User using an Approved Wallet Provider. 
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Affiliate</TermsBold> means, in relation to a Party, another person or entity that directly or indirectly owns or controls, is owned or controlled by, or is under common ownership or common control with the Party, or a Party's principal partners, shareholders, or owners of some other ownership interest.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Applicable Law</TermsBold> means, with respect to any Person, any domestic or foreign, federal, state, provincial or local statute, law, ordinance, rule, administrative interpretation, regulation, order, writ, injunction, judgment, decree or other requirement of any Regulator which is legally binding and applicable to such Person or any of its Affiliates or any of their respective properties, assets, equity holders, officers, directors, members, partners, employees, consultants or agents.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Approved Regions</TermsBold> means Canada, the United States and those places not listed in Exhibit “A” to this Agreement and where the Services are made available, such as they may be from time to time.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Approved Wallet Provider</TermsBold> means a Wallet provider that is compatible with the Flow Blockchain, as determined by Dapper in its sole discretion. Ledger and Portto (Blocto) are Approved Wallet Providers. Dapper reserves the right to withdraw or grant Approved Wallet Provider status to any Approved Wallet Provider at its discretion.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Assets</TermsBold> means, collectively, all Flow Tokens and Secondary Flow Tokens.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Flow Blockchain</TermsBold> means a decentralized computation environment that creates an electronic record established by multiple parties to verify and store a digital record of transactions, which is secured by the use of a mathematical algorithm which, utilizing previous transaction information, performs a one way conversion of input data into output data of a specified size to verify the integrity of the data.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Flow Token</TermsBold> means the native token of the Flow Blockchain.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Other User</TermsBold> means a Person that is not the User or an Affiliate of the User, who is party to an agreement with Dapper substantively identical to this Agreement and that is a user of the Services.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Person</TermsBold> means any individual, corporation, limited liability company, partnership, joint venture, trust, unincorporated organization, Regulator or government or any agency or political subdivision thereof.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Privacy Policy</TermsBold> means the Flow Port Privacy Policy, posted at the Site, such as it is from time to time.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Regulator</TermsBold> means any federal, provincial, state or local or foreign government or any court, arbitrator, administrative or regulatory agency or commission or other governmental authority or agency.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Secondary Flow Token</TermsBold> means any token that exists on the Flow Blockchain that is not a Flow Token. Secondary Flow Tokens can include both fungible tokens and non-fungible tokens.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Settings</TermsBold> means selections you make through the Account or in a means otherwise provided by or through User Data delivered through the Service.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Third Party Content</TermsBold> means links, Transaction information, graphics, images, social media content to third party sites, content or other information supplied by third parties and that can be viewed or accessed from the Service.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Third Party Servicer Agreement</TermsBold> means an agreement between User and a Third Party Servicer pursuant to which the Third Party Servicer delivers their services.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Third Party Servicer</TermsBold> means a third party: (i) whose service are compatible with the Services, as indicated by Dapper; (ii) that User has retained under a Third Party Servicer Agreement; (iii) with whom User wishes to share User Data; and (iv) through which or from which User wishes to deliver or receive User Data with respect to the Account. Approved Wallet Providers that you use are Third Party Servicers.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Transaction</TermsBold> means any of a Flow Token or Secondary Flow Token transaction initiated or accessible through the Services.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>User Data</TermsBold> means the information collected, stored, accessed or used by Dapper in the course of supplying the Services in respect of the Account.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>User Device</TermsBold> means an electronic device used by User to access the Services, such as a computer, tablet, mobile phone or other device.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Wallet</TermsBold> means a valid account established at any Approved Wallet Provider or otherwise capable of receiving or otherwise transacting in Flow Tokens or Secondary Flow Tokens.
                </TermsParagraph>

                <TermsParagraph>
                    <TermsBold>Prohibited Activity</TermsBold> means the operation of or the direct or indirect facilitation of any of the following:
                </TermsParagraph>

                <TermsUL>
                    <TermsLI>
                        Any act that is illegal in Canada, the United States or in the jurisdiction where the Person carrying out the activity is resident, domiciled or located;
                    </TermsLI>
                    <TermsLI>
                        Operation of a business that is similar to or competitive with the Services;
                    </TermsLI>
                    <TermsLI>
                        Operation of a crypto currency exchange;
                    </TermsLI>
                    <TermsLI>
                        Operation of a payment processor, money transmitter, check casher, foreign exchange dealer, provider of prepaid access or other money services business;
                    </TermsLI>
                    <TermsLI>
                        Betting, including lottery tickets, casino gaming chips, off-track betting, memberships on gambling-related internet sites and wagers at races;
                    </TermsLI>
                    <TermsLI>
                        Bill payment services;
                    </TermsLI>
                    <TermsLI>
                        Credit counseling or repair agencies;
                    </TermsLI>
                    <TermsLI>
                        Credit protection or identity theft protection services;
                    </TermsLI>
                    <TermsLI>
                        Direct marketing or subscription offers;
                    </TermsLI>
                    <TermsLI>
                        Inbound or outbound telemarketing businesses including lead generation businesses;
                    </TermsLI>
                    <TermsLI>
                        Internet, mail or telephone order pharmacies or pharmacy referral services;
                    </TermsLI>
                    <TermsLI>
                        Creation, promotion or sale of items that encourage, promote, facilitate or instruct others to engage in illegal activity;
                    </TermsLI>
                    <TermsLI>
                        Creation, promotion or sale of items that may be counterfeit;
                    </TermsLI>
                    <TermsLI>
                        Creation, promotion or sale of items that may infringe or violate any copyright, trademark, right of publicity or privacy or any other proprietary right under the laws of any jurisdiction;
                    </TermsLI>
                    <TermsLI>
                        Creation, promotion or sale of items that promote hate, violence, racial intolerance, or the financial exploitation of a crime;
                    </TermsLI>
                    <TermsLI>
                        Creation, promotion or sale of items that promote, support or glorify acts of violence or harm towards self or others;
                    </TermsLI>
                    <TermsLI>
                        Using the Services in any manner related to live animals;
                    </TermsLI>
                    <TermsLI>
                        Using the Services in any manner related to payment aggregators;
                    </TermsLI>
                    <TermsLI>
                        Purchase, sale or promotion of drugs, alcohol, or drug paraphernalia, or items that may represent these uses;
                    </TermsLI>
                    <TermsLI>
                        Unregulated sales of money-orders or foreign currency;
                    </TermsLI>
                    <TermsLI>
                        Using the Services for any illegal purpose, or in violation of any local, state, national, or international law, including, without limitation, laws governing intellectual property, taxation and other proprietary rights and data collection and privacy;
                    </TermsLI>
                    <TermsLI>
                        Using the Services in a manner that Dapper or any of its suppliers reasonably believes to be an abuse of the Services;
                    </TermsLI>
                    <TermsLI>
                        Using the Services in a manner so as to avoid payment of Fees;
                    </TermsLI>
                    <TermsLI>
                        Using the Services in any manner that could damage, disable, overburden, or impair Dapper or any of the Services including without limitation, using the Services in an automated manner;
                    </TermsLI>
                    <TermsLI>
                        Using the Services in any way that assists User or others in the violation of any law, statute or ordinance;
                    </TermsLI>
                    <TermsLI>
                        Using the Services to collect payments that support pyramid or ponzi schemes, matrix programs, other “business opportunity” schemes or certain multi-level marketing programs;
                    </TermsLI>
                    <TermsLI>
                        Using the Services to control an account that is linked to another account that has engaged in any of the foregoing activities;
                    </TermsLI>
                    <TermsLI>
                        Using the Services to defame, harass, abuse, threaten or defraud others, or collect, or attempt to collect, personal information about users, registered recipients, or third parties without their consent;
                    </TermsLI>
                    <TermsLI>
                        Using the Services to intentionally interfere with another user’s enjoyment of it, by any means, including uploading or otherwise disseminating viruses, adware, spyware, worms or other malicious code;
                    </TermsLI>
                    <TermsLI>
                        Using the Services to make unsolicited offers, advertisements, proposals, or to send junk mail or spam to others;
                    </TermsLI>
                    <TermsLI>
                        Using the Services to send or receive what Dapper considers to be funds for something that may have resulted from fraud or other illegal behavior;
                    </TermsLI>
                    <TermsLI>
                        Using the Services while impersonating any person or entity or falsely claiming an affiliation with any person or entity; or
                    </TermsLI>
                    <TermsLI>
                        Using the Services in any manner related to weapons including replicas and collectible items.
                    </TermsLI>
                </TermsUL>

                <TermsCentered>EXHIBIT “A”</TermsCentered>

                <TermsParagraph>
                    The Service cannot be used in the following jurisdictions:
                </TermsParagraph>

                <TermsH3>Canada - Sanctioned Country List</TermsH3>

                <TermsUL>
                    <TermsLI>
                        Central African Republic
                    </TermsLI>
                    <TermsLI>
                        Democratic Republic of the Congo
                    </TermsLI>
                    <TermsLI>
                        Eritrea
                    </TermsLI>
                    <TermsLI>
                        Iran
                    </TermsLI>
                    <TermsLI>
                        Iraq
                    </TermsLI>
                    <TermsLI>
                        Lebanon
                    </TermsLI>
                    <TermsLI>
                        Libya
                    </TermsLI>
                    <TermsLI>
                        Mali
                    </TermsLI>
                    <TermsLI>
                        Myanmar
                    </TermsLI>
                    <TermsLI>
                        North Korea
                    </TermsLI>
                    <TermsLI>
                        Somalia
                    </TermsLI>
                    <TermsLI>
                        South Sudan
                    </TermsLI>
                    <TermsLI>
                        Sudan
                    </TermsLI>
                    <TermsLI>
                        Syria
                    </TermsLI>
                    <TermsLI>
                        Tunisia
                    </TermsLI>
                    <TermsLI>
                        Venezuela
                    </TermsLI>
                    <TermsLI>
                        Yemen
                    </TermsLI>
                    <TermsLI>
                        Zimbabwe
                    </TermsLI>
                </TermsUL>

                <TermsH3>OFAC - Sanctioned Country List</TermsH3>

                <TermsUL>
                    <TermsLI>
                        Afghanistan
                    </TermsLI>
                    <TermsLI>
                        Balkans
                    </TermsLI>
                    <TermsLI>
                        Belarus
                    </TermsLI>
                    <TermsLI>
                        Burundi
                    </TermsLI>
                    <TermsLI>
                        Central African Republic
                    </TermsLI>
                    <TermsLI>
                        Crimea (Ukraine)
                    </TermsLI>
                    <TermsLI>
                        Cuba
                    </TermsLI>
                    <TermsLI>
                        Democratic Republic of Congo
                    </TermsLI>
                    <TermsLI>
                        Guinea-Bissau
                    </TermsLI>
                    <TermsLI>
                        Iran
                    </TermsLI>
                    <TermsLI>
                        Iraq
                    </TermsLI>
                    <TermsLI>
                        Lebanon
                    </TermsLI>
                    <TermsLI>
                        Libya
                    </TermsLI>
                    <TermsLI>
                        Mali
                    </TermsLI>
                    <TermsLI>
                        Myanmar
                    </TermsLI>
                    <TermsLI>
                        Nicaragua
                    </TermsLI>
                    <TermsLI>
                        Nicaragua
                    </TermsLI>
                    <TermsLI>
                        North Korea
                    </TermsLI>
                    <TermsLI>
                        Somalia
                    </TermsLI>
                    <TermsLI>
                        Sudan and Darfur
                    </TermsLI>
                    <TermsLI>
                        South Sudan
                    </TermsLI>
                    <TermsLI>
                        Syria
                    </TermsLI>
                    <TermsLI>
                        Syrian Arab Republic
                    </TermsLI>
                    <TermsLI>
                        Venezuela
                    </TermsLI>
                    <TermsLI>
                        Yemen
                    </TermsLI>
                    <TermsLI>
                        Zimbabwe
                    </TermsLI>
                </TermsUL>

                <TermsH3>User Agreement last updated</TermsH3>

                <TermsH3>October 22, 2020</TermsH3>
                
            </TermsInner>
        </TermsWrapper>
    )
}