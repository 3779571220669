import React from "react"
import styled from "styled-components"

const StyledButton = styled.button`
    width: 100%;
    padding: 1rem 2rem 1rem 2rem;
    font-size: 1rem;
    line-height: 1.5rem;
    border: none;
    border-radius: 0.5rem;
    background-color: ${props => props.isActive ? "#3745f5" : "#3745f561"};
    color: ${props => props.isActive ? "#FFFFFF" : "#FFFFFF61"};
    cursor: pointer;
    word-break: keep-all;

    transition: background-color 0.2s, color 0.2s;

    -webkit-appearance: none;
    -moz-appearance: none;
`

const StyledButtonColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const StyledButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const StyledButtonTitle = styled.div`
    font-weight: bold;
`

const StyledButtonSubtitle = styled.div`
    
`

const StyledButtonIconWrapper = styled.div`
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
`

export const Button = ({ title, subtitle, Icon = null, style = {}, onClick, isActive = true }) => {
    return (
        <StyledButton style={style} onClick={onClick} isActive={isActive}>
            <StyledButtonColumn>
                <StyledButtonRow>
                    {Icon && <StyledButtonIconWrapper><Icon /></StyledButtonIconWrapper> }
                    <StyledButtonTitle>{ title }</StyledButtonTitle>
                </StyledButtonRow>
                <StyledButtonRow>
                    <StyledButtonSubtitle>{ subtitle }</StyledButtonSubtitle>
                </StyledButtonRow>
            </StyledButtonColumn>
        </StyledButton>
    )
}