import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `
import FlowStakingCollection from 0xSTAKINGCOLLECTIONADDRESS
import FlowIDTableStaking from 0xIDENTITYTABLEADDRESS
import LockedTokens from 0xLOCKEDTOKENADDRESS

pub struct StakeInfo {
    pub let id: String
    pub let role: UInt8
    pub let networkingAddress: String
    pub let networkingKey: String
    pub let stakingKey: String
    pub let tokensStaked: UFix64
    pub let totalTokensStaked: UFix64
    pub let tokensCommitted: UFix64
    pub let tokensUnstaking: UFix64
    pub let tokensUnstaked: UFix64
    pub let tokensRewarded: UFix64

    pub let delegators: [UInt32]
    pub let delegatorIDCounter: UInt32
    pub let tokensRequestedToUnstake: UFix64
    pub let initialWeight: UInt64

    // Projected Values
    pub let nodeID: String
    pub let unstakableTokens: UFix64
    pub let tokensDelegated: UFix64
    pub let restakableUnstakedTokens: UFix64

    pub let machineAccountAddress: Address?
    pub let machineAccountBalance: UFix64?

    init(nodeInfo: FlowIDTableStaking.NodeInfo, machineAccountInfo: FlowStakingCollection.MachineAccountInfo?) {
        self.id = nodeInfo.id
        self.role = nodeInfo.role
        self.networkingAddress = nodeInfo.networkingAddress
        self.networkingKey = nodeInfo.networkingKey
        self.stakingKey = nodeInfo.stakingKey
        self.tokensStaked = nodeInfo.tokensStaked
        self.totalTokensStaked = nodeInfo.totalStakedWithDelegators()
        self.tokensCommitted = nodeInfo.tokensCommitted
        self.tokensUnstaking = nodeInfo.tokensUnstaking
        self.tokensUnstaked = nodeInfo.tokensUnstaked
        self.tokensRewarded = nodeInfo.tokensRewarded

        self.delegators = nodeInfo.delegators
        self.delegatorIDCounter = nodeInfo.delegatorIDCounter
        self.tokensRequestedToUnstake = nodeInfo.tokensRequestedToUnstake
        self.initialWeight = nodeInfo.initialWeight

        // Projected Values
        self.nodeID = nodeInfo.id
        self.unstakableTokens = self.tokensStaked + self.tokensCommitted
        let nodeStakedBalanceWithDelegators = nodeInfo.totalStakedWithDelegators()
        self.tokensDelegated = nodeStakedBalanceWithDelegators - nodeInfo.tokensStaked
        self.restakableUnstakedTokens = self.tokensUnstaked + self.tokensRequestedToUnstake

        if let _machineAccountInfo = machineAccountInfo {
            let _machineAccountAddress = _machineAccountInfo.getAddress()

            let machineAccount = getAccount(_machineAccountAddress)

            self.machineAccountAddress = _machineAccountAddress
            self.machineAccountBalance = machineAccount.balance
        } else {
            self.machineAccountAddress = nil
            self.machineAccountBalance = nil
        }
    }
}

pub fun main(account: Address): {String: StakeInfo}? {
    let doesAccountHaveStakingCollection = FlowStakingCollection.doesAccountHaveStakingCollection(address: account)
    if (!doesAccountHaveStakingCollection) {
        return nil
    }

    let formattedNodeInfo: {String: StakeInfo} = {}
    let allNodeInfo: [FlowIDTableStaking.NodeInfo] = FlowStakingCollection.getAllNodeInfo(address: account)
    let machineAccounts: {String: FlowStakingCollection.MachineAccountInfo} = FlowStakingCollection.getMachineAccounts(address: account)

    for nodeInfo in allNodeInfo {
        formattedNodeInfo[nodeInfo.id] = StakeInfo(nodeInfo: nodeInfo, machineAccountInfo: machineAccounts[nodeInfo.id])
    }

    return formattedNodeInfo
}
`

export const getAllStakeInfo = async (address) => {
    return await fcl.decode(await fcl.send([
        fcl.script(CODE),
        fcl.args([fcl.arg(address, t.Address)])
    ]))
}
