import React, {useState, useEffect} from "react"
import {Link} from "react-router-dom"
import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import { Transaction } from "../../transaction.js"

import { useFUSDBalance } from "../../../../hooks/use-fusd-balance.js"
import { useDoesAccountHaveFUSDSupport } from "../../../../hooks/use-does-account-have-fusd-support.js"

import {
  template as fusdTransferTemplate,
  CODE as fusdTransferCode,
  HASH as fusdTransferHash,
  DESCRIPTION as fusdTransferDescription,
} from "@onflow/six-fusd-transfer"
import { HASH as fusdSetupHash } from "@onflow/six-fusd-setup"

import { TRANSACTION_MANIFEST_VALUE, TRANSACTION_MANIFEST_ARG } from "../../transaction-manifest-constructors.js"

import {
  invalidAccountError,
  invalidUFix64ArgError,
  sufficientTokensError,
  accountFUSDConfiguredError,
} from "../../transaction-errors.js"

import {
    validateUFix64,
    validateAddress,
} from "../../transaction-validators.js"

import {
    xformUFix64,
    xformAddress,
} from "../../transaction-xforms.js"

export const Manifest = ({ 
  qp,
  hash,
  txn,
  showCode,
  consent,
  qpString,
  submitTransaction,
  submitTransactionLocked,
  currentUserAddr,
  hiddenArgs,
  lockedArgs,
}) => {
  const [manifestValue, setManifestValue] = useState(new TRANSACTION_MANIFEST_VALUE({
    hash: fusdTransferHash,
    title: "Transaction - FUSD Transfer",
    description: fusdTransferDescription,
    code: fusdTransferCode,
    args: {},
    isSix: true,
    transactionFunction: () => {}
  }))

  const { fusdBalance } = useFUSDBalance(currentUserAddr)
  const { doesAccountHaveFUSDSupport } = useDoesAccountHaveFUSDSupport(currentUserAddr)

  const computeManifestValue = async () => {
    setManifestValue(
      new TRANSACTION_MANIFEST_VALUE({
        hash: fusdTransferHash,
        disclaimer: null,
        error: !doesAccountHaveFUSDSupport ? (
          <span>
            Your account is not yet configured for FUSD. Configure your account{" "}
            <Link to={`/transaction?hash=${fusdSetupHash}&showcode=false`}>
              here.
            </Link>
          </span>
        ) : null,
        title: "Transaction - FUSD Transfer",
        description: fusdTransferDescription,
        code: fusdTransferCode,
        args: {
          0: new TRANSACTION_MANIFEST_ARG({
            title: "To",
            qp: "to",
            validate: validateAddress,
            xform: xformAddress,
            placeholder: "0xABC123DEF456",
            description: "The Flow account the FUSD tokens will go to.",
            type: types.Address,
            value: qp.get("to") || "",
            errors: [
              await invalidAccountError(qp.get("to")),
              await accountFUSDConfiguredError(
                qp.get("to")
              ),
            ].filter((w) => w !== null),
          }),
          1: new TRANSACTION_MANIFEST_ARG({
            title: "Amount",
            qp: "amount",
            validate: validateUFix64,
            xform: xformUFix64,
            description: (
              <span>
                {`The amount of FUSD tokens to send.`}
                {fusdBalance ? (
                  <span>
                    {" "}
                    You have <b>{fusdBalance}</b> FUSD
                    tokens to send.
                  </span>
                ) : (
                  ""
                )}
              </span>
            ),
            placeholder: "1.00",
            type: types.UFix64,
            value: qp.get("amount") || "",
            warnings: [].filter((w) => w !== null),
            errors: [
              invalidUFix64ArgError(qp.get("amount")),
              await sufficientTokensError(
                fusdBalance,
                qp.get("amount"),
                "Error: Not enough FUSD tokens available to send. Reduce the amount of tokens to send."
              ),
            ].filter((w) => w !== null),
          }),
        },
        isSix: true,
        transactionFunction: async (hash, txn, args) => {
          return await fcl.decode(
            await fcl.send([
              fusdTransferTemplate({
                proposer: fcl.currentUser().authorization,
                authorization: fcl.currentUser().authorization,
                payer: fcl.currentUser().authorization,
                amount: args[1].xform(args[1].value),
                to: args[0].xform(args[0].value),
              }),
              fcl.limit(9999),
            ])
          )
        },
      })
    )
  }

  useEffect(() => {
    computeManifestValue()
  }, [qpString, fusdBalance, doesAccountHaveFUSDSupport])

  return (
    <Transaction 
      title={manifestValue?.title}
      description={manifestValue?.description}
      disclaimer={manifestValue?.disclaimer}
      error={manifestValue?.error}
      code={manifestValue?.code}
      isSix={manifestValue?.isSix}
      args={manifestValue?.args}
      transactionFunction={manifestValue?.transactionFunction}
      submitTransaction={submitTransaction}
      submitTransactionLocked={submitTransactionLocked}
      consent={consent}
      showCode={showCode}
      hash={hash}
      txn={txn}
      qp={qp}
    />
  )
}
