import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {Link, useLocation} from "react-router-dom"
import * as fcl from "@onflow/fcl"
import {
    FaBars,
    FaTimes,
    FaDiscord,
    FaYoutube,
    FaTwitter,
    FaGithub,
} from "react-icons/fa"
import {getTotalFlowBalance} from "../interactions/get-total-flow-balance.js"
import {useTotalFlowBalance} from "../hooks/use-total-flow-balance.js"
import {screenMinWidth} from "../mixins/breakpoints.js"
import {UserBalancesChip} from "../components/user-balances-chip.js"
import { NetworkStatus } from './network-status.js'
import { MAINNET } from '../global/constants.js'
import { LogoWithNetwork } from './Logo.js'
import { useNetwork } from '../hooks/use-network.js'

const StyledHeader = styled.header`
    height: 100px;
    padding: 0rem 2rem 0rem 2rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    box-sizing: border-box;
`

const StyledHeaderLogoWrapper = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #2a2825;
    display: block;

    ${screenMinWidth.sm`
        display: none;
    `}
`

const StyledHeaderLogo = styled.img`
    margin-right: 0.5rem;
    height: 3rem;
`

const StyledHeaderLinks = styled.div`
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: white;
    padding: 0.5rem;
    border: 0.1rem solid #E8E8E8;
    border-radius: 1rem;
    display: none;

    ${screenMinWidth.sm`
        display: flex;
    `}
`

const StyledHeaderLink = styled.a`
    margin-right: 1rem;
    margin-left: 1rem;
    text-decoration: none;
    color: #6a6a6a; 
    -webkit-appearance: none;
    -moz-appearance: none;
    transform: translateY(2.5px);
`

const StyledHeaderSidebarActionsWrapper = styled.div``

const StyledFaTimes = styled(FaTimes)`
    height: 2rem;
    display: block;
    cursor: pointer;

    ${screenMinWidth.sm`
        display: none;
    `}
`

const StyledFaBars = styled(FaBars)`
    height: 2rem;
    display: block;
    cursor: pointer;

    ${screenMinWidth.sm`
        display: none;
    `}
`


const StyledHeaderUserWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: none;

    ${screenMinWidth.sm`
        display: flex;
        align-items: center;
    `}
`

export const Header = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const location = useLocation()
    const [currentUserAddr, setCurrentUserAddr] = useState(null)
    const { totalFlowBalance, refresh: refreshTotalFlowBalance } = useTotalFlowBalance(currentUserAddr, { lazyLoad: true })
    const {network } = useNetwork()

    useEffect(() => {
        fcl.currentUser().subscribe(currentUser => setCurrentUserAddr(currentUser.addr ? fcl.withPrefix(currentUser.addr) : null))
    }, [])

    useEffect(() => {
        (async function() {
            if (currentUserAddr) {
                refreshTotalFlowBalance()
            }
        })()
    }, [currentUserAddr, location.pathname])

    return (
        <StyledHeader>
            <StyledHeaderLogoWrapper to="/">
                { !isSidebarOpen && <LogoWithNetwork />}
            </StyledHeaderLogoWrapper>
            <StyledHeaderSidebarActionsWrapper>
                { !isSidebarOpen && <StyledFaBars onClick={() => setIsSidebarOpen(true)} /> }
            </StyledHeaderSidebarActionsWrapper>
            {network === MAINNET && <NetworkStatus />}
            <StyledHeaderLinks>
                <StyledHeaderLink href="https://onflow.org/discord" target="_blank"><FaDiscord /></StyledHeaderLink>
                <StyledHeaderLink href="https://www.twitter.com/flow_blockchain" target="_blank"><FaTwitter /></StyledHeaderLink>
                <StyledHeaderLink href="https://www.youtube.com/channel/UCs9r5lqmYQsKCpLB9jKwocg" target="_blank"><FaYoutube /></StyledHeaderLink>
                <StyledHeaderLink href="https://www.github.com/onflow" target="_blank"><FaGithub /></StyledHeaderLink>
            </StyledHeaderLinks>
            <StyledHeaderUserWrapper>                
                <UserBalancesChip currentUserAddr={currentUserAddr} flowBalance={totalFlowBalance} fatBalance={null} />
            </StyledHeaderUserWrapper>
        </StyledHeader>
    )
}