import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import * as fcl from "@onflow/fcl"
import { HASH as transferFlowTokenHash } from "@onflow/six-transfer-tokens"
import { HASH as addNewKeyHash } from "@onflow/six-add-new-key"
import { HASH as createAccountHash } from "@onflow/six-create-account"
import {
    Link,
    useLocation
} from "react-router-dom"
import { screenMinWidth } from "../mixins/breakpoints.js"
import { FaTimes, FaUser, FaKey } from "react-icons/fa"
import { GetFlowIcon } from "../assets/get-flow-icon.js"
import { LogoutIcon } from "../assets/logout-icon.js"
import { DashboardIcon } from "../assets/dashboard-icon.js"
import { BalancesIcon } from "../assets/balances-icon.js"
import { SendFlowIcon } from "../assets/send-flow-icon.js"
import { StakeDelegateIcon } from "../assets/stake-delegate-icon.js"
import { LinkArrow } from "../assets/link-arrow.js"
import { NetworkPicker } from './Network-picker.js'
import { LogoWithNetwork } from './Logo.js'
import { CollapsibleSection } from './CollapsibleSection.js'

const StyledSidebar = styled.div`
    height: 100%;
    padding: 0rem 2rem 1rem 2rem;

    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
    
    border-right: 0.1rem solid #E8E8E8;

    box-sizing: border-box;

    background-color: white;
`

const StyledHeaderActionsWrapper = styled.div``

const StyledFaTimes = styled(FaTimes)`
    height: 2rem;
    display: block;
    cursor: pointer;

    ${screenMinWidth.sm`
        display: none;
    `}
`

const StyledHeaderLogoWrapper = styled(Link)`
margin-top: 1rem;

`

const StyledSidebarTop = styled.div`
    display: grid;
    grid-gap: 1rem;
    align-items: flex-start;
    justify-content: flex-start;

    box-sizing: border-box; 
`

const StyledSidebarTopRow = styled.div`
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E8E8E8;
`

const StyledSidebarBottom = styled.div`
    padding-top: 1rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    box-sizing: border-box;
    border-top: 1px solid #E8E8E8;
`

const StyledSidebarItem = styled(Link)`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    text-decoration: none;
    color: ${props => props.isActive ? "#2a2825" : "#6a6a6a"};
    font-weight: ${props => props.isActive ? "bold" : "normal"};

    transition: margin-left 0.2s, color 0.2s, font-weight 0.2s;
`

const StyledSidebarItemIcon = styled.div`
    margin-right: 1rem;
    height: 0.75rem;
    width: 0.75rem;
    color: ${props => props.isActive ? "#2a2825" : "#6a6a6a"};
    z-index: 2;
    transform: translate(0%, -25%);

    svg {
        path {
            fill: ${props => props.isActive ? "#2a2825" : "#6a6a6a"};    
        }
    }
`

const StyledSidebarAttribution = styled.span`
    font-size: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-decoration: none;
    color: #6a6a6a;
    font-weight: normal;
    line-height: 0.5rem;
`

const StyledSidebarLink = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-decoration: none;
    color: #6a6a6a;
    font-weight: normal;
`

const StyledAdvancedSection = styled.div`
    display: grid;
    grid-gap: 0.5rem;
    margin: 0.5rem;
    max-width: 200px;
`

export const Sidebar = ({ setIsSidebarOpen, isSidebarOpen }) => {
    const [currentUserAddr, setCurrentUserAddr] = useState(null)

    const location = useLocation()
    const fullLocation = location.pathname + location.search

    useEffect(() => {
        fcl.currentUser().subscribe(currentUser => setCurrentUserAddr(currentUser.addr ? fcl.withPrefix(currentUser.addr) : null))
    }, [])
    
    const closeSidebar = () => {
        setIsSidebarOpen(false)
    }

    return (
        <StyledSidebar>
            <StyledSidebarTop>
                <StyledSidebarTopRow>
                    <StyledHeaderLogoWrapper to="/">
                        <LogoWithNetwork medium short />
                    </StyledHeaderLogoWrapper>
                    <StyledHeaderActionsWrapper>
                        {!isSidebarOpen && <StyledFaTimes onClick={closeSidebar} />}
                    </StyledHeaderActionsWrapper>
                </StyledSidebarTopRow>
                {currentUserAddr && <StyledSidebarItem onClick={closeSidebar} isActive={fullLocation.includes("/account")} to={`/account/${currentUserAddr ? fcl.withPrefix(currentUserAddr) : ""}`}><StyledSidebarItemIcon isActive={fullLocation.includes("/account")}><DashboardIcon /></StyledSidebarItemIcon>Dashboard</StyledSidebarItem>}
                {currentUserAddr && <StyledSidebarItem onClick={closeSidebar} isActive={fullLocation.includes("/balances")} to={`/balances/${currentUserAddr ? fcl.withPrefix(currentUserAddr) : ""}`}><StyledSidebarItemIcon isActive={fullLocation.includes("/balances")}><BalancesIcon /></StyledSidebarItemIcon>Balances</StyledSidebarItem>}
                {currentUserAddr && <StyledSidebarItem onClick={closeSidebar} isActive={fullLocation.includes("stake-delegate")} to={`/stake-delegate/${currentUserAddr ? fcl.withPrefix(currentUserAddr) : ""}`}><StyledSidebarItemIcon isActive={fullLocation.includes("/stake-delegate")}><StakeDelegateIcon /></StyledSidebarItemIcon>Stake & Delegate</StyledSidebarItem>}
                <StyledSidebarItem onClick={closeSidebar} isActive={fullLocation.includes(`/get-flow`)} to="/get-flow"><StyledSidebarItemIcon isActive={fullLocation.includes(`/get-flow`)}><GetFlowIcon /></StyledSidebarItemIcon>Get FLOW</StyledSidebarItem>
                {process.env.REACT_APP_ENABLE_SEND_FLOW === "true" && <StyledSidebarItem onClick={closeSidebar} isActive={fullLocation.includes(`/transaction?hash=${transferFlowTokenHash}`)} to={`/transaction?hash=${transferFlowTokenHash}&showcode=false`}><StyledSidebarItemIcon isActive={fullLocation.includes(`/transaction?hash=${transferFlowTokenHash}`)}><SendFlowIcon /></StyledSidebarItemIcon>Send FLOW</StyledSidebarItem>}
            </StyledSidebarTop>
            <StyledSidebarBottom>
                <CollapsibleSection label="Advanced">
                    <StyledAdvancedSection>
                        <StyledSidebarItem isActive={fullLocation.includes(createAccountHash)} to={`/transaction?hash=${createAccountHash}&showcode=false`}>
                            <StyledSidebarItemIcon isActive={fullLocation.includes(createAccountHash)}><FaUser /></StyledSidebarItemIcon>
                            Create New Account
                        </StyledSidebarItem>
                        <StyledSidebarItem isActive={fullLocation.includes(addNewKeyHash)} to={`/transaction?hash=${addNewKeyHash}&showcode=false`}>
                            <StyledSidebarItemIcon isActive={fullLocation.includes(addNewKeyHash)}><FaKey /></StyledSidebarItemIcon>
                            Add New Key
                        </StyledSidebarItem>
                        <NetworkPicker />
                    </StyledAdvancedSection>
                </CollapsibleSection>
                <StyledSidebarLink target="_blank" href="https://docs.onflow.org/flow-port/"><StyledSidebarItemIcon style={{ height: "1rem" }}><LinkArrow /></StyledSidebarItemIcon>Learn More</StyledSidebarLink>
                {currentUserAddr && <StyledSidebarItem to="/" onClick={fcl.unauthenticate}><StyledSidebarItemIcon><LogoutIcon /></StyledSidebarItemIcon>Logout</StyledSidebarItem>}
                <StyledSidebarAttribution>Designed by Dapper Labs</StyledSidebarAttribution>
            </StyledSidebarBottom>
        </StyledSidebar>
    )
}