import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `
import FlowEpoch from 0xEPOCHADDRESS

pub fun main(): UInt64 {
    return FlowEpoch.currentEpochCounter
}`

export const getEpochCurrentCounter = async () => {
    return await fcl.decode(await fcl.send([
        fcl.script(CODE),
    ]))
}
