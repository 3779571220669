import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `
import FlowStakingCollection from 0xSTAKINGCOLLECTIONADDRESS

/// Determines if an account is set up with a Staking Collection

pub fun main(address: Address): Bool {
    return FlowStakingCollection.doesAccountHaveStakingCollection(address: address)
}
`

export const doesAcctHaveStakingCollectionSupport = async (address) => {
    return await fcl.decode(await fcl.send([
        fcl.script(CODE),
        fcl.args([fcl.arg(address, t.Address)])
    ]))
}
