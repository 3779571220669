import React, {useState, useEffect} from "react"
import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import { Transaction } from "../transaction.js"

import {
  template as addNewKeyTemplate,
  CODE as addNewKeyCode,
  HASH as addNewKeyHash,
  DESCRIPTION as addNewKeyDescription,
} from "@onflow/six-add-new-key"

import { 
  TRANSACTION_MANIFEST_VALUE,
  TRANSACTION_MANIFEST_ARG,
  TRANSACTION_MANIFEST_ARG_INPUT_TYPES,
} from "../transaction-manifest-constructors.js"

import {
  validateString,
  validateUInt8,
  validateUFix64
} from "../transaction-validators.js"

import {
  validKeyWeightError,
} from "../transaction-errors.js"

import {
  xformString,
  xformUInt8,
  xformUFix64
} from "../transaction-xforms.js"

export const Manifest = ({ 
  qp,
  hash,
  txn,
  showCode,
  consent,
  qpString,
  submitTransaction,
  submitTransactionLocked,
  currentUserAddr,
  hiddenArgs,
  lockedArgs,
}) => {
  const [currentUser, setCurrentUser] = useState(null)

  const [manifestValue, setManifestValue] = useState(new TRANSACTION_MANIFEST_VALUE({
    hash: addNewKeyHash,
    title: "Transaction - Add New Key",
    description: addNewKeyDescription,
    code: addNewKeyCode,
    args: {},
    isSix: true,
    transactionFunction: () => {}
  }))

  useEffect(() => fcl.currentUser().subscribe(setCurrentUser), [])

  const isLedgerUser = currentUser?.services[0]?.uid === "fcl-ledger-authz"

  const computeManifestValue = async () => {
    setManifestValue(
      new TRANSACTION_MANIFEST_VALUE({
        hash: addNewKeyHash,
        disclaimer: <span>For supported Signature & Hash Algorithms, see <a href="https://docs.onflow.org/cadence/language/crypto/" target="_blank">here</a></span>,
        error: isLedgerUser ? <span>This transaction is not available for Ledger users at this time. Ledger support coming soon.</span> : null,
        title: "Transaction - Add New Key",
        description: addNewKeyDescription,
        code: addNewKeyCode,
        args: {
          0: new TRANSACTION_MANIFEST_ARG({
            title: "Public Key",
            qp: "pk",
            xform: xformString,
            validate: validateString,
            type: types.String,
            placeholder: "Public Key",
            description: "The new Public Key to add to the Flow account.",
            value: qp.get("pk") || "",
          }),
          1: new TRANSACTION_MANIFEST_ARG({
            title: "Signature Algorithm",
            qp: "salg",
            xform: xformUInt8,
            validate: validateString,
            type: types.String,
            inputType: TRANSACTION_MANIFEST_ARG_INPUT_TYPES.SELECT,
            options: {
              1: {
                title: "ECDSA_P256",
                value: 1
              },
              2: {
                title: "ECDSA_secp256k1",
                value: 2
              }
            },
            placeholder: "ECDSA_P256",
            description: "The signature algorithm of the new Public Key to add to the Flow account.",
            value: qp.get("salg") || "1",
          }),
          2: new TRANSACTION_MANIFEST_ARG({
            title: "Hash Algorithm",
            qp: "halg",
            xform: xformUInt8,
            validate: validateString,
            type: types.String,
            inputType: TRANSACTION_MANIFEST_ARG_INPUT_TYPES.SELECT,
            options: {
              1: {
                title: "SHA2_256",
                value: 1
              },
              2: {
                title: "SHA3_256",
                value: 3
              },
            },
            placeholder: "1",
            description: "The hash algorithm of the new Public Key to add to the Flow account.",
            value: qp.get("halg") || "1",
          }),
          3: new TRANSACTION_MANIFEST_ARG({
            title: "Weight",
            qp: "weight",
            xform: xformUFix64,
            validate: validateUFix64,
            placeholder: "1000",
            type: types.String,
            description: "The weight of the new Public Key to add to the Flow account.",
            value: qp.get("weight") || "",
            errors: [
              validKeyWeightError(qp.get("weight"))
            ].filter(e => e !== null)
          }),
        },
        isSix: true,
        transactionFunction: async (hash, txn, args) => {
          return await fcl.decode(
            await fcl.send([
              addNewKeyTemplate({
                proposer: fcl.currentUser().authorization,
                authorization: fcl.currentUser().authorization,
                payer: fcl.currentUser().authorization,
                publicKey: args[0].xform(args[0].value),
                signatureAlgorithm: Number(args[1].xform(args[1].value)),
                hashAlgorithm: Number(args[2].xform(args[2].value)),
                weight: args[3].xform(args[3].value)
              }),
              fcl.limit(9999),
            ])
          )
        },
      })
    )
  }

  useEffect(() => {
    computeManifestValue()
  }, [qpString, currentUser])

  return (
    <Transaction 
      title={manifestValue?.title}
      description={manifestValue?.description}
      disclaimer={manifestValue?.disclaimer}
      error={manifestValue?.error}
      code={manifestValue?.code}
      isSix={manifestValue?.isSix}
      args={manifestValue?.args}
      transactionFunction={manifestValue?.transactionFunction}
      submitTransaction={submitTransaction}
      submitTransactionLocked={submitTransactionLocked}
      consent={consent}
      showCode={showCode}
      hash={hash}
      txn={txn}
      qp={qp}
    />
  )
}
