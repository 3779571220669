import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FUSD from 0xFUSDADDRESS

pub fun main(address: Address): Bool {
    let account = getAccount(address)

    let fusdBalanceCap = account
      .getCapability
      <&FUSD.Vault{FungibleToken.Balance}>
      (/public/fusdBalance)

    if fusdBalanceCap == nil || !(fusdBalanceCap!.check()) {
        return false
    }
    return true
}
`

export const doesAcctHaveFUSDSupport = async (address) => {
    return await fcl.decode(await fcl.send([
        fcl.script(CODE),
        fcl.args([fcl.arg(address, t.Address)])
    ]))
}
