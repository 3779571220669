import React, { useState } from "react";
import styled from 'styled-components'
import { FaCog } from "react-icons/fa"
import { useSpring, animated, config } from 'react-spring'

const StyledCollapsibleSection = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
`

const StyledChildrenContainer = styled(animated.div)`
    display: ${({ visible }) => visible ? "flex" : "none"};
    flex-direction: column;
    transition: margin-left 0.2s, color 0.2s, font-weight 0.2s;

`
const StyledHeader = styled.div`
    display: flex;
    margin: 0.25rem 0;
`
const StyledGear = styled(FaCog)`
    margin-right: 1rem;
    fill: var(--text-normal);
`
const StyledLabel = styled.span`
    transform: translate(0%, -25%);
    font-weight: 400;
    font-size: 1rem;
    color: var(--text-normal);
    align-items: flex-start;
    cursor: pointer;
    height: 0.75rem;
    width: 0.75rem;
    color: ${({ isOpen }) => isOpen ? "#2a2825" : "#6a6a6a"};
`

export const CollapsibleSection = ({ label = "Section", children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const advancedSectionTransitions = useSpring({
        to: {
            opacity: isOpen ? 1 : 0,
            transform: isOpen ? "translateY(0%)" : "translateY(4%)",
            display: isOpen ? "block" : "none",
        },
        config: config.slow,
    })

    return (
        <StyledCollapsibleSection>
            <StyledHeader>
                <StyledGear />
                <StyledLabel onClick={toggle}>{label}</StyledLabel>
            </StyledHeader>
            <StyledChildrenContainer visible={isOpen} style={advancedSectionTransitions}>
                {children}
            </StyledChildrenContainer>
        </StyledCollapsibleSection>
    )
}