import {useEffect} from "react"
import * as fcl from "@onflow/fcl"
import {atomFamily, selectorFamily, useRecoilState, useSetRecoilState} from "recoil"
import {getBlockAtId} from "../interactions/get-block-at-id.js"
import {INIT, INIT_PROCESSING, ERROR, IDLE, PROCESSING} from "../global/constants.js"

export const valueAtom = atomFamily({
    key: "get-block-at-id::state",
    default:  null
})

export const statusAtom = atomFamily({
    key: "get-block-at-id::status",
    default: INIT,
})

export function useBlockAtId(blockId) {
    const [block, setBlock] = useRecoilState(valueAtom(blockId))
    const [status, setStatus] = useRecoilState(statusAtom(blockId))

    async function refresh() {
        try {
            setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
            await getBlockAtId(blockId).then(setBlock)
            setStatus(IDLE)
        } catch (e) {
            setStatus(ERROR)
        }
    }

    useEffect(() => {
        async function load({ blockId }) {
            if (blockId) {
                try {
                    setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
                    await getBlockAtId(blockId).then(_block => {
                        setBlock(_block)
                        setStatus(IDLE)
                    })
                } catch (e) {
                    setStatus(ERROR)
                }
            }
        }
        load({ blockId })
    }, [blockId])

    return {
        block,
        status,
        fetch: refresh,
        refresh
    }
}