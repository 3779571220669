import React from "react";

export const Unlock = () => {
    return (
        <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 12C4.5 13.4587 5.07946 14.8576 6.11091 15.8891C7.14236 16.9205 8.54131 17.5 10 17.5C11.4587 17.5 12.8576 16.9205 13.8891 15.8891C14.9205 14.8576 15.5 13.4587 15.5 12C15.5 10.5413 14.9205 9.14236 13.8891 8.11091C12.8576 7.07946 11.4587 6.5 10 6.5C8.54131 6.5 7.14236 7.07946 6.11091 8.11091C5.07946 9.14236 4.5 10.5413 4.5 12V12Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.75 12C7.75 12.5967 7.98705 13.169 8.40901 13.591C8.83097 14.0129 9.40326 14.25 10 14.25C10.5967 14.25 11.169 14.0129 11.591 13.591C12.0129 13.169 12.25 12.5967 12.25 12C12.25 11.4033 12.0129 10.831 11.591 10.409C11.169 9.98705 10.5967 9.75 10 9.75C9.40326 9.75 8.83097 9.98705 8.40901 10.409C7.98705 10.831 7.75 11.4033 7.75 12Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10"/>
            <path d="M7 7.5V4.5C7 3.70435 6.68393 2.94129 6.12132 2.37868C5.55871 1.81607 4.79565 1.5 4 1.5C3.20435 1.5 2.44129 1.81607 1.87868 2.37868C1.31607 2.94129 1 3.70435 1 4.5V6.75667" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}