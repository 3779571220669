import {useEffect} from "react"
import * as fcl from "@onflow/fcl"
import {atomFamily, selectorFamily, useRecoilState, useSetRecoilState} from "recoil"
import {getEpochCurrentView} from "../interactions/get-epoch-current-view.js"
import {INIT, INIT_PROCESSING, IDLE, PROCESSING, ERROR} from "../global/constants.js"

export const valueAtom = atomFamily({
    key: "epoch-current-view::state",
    default: null
})

export const statusAtom = atomFamily({
    key: "epoch-current-view::status",
    default: INIT,
})

export function useEpochCurrentView() {
    const [epochCurrentView, setEpochCurrentView] = useRecoilState(valueAtom("current"))
    const [status, setStatus] = useRecoilState(statusAtom("current"))

    async function refresh() {
        try {
            setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
            await getEpochCurrentView().then(setEpochCurrentView)
            setStatus(IDLE)
        } catch (e) {
            setStatus(ERROR)
        }
    }

    useEffect(() => {
        async function load() {
            try {
                setStatus(status === INIT ? INIT_PROCESSING : PROCESSING)
                await getEpochCurrentView().then(_ecv => {
                    setEpochCurrentView(_ecv)
                    setStatus(IDLE)
                })
            } catch (e) {
                setStatus(ERROR)
            }
        }
        load()
    }, [])

    return {
        epochCurrentView,
        status,
        fetch: refresh,
        refresh
    }
}