import React from "react"
import styled from "styled-components"
import * as fcl from "@onflow/fcl"
import {StakeDelegateIcon} from "../assets/stake-delegate-icon.js"
import {HashLink} from "react-router-hash-link"
import Clock from "../assets/clock.svg"
import stakingBubbleIcon from "../assets/staking-bubble-icon.png"
import {screenMinWidth} from "../mixins/breakpoints.js"

const SummaryStakeCardWrapper = styled.div`
    position: relative;
    background-color: white;
    border-radius: 0.5rem;
    background: white;
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

const SummaryStakeCardTopRow = styled.div`
    padding: 1rem 1rem 0.25rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    div:last-child {
        margin-bottom: 0;
    }
`

const SummaryStakeCardIconWrapper = styled.div`
    height: 1rem;
    width: auto;
    svg {
        path {
            fill: #2a2825;
        }
    }
` 

const SummaryStakeCardTitleRow = styled.div`
    padding: 0.5rem 1rem 0rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    div:last-child {
        margin-bottom: 0;
    }
`

const SummaryStakeCardStartStakingLink = styled(HashLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: fit-content;
    box-sizing: border-box;
    background-color: #3745f5;
    border-radius: 1rem;
    padding: 0.5rem 2rem 0.5rem 2rem;
    font-size: 1rem;
    line-height: 1rem;
    color: white;
    cursor: pointer;
    text-decoration: none;
`

const SummaryStakeCardTopLink = styled(HashLink)`
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 0.5rem;
    border: 0.1rem solid #3745f5;
    border-radius: 1rem;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #3745f5;
    cursor: pointer;
    text-decoration: none;

    transition: background-color 0.2s;

    &:hover {
        background-color: #3745f5;
        color: white;
    }
`

const SummaryStakeCardRow = styled.div`
    margin: 1rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    div:last-child {
        margin-bottom: 0;
    }
`

const SummaryStakeCardTitle = styled.div`
    position: relative;
    margin-bottom: 0.5rem;

    text-decoration: none;
    font-weight: bold;

    display: flex;
    flex-direction: row;
    align-items: center;

    &:after {
        position: absolute;
        opacity: 0.5;
        content: "";
        display: block;
        left: 0rem;
        top: 1.5rem;
        bottom: calc(1rem - 3px - 6px);
        background: #00ff76;
        height: 3px;
        width: 1rem;
        border-radius: 3px;    
    }
`

const SummaryStakeCardRowStakeInProgress = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    
    background-color: #00ef8c;
    color: black;
    border-bottom: none;
`

const SummaryStakeCardRowUnstakeInProgress = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    
    background-color: #f4c6fa;
    color: black;
    border-bottom: none;
`

const SummaryStakeCardRowInProgressLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const SummaryStakeCardRowInProgressRight = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
    font-size: 0.75rem;
`

const SummaryStakeCardSection = styled.div`
    margin-bottom: 2rem;   
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;

    ${screenMinWidth.sm`
        margin-bottom: 0rem;
        min-width: calc(50% - 0.5rem);
    `}
`

const SummaryStakeCardSectionCentered = styled(SummaryStakeCardSection)`
    width: 100%;
    justify-content: center;
    align-items: center;
`

const SummaryStakeCardSectionTitleSubtitle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

const SummaryStakeCardSectionTitleSubtitleCentered = styled(SummaryStakeCardSectionTitleSubtitle)`
    align-items: center;
    font-weight: normal;
`

const SummaryStakeCardSectionTitle = styled.div`
    position: relative;
    font-size: 1rem;
    color: black;
    font-weight: bold;
    text-align: left;
`

const SummaryStakeCardSectionTitleCentered = styled(SummaryStakeCardSectionTitle)`
    text-align: center;
    font-weight: normal;
`

const SummaryStakeCardSectionStakingIcon = styled.img`
    height: 2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
`

const SummaryStakeCardSectionSubitle = styled.div`
    color: #808080;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
`

const SummaryStakeCardSectionFlow = styled.div`
    margin-left: 0.25rem;
    line-height: 1rem;
    font-size: 0.5rem;
    font-weight: bold;
`

const SummaryStakeCardRowInProgressImg = styled.img`
    margin-right: 0.5rem;
    height: 1rem;
`

const SummaryStakeCardRowInProgressTitle = styled.div`
    font-size: 0.75rem;
    margin-right: 1rem;
    color: black;
`

const SummaryStakeCardRowInProgressAmount = styled.div`
    margin-right: 1rem;
    font-size: 0.75rem;
    color: black;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const SummaryStakeDelegateCard = ({currentUserAddr = "", summaryStakeDelegateInfo = null, showStakeSummary = false, showDelegateSummary = false}) => {

    const showManageStakeDelegateLink = summaryStakeDelegateInfo && (showStakeSummary || showDelegateSummary)
    const showSummaryStakeDelegateTitleRow = summaryStakeDelegateInfo && (
        (showStakeSummary && summaryStakeDelegateInfo.nodeCount > 0) ||
        (showDelegateSummary && summaryStakeDelegateInfo.delegateCount > 0) ||  
        (!showStakeSummary && !showDelegateSummary && (summaryStakeDelegateInfo.nodeCount > 0 || summaryStakeDelegateInfo.delegateCount > 0))
    )

    const showStartStakingAndDelegatingSection = summaryStakeDelegateInfo && !showStakeSummary && !showDelegateSummary && summaryStakeDelegateInfo.nodeCount === 0 && summaryStakeDelegateInfo.delegateCount === 0
    const showStartStakingSection = summaryStakeDelegateInfo && showStakeSummary && summaryStakeDelegateInfo.nodeCount === 0
    const showStartDelegatingSection = summaryStakeDelegateInfo && showDelegateSummary && summaryStakeDelegateInfo.delegateCount === 0

    const showStakedAmountSection = summaryStakeDelegateInfo && (
        (showStakeSummary && summaryStakeDelegateInfo.nodeCount > 0) ||
        (showDelegateSummary && summaryStakeDelegateInfo.delegateCount > 0) ||
        (!showStakeSummary && !showDelegateSummary && (summaryStakeDelegateInfo.delegateCount > 0 || summaryStakeDelegateInfo.nodeCount > 0))
    )

    const stakedAmount = (() => {
        if (!showStakeSummary && !showDelegateSummary) {
            if (Number(summaryStakeDelegateInfo.totalTokensStaked) === 0 && Number(summaryStakeDelegateInfo.totalTokensCommitted) !== 0) return "Pending"
            if (Number(summaryStakeDelegateInfo.totalTokensStaked) === 0 && Number(summaryStakeDelegateInfo.totalTokensRequestedToUnstake) !== 0) return "Pending"
            return <>{summaryStakeDelegateInfo.totalTokensStaked}<SummaryStakeCardSectionFlow>FLOW</SummaryStakeCardSectionFlow></>
        } else if (showStakeSummary) {
            if (Number(summaryStakeDelegateInfo.stakeTokensStaked) === 0 && Number(summaryStakeDelegateInfo.stakeTokensCommitted) !== 0) return "Pending";
            if (Number(summaryStakeDelegateInfo.stakeTokensStaked) === 0 && Number(summaryStakeDelegateInfo.stakeTokensRequestedToUnstake) !== 0) return "Pending"
            return <>{summaryStakeDelegateInfo.stakeTokensStaked}<SummaryStakeCardSectionFlow>FLOW</SummaryStakeCardSectionFlow></>
        } else {
            if (Number(summaryStakeDelegateInfo.delegateTokensStaked) === 0 && Number(summaryStakeDelegateInfo.delegateTokensCommitted) !== 0) return "Pending"
            if (Number(summaryStakeDelegateInfo.delegateTokensStaked) === 0 && Number(summaryStakeDelegateInfo.delegateTokensRequestedToUnstake) !== 0) return "Pending"
            return <>{summaryStakeDelegateInfo.delegateTokensStaked}<SummaryStakeCardSectionFlow>FLOW</SummaryStakeCardSectionFlow></>
        }
    })()

    const rewardAmount = (() => {
        if (!showStakeSummary && !showDelegateSummary) {
            return <>{summaryStakeDelegateInfo.totalTokensRewarded}<SummaryStakeCardSectionFlow>FLOW</SummaryStakeCardSectionFlow></>
        } else if (showStakeSummary) {
            return <>{summaryStakeDelegateInfo.stakeTokensRewarded}<SummaryStakeCardSectionFlow>FLOW</SummaryStakeCardSectionFlow></>
        } else {
            return <>{summaryStakeDelegateInfo.delegateTokensRewarded}<SummaryStakeCardSectionFlow>FLOW</SummaryStakeCardSectionFlow></>
        }
    })()

    const showTotalTokensCommitted = summaryStakeDelegateInfo && (
        (!showStakeSummary && !showDelegateSummary && (Number(summaryStakeDelegateInfo.totalTokensCommitted) > 0)) || 
        (summaryStakeDelegateInfo && showStakeSummary && (Number(summaryStakeDelegateInfo.stakeTokensCommitted) > 0)) ||
        (summaryStakeDelegateInfo && showDelegateSummary && (Number(summaryStakeDelegateInfo.delegateTokensCommitted)) > 0)
    )
    const totalTokensCommittedAmount = (() => {
        if (summaryStakeDelegateInfo && !showStakeSummary && !showDelegateSummary) {
            return summaryStakeDelegateInfo.totalTokensCommitted
        } else if (summaryStakeDelegateInfo && showStakeSummary) {
            return summaryStakeDelegateInfo.stakeTokensCommitted
        } else if (summaryStakeDelegateInfo && showDelegateSummary) {
            return summaryStakeDelegateInfo.delegateTokensCommitted
        } else {
            return null
        }
    })()

    const showTotalTokensUnstaking = summaryStakeDelegateInfo && (
        (!showStakeSummary && !showDelegateSummary && (Number(summaryStakeDelegateInfo.totalTokensUnstaking) > 0)) || 
        (summaryStakeDelegateInfo && showStakeSummary && (Number(summaryStakeDelegateInfo.stakeTokensUnstaking) > 0)) ||
        (summaryStakeDelegateInfo && showDelegateSummary && (Number(summaryStakeDelegateInfo.delegateTokensUnstaking)) > 0)
    )
    const totalTokensUnstakingAmount = (() => {
        if (summaryStakeDelegateInfo && !showStakeSummary && !showDelegateSummary) {
            return summaryStakeDelegateInfo.totalTokensUnstaking
        } else if (summaryStakeDelegateInfo && showStakeSummary) {
            return summaryStakeDelegateInfo.stakeTokensUnstaking
        } else if (summaryStakeDelegateInfo && showDelegateSummary) {
            return summaryStakeDelegateInfo.delegateTokensUnstaking
        } else {
            return null
        }
    })()

    const showTotalTokensRequestedToUnstake = summaryStakeDelegateInfo && (
        (!showStakeSummary && !showDelegateSummary && (Number(summaryStakeDelegateInfo.totalTokensRequestedToUnstake) > 0)) || 
        (summaryStakeDelegateInfo && showStakeSummary && (Number(summaryStakeDelegateInfo.stakeTokensRequestedToUnstake) > 0)) ||
        (summaryStakeDelegateInfo && showDelegateSummary && (Number(summaryStakeDelegateInfo.delegateTokensRequestedToUnstake)) > 0)
    )
    const totalTokensRequestedToUnstakeAmount = (() => {
        if (summaryStakeDelegateInfo && !showStakeSummary && !showDelegateSummary) {
            return summaryStakeDelegateInfo.totalTokensRequestedToUnstake
        } else if (summaryStakeDelegateInfo && showStakeSummary) {
            return summaryStakeDelegateInfo.stakeTokensRequestedToUnstake
        } else if (summaryStakeDelegateInfo && showDelegateSummary) {
            return summaryStakeDelegateInfo.delegateTokensRequestedToUnstake
        } else {
            return null
        }
    })()

    return (
        <SummaryStakeCardWrapper>

            { showSummaryStakeDelegateTitleRow &&
                <>
                    <SummaryStakeCardTopRow>
                        <SummaryStakeCardIconWrapper><StakeDelegateIcon /></SummaryStakeCardIconWrapper>
                    </SummaryStakeCardTopRow>

                    <SummaryStakeCardTitleRow>
                        <SummaryStakeCardTitle>{showDelegateSummary ? "Delegation" : "Staking"} Summary</SummaryStakeCardTitle>
                        { showManageStakeDelegateLink &&
                            <SummaryStakeCardTopLink to={`/stake-delegate/${fcl.withPrefix(currentUserAddr)}${showStakeSummary ? "#nodes" : (showDelegateSummary ? "#delegations" : "")}`}>Manage {showDelegateSummary ? "Delegations" : "Staking"}</SummaryStakeCardTopLink>
                        }
                    </SummaryStakeCardTitleRow>
                </>
            }
            
            <SummaryStakeCardRow>

                { showStartStakingAndDelegatingSection &&
                    <SummaryStakeCardSectionCentered>
                        <SummaryStakeCardSectionTitleSubtitleCentered>
                            <SummaryStakeCardSectionStakingIcon src={stakingBubbleIcon}/>
                            <SummaryStakeCardSectionTitleCentered>Participate and help secure Flow's network by staking a node or delegating.</SummaryStakeCardSectionTitleCentered>
                            <SummaryStakeCardStartStakingLink to={`/stake-delegate/${fcl.withPrefix(currentUserAddr)}#stakedelegateoptions`}>Start Staking or Delegating</SummaryStakeCardStartStakingLink>
                        </SummaryStakeCardSectionTitleSubtitleCentered>
                    </SummaryStakeCardSectionCentered>
                }

                { showStartStakingSection &&
                    <SummaryStakeCardSectionCentered>
                        <SummaryStakeCardSectionTitleSubtitleCentered>
                            <SummaryStakeCardSectionStakingIcon src={stakingBubbleIcon}/>
                            <SummaryStakeCardSectionTitleCentered>Participate and help secure Flow's network by staking a node.</SummaryStakeCardSectionTitleCentered>
                            <SummaryStakeCardStartStakingLink to={`/stake-delegate/${fcl.withPrefix(currentUserAddr)}#stakedelegateoptions`}>Start Staking</SummaryStakeCardStartStakingLink>
                        </SummaryStakeCardSectionTitleSubtitleCentered>
                    </SummaryStakeCardSectionCentered>
                }

                { showStartDelegatingSection &&
                    <SummaryStakeCardSectionCentered>
                        <SummaryStakeCardSectionTitleSubtitleCentered>
                            <SummaryStakeCardSectionStakingIcon src={stakingBubbleIcon}/>
                            <SummaryStakeCardSectionTitleCentered>Participate and help secure Flow's network by delegating to a node.</SummaryStakeCardSectionTitleCentered>
                            <SummaryStakeCardStartStakingLink to={`/stake-delegate/${fcl.withPrefix(currentUserAddr)}#stakedelegateoptions`}>Start Delegating</SummaryStakeCardStartStakingLink>
                        </SummaryStakeCardSectionTitleSubtitleCentered>
                    </SummaryStakeCardSectionCentered>
                }

                { showStakedAmountSection && 
                    <>
                        <SummaryStakeCardSection>
                            <SummaryStakeCardSectionTitleSubtitle>
                                <SummaryStakeCardSectionTitle>{showDelegateSummary ? "Delegated" : "Staked"} Amount</SummaryStakeCardSectionTitle>
                                <SummaryStakeCardSectionSubitle>
                                    {stakedAmount}
                                </SummaryStakeCardSectionSubitle>
                            </SummaryStakeCardSectionTitleSubtitle>
                        </SummaryStakeCardSection>

                        <SummaryStakeCardSection>
                            <SummaryStakeCardSectionTitleSubtitle>
                                <SummaryStakeCardSectionTitle>Available Rewards</SummaryStakeCardSectionTitle>
                                <SummaryStakeCardSectionSubitle>
                                    {rewardAmount}
                                </SummaryStakeCardSectionSubitle>
                            </SummaryStakeCardSectionTitleSubtitle>
                        </SummaryStakeCardSection>
                    </>
                }

            </SummaryStakeCardRow>

            {showTotalTokensCommitted && (
                <SummaryStakeCardRowStakeInProgress>
                    <SummaryStakeCardRowInProgressLeft>
                        <SummaryStakeCardRowInProgressImg src={Clock} />
                        <SummaryStakeCardRowInProgressTitle>Total Stake Committed</SummaryStakeCardRowInProgressTitle>
                        <SummaryStakeCardRowInProgressAmount>+{totalTokensCommittedAmount}<SummaryStakeCardSectionFlow>FLOW</SummaryStakeCardSectionFlow></SummaryStakeCardRowInProgressAmount>
                    </SummaryStakeCardRowInProgressLeft>
                </SummaryStakeCardRowStakeInProgress>
            )}

            {showTotalTokensUnstaking && (
                <SummaryStakeCardRowUnstakeInProgress>
                    <SummaryStakeCardRowInProgressLeft>
                        <SummaryStakeCardRowInProgressImg src={Clock} />
                        <SummaryStakeCardRowInProgressTitle>Total Unstake In Progress</SummaryStakeCardRowInProgressTitle>
                        <SummaryStakeCardRowInProgressAmount>-{totalTokensUnstakingAmount}<SummaryStakeCardSectionFlow>FLOW</SummaryStakeCardSectionFlow></SummaryStakeCardRowInProgressAmount>
                    </SummaryStakeCardRowInProgressLeft>
                </SummaryStakeCardRowUnstakeInProgress>
            )}

            {showTotalTokensRequestedToUnstake && (
                <SummaryStakeCardRowUnstakeInProgress>
                    <SummaryStakeCardRowInProgressLeft>
                        <SummaryStakeCardRowInProgressImg src={Clock} />
                        <SummaryStakeCardRowInProgressTitle>Total Requested unstake In Progress</SummaryStakeCardRowInProgressTitle>
                        <SummaryStakeCardRowInProgressAmount>-{totalTokensRequestedToUnstakeAmount}<SummaryStakeCardSectionFlow>FLOW</SummaryStakeCardSectionFlow></SummaryStakeCardRowInProgressAmount>
                    </SummaryStakeCardRowInProgressLeft>
                </SummaryStakeCardRowUnstakeInProgress>
            )}

        </SummaryStakeCardWrapper>
    )
}