import styled from "styled-components"
import {animated} from 'react-spring'
import {screenMinWidth} from "../../mixins/breakpoints.js"

export const StyledAccountItemFullwidthWrapper = styled(animated.div)`
    margin-bottom: 1rem;
    width: 100%;
`

export const StyledAccountItemFullwidth = styled(StyledAccountItemFullwidthWrapper)`
    margin-bottom: 1rem;

    background-color: white;
    border-radius: 0.5rem;
    border: 0.1rem solid #E8E8E8;
    padding: 1rem;
    box-sizing: border-box;
`

export const StyledAccountItemHalfwidthWrapper = styled.div`
    margin-bottom: 1rem;    
    min-height: 10rem;
    width: 100%;
    min-height: 3.5rem;
    margin-right: unset;
    box-sizing: border-box;

    ${screenMinWidth.sm`
        width: calc(50% - 0.5rem);
        min-width: 12rem;
    `}
`

export const StyledAccountItemTitle = styled.span`
    position: relative;
    margin-bottom: 0.5rem;

    text-decoration: none;
    font-weight: bold;

    display: flex;
    flex-direction: row;
    align-items: center;

    &:after {
        position: absolute;
        opacity: 0.5;
        content: "";
        display: block;
        left: 0rem;
        top: 1.5rem;
        bottom: calc(1rem - 3px - 6px);
        background: #00ff76;
        height: 3px;
        width: 1rem;
        border-radius: 3px;    
    }
`

export const StyledAccountItemTitleImage = styled.img`
    margin-right: 0.5rem;
    width: 1rem;
    height: auto;
`

export const StyledAccountItemContent = styled(animated.div)`
    margin-top: 1rem;
    position: relative;
    white-space: pre-wrap;
`

export const StyledAccountItemHalfwidth = styled(StyledAccountItemHalfwidthWrapper)`
    border-radius: 0.5rem;
    padding: 1rem;
`

export const StyledAccountItemFullwidthIcon = styled.div`
    margin-bottom: 0.5rem;
`

export const StyledAccountItemFullwidthBottom = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
`

export const StyledAccountItemFullwidthTop = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
`

export const StyledAccountItemFullwidthTopButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`

export const StyledAccountItemFullwidthButton = styled.div`
    margin-left: 0.5rem;
    border: 0.1rem solid #E8E8E8;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: var(--text-primary);
    cursor: pointer;

    transition: background-color 0.2s;

    &:hover {
        background-color: #E8E8E8;
    }
`