import React, {useState, useEffect} from "react"
import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import { Transaction } from "../../transaction.js"

import { useTotalUnlockedAccountFlowBalance } from "../../../../hooks/use-total-unlocked-account-flow-balance.js"
import { useTotalFlowBalance } from "../../../../hooks/use-total-flow-balance.js"
import { useAccountStorageInfo } from "../../../../hooks/use-account-storage-info.js"
import { useTotalLockedAccountFlowBalance } from "../../../../hooks/use-total-locked-account-flow-balance.js"

import {
  UFIX64_ACCT_MIN_STORAGE_BALANCE,
  UFIX64_GAS_FEE_INCLUSION_RATE,
} from "../../../../global/constants.js"

import {
  template as stakingCollectionStakeNewTokensTemplate,
  CODE as stakingCollectionStakeNewTokensCode,
  HASH as stakingCollectionStakeNewTokensHash,
  DESCRIPTION as stakingCollectionStakeNewTokensDescription,
} from "@onflow/six-stakingcollection-stake-new-tokens"

import { TRANSACTION_MANIFEST_VALUE, TRANSACTION_MANIFEST_ARG } from "../../transaction-manifest-constructors.js"

import {
  invalidUFix64ArgError,
  storageFeeError,
  sufficientTokensError,
  gasFeeWarning,
} from "../../transaction-errors.js"

import {
  calculateAvailableFLOWTokens,
} from "../../transaction-utils.js"

import {
  validateUFix64,
  validateString,
  validateUInt32,
} from "../../transaction-validators.js"

import {
  xformUInt32,
  xformString,
  xformUFix64,
} from "../../transaction-xforms.js"

export const Manifest = ({ 
  qp,
  hash,
  txn,
  showCode,
  consent,
  qpString,
  submitTransaction,
  submitTransactionLocked,
  currentUserAddr,
  hiddenArgs,
  lockedArgs,
}) => {
  const [manifestValue, setManifestValue] = useState(new TRANSACTION_MANIFEST_VALUE({
    hash: stakingCollectionStakeNewTokensHash,
    title: "Transaction - Stake New Tokens",
    description: stakingCollectionStakeNewTokensDescription,
    code: stakingCollectionStakeNewTokensCode,
    args: {},
    isSix: true,
    transactionFunction: () => {}
  }))

  const { accountStorageInfo } = useAccountStorageInfo(currentUserAddr)
  const { totalFlowBalance } = useTotalFlowBalance(currentUserAddr)
  const { totalUnlockedAccountFlowBalance } = useTotalUnlockedAccountFlowBalance(currentUserAddr)
  const { totalLockedAccountFlowBalance } = useTotalLockedAccountFlowBalance(currentUserAddr)

  const computeManifestValue = async () => {
    setManifestValue(
      new TRANSACTION_MANIFEST_VALUE({
        hash: stakingCollectionStakeNewTokensHash,
        disclaimer: null,
        error: null,
        title: "Transaction - Stake New Tokens",
        description: stakingCollectionStakeNewTokensDescription,
        code: stakingCollectionStakeNewTokensCode,
        args: {
          0: new TRANSACTION_MANIFEST_ARG({
            title: "Node ID",
            qp: "nodeid",
            xform: xformString,
            validate: validateString,
            type: types.String,
            description: "The Node ID.",
            value: qp.get("nodeid") || "",
          }),
          1: new TRANSACTION_MANIFEST_ARG({
            title: "Delegator ID",
            description: "The Delegator ID.",
            qp: "delegatorid",
            xform: xformUInt32,
            validate: validateUInt32,
            type: types.UInt32,
            value: qp.get("delegatorid") || "",
            hidden: hiddenArgs.includes("delegatorid"),
          }),
          2: new TRANSACTION_MANIFEST_ARG({
            title: "Amount",
            qp: "amount",
            xform: xformUFix64,
            validate: validateUFix64,
            description: (
              <span>
                {"The amount of FLOW tokens to stake."}
                {(totalFlowBalance && accountStorageInfo && totalLockedAccountFlowBalance) ? (
                  <span>
                    {" You have "}
                    {totalFlowBalance}
                    {" tokens available to stake. After considering your accounts"}
                    {" storage requirements, you have "}
                    <b>
                      {calculateAvailableFLOWTokens(
                        accountStorageInfo?.useableAccountBalance || "0.0",
                        totalLockedAccountFlowBalance
                      )}
                    </b>
                    {" tokens available to stake."}
                  </span>
                ) : (
                  ""
                )}
              </span>
            ),
            value: qp.get("amount") || "",
            type: types.Array(types.String),
            warnings: [].filter((w) => w !== null),
            errors: [
              invalidUFix64ArgError(qp.get("amount")),
              await storageFeeError(currentUserAddr, qp.get("amount")),
              await sufficientTokensError(
                totalFlowBalance,
                qp.get("amount"),
                "Error: Not enough FLOW tokens available to stake. Reduce the number of tokens to stake."
              ),
            ].filter(
              (w) => w !== null
            ),
          }),
        },
        isSix: true,
        transactionFunction: async (hash, txn, args) => {
          return await fcl.decode(
            await fcl.send([
              stakingCollectionStakeNewTokensTemplate({
                proposer: fcl.currentUser().authorization,
                authorization: fcl.currentUser().authorization,
                payer: fcl.currentUser().authorization,
                nodeId: args[0].xform(args[0].value),
                delegatorId: args[1].xform(args[1].value),
                amount: args[2].xform(args[2].value),
              }),
              fcl.limit(9999),
            ])
          )
        },
      })
    )
  }

  useEffect(() => {
    computeManifestValue()
  }, [qpString, accountStorageInfo, totalFlowBalance, totalUnlockedAccountFlowBalance, totalLockedAccountFlowBalance])

  return (
    <Transaction 
      title={manifestValue?.title}
      description={manifestValue?.description}
      disclaimer={manifestValue?.disclaimer}
      error={manifestValue?.error}
      code={manifestValue?.code}
      isSix={manifestValue?.isSix}
      args={manifestValue?.args}
      transactionFunction={manifestValue?.transactionFunction}
      submitTransaction={submitTransaction}
      submitTransactionLocked={submitTransactionLocked}
      consent={consent}
      showCode={showCode}
      hash={hash}
      txn={txn}
      qp={qp}
    />
  )
}
