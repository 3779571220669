import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

const CODE = `
import LockedTokens from 0xLOCKEDTOKENADDRESS
pub fun main(address: Address): Bool {
    let account = getAccount(address)
    let lockedAccountInfoCap = account
      .getCapability
      <&LockedTokens.TokenHolder{LockedTokens.LockedAccountInfo}>
      (LockedTokens.LockedAccountInfoPublicPath)
    if lockedAccountInfoCap == nil || !(lockedAccountInfoCap!.check()) {
        return false
    }
    return true
}
`

export const doesAcctHaveLockedTokenSupport = async (address) => {
    return await fcl.decode(await fcl.send([
        fcl.script(CODE),
        fcl.args([fcl.arg(address, t.Address)])
    ]))
}
