import React, {useState, useEffect} from "react"
import styled from "styled-components"
import {Link} from "react-router-dom"
import {useHistory} from "react-router-dom"
import {Button} from "../components/button.js"

const StyleFourOhFour = styled.div`
    height: 100%;
    width: 100%;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #f3f3f3;
`

const StyleFourOhFourTitle = styled.div`
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #919191;
`

const StyleFourOhFourDescription = styled.div`

`

export const FourOhFour = () => {
    return (
        <StyleFourOhFour>
            <StyleFourOhFourTitle>Fl-Oh No! This page isn't available or doesn't exist.</StyleFourOhFourTitle>
            <StyleFourOhFourDescription><Link to="/"><Button title="Go Home" /></Link></StyleFourOhFourDescription>
        </StyleFourOhFour>
    )
}