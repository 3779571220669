import React, {useState, useEffect} from "react"
import styled from "styled-components"
import {Helmet} from "react-helmet";
import * as fcl from "@onflow/fcl"
import {useTransition, animated, config} from 'react-spring'
import {FaCopy, FaWallet} from "react-icons/fa"
import {useParams} from "react-router-dom"
import {screenMinWidth} from "../../mixins/breakpoints.js"
import logomark from "../../assets/logomark.svg"
import stakeIconGreen from "../../assets/stake-icon-green.png"
import {PageSectionDivider} from "../../components/page-section-divider.js"
import {useTotalLockedAccountFlowBalance} from "../../hooks/use-total-locked-account-flow-balance.js"
import {useUnlockedLockedAccountFlowBalance} from "../../hooks/use-unlocked-locked-account-flow-balance.js"
import {useTotalUnlockedAccountFlowBalance} from "../../hooks/use-total-unlocked-account-flow-balance.js"
import {useFUSDBalance} from "../../hooks/use-fusd-balance.js"
import {useUSDCBalance} from "../../hooks/use-usdc-balance.js"
import {useDoesAccountHaveFUSDSupport} from "../../hooks/use-does-account-have-fusd-support.js"
import {useDoesAccountHaveUSDCSupport} from "../../hooks/use-does-account-have-usdc-support.js"
import {useDoesAccountHaveLockedTokenSupport} from "../../hooks/use-does-account-have-locked-token-support.js"
import {useDoesAccountHaveLockedTokenSupportWithDapperlabsKey} from "../../hooks/use-does-account-have-locked-token-support-with-dapperlabs-key.js";
import {useAccount} from "../../hooks/use-account.js"

import { ERROR, INIT_PROCESSING } from "../../global/constants.js"
import {AccountBalances} from "./account-balances.js"
import {AccountFlowBalanceBreakdown} from "./account-flow-balance-breakdown.js"

import {useTotalFlowBalance} from "../../hooks/use-total-flow-balance.js"
import {
    StyledAccountItemFullwidth,
    StyledAccountItemTitle,
    StyledAccountItemContent,
    StyledAccountItemHalfwidth,
    StyledAccountItemFullwidthIcon,
    StyledAccountItemFullwidthTop,
} from "./account-components.js"
import { CheckNetwork } from "../checkNetwork.js";

const StyledAccountContainer = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background-color: ${props => props.isLoading ? "#f8f8f8" : "#f8f8f8300" };

    transition: background-color 0.2s;
    overflow-y: ${props => props.isError || props.isLoading ? "hidden" : "auto" };

    display: flex;
    justify-content: center;
`

const StyledLoadingAccount = styled(animated.div)`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
`

const StyledLoadingAccountError = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #919191;
    background-color: #fafafa;
    font-size: 1.5rem;
    text-align: center;
    z-index:2;
`

const StyledLoadingAccountImg = styled.img`
    @keyframes spin {
        0%   {transform: rotate(0deg);}
        25%  {transform: rotate(810deg);}
        50% {transform: rotate(700deg);}
        60% {transform: rotate(725deg);}
        65% {transform: rotate(720deg);}
        100% {transform: rotate(720deg);}
    }

    margin-bottom: 2rem;
    height: 10rem;
    width: auto;
    animation-name: spin;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: forwards;
    animation-delay: 1s;
`

const StyledAccount = styled(animated.div)`
    padding: 1rem 2rem 1rem 2rem;
    max-width: 60rem;
    width: 100%;

    display: flex;
    flex-direction: column;

    box-sizing: border-box;
`

const StyledBalancesTitle = styled.div`
    margin-bottom: 1rem;
    font-size: 3rem;
    line-height: 3.5rem;
    width: 100%;
    font-weight: bold;
    word-break: keep-all;

    transform: scale(1, 0.8);

    ${screenMinWidth.sm`
        width: 50%;
    `}
`

const StyledAccountRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
`

const StyledBalancesTop = styled(StyledAccountRow)`
    flex-wrap: wrap;
    align-items: center;
    min-height: 2.5rem;
`

const StyledBalancesAddressItem = styled(StyledAccountItemHalfwidth)`
    height: 2.5rem;
    margin-bottom: 2rem;
    padding: 0rem;
`

const StyledAccountAddressItemContent = styled(StyledAccountItemContent)`
    position: relative;
`

const StyledAccountAddress = styled(animated.div)`
    width: 100%;
    box-sizing: border-box;
    background: #eaeaea;
    border-radius: 2rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
`

const StyledAccountCopy = styled.div`
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: fit-content;
    cursor: pointer;
    background: transparent;

    transition: color 0.2s;

    svg {
        margin-right: 0.5rem;
        path {
            transition: fill 0.2s;
            fill: #3745f5;
        }
    }

    &:hover {
        color: black;
        svg {
            margin-right: 0.5rem;
            path {
                fill: black;
            }
        }
    }
`

const StyledFaCopy = styled(FaCopy)`
    margin-left: 0.5rem;
    cursor: pointer;
`

const StyledAccountWalletActionLink = styled.a`
    border: 0.1rem solid #E8E8E8;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: var(--text-primary);
    cursor: pointer;
    text-decoration: none;

    transition: background-color 0.2s;

    &:hover {
        background-color: #E8E8E8;
    }
`

export const Balances = ({ location }) => {
    const { address } = useParams()

    const [currentUserAddr, setCurrentUserAddr] = useState(null)
    const [currentUser, setCurrentUser] = useState(null)
    const [isCopying, setIsCopying] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const { account, status: accountStatus } = useAccount(address)
    const { unlockedLockedAccountFlowBalance, status: unlockedLockedAccountFlowBalanceStatus } = useUnlockedLockedAccountFlowBalance(address)
    const { totalUnlockedAccountFlowBalance, status: totalUnlockedAccountFlowBalanceStatus } = useTotalUnlockedAccountFlowBalance(address)
    const { totalLockedAccountFlowBalance, status: totalLockedAccountFlowBalanceStatus } = useTotalLockedAccountFlowBalance(address)
    const { totalFlowBalance, status: totalFlowBalanceStatus } = useTotalFlowBalance(address)
    const { fusdBalance, status: fusdBalanceStatus } = useFUSDBalance(address)
    const { usdcBalance, status: usdcBalanceStatus } = useUSDCBalance(address)
    const { doesAccountHaveFUSDSupport, status: doesAccountHaveFUSDSupportStatus } = useDoesAccountHaveFUSDSupport(address)
    const { doesAccountHaveUSDCSupport, status: doesAccountHaveUSDCSupportStatus } = useDoesAccountHaveUSDCSupport(address)
    const { doesAccountHaveLockedTokenSupport, status: doesAccountHaveLockedTokenSupportStatus } = useDoesAccountHaveLockedTokenSupport(address)
    const { doesAccountHaveLockedTokenSupportWithDapperlabsKey, status: doesAccountHaveLockedTokenSupportStatusWithDapperlabsKeyStatus } = useDoesAccountHaveLockedTokenSupportWithDapperlabsKey(address)

    const qp = new URLSearchParams(location.search)
    const showBalanceBreakdown = qp.get("showbalancebreakdown") ? (qp.get("showbalancebreakdown") === "false" ? false : true) : true

    const isCurrentUsersAccount = address && currentUserAddr && account && fcl.withPrefix(account.address) === currentUserAddr

    const showLedgerWalletActions = currentUser?.services[0]?.uid === "fcl-ledger-authz"

    useEffect(() => {
        setIsLoading(unlockedLockedAccountFlowBalanceStatus === INIT_PROCESSING || accountStatus === INIT_PROCESSING || totalUnlockedAccountFlowBalanceStatus === INIT_PROCESSING || totalLockedAccountFlowBalanceStatus === INIT_PROCESSING || totalFlowBalanceStatus === INIT_PROCESSING || fusdBalanceStatus === INIT_PROCESSING || doesAccountHaveFUSDSupportStatus === INIT_PROCESSING || doesAccountHaveLockedTokenSupportStatus === INIT_PROCESSING || doesAccountHaveLockedTokenSupportStatusWithDapperlabsKeyStatus === INIT_PROCESSING || usdcBalanceStatus === INIT_PROCESSING || doesAccountHaveUSDCSupportStatus === INIT_PROCESSING)
        setIsError(unlockedLockedAccountFlowBalanceStatus === ERROR || accountStatus === ERROR || totalUnlockedAccountFlowBalanceStatus === ERROR || totalLockedAccountFlowBalanceStatus === ERROR || totalFlowBalanceStatus === ERROR || fusdBalanceStatus === ERROR || doesAccountHaveFUSDSupportStatus === ERROR|| doesAccountHaveLockedTokenSupportStatus === ERROR || doesAccountHaveLockedTokenSupportStatusWithDapperlabsKeyStatus === ERROR || usdcBalanceStatus === ERROR || doesAccountHaveUSDCSupportStatus === ERROR)
    }, [unlockedLockedAccountFlowBalanceStatus, totalUnlockedAccountFlowBalanceStatus, accountStatus, totalLockedAccountFlowBalanceStatus, totalFlowBalanceStatus, fusdBalanceStatus, doesAccountHaveFUSDSupportStatus, doesAccountHaveLockedTokenSupportStatus, doesAccountHaveLockedTokenSupportStatusWithDapperlabsKeyStatus, usdcBalanceStatus, doesAccountHaveUSDCSupportStatus ])

    useEffect(() => {
        return fcl.currentUser().subscribe(async currentUser => {
            setCurrentUser(currentUser)
            setCurrentUserAddr(currentUser.addr ? fcl.withPrefix(currentUser.addr) : null)
        })
    }, [])

    const handleCopyAddress = () => {
        if (account) {
            const textField = document.createElement('textarea')
            textField.innerText = fcl.withPrefix(account.address)
            document.body.appendChild(textField)
            textField.select()
            document.execCommand('copy')
            textField.remove()
        }
        setIsCopying(true)
        setTimeout(() => setIsCopying(false), 1500)
    }

    const copyTransitions = useTransition(isCopying, null, {
        from: { 
            position: 'absolute', opacity: 0
        },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.default,
    })

    const loadingTransitions = useTransition(isLoading, null, {
        from: { 
            position: 'absolute', opacity: 0, width: "100%"
        },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.default,
    })

    const balanceBreakdownTransitions = useTransition(showBalanceBreakdown, null, {
        from: {  
            opacity: 0, width: "100%"
        },
        enter: { opacity: 1, display: "block" },
        leave: { opacity: 0, display: "none" },
        config: config.default,
    })

    return (
        <StyledAccountContainer isLoading={isLoading} isError={isError}>
            <Helmet><title>Flow Port | Dashboard</title></Helmet>
            { isError && <CheckNetwork /> }
            { !isError &&
                loadingTransitions.map(({ item, key, props }) => 
                    item ? <StyledLoadingAccount style={props} key={key}><StyledLoadingAccountImg src={logomark}/>Loading...</StyledLoadingAccount> 
                    : (
                    <StyledAccount style={props} key={key}>
                        { 
                            isCurrentUsersAccount && (
                                <StyledBalancesTop>
                                    <StyledBalancesTitle>Balances</StyledBalancesTitle>
                                    <StyledBalancesAddressItem>
                                        <StyledAccountAddressItemContent>
                                            { copyTransitions.map(({ item, key, props }) => 
                                                item ? (<StyledAccountAddress style={props} key={key}>Copied to Clipboard</StyledAccountAddress>)
                                                : (<StyledAccountAddress style={props} key={key}>Your Address: <b>{account && fcl.withPrefix(account.address)}</b></StyledAccountAddress>)
                                            )}
                                            <StyledAccountCopy onClick={handleCopyAddress}><StyledFaCopy /></StyledAccountCopy>
                                        </StyledAccountAddressItemContent>
                                    </StyledBalancesAddressItem>
                                </StyledBalancesTop>
                            )
                        }
                        <StyledBalancesTop>
                            <AccountBalances 
                                address={address}
                                currentUser={currentUser} 
                                totalFlowBalance={totalFlowBalance}
                                fusdBalance={fusdBalance}
                                usdcBalance={usdcBalance}
                                doesAccountHaveFUSDSupport={doesAccountHaveFUSDSupport}
                                doesAccountHaveUSDCSupport={doesAccountHaveUSDCSupport}
                                doesAccountHaveLockedTokenSupport={doesAccountHaveLockedTokenSupport}
                                doesAccountHaveLockedTokenSupportWithDapperlabsKey={doesAccountHaveLockedTokenSupportWithDapperlabsKey}
                                showBalanceBreakdown={showBalanceBreakdown}
                            />
                        </StyledBalancesTop>

                        { doesAccountHaveLockedTokenSupport && balanceBreakdownTransitions.map(({ item, key, props }) => 
                            item ? (
                                <StyledAccountItemFullwidth key={key} style={props}>
                                    <StyledAccountItemFullwidthTop>
                                        <StyledAccountItemTitle>FLOW Balance Breakdown</StyledAccountItemTitle>
                                    </StyledAccountItemFullwidthTop>                            
                                    <StyledAccountItemContent style={{marginTop: "0rem"}}>
                                        <AccountFlowBalanceBreakdown 
                                            address={address}
                                            unlockedLockedAccountFlowBalance={unlockedLockedAccountFlowBalance}
                                            totalUnlockedAccountFlowBalance={totalUnlockedAccountFlowBalance}
                                            totalLockedAccountFlowBalance={totalLockedAccountFlowBalance}
                                            totalFlowBalance={totalFlowBalance}
                                            isCurrentUsersAccount={isCurrentUsersAccount}
                                        />
                                    </StyledAccountItemContent>
                                </StyledAccountItemFullwidth>
                            )
                            : <animated.div key={key} style={props} />
                        )}

                        { showLedgerWalletActions && <PageSectionDivider title="Wallet Actions" icon={stakeIconGreen} />}

                        { showLedgerWalletActions && (
                            <StyledAccountItemFullwidth>
                                <StyledAccountItemFullwidthIcon><FaWallet /></StyledAccountItemFullwidthIcon>
                                <StyledAccountItemFullwidthTop>
                                    <StyledAccountItemTitle>Ledger Wallet Actions</StyledAccountItemTitle>
                                </StyledAccountItemFullwidthTop>  
                                <StyledAccountItemContent>
                                    <StyledAccountWalletActionLink href="https://fcl-ledger.onflow.org/showkey" target="_blank">View Public Key on Device</StyledAccountWalletActionLink>
                                </StyledAccountItemContent>
                            </StyledAccountItemFullwidth>
                        )}
                        
                    </StyledAccount>
                ))
            }
        </StyledAccountContainer>
    )
}