import React, {useState} from "react"
import styled from "styled-components"
import {useLocation} from "react-router-dom"
import {screenMinWidth} from "../mixins/breakpoints.js"
import {Header} from "./header.js"
import {Sidebar} from "./sidebar.js"

const StyledAppStructure = styled.div`
    height: 100vh;  
    width: 100vw;
    display: grid;
    grid-template-columns: ${({isSidebarOpen, showHeaderOrSidebar}) => showHeaderOrSidebar ? isSidebarOpen ? "100vw 0vw" : "0vw 100vw" : "0vw 100vw"};
    grid-template-rows: ${({showHeaderOrSidebar}) => showHeaderOrSidebar ? "100px" : "0px"} auto;
    grid-template-areas: 
      "sidebar header"
      "sidebar main";

    overflow-x: hidden;

    transition: grid-template-columns 0.2s;

    ${screenMinWidth.sm`
        grid-template-columns: ${({showHeaderOrSidebar}) => showHeaderOrSidebar ? "230px calc(100vw - 230px)" : "0vw 100vw"};
    `}
`

const StyledHeaderStructure = styled.section`
    grid-area: header;
    z-index: 1;
    background-color: #f8f8f8;
`

const StyledSidebarStructure = styled.section`
    grid-area: sidebar;
    max-height: 100vh;
    position: sticky;
    top: 0;
    overflow: hidden;
`

const StyledMainAreaStructure = styled.section`
    grid-area: main;
    background-color: #f8f8f8;
`

export const AppStructure = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const location = useLocation()
    const fullLocation = location.pathname + location.search
    const showHeaderOrSidebar = !(fullLocation.includes("/stake-delegate-wizard"))

    return (
        <StyledAppStructure showHeaderOrSidebar={showHeaderOrSidebar} isSidebarOpen={isSidebarOpen}>
            { showHeaderOrSidebar && <StyledHeaderStructure><Header setIsSidebarOpen={setIsSidebarOpen} isSidebarOpen={isSidebarOpen} /></StyledHeaderStructure> }
            { showHeaderOrSidebar && <StyledSidebarStructure><Sidebar setIsSidebarOpen={setIsSidebarOpen} /></StyledSidebarStructure> }
            <StyledMainAreaStructure>{ children }</StyledMainAreaStructure>
        </StyledAppStructure>
    )
}