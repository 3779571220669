import React from "react"
import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import styled from "styled-components"
import { doesAmountReduceAcctBelowMinForStorageFees } from "../../interactions/does-amount-reduce-account-below-min-for-storage-fees.js"
import { doesAmountReduceAcctBelowMinForTxnFees } from "../../interactions/does-amount-reduce-account-below-min-for-txn-fees.js"
import { doesAcctHaveFUSDSupport } from "../../interactions/does-acct-have-fusd-support.js"
import { doesAcctHaveUSDCSupport } from "../../interactions/does-acct-have-usdc-support.js"
import { xformUFix64 } from "./transaction-xforms.js"
import {
    UFIX64_ACCT_MIN_STORAGE_BALANCE,
    UFIX64_GAS_FEE_INCLUSION_RATE,
} from "../../global/constants.js"

const StyledTransactionIssueLink = styled.a`
    color: ${props => props.color || "var(--text-primary)"};
    text-decoration: none;
    cursor: pointer;

    b {
        color: var(--text-primary);
    }
`

export const argumentExistsError = (doesExist = true, error) => 
    doesExist ?
        null
        :
        <span>{ error ? error : "Error: Argument must be defined." }</span>


export const gasFeeWarning = async (currentUserAddr, amount, lockedBalanceToUse, gasFeeEstimate = UFIX64_GAS_FEE_INCLUSION_RATE, reserveAmount = "0.0") => {
    if (!amount || !currentUserAddr) return null

    let gasFeeWarning = false;
    try {
        let amt = xformUFix64(amount)
        let lockedAmtToUse = lockedBalanceToUse ? xformUFix64(lockedBalanceToUse) : null
        let reserveAmt = reserveAmount ? xformUFix64(reserveAmount) : null
        gasFeeWarning = await doesAmountReduceAcctBelowMinForTxnFees(currentUserAddr, amt, lockedAmtToUse, xformUFix64(gasFeeEstimate), reserveAmt)
    } catch (e) {
        console.error("Error computing gasFeeWarning: ", e)
    }

    return gasFeeWarning
        ?
        <span>Warning: The amount input may cause this transaction to fail because it may reduce the amount of FLOW in your account below whats needed to pay for this transactions gas fees. <StyledTransactionIssueLink href="https://docs.onflow.org" target="_black">Learn more about gas fees <b>here.</b></StyledTransactionIssueLink></span>
        : 
        null
}

export const storageFeeError = async (currentUserAddr, amount, lockedBalanceToUse, reserveAmount = UFIX64_GAS_FEE_INCLUSION_RATE) => {
    if (!currentUserAddr || !amount) return null
    
    let storageFeeError = false;
    try {
        let amt = xformUFix64(amount)
        let lockedAmtToUse = lockedBalanceToUse ? xformUFix64(lockedBalanceToUse) : null
        let reserveAmt = reserveAmount ? xformUFix64(reserveAmount) : null
        storageFeeError = await doesAmountReduceAcctBelowMinForStorageFees(currentUserAddr, amt, lockedAmtToUse, reserveAmt)
    } catch (e) {
        console.error("Error computing storageFeeError: ", e)
    }
    
    return storageFeeError
        ?
        <span>Error: The amount input may cause this transaction to fail because it may reduce the amount of FLOW in your account below whats needed to pay for your accounts storage usage. Reduce the amount specified. <StyledTransactionIssueLink href="https://docs.onflow.org" color="white" target="_black">Learn more about account storage <b>here.</b></StyledTransactionIssueLink></span> 
        : 
        null
}

export const sufficientTokensError = async (balance, amount, error) => {
    if (!balance || !amount) return null

    return balance && Number(balance) - Number(amount) < 0 ?
        <span>{ error ? error : "Error: Not enough FLOW tokens. Reduce the amount of tokens."}</span>
        : 
        null
}

export const invalidAccountError = async (toAddress) => {
    if (!toAddress) return null

    try {
        await fcl.account(toAddress)
    } catch (e) {
        return <span>Error: Invalid account address.</span>
    }

    return null
}

export const invalidUFix64ArgError = (arg) => {
    if (!arg) return null

    if (typeof arg !== "string") return <span>Argument must be a expressed as a string.</span>

    if (arg.match(/[^0-9.]/)) return <span>Must only contain numbers.</span>

    const parts = arg.split(".")

    if (parts.length === 1) return null
    if (parts.length > 2) return <span>Cannot have multiple decimals.</span>
    
    if (parts[1].length === 0) return <span>At least one digit after the decimal is required.</span>

    if (parts[1].length > 8) return <span>Cannot have more than 8 digits after the decimal place.</span>

    return null
}

export const accountFUSDConfiguredError = async (toAddress) => {
    if (!toAddress) return null

    const errorMessage = <span>Error: Account to send FUSD to is not configured to receive FUSD.</span>
    try {
        return await doesAcctHaveFUSDSupport(toAddress) ? null : errorMessage
    } catch (e) {
        return errorMessage
    }
}

export const accountUSDCConfiguredError = async (toAddress) => {
    if (!toAddress) return null

    const errorMessage = <span>Error: Account to send USDC to is not configured to receive USDC.</span>
    try {
        return await doesAcctHaveUSDCSupport(toAddress) ? null : errorMessage
    } catch (e) {
        return errorMessage
    }
}

export const validKeyWeightError = (keyWeight) => {
    if (!keyWeight) return null

    if (!Number.isInteger(Number(keyWeight))) return <span>Must only contain numbers</span>

    if ((Number(keyWeight) - 1000) > 0) return <span>Must be a weight between 0 and 1000</span>
    if (Number(keyWeight) < 0) return <span>Must be a weight between 0 and 1000</span>

    return null
}