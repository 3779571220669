const validateNotEmptyString = (v) => v != ""

const validateOnlyNumbers = (v) => !(v.match(/[^0-9]/))

export const validateAddress = (v) => validateNotEmptyString(v) && v.charAt(0) === "0" && v.charAt(1) === "x"

export const validateString = validateNotEmptyString

export const validateUFix64 = (v) => validateNotEmptyString(v) && !(v.match(/[^0-9.]/))

export const validateUInt64 = (v) => validateNotEmptyString(v) && validateOnlyNumbers(v)

export const validateUInt32 = (v) => validateNotEmptyString(v) && validateOnlyNumbers(v)

export const validateUInt8 = (v) => validateNotEmptyString(v) && validateOnlyNumbers(v)

export const validateArrayOfStrings = (v) => validateNotEmptyString(v) && validateOnlyNumbers(v)