import * as fcl from "@onflow/fcl"

const CODE = `  
import FlowIDTableStaking from 0xIDENTITYTABLEADDRESS

pub fun main(): Bool {
    return FlowIDTableStaking.stakingEnabled()
}`

export const getIsStakingEnabled = async () => {
    return await fcl.decode(await fcl.send([
        fcl.script(CODE),
    ]))
}
