import React, {useState, useEffect} from "react"
import * as fcl from "@onflow/fcl"
import * as types from "@onflow/types"
import { Transaction } from "../transaction.js"

import { useTotalUnlockedAccountFlowBalance } from "../../../hooks/use-total-unlocked-account-flow-balance.js"
import { useAccountStorageInfo } from "../../../hooks/use-account-storage-info.js"

import {
  UFIX64_ACCT_MIN_STORAGE_BALANCE,
  UFIX64_GAS_FEE_INCLUSION_RATE,
} from "../../../global/constants.js"

import {
  template as transferFlowTokenTemplate,
  CODE as sendFlowTokenCode,
  HASH as sendFlowTokenHash,
  DESCRIPTION as sendFlowTokenDescription,
} from "@onflow/six-transfer-tokens"

import { TRANSACTION_MANIFEST_VALUE, TRANSACTION_MANIFEST_ARG} from "../transaction-manifest-constructors.js"

import {
  invalidAccountError,
  invalidUFix64ArgError,
  storageFeeError,
  sufficientTokensError,
  gasFeeWarning,
} from "../transaction-errors.js"

import {
  calculateAvailableFLOWTokens,
} from "../transaction-utils.js"

import {
  validateAddress,
  validateUFix64
} from "../transaction-validators.js"

import {
  xformAddress,
  xformUFix64
} from "../transaction-xforms.js"

export const Manifest = ({ 
  qp,
  hash,
  txn,
  showCode,
  consent,
  qpString,
  currentUserAddr,
  submitTransaction,
  submitTransactionLocked,
  hiddenArgs,
  lockedArgs,
}) => {
  const [manifestValue, setManifestValue] = useState(new TRANSACTION_MANIFEST_VALUE({
    hash: sendFlowTokenHash,
    title: "Transaction - Transfer Flow Tokens",
    description: sendFlowTokenDescription,
    code: sendFlowTokenCode,
    args: {},
    isSix: true,
    transactionFunction: () => {}
  }))

  const { accountStorageInfo } = useAccountStorageInfo(currentUserAddr)
  const { totalUnlockedAccountFlowBalance } = useTotalUnlockedAccountFlowBalance(currentUserAddr)

  const computeManifestValue = async () => {
    setManifestValue(
      new TRANSACTION_MANIFEST_VALUE({
        hash: sendFlowTokenHash,
        disclaimer: null,
        error: null,
        title: "Transaction - Transfer Flow Tokens",
        description: sendFlowTokenDescription,
        code: sendFlowTokenCode,
        args: {
          0: new TRANSACTION_MANIFEST_ARG({
            title: "To",
            qp: "to",
            validate: validateAddress,
            xform: xformAddress,
            placeholder: "0xABC123DEF456",
            description: "The Flow account the tokens will go to.",
            type: types.Address,
            value: qp.get("to") || "",
            errors: [await invalidAccountError(qp.get("to"))].filter(
              (w) => w !== null
            ),
          }),
          1: new TRANSACTION_MANIFEST_ARG({
            title: "Amount",
            qp: "amount",
            validate: validateUFix64,
            xform: xformUFix64,
            description: (
              <span>
                {"The amount of FLOW tokens to send."}
                {totalUnlockedAccountFlowBalance ? (
                  <span>
                    {" You have "}
                    {totalUnlockedAccountFlowBalance}
                    {" unlocked tokens to send. After considering your accounts storage "}
                    {"requirements, you have "}
                    <b>
                      {calculateAvailableFLOWTokens(
                        accountStorageInfo?.useableAccountBalance,
                      )}
                    </b>
                    {" tokens available to send."}
                  </span>
                ) : (
                  ""
                )}
              </span>
            ),
            placeholder: "1.00",
            type: types.UFix64,
            value: qp.get("amount") || "",
            warnings: [
              await gasFeeWarning(currentUserAddr, qp.get("amount"), "0.0"),
            ].filter((w) => w !== null),
            errors: [
              invalidUFix64ArgError(qp.get("amount")),
              await storageFeeError(currentUserAddr, qp.get("amount"), "0.0"),
              await sufficientTokensError(
                totalUnlockedAccountFlowBalance,
                qp.get("amount"),
                "Error: Not enough FLOW tokens available to send. Reduce the number of tokens to send."
              ),
            ].filter((w) => w !== null),
          }),
        },
        isSix: true,
        transactionFunction: async (hash, txn, args) => {
          return await fcl.decode(
            await fcl.send([
              transferFlowTokenTemplate({
                proposer: fcl.currentUser().authorization,
                authorization: fcl.currentUser().authorization,
                payer: fcl.currentUser().authorization,
                amount: args[1].xform(args[1].value),
                to: args[0].xform(args[0].value),
              }),
              fcl.limit(9999),
            ])
          )
        },
      })
    )
  }

  useEffect(() => {
    computeManifestValue()
  }, [qpString, accountStorageInfo, totalUnlockedAccountFlowBalance])

  return (
    <Transaction 
      title={manifestValue?.title}
      description={manifestValue?.description}
      disclaimer={manifestValue?.disclaimer}
      error={manifestValue?.error}
      code={manifestValue?.code}
      isSix={manifestValue?.isSix}
      args={manifestValue?.args}
      transactionFunction={manifestValue?.transactionFunction}
      submitTransaction={submitTransaction}
      submitTransactionLocked={submitTransactionLocked}
      consent={consent}
      showCode={showCode}
      hash={hash}
      txn={txn}
      qp={qp}
    />
  )
}
