import React from "react"
import styled from "styled-components"
import {FaHammer, FaWrench} from "react-icons/fa"
import { LogoWithNetwork } from "../components/Logo"

const StyledMaintenance = styled.div`
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    color: black;
    background-color: #f3f3f3;
    text-align: center;
`

const StyledMaintenanceTitle = styled.div`
    width: 100%;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: black;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const StyledMaintenanceTitleIconRight = styled(FaHammer)`
    margin-left: 1rem;
    height: 3rem;
    width: auto;
    color: black;
`

const StyledMaintenanceTitleIconLeft = styled(FaWrench)`
    margin-right: 1rem;
    height: 3rem;
    width: auto;
    color: black;
`

const StyleMaintenanceSubtitle = styled.div`
    width: 100%;
    color: black;
`

export const Maintenance = () => {
    return (
        <StyledMaintenance>
            <LogoWithNetwork />
            <StyledMaintenanceTitle><StyledMaintenanceTitleIconLeft />Flow is currently undergoing scheduled maintenance. <StyledMaintenanceTitleIconRight /></StyledMaintenanceTitle>
            <StyleMaintenanceSubtitle> Please try again later, or check out our <a href="https://onflow.org/discord" target="_blank">discord</a> for updates.</StyleMaintenanceSubtitle>
        </StyledMaintenance>
    )
}